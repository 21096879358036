<template>
    <div>
        <MasterLayout>
            <div class="employees__assessment__wrapper">
                <div class="welcome_message">
                    <div class="row">
                        <div class="col-7">    
                            <h5 class=''><span class="greeeting__text"> Hi Admin</span>
                                <!--  you have <span class="branches__count"> {{employees.length}} </span> Employees -->
                            </h5>
                        </div>
                        <div class="col-5 text-right mb-3">                    
                            <div class="search_filter"  style="float: left; width: calc(100% - 105px);">
                                <basic-select
                                    :options="organogramList"
                                    :selected-option="selectedUpline"
                                    placeholder="Select an Employee to report to"
                                    @select="onSelect">
                                </basic-select>
                            </div>
                            <div class="" style="float: right;">
                                <button class="creaet_branch_btn" v-if="levels.length <= 0" @click="saveSetup()" style="margin: 0 0 5px 0;"
                                    :disabled="isButtonDisabled ? true : null"
                                    :style="[
                                    isButtonDisabled
                                        ? { cursor: 'not-allowed' }
                                        : { cursor: 'pointer' }
                                    ]">
                                    <span class="">
                                        Save
                                        <i class="fa fa-save" v-if="!showLoader"></i>
                                        <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="16px"/>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <section class="table__section mt-1 pb-5" v-show="organogramList.length > 0 && !showLoader">
                    <ejs-grid
                        ref="dataGrid"
                        :created="refreshGrid"
                        :allowPaging="true"
                        :allowSorting="true"
                        :pageSettings="pageSettings"
                        :toolbar="toolbar"
                        :searchSettings="search"
                        :allowExcelExport="true"
                        :allowPdfExport="true"
                        :toolbarClick="toolbarClick">
                        <e-columns>
                            <e-column headerText="" :template="checkBoxTemplate" width="50px"></e-column>
                            <e-column field="index" headerText="#" width="60px"></e-column>
                            <e-column field="fullName" headerText="Full Name" width="350px"></e-column>
                            <e-column field="empOtherInfo" headerText="Other Info" width="350px"></e-column>
                            <e-column field="reportTo" headerText="Report To"></e-column>
                            <!-- <e-column field="gradeLevel" width="80px" headerText="Grade"></e-column>
                            <e-column field="employmentDateString" headerText="Employment Date" width="135px"></e-column>
                            <e-column field="phoneNumber" headerText="Phone" :visible="false" width="120px"></e-column>
                            <e-column field="email" headerText="Email" width="150px"></e-column> -->
                            <!-- <e-column :template="deletedTemplate" width="150px" headerText="Status"></e-column> -->
                            <e-column width="10px" headerText=""></e-column>
                        </e-columns>
                    </ejs-grid>
                </section>
                <div class="empty_branch__div mt-3" v-if="organogramList.length < 1 && !showLoader">
                    <div class="text-center">
                        <h6 class="text-center">Organogram not created Yet!</h6>
                        <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                    </div>
                </div>

                <TableLoader v-show="showLoader"></TableLoader>
            </div>
        </MasterLayout>
    </div>
</template>

<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import UserDP from "@/assets/img/avatar.svg";
import TableLoader from '@/components/TableLoading.vue';
import configObject from "@/config";
import JQuery from "jquery";
import moment from "moment";
import { BasicSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
let $ = JQuery;

import {
    Page,
    Sort,
    Toolbar,
    Search,
    ExcelExport,
    PdfExport,
    
} from "@syncfusion/ej2-vue-grids";

export default {
    title: 'Organogram',
    components: {
        MasterLayout,
        BasicSelect,
        TableLoader
    },
    provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            UserDP,
            parentId: null,
            showLoader: false,
            isButtonDisabled: false,
            levels: [],
            organogramList: [],
            allowFiltering: true,
            organogramList1: [
                {
                    id: 1,
                    "index": 1,
                    "fullName": "Ojomu Gabriel (gabe-oj@mailinator.com)",
                    "empOtherInfo": "Director",
                    "reportTo": "",
                    "display": "Ojomu Gabriel (Director)"
                },
                {
                    "id": 3,
                    "index": 2,
                    "fullName": "Lamar Kendric (lamar_ken@mailinator.com)",
                    "empOtherInfo": "Deputy Director :: E & S",
                    "reportTo": "Ojomu Gabriel (Director)",
                    display: "Lamar Kendric (Deputy Director :: E & S)"
                },
                {
                    "id": 4,
                    "index": 3,
                    "fullName": "Lewis Akinolu (akin-lewis@dpr.gov.ng)",
                    "empOtherInfo": "Assistant Deputy Director :: E & S",
                    "reportTo": "Lamar Kendric (Deputy Director)",
                    display: "Lewis Akinolu (Assistant Deputy Director :: E & S)"
                }
            ],
            selectedUpline: { value: '', text: ''},
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-success" title="Map Competencies" v-on:click="_mapCompetencies(data.id)">' +
                                    '<i class="fa fa-refresh" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-info" title="View" v-on:click="_viewEmployee(data.id)">' +
                                '<i class="fa fa-eye" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-primary" title="Edit" v-on:click="_editEmployee(data.id)">' +
                                '<i class="fa fa-edit" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-danger" title="Delete" v-on:click="_deleteEmployee(data.id)" v-if="data.deleted === false">' +
                                '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-success" title="Re-Add" v-on:click="_deleteEmployee(data.id)" v-if="data.deleted === true">' +
                                '<i class="fa fa-plus" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _deleteEmployee: function(id) {
                                this.$eventHub.$emit("deleteEmployeeVM", id);
                            },
                            _editEmployee: function(id) {
                                this.$eventHub.$emit("editEmployeeVM", id);
                            },
                            _viewEmployee: function(id){
                                this.$eventHub.$emit("viewEmployeeVM", id);
                            },
                            _mapCompetencies: function(employeeId) {
                                this.$eventHub.$emit("mapCompetenciesVM", employeeId);
                            },
                        }
                    })
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>` +
                            `<div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            },
            checkBoxTemplate: function() {
                return {
                    template: Vue.component("columnTemplate", {
                        template: `<div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="data.selected" v-on:change="selectItem($event, data.id)" checked />
                                        <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="!data.selected" v-on:change="selectItem($event, data.id)" />
                                        <label class="custom-control-label" v-bind:for="data.id"></label>
                                    </div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            selectItem: function(e, id) {
                                this.$eventHub.$emit("_itemSelected", e, id);
                            }
                        }
                    })
                };
            },
        }
    },
    mounted() {
        this.getOrganogram();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created() {
        this.$eventHub.$on("_refreshOrganogram", (id) => {
            this.getOrganogram(id);
        });
        this.$eventHub.$on("refreshOrganogram", () => {
            this.getOrganogram();
        });
        this.$eventHub.$on("_itemSelected", (event, id) => {
            this.itemSelected(event, id);
        });
    },
    methods: {
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.dataGrid.getColumns()[6].visible = true;
                    this.$refs.dataGrid.getColumns()[8].visible = false;
                    this.$refs.dataGrid.pdfExport({
                        pageOrientation: 'Landscape'
                    });
                    this.$refs.dataGrid.pdfExport({
                        pageOrientation: 'Landscape',
                        fileName: 'Employees.pdf',
                        theme: {
                            header: {
                                bold: true,
                                fontColor: "#000000",
                                fontName: 'Calibri',
                                fontSize: 10
                            },
                            record: {
                                fontColor: '#000000',
                                fontName: "Calibri",
                                fontSize: 8
                            }
                        },
                        header: {
                            fromTop:0,
                            height: 130,
                            contents:  [{
                                type: "Text",
                                value:  'Employees',
                                position: {x:0, y: 50},
                                style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],                        
                        }
                    });
                    break;                
                case "Excel Export":
                    this.$refs.dataGrid.getColumns()[6].visible = true;
                    this.$refs.dataGrid.getColumns()[8].visible = false;
                    this.$refs.dataGrid.excelExport({
                        fileName: 'Employees.xlsx',
                        theme: {
                            header: {
                                bold: true,
                                fontColor: "#000000",
                                fontName: 'Calibri',
                                fontSize: 10
                            },
                            record: {
                                fontColor: '#000000',
                                fontName: "Calibri",
                                fontSize: 8
                            }
                        },
                        header: {
                            fromTop:0,
                            height: 130,
                            contents:  [{
                                type: "Text",
                                value:  `Employees`,
                                position: {x:0, y: 50},
                                style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                                type: "Text",
                                value: "Thank You",
                                position: {x:0, y: 50},
                                style: {fontSize: 20}
                            }],                        
                        }
                    });
                    break;
            }
            this.$refs.dataGrid.getColumns()[6].visible = false;
            this.$refs.dataGrid.getColumns()[8].visible = true;
        },
        itemSelected(event, id){
            let emp = this.organogramList.findIndex(a => a.id === id);
            console.log(emp);
            if(emp >= 0){
                if (event.target.checked){
                    // Selected
                    console.log('Checked: ' + emp + ' >> ' + this.organogramList[emp].selected)
                    this.organogramList[emp].selected = true;
                    console.log('Checked: ' + emp + ' >> ' + this.organogramList[emp].selected)
                    this.refreshGrid();
                }
                else{
                    // Unselected
                    console.log('Unchecked: ' + emp)
                    this.organogramList[emp].selected = false;
                    this.refreshGrid();
                }
            }
        },
        selectOption () {
            // select option from parent component
            this.selectedUpline = { value: '', text: ''};// this.organogramList[0]
        },
        onSelect (item) {
            this.selectedUpline = item
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        getOrganogram() {
            this.showLoader = true;
            let grid = this.$refs.dataGrid;
            this.axios.get(`${configObject.apiBaseUrl}/Organogram`, configObject.authConfig)
            .then(response => {
                this.showLoader = false;
                this.organogramList = response.data
                let index = 0;
                this.organogramList.forEach(a => {
                    a.value = a.id, 
                    a.text = `${a.firstname} ${a.surname} (${a.designationName} :: ${a.divisionName})` // Lewis Akinolu (Assistant Deputy Director :: E & S)
                    index += 1;
                    a.index = index
                    a.fullName = `${a.firstname} ${a.surname} (${a.email})`;
                    a.empOtherInfo = a.designationName + ' :: ' + a.divisionName,
                    a.reportTo = a.upline ? a.upline.firstname + ' ' + a.upline.surname + ' (' + a.upline.designationName + ')' : ''

                });
                grid.ej2Instances.setProperties({ dataSource: this.organogramList });
                this.refreshGrid();
            })
            .catch((error) => {
                this.showLoader = false;
                console.log(error);
                // this.$toast.open({
                //     message: "unable to load employees",
                //     type: "error",
                //     duration: 3000
                // });
            });
        },
        saveSetup(){
            this.showLoader = true;
            this.isButtonDisabled = true;
            if(!this.selectedUpline.value || parseInt(this.selectedUpline.value) <= 0){
                this.$toast.open({
                    message: "Please select an Upline Employee to Report to",
                    type: "error",
                    duration: 5000
                });
                this.showLoader = false;
                this.isButtonDisabled = false;
                return;
            }

            let dataArray = [];
            this.organogramList.forEach(a => {
                if(a.selected){
                    dataArray.push(a.id)
                }
            });
            if(dataArray.length < 1){
                this.$toast.open({
                    message: "Please select atleast one competency (and its level) before proceeding",
                    type: "error",
                    duration: 4000
                });
                this.showLoader = false;
                this.isButtonDisabled = false;
                return;
            }
            let dataToSend = {
                supervisorId: this.selectedUpline.value,
                superviseesId: dataArray
            };
            console.log(dataToSend)
            this.axios.post(`${configObject.apiBaseUrl}/Employee/AddOrganogram`, dataToSend, configObject.authConfig)
            .then(response => {
                this.showLoader = false;
                this.isButtonDisabled = false;
                this.$toast.open({
                    message: "Employee upline set successfully",
                    type: "success",
                    duration: 4000
                });
                this.getOrganogram()
                this.selectOption()
            })
            .catch((error) => {
                console.log(error);
                this.$toasted.show("Unable to complete your request", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 5000
                });
                this.isButtonDisabled = false;
                this.showLoader = false;
            });
        }
    }
}
</script>

<style scoped>
    .ui.selection.dropdown{
        padding: 15px;
        font-size: 15px;
    }
    .ui.fluid.dropdown > .dropdown.icon {
        padding: 15px 10px!important;
    }
    .ui.selection.dropdown > input{
        padding: 15px!important;
    }
</style>