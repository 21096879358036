<template>
    <MasterLayout>
        <div class="row">
            <div class="col-md-12">
                <div id="competency_profile" class="mt-5">
                    <h3>Competency Profile</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque consequat id justo non hendrerit. Donec viverra auctor mauris at fringilla. Fusce vitae mauris tristique, tristique neque id, rutrum lorem. Quisque ut tortor vel sapien sagittis scelerisque. Nulla quis placerat lacus. Nam dignissim enim ac ipsum sagittis, vitae dapibus enim molestie. Aliquam et risus facilisis, varius massa vitae, volutpat risus. Aliquam erat volutpat. Nullam finibus sapien eget elit semper, sit amet tincidunt nulla tempor.</p>

                    <p>Etiam in nunc sed augue accumsan efficitur. Curabitur at vulputate augue, at tincidunt risus. In vitae urna volutpat, efficitur nulla vel, consectetur libero. Aliquam pharetra egestas ante ac pretium. Integer vel facilisis ex. Nunc aliquet lacinia nisi sed sagittis. Quisque a sagittis ex, nec pretium nisi. Maecenas vitae nunc felis. Fusce gravida eget nunc et condimentum. Maecenas non neque pretium velit malesuada mattis id id orci. Cras dictum ante ac ligula lobortis dictum. Nullam vel venenatis massa.</p>

                    <p>Donec a volutpat diam, sit amet posuere sapien. Donec a justo porta, rutrum nulla varius, semper purus. Praesent efficitur risus sed ligula cursus scelerisque a at quam. In arcu justo, molestie et orci non, dictum hendrerit velit. Vivamus accumsan, erat eu auctor viverra, justo mi malesuada tellus, mattis efficitur purus nibh nec augue. Phasellus aliquet, turpis eu interdum hendrerit, neque urna consectetur mauris, eget auctor nibh velit vel purus. In dictum sem augue, vitae tristique metus bibendum in. Nunc imperdiet tempus mi eu posuere. Aenean quis mi neque. Maecenas egestas nisi volutpat condimentum sodales. Morbi laoreet erat in est faucibus sagittis vitae in nibh. Suspendisse neque erat, bibendum sed nulla eget, venenatis tempor orci. Pellentesque hendrerit tempus ipsum quis auctor. Aliquam consequat mollis tortor, ac scelerisque magna ornare eu. Vivamus vestibulum velit nibh, cursus efficitur leo tempus sit amet. Aliquam erat volutpat.</p>

                    <p>Praesent sed massa pellentesque, blandit tortor in, accumsan mi. Sed magna augue, mattis vel ultricies ut, feugiat malesuada erat. Aenean sed eleifend erat. Sed tortor mauris, volutpat tincidunt quam quis, elementum sagittis mauris. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam ac tincidunt est. Aenean a eros iaculis, eleifend erat a, faucibus magna. Suspendisse fermentum auctor velit, nec sodales diam gravida a. Proin vulputate enim eget pretium efficitur. Curabitur ac metus cursus, varius nisi non, aliquet velit. Vestibulum facilisis fringilla nisi ac mollis. Ut id massa vestibulum, tincidunt enim consectetur, lacinia dolor.</p>
                </div>
                <div id="benchmarking" class="mt-5">
                    <h3>Assessment &amp; Benchmarking</h3>
                    <p>Praesent vehicula pretium ex et dictum. Vestibulum auctor, massa ac scelerisque volutpat, tortor ligula tempor tellus, a cursus felis ex vel leo. Nulla facilisi. Sed ac facilisis lorem, at sollicitudin velit. Nulla malesuada velit ut dolor tincidunt molestie. Ut risus justo, placerat non cursus vel, ultricies in risus. Mauris imperdiet hendrerit ex a suscipit. Nulla in ipsum vel ex tincidunt congue. Donec tempor tincidunt elit sed tincidunt. Donec egestas elit eget augue aliquet rutrum ac a lorem. Maecenas at dapibus sem. In vel mi urna. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Ut sagittis augue porta, mollis dolor faucibus, euismod sem.</p>

                    <p>Morbi vel nulla ullamcorper, sagittis elit euismod, consequat ipsum. Phasellus nec risus nibh. Mauris auctor lorem ac bibendum commodo. Fusce eleifend rutrum velit a pulvinar. Nullam a consequat ex. Quisque condimentum tempor risus a varius. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi nibh est, blandit ut mauris vel, interdum faucibus nisi. Morbi id fermentum lectus. Nullam placerat libero vitae velit condimentum commodo nec sed dui.</p>

                    <p>Vestibulum vel augue eget nisl dignissim vulputate. Maecenas mollis eros sed urna commodo, nec maximus sapien aliquet. Mauris mollis ex id nibh viverra, et gravida leo laoreet. Suspendisse potenti. Ut elit ligula, porttitor ac convallis quis, condimentum ac urna. Nullam rhoncus orci nisl, eleifend maximus leo ullamcorper in. Aliquam et fermentum quam.</p>
                </div>
                <div id="report_analytics" class="mt-5">
                    <h3>Report Analytics</h3>
                    <p>Curabitur interdum varius diam, eu varius justo placerat ut. In sit amet commodo dolor. Mauris non sollicitudin arcu. Mauris tempus dapibus magna non venenatis. Aliquam lacinia ante non ullamcorper pellentesque. Aliquam dapibus ex sit amet justo malesuada tincidunt nec sed quam. Nulla feugiat, mi quis convallis bibendum, orci magna tempor lorem, ac pellentesque erat mauris et nulla. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Pellentesque varius faucibus porta. Phasellus eu nulla eget massa faucibus pretium. Pellentesque eu erat at purus lobortis finibus at et dolor. Nulla facilisi. Morbi vitae pellentesque dolor, et vulputate lectus. Suspendisse potenti.</p>

                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Curabitur congue felis nec vulputate consequat. Aliquam et eleifend dui. Donec placerat arcu posuere urna porta, et maximus ex placerat. Vivamus leo lorem, condimentum et ex non, suscipit laoreet arcu. Vestibulum tristique vestibulum aliquet. Proin ut rhoncus tellus, sit amet blandit sem. Maecenas auctor sit amet mi ut dictum. Donec rutrum sodales hendrerit. Donec ac dolor vel turpis pellentesque laoreet eget a neque. Curabitur congue, neque eget blandit sagittis, odio velit fringilla risus, mattis malesuada nibh erat vel justo. Nulla tincidunt lacinia elit dignissim gravida. Proin ac nulla vel quam porta rhoncus. Cras non ullamcorper dolor.</p>

                    <p>Phasellus ipsum diam, laoreet eu nunc a, faucibus egestas lacus. Aliquam dui elit, vulputate at imperdiet eu, commodo id urna. Phasellus et fringilla nisl, at tincidunt ipsum. Ut eget cursus ex. Ut rutrum diam a dui tincidunt, nec consequat est posuere. Morbi id venenatis orci. Vestibulum consectetur ullamcorper nunc, non placerat nisi sollicitudin et. Cras felis ipsum, malesuada sit amet metus sit amet, tempus tincidunt massa. Nullam viverra consectetur metus sed sodales. Aliquam sit amet rhoncus leo, non varius orci. Morbi nunc purus, mollis eget dolor vel, blandit rhoncus massa. Sed at molestie eros. Integer elementum urna aliquet mauris lobortis, at pellentesque metus mattis. Donec sit amet magna enim.</p>
                </div>
            </div>
        </div>
    </MasterLayout>
</template>
<script>
    import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
    import configObject from "@/config";
    import MasterLayout from "@/views/Accounts/StaticLayout.vue";


export default {
    components:{
        MasterLayout,
    },
    data() {
        return {
            
        }
    },
    created() {
               
    },
    methods: {
    }
    
}
</script>
<style>
</style>