<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">

             <div class="welcome_message">
                 <div class="row">
                     <div class="col-8">
                        <h5 class=''>
                            Map Roles to Designation <span class="greeeting__text">"{{designationName}}"</span>.
                            There are <span class="branches__count"> {{roleTasks.length}}</span> Roles available
                        </h5>
                     </div>
                     <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn default" @click="backToDesignations"><i class="fa fa-arrow-left"></i> Back</button>
                            <button class="creaet_branch_btn" @click="mapRolesToDesig">
                                Map Roles To Designation
                                <span class="">
                                    <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                                </span>

                            </button>
                        </div>
                     </div>
                 </div>
             </div>
             <section class="table__section mt-1 pb-5">
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column headerText="#"  :headerTemplate="selectAllTemplate" :template="checkBoxTemplate" width="80px"></e-column>
                        <e-column field="task" headerText="Role"></e-column>
                        <e-column field="" headerText="" width="20px"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
            <div class="empty_branch__div mt-3" v-if="requirementsFetched"> 
                <div class="text-center">
                    <h6 class="text-center"> Please wait while we get requirements</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    components: {
        MasterLayout,
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            showLoader: false,
            backgroundUrl,
            UserDP,
            componentKey: 0,
            requirements: null,
            designationName: '',
            unit: {},
            requirementsFetched: false,
            branch: {},
            newMappedRolesArray: [],
            roleTasks: [],
            rolesArray: [],
            requirementTypes: [],
            requirements: null,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            checkBoxTemplate: function() {
                return {
                    template: Vue.component("columnTemplate", {
                        template: `<div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input chkBox" v-bind:id="data.id" v-if="data.selected" v-on:change="_createMappedRolesArray($event, data.id)" checked />
                            <input type="checkbox" class="custom-control-input chkBox" v-bind:id="data.id" v-if="!data.selected" v-on:change="_createMappedRolesArray($event, data.id)" />
                            <label class="custom-control-label" v-bind:for="data.id"></label>
                        </div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _createMappedRolesArray: function(e, roleId) {
                                this.$eventHub.$emit("rolesCheck", e, roleId);
                            }
                        }
                    })
                };
            },
            selectAllTemplate: function() {
                return {
                template: Vue.component("columnTemplate", {
                    template: `<div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="selectAll" v-on:change="_selectAll($event)"/>
                        <label class="custom-control-label" for="selectAll"></label></div>`,
                    data: function() {
                    return {
                        data: {}
                    };
                    },
                    methods: {
                    _selectAll: function(e) {
                        this.$eventHub.$emit("selectAll", e);
                    }
                    }
                })
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>` +
                            `<div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            }            
        }
    },
    mounted() {
        console.log('Second CALL');
        this.getRoleTasks();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created(){
        this.designationName = this.$route.query.name ? this.$route.query.name : '';
        // this.id = this.$route.query.id ? parseInt(this.$route.query.id) : null;

        this.$eventHub.$on("rolesCheck", (event, roleId) => {
            this.createMappedRolesArray(event, roleId);
        });

         this.$eventHub.$on("selectAll", event => {
            this.selectAll(event);
        });
    },
    methods: {
        selectAll(event) {
            event.preventDefault();
            let _this = this;
            if (event.target.checked) {
                $(":checkbox").each(function() {
                    this.checked = true;
                });
                
                this.roleTasks.forEach(rol => {
                    this.rolesArray.push({id: rol.id});
                })
            } else {
                $(":checkbox").each(function() {
                    this.checked = false;
                });
                _this.rolesArray = [];
            }
        },
        createMappedRolesArray(event, roleId){
            let _roleId = this.roleTasks.findIndex(a => a.id === roleId);
            if(_roleId !== -1){
                if (event.target.checked){
                    // Selected
                    this.roleTasks[_roleId].selected = true;
                    if($(".chkBox:not(:checked)").length <= 0){
                        //console.log('all selected');
                        $("#selectAll").prop('checked', true);
                    };
                }
                else{
                    // Unselected
                    this.roleTasks[_roleId].selected = false;
                    $("#selectAll").prop('checked', false);
                }
                //this.refreshGrid();
            }
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        mapRolesToDesig() {
            this.showLoader = true;
            this.isButtonDisabled = true;
            let dataArray = [];
            let data = {}

            this.roleTasks.forEach(c => {
                if(c.selected){
                    dataArray.push(c.id)
                }
            });
            data.listOfIds = dataArray;

            console.log(data);
            let url = `${configObject.apiBaseUrl}/Designation/MapRoles/${parseInt(this.$route.query.id)}`;
            console.log(url);   
            this.axios.post(url, data, configObject.authConfig)
                .then((res) => {
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    this.$toast.success("Roles mapped to Designation successfully!");
                    console.log(res.data);
                    })
                    .catch((error) => {
                        this.isButtonDisabled = false;
                        this.showLoader = false;
                        this.$toast.open({
                            message: "unable to map roles to designation",
                            type: "error",
                            duration: 3000
                        });
                        console.log(error);
                });
        },
        getRoleTasks(){
            let grid = this.$refs.dataGrid;
            this.axios
                .get(`${configObject.apiBaseUrl}/RoleTask/ForMapToDesignation/${this.$route.query.id}`, configObject.authConfig)
                .then(response => {
                    //console.log(response.data);
                    let index = 0;
                    response.data.forEach(role => {
                        index++;
                        role.index = index;
                    });
                    this.roleTasks = response.data;
                    grid.ej2Instances.setProperties({ dataSource: this.roleTasks });
                    this.refreshGrid();
                })
                .catch((error) => {
                    console.log(error);
                    // this.$toast.open({
                    //     message: "Unable to load role tasks",
                    //     type: "error",
                    //     duration: 4000
                    // });
                });
        },
        backToDesignations(){
            this.$router.push({
                name: 'designation'
            });
        }
    }
}
</script>

        