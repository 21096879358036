<template>
<modal name="editProfileModal" transition="pop-out" :width="modalWidth" :height="400">
  <div class="modal__header">
      <h4>Edit Designation Profile</h4>
  </div>
 <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-6">
                <div class="" style="width: 100%">
                    <span>Designation</span>
                    <div class="">
                        <select ref="stateDesignation" class="ml-3 selector" v-model="profile.designationId" requireds disabled>
                            <option value="" selected>Select Designation</option>
                            <option v-for="d in designations" v-bind:key="d.id" v-bind:value="d.id">{{d.designationName}}</option>
                        </select>
                        
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="" style="width: 100%">
                    <span>Grade Level</span>
                    <div class="">
                        <select ref="stateGrade" class="ml-3" v-model="profile.gradeId" required>
                            <option value="" selected>Select Grade Level</option>
                            <option v-for="g in grades" v-bind:key="g.id" v-bind:value="g.id">{{g.gradeName}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-4">
                <div class="" style="width: 100%">
                    <span>Division</span>
                    <div class="">
                        <select ref="divisionRef" class="ml-3" v-model="profile.divisionId" required >
                            <option value="" selected>Select Division</option>
                            <option v-for="div in divisions" v-bind:key="div.id" v-bind:value="div.id">{{div.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="" style="width: 100%">
                    <span>Branch</span>
                    <div class="">
                        <select ref="branchRef" class="ml-3" v-model="profile.branchId" required >
                            <option value="" selected>Select Branch</option>
                            <option v-for="b in branches" v-bind:key="b.id" v-bind:value="b.id">{{b.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="" style="width: 100%">
                    <span>Unit</span>
                    <div class="">
                        <select ref="unitRef" class="ml-3" v-model="profile.unitId" required >
                            <option value="" selected>Select Unit</option>
                            <option v-for="u in units" v-bind:key="u.id" v-bind:value="u.id">{{u.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="text-center mt-4">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Update Profile
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 850;
import configObject from "@/config";

export default {
  name: 'editProfileModal',
  props: ['profile'],
  data () {
    return {
        modalWidth: MODAL_WIDTH,
        isButtonDisabled:  false,
        showLoader: false,
        branches: [],
        designations: [],
        divisions: [],
        units: [],
        grades: [],
        designationId: 0,
        divisionId: 0,
        branchId: 0,
        unitId: 0,
        gradeId: 0,
    }
  },

  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

    console.log(configObject.authConfig);

  },
  mounted(){
    this.getBranches();
    this.getDesignations();
    this.getDivisions();
    this.getGrades();
    this.getUnits();
  },
  methods: {
    closeCreateProfileModal(){
        this.$modal.hide('editProfileModal');
    },
      getBranches(){
        this.axios.get(`${configObject.apiBaseUrl}/Branch`, configObject.authConfig)
        .then(resp => {
            this.branches = resp.data;
        });
    },
    getDesignations(){
        this.axios.get(`${configObject.apiBaseUrl}/Designation`, configObject.authConfig)
        .then(resp => {
            this.designations = resp.data;
        });
    },
    getDivisions(){
        this.axios.get(`${configObject.apiBaseUrl}/Divisions`, configObject.authConfig)
        .then(resp => {
            this.divisions = resp.data;
            console.log('DIVS >>> ' + resp.data);
        });
    },
    getGrades(){
        this.axios.get(`${configObject.apiBaseUrl}/Grades`, configObject.authConfig)
        .then(resp => {
            this.grades = resp.data;
        });
    },
    getUnits(){
        console.log('DIV ID :: ' + this.divisionId);
        if(this.divisionId === 0){
            this.axios.get(`${configObject.apiBaseUrl}/Unit`, configObject.authConfig)
            .then(resp => {
                this.units = resp.data;
            });
        }
        else{
            this.axios.get(`${configObject.apiBaseUrl}/Unit/ByDivision/${this.divisionId}`, configObject.authConfig)
            .then(resp => {
                this.units = resp.data;
                console.log(this.units);
            });
        }
    },
     validateDetails($event) {
        $event.preventDefault();
        this.editProfile();
    },
    editProfile() {
        let data = {
            divisionId: this.profile.divisionId,
            designationId: this.profile.designationId,
            gradeId: this.profile.gradeId,
            unitId: this.profile.unitId,
            branchId: this.profile.branchId,
            id: this.profile.id
        };
        console.log(data);
        console.log(this.profile.id);
        this.showLoader = true;
        this.isButtonDisabled = true;
        this.axios.put(`${configObject.apiBaseUrl}/Designation/Profile/Edit/${this.profile.id}`, data,  configObject.authConfig)
            .then(response => {
                this.showLoader = false;
                this.isButtonDisabled = true;
                console.log(response.data);
                this.closeCreateProfileModal();
                this.$eventHub.$emit("refreshProfileTable");
                this.$toast.success("Profile Updated successfully");
                this.isButtonDisabled = false;
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "unable to edit profile",
                        type: "error",
                        duration: 3000
                    });
                    console.log(error);
            });
    },
  }
}
</script>
