<template>
    <modal name="editModal" transition="pop-out" :width="modalWidth" :height="300">
        <div class="modal__header">
            <h4>Edit Requirement</h4>
        </div>
        <div class="cas_input">
            <div class="row__wrapper">
                <div class="row">
                    <div class="col-md-12">
                        <div class="input_wrapper" style="">
                            <span>Requirement Name</span>
                            <div class="">
                                <input type="text" placeholder="Enter Requirement name" class="ml-3" v-model="requirement.task" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="text-center mt-4">
                <button class="creaet_branch_btn"
                    @click="validateDetails($event)"
                    :disabled="isButtonDisabled ? true : null"
                    :style="[
                    isButtonDisabled
                        ? { cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                    ]">
                    Save Edit
                    <span class="">
                        <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                    </span>
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
const MODAL_WIDTH = 500;
import configObject from "@/config";


export default {
    name: 'createModal',
    props: ['requirement'],
    data(){
        return{
            showLoader: false,
            isButtonDisabled:  false,
        }
    },
    created () {
        this.modalWidth = window.innerWidth < MODAL_WIDTH
        ? MODAL_WIDTH / 2
        : MODAL_WIDTH

        console.log(this.$store.getters.getBranchdetails);
        
    },
    mounted(){

    },
    methods: {
        validateDetails($event){
            $event.preventDefault();
            if(!this.requirement.task){
                this.$toasted.show("Fill in the missing Fields", {
                        theme: "bubble",
                        position: "top-center",
                        duration: 4000
                    });
            }else {
                this.editRequirement();
            }
        },
        editRequirement(){
            this.isButtonDisabled = true;
            this.showLoader = true;
            let data = {
                task: this.requirement.task,
                id: this.requirement.id
            }

            this.axios.put(`${configObject.apiBaseUrl}/Requirement/Edit/${this.requirement.id}`, data, configObject.authConfig)
            .then(resp => {
                this.isButtonDisabled = false;
                this.showLoader = false;
                this.$toast.success("Requirement edited successfully");
                this.closeModal();
                this.$eventHub.$emit("refreshRequirementTable");
            })
            .catch(error => {
                this.isButtonDisabled = false;
                this.showLoader = false;
                this.$toast.open({
                    message: "unable to edit requirement",
                    type: "error",
                    duration: 4000
                });
                console.log(error);
            })
        },
        closeModal(){
            this.$modal.hide('editModal');
        }
    }
}
</script>