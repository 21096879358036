<template>
<modal name="editUnitModal" transition="pop-out" :width="modalWidth" :height="400">
  <div class="modal__header">
      <h4>Edit Unit</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-12">
                <div class="input_wrapper" style="">
                    <span>Unit Name</span>
                    <div class="">
                        <!-- <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" /> -->
                        <input type="text" placeholder="Enter Unit name" class="ml-3" v-model="unit.name" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <div class="input_wrapper" style="">
                    <span>Unit Objective</span>
                    <div class="">
                        <textarea type="text" placeholder="Enter Unit objective" class="" v-model="unit.objective" style="height: 115px;"></textarea>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="text-center mt-2">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Update Unit
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 500;
import configObject from "@/config";

export default {
  name: 'editUnitModal',
  props:  ['unit'],
  data () {
    return {
      modalWidth: MODAL_WIDTH,
      isButtonDisabled:  false,
      showLoader: false,
    }
  },

  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

    console.log(configObject.authConfig);

  },
  methods: {
    closeModal(){
        this.$modal.hide('editUnitModal');
    },
     validateDetails($event) {
        $event.preventDefault();
        if(!this.unit.name ){
            this.$toasted.show("Fill in the missing Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
        }else {
            this.editUnit();
        }
    },
    editUnit() {
        let data = {
            name: this.unit.name,
            id: this.unit.id,
            objective: this.unit.objective
        };
        console.log(data);
        this.showLoader = true;
        this.isButtonDisabled = true;
        this.axios.put(`${configObject.apiBaseUrl}/Unit/Edit/${this.unit.id}`, data,  configObject.authConfig)
            .then(response => {
                this.showLoader = false;
                this.isButtonDisabled = true;
                console.log(response.data);
                this.closeModal();
                this.$eventHub.$emit("refreshUnitTable");
                this.$toast.success("Unit Updated successfully");
                this.isButtonDisabled = false;
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "unable to edit unit",
                        type: "error",
                        duration: 3000
                    });
                    console.log(error);
            });
    },
  }
}
</script>


<style scoped>
.cas_input .input_wrapper {
    margin-bottom: 5px!important;
}
.cas_input textarea, .cas_input [type='text']{
    margin-bottom: 5px!important;
}
</style>
