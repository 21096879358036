<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">
            <div class="welcome_message">
                <div class="row">
                    <div class="col-8">   
                        <h5 class=''><span class="greeeting__text"> Hi {{userInfo.firstName}},
                            </span>Creating new {{aType}}. Step <span class="branches__count">1</span> of 3
                        </h5>  
                    </div>
                    <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn default" @click="backToAssessment"><i class="fa fa-arrow-left"></i> Back</button>
                                                
                        </div>
                    </div>
                </div>
            </div>
            <section class="table__section mt-1 pb-5">
                <div class="cas_input mt-3">
                    <div class="row">
                        <div class="col-md-offset-2 col-md-8">
                            <div class="row__wrapper push-up">
                                <div class="row" v-show="aType === 'Training'">
                                    <div class="col-md-6">
                                        <div class="" style="width: 100%">
                                            <span>Training Name</span>
                                            <div class="">                                                
                                                <input type="text" placeholder="Enter Training name here" v-model="trainingName"  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="" style="width: 100%">
                                            <span>Training Date</span>
                                            <div class="">
                                                <!-- <datepicker placeholder="Select Date" v-model="trainingDate" :format="format" v-on:selected="dateChanged($event)"></datepicker> -->
                                                <datepicker placeholder="Select Date" v-model="trainingDate" :format="format"></datepicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="" style="width: 100%">
                                            <span v-if="aType !== 'Assessment'">General Commments</span>
                                            <span class="mb-2" style="display: block;" v-if="aType === 'Assessment'">Assessment Note <small><i>(Max 4000 characters)</i></small></span>
                                            <div class="">
                                                <textarea type="text" class="" v-model="comment"
                                                    :style="[ aType === 'Assessment' ? { height: '300px' } : { height: '150px' } ]"
                                                    :placeholder="aType === 'Assessment' ? 'Enter Assessment note here' : 'Enter Training comment here'"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-center">
                                    <!-- @click="saveAssessment" -->
                                    <button class="creaet_branch_btn"                                         
                                        @click="validateDetails($event)"
                                        :disabled="isButtonDisabled ? true : null"
                                        :style="[
                                        isButtonDisabled
                                            ? { cursor: 'not-allowed' }
                                            : { cursor: 'pointer' }
                                        ]">
                                        Continue
                                        <span class="">
                                            <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                                        </span><i class="fa fa-paper-plane " v-if="!showLoader"></i>
                                    </button> 
                                </div>
                            </div>
                        </div>
                        
                    </div>               
                </div>
                
            </section>
        </div>
    </MasterLayout>
</template>

<script>
import Vue from "vue";
import MasterLayout from "@/views/Employee/MasterLayout.vue";
import configObject from "@/config";
import JQuery from "jquery";
import moment from "moment";
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
// import * as lang from "vuejs-datepicker/src/locale";

let $ = JQuery;

export default {
    title: 'Assessment/Trainings - Step 1',
    components: {
        MasterLayout,
        Datepicker
    },
    data(){
        return {
            showLoader: false,
            isButtonDisabled: false,
            trainingDate: null,
            trainingName: null,
            comment: null,
            assessmentId: 0,
            format: "MM-dd-yyyy",
            userInfo: localStorage.getItem("dcasUserDetails")
                ? JSON.parse(localStorage.getItem("dcasUserDetails"))
                : null,
            aType: '',
        }
    },
    created(){
        this.continueAssessment();
    },
    mounted(){
        this.aType = this.$route.query.typ;
    },
    methods:{
        validateDetails($event) {
            $event.preventDefault();

            if(this.aType === 'Training'){
                // Assessment
                if(!this.trainingName || !this.trainingDate || !this.comment){
                    this.$toasted.show("Please fill all Fields", {
                            theme: "bubble",
                            position: "top-center",
                            duration: 4000
                        });
                }else {
                    this.saveAssessment();
                }
            }
            else if(this.aType === 'Assessment'){
                // Appraisal
                if(!this.comment){
                    this.$toasted.show("Please fill all Fields", {
                            theme: "bubble",
                            position: "top-center",
                            duration: 4000
                        });
                }else {
                    this.trainingName = 'Assessment';
                    this.trainingDate = new Date();
                    this.saveAssessment();
                }
            }
            else{
                console.log('Unknown type >>> ' + this.aType);
            }
        },
        continueAssessment(){
            let saId = this.$route.query.id;
            if(saId > 0){
                let url = `${configObject.apiBaseUrl}/Employee/SelfAssessment/${this.userInfo.employeeId}?id=${saId}&cont=true`;
                this.axios.get(url, configObject.authConfig)
                    .then(response => {
                        let d = response.data;
                        console.log(d);
                        this.comment = d.employeeComment;
                        this.assessmentId = d.id;
                        this.trainingName = d.trainingName;
                        this.trainingDate = d.trainingDate;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        saveAssessment(){
            this.showLoader = true;
            this.isButtonDisabled = true;
            let sa = {};
            sa.assessmentId = this.assessmentId;
            sa.employeeId = this.userInfo.employeeId;
            sa.employeeComment = this.comment;
            sa.trainingName = this.trainingName;
            sa.trainingDate = this.trainingDate;
            sa.type = this.aType;// === 'ass' ? 'Training' : 'Assessment';
            console.log(sa);

            let url = `${configObject.apiBaseUrl}/Employee/SelfAssessment/Step1`;
            this.axios.post(url, sa, configObject.authConfig) //data, configObject.authConfig)
                .then((resp) => {
                    console.log(resp.data);
                    if(resp.data.assessmentId > 0){
                        this.showLoader = false;
                        this.isButtonDisabled = false;
                        this.$toast.success("Self Assessement Saved Succssfully!");
                        
                        this.$router.push({
                            name: 'step_two', 
                            query: {
                                id: resp.data.assessmentId,
                                typ: this.aType
                            }
                        });
                    }
                    else{
                        this.$toast.open({
                            message: "Unable to save self assessment",
                            type: "error",
                            duration: 4000
                        });
                    }
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "Unable to save self assessments",
                        type: "error",
                        duration: 4000
                    });
                    console.log(error);
                });
        
        },
        backToAssessment(){
            this.$router.push({
                name: 'self_assessment'
            });
        },
        // dateChanged(d){
        //     // let newD = moment(d, 'MM-DD-YYYY').format('MM-DD-YYYY');
        //     // console.log('New Selection: ' + newD);
        //     // alert('Date changed - ' + newD);
        //     // this.trainingDate = newD;
        //     // console.log(this.trainingDate);
        // }
    }
}
</script>