<template>
<modal name="createProfileModal" transition="pop-out" :width="modalWidth" :height="400">
  <div class="modal__header">
      <h4>Add New Designation Profile</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-6">
                <div class="" style="width: 100%">
                    <span>Designation</span>
                    <div class="">
                        <select ref="stateDesignation" class="ml-3 selector" v-model="designationId" required>
                            <option value="">Select Designation</option>
                            <option v-for="d in designations" v-bind:key="d.id" v-bind:value="d.id">{{d.designationName}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="" style="width: 100%">
                    <span>Grade Level</span>
                    <div class="">
                        <select ref="stateGrade" class="ml-3" v-model="gradeId" required>
                            <option value="">Select Grade Level</option>
                            <option v-for="g in grades" v-bind:key="g.id" v-bind:value="g.id">{{g.gradeName}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-4">
                <div class="" style="width: 100%">
                    <span>Division</span>
                    <div class="">
                        <select ref="divisionRef" class="ml-3" v-model="divisionId" required @change="divChanged($event)">
                            <option value="">Select Division</option>
                            <option v-for="div in divisions" v-bind:key="div.id" v-bind:value="div.id">{{div.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="" style="width: 100%">
                    <span>Branch</span>
                    <div class="">
                        <select ref="branchRef" class="ml-3" v-model="branchId" required @change="brChanged($event)">
                            <option value="">Select Branch</option>
                            <option v-for="b in branches" v-bind:key="b.id" v-bind:value="b.id">{{b.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="" style="width: 100%">
                    <span>Unit</span>
                    <div class="">
                        <select ref="unitRef" class="ml-3" v-model="unitId" required @change="unitChanged($event)">
                            <option value="">Select Unit</option>
                            <option v-for="u in units" v-bind:key="u.id" v-bind:value="u.id">{{u.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <div class="text-center mt-4">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Add Designation 
            <!-- <i class="fa fa-save"></i>
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span> -->
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 850;
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;

export default {
  name: 'createProfileModal',
  data () {
    return {
        modalWidth: MODAL_WIDTH,
        branches: [],
        designations: [],
        divisions: [],
        units: [],
        grades: [],
        designationId: 0,
        divisionId: 0,
        branchId: 0,
        unitId: 0,
        gradeId: 0,        
        isButtonDisabled:  false,
        showLoader: false,
        profile: this.$store.getters.getProfiledetail,
        
    }
  },
  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

    //console.log(this.$store.getters.getProfiledetails);
    
  },
  mounted(){
    this.getBranches();
    this.getDesignations();
    this.getDivisions();
    this.getGrades();
    this.getUnits();
    this.resetFields();
  },
  methods: {
      validateDetails($event) {
        $event.preventDefault();
        if(!this.designationId || !this.divisionId || !this.branchId || !this.gradeId || !this.unitId){
            this.$toasted.show("Please complete all Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
        }else {
            this.createProfile();
        }
    },
    closeCreateProfileModal(){
        this.$modal.hide('createProfileModal');
    },
    getBranches(){
        this.axios.get(`${configObject.apiBaseUrl}/Branch`, configObject.authConfig)
        .then(resp => {
            this.branches = resp.data;
            //this.state = resp.data[0].name
            //console.log("STATES" + JSON.stringify(resp.data));
        });
    },
    getDesignations(){
        this.axios.get(`${configObject.apiBaseUrl}/Designation`, configObject.authConfig)
        .then(resp => {
            this.designations = resp.data;
        });
    },
    getDivisions(){
        this.axios.get(`${configObject.apiBaseUrl}/Divisions`, configObject.authConfig)
        .then(resp => {
            this.divisions = resp.data;
            //console.log('DIVS >>> ' + this.divisions);
        });
    },
    getGrades(){
        this.axios.get(`${configObject.apiBaseUrl}/Grades`, configObject.authConfig)
        .then(resp => {
            this.grades = resp.data;
        });
    },
    getUnits(){
        //console.log('DIV ID :: ' + this.divisionId);
        if(this.divisionId === 0){
            this.axios.get(`${configObject.apiBaseUrl}/Unit`, configObject.authConfig)
            .then(resp => {
                console.log(resp.data);
                this.units = resp.data;
            });
        }
        else{
            this.axios.get(`${configObject.apiBaseUrl}/Unit/ByDivision/${this.divisionId}`, configObject.authConfig)
            .then(resp => {
                this.units = resp.data;
                console.log(this.units);
            });
        }
    },
    divChanged(event){
        let val = event.target.value;
        if(val === undefined || val.length <= 0){
            console.log('Nothing Selected');
        }
        else{
            //console.log("Division CHANGED :: " + val);
            this.getUnits();
        }
    },
    brChanged(event){
        let val = event.target.value;
        //console.log("Branch CHANGED :: " + val);
        this.getUnits(val);
    },
    unitChanged(event){
        let val = event.target.value;
        //console.log("Unit CHANGED :: " + val);
        //this.getUnits(val);
    },
    resetFields(){
        this.designationId = 0;
        this.divisionId = 0;
        this.branchId = 0;
        this.unitId = 0;
        this.gradeId = 0;
    },
    createProfile() {
        let dataToSend = {
            divisionId: this.divisionId,
            designationId: this.designationId,
            gradeId: this.gradeId,
            unitId: this.unitId,
            branchId: this.branchId
        };
        this.showLoader = true;
        this.isButtonDisabled = true;
        this.axios.post(`${configObject.apiBaseUrl}/Designation/Profile/Add`, dataToSend, configObject.authConfig)
            .then(() => {
                this.showLoader = false;
                this.isButtonDisabled = false;
                this.resetFields();
                this.closeCreateProfileModal();
                this.$eventHub.$emit("refreshProfileTable");
                this.$toast.success("Profile created successfully");
                this.divisionId = null;
                this.designationId = null;
                this.gradeId = null;
                this.unitId = null;
                this.branchId = null;
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "unable to create profile",
                        type: "error",
                        duration: 3000
                    });
                    console.log('ERROR::: ' + error);
            });
    },
  }
}

$(function(){
    console.log('JQuery Ready');

    $('.selector').on('change', function(){
        alert('Valeu changed to ' + $(this).val());
    })
})
</script>
