<template>
<modal name="createUnitModal" transition="pop-out" :width="modalWidth" :height="400">
  <div class="modal__header">
      <h4>Add New Unit</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-12">
                <div class="input_wrapper" style="">
                    <span>Unit Name</span>
                    <div class="">
                        <input type="text" placeholder="Enter Unit name" class="ml-3" v-model="unitName" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <div class="input_wrapper" style="">
                    <span>Unit Objective</span>
                    <div class="">
                        <textarea type="text" placeholder="Enter Unit objective" class="" v-model="objective" style="height: 115px;"></textarea>
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <div class="text-center mt-2">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Create Unit
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 500;
import configObject from "@/config";

export default {
  name: 'createUnitModal',
  data () {
    return {
      modalWidth: MODAL_WIDTH,
      unitName: null,
      objective: null,
      isButtonDisabled:  false,
      showLoader: false,
      branch: this.$store.getters.getBranchdetail
    }
  },
  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

    console.log(this.$store.getters.getBranchdetails);
    
  },
  mounted(){
    console.log(configObject.authConfig);
  },
  methods: {
      validateDetails($event) {
        $event.preventDefault();
        if(!this.unitName){
            this.$toasted.show("Fill in the missing Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
        }else {
            this.createUnit();
        }
    },
    closeCreateBranchModal(){
        this.$modal.hide('createUnitModal');
    },
    createUnit() {
        let dataToSend = {
            name: this.unitName,
            objective: this.objective
        };
        this.showLoader = true;
        this.isButtonDisabled = true;
        this.axios.post(`${configObject.apiBaseUrl}/Unit/Add`, dataToSend, configObject.authConfig)
            .then(() => {
                this.showLoader = false;
                this.isButtonDisabled = false;
                this.closeCreateBranchModal();
                this.$eventHub.$emit("refreshUnitTable");
                this.$toast.success("Unit created successfully");
                this.unitName = null;
                this.objective = null;
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "unable to create unit",
                        type: "error",
                        duration: 3000
                    });
                    console.log(error);
            });
    },
  }
}
</script>

<style scoped>
.cas_input .input_wrapper {
    margin-bottom: 5px!important;
}
.cas_input textarea, .cas_input [type='text']{
    margin-bottom: 5px!important;
}
</style>
