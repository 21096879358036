<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">

             <div class="welcome_message">
                 <div class="row">
                     <div class="col-8">    
                         <h5 class=''><span class="greeeting__text"> Hi Admin,
                            </span> you have <span class="branches__count"> {{companies.length}} </span> Companies
                        </h5> 
                     </div>
                     <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn" @click="openCreateCompanyModal">Create Company</button>
                        </div>
                     </div>
                 </div>
             </div>
             <section class="table__section mt-1 pb-5" v-show="companies.length > 0">
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" headerText="#" width="50px" ></e-column>
                        <e-column field="name" headerText="Company Name"></e-column>
                        <e-column field="objective" headerText="Objective" width="250px"></e-column>
                        <e-column field="streetName" width="160px" headerText="Street"></e-column>
                        <e-column field="city" headerText="City"></e-column>
                        <e-column field="lga" headerText="LGA"></e-column>
                        <e-column field="state" headerText="State"></e-column>
                        <e-column :template="actionButtonsTemplate" width="150px" headerText="Actions"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
            <div class="empty_branch__div mt-3" v-if="companies.length < 1">
                <div class="text-center">
                    <h6 class="text-center"> You are yet to create a Company</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
            <CreateCompanyModal></CreateCompanyModal>
            <EditCompany :company="company" :states="states"></EditCompany>
            <CompanyDetails :company="company"></CompanyDetails>
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
import CreateCompanyModal from '@/components/Modals/Company/createCompanyModal.vue';
import EditCompany from '@/components/Modals/Company/editCompanyModal.vue';
import CompanyDetails from '@/components/Modals/Company/companyDetails.vue'

import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    components: {
        MasterLayout,
        CreateCompanyModal,
        EditCompany,
        CompanyDetails
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            backgroundUrl,
            UserDP,
            companies: [],
            company: {},
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("columnTemplate", {
                        template:
                            '<div class="btn-group btn-group-sm" role="group">'+
                                '<button type="button" class="btn btn-info" title="View" v-on:click="_viewCompanyDetails(data.id)">' +
                                    '<i class="fa fa-eye" aria-hidden="true"></i>' +
                                '</button>' +
                                '<button type="button" class="btn btn-primary" title="Edit" v-on:click="_editCompany(data.id)">' +
                                    '<i class="fa fa-edit" aria-hidden="true"></i>' +
                                '</button>' +
                                '<button type="button" class="btn btn-danger" title="Delete" v-on:click="_deleteCompany(data.id)">' +
                                    '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                                '</button>' +
                            '</div>',
                        data: function() {
                        return {
                            data: {}
                        };
                        },
                        methods: {
                            _deleteCompany: function(companyId) {
                                this.$eventHub.$emit("deleteCompany", companyId);
                            },
                            _editCompany: function(companyId) {
                                this.$eventHub.$emit("editCompany", companyId);
                            },
                            _viewCompanyDetails: function(companyId) {
                                console.log(companyId);
                                this.$eventHub.$emit("viewCompanyDetails", companyId);
                            },
                        }
                    })
                };
            }
        }
    },
     mounted() {
        this.getcompanies();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created() {
        this.$eventHub.$on("deleteCompany", (companyId) => {
            this.deleteCompany(companyId);
        });
        this.$eventHub.$on("editCompany", (companyId) => {
            this.editCompany(companyId);
        });
        this.$eventHub.$on("refreshCompanyTable", () => {
            this.getcompanies();
        });
        this.$eventHub.$on("viewCompanyDetails", (companyId) => {
            this.viewCompanyDetails(companyId);
        });
    },
    beforeRouteEnter(to, from, next) {
        next(() => {
            if(from.name === 'login'){
                window.location.reload();
            }
        })
    },
    methods: {
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        openCreateCompanyModal(){
            this.$modal.show('createCompanyModal');
        },
        openEditCompanyModal(){
            this.$modal.show('editCompanyModal');
        },
        openCompanyDetail(){
            this.$modal.show('companyDetail');
        },
        viewCompanyDetails(companyId) {
            //console.log(companyId);
            let companyDetail = this.companies.filter(company => company.id === companyId)[0];
            //console.log(companyDetail);
            this.company = companyDetail;
            this.openCompanyDetail();
        },
        editCompany(companyId) {
            let companyDetail = this.companies.filter(company => company.id === companyId)[0];
            //console.log(companyDetail);
            this.company = companyDetail;
            this.openEditCompanyModal();
        },
        deleteCompany(companyId) {
            console.log(companyId);
            var resp = confirm("Are you sure  you want to delete this Company");
            if (resp) {
                this.axios
                    .delete(
                        `${configObject.apiBaseUrl}/Company/Delete/${companyId}`,
                        configObject.authConfig
                    )
                    .then(() => {
                        this.getcompanies();
                        this.$toast.open({
                            message: "Company deleted successfully!",
                            type: "success",
                            duration: 3000
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        this.$toast.open({
                            message: "unable to delete company",
                            type: "error",
                            duration: 3000
                        });
                    });
            }
        },
        getcompanies() {
            console.log("companies");
            this.showLoader = true;
            let grid = this.$refs.dataGrid;
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Company`,
                    configObject.authConfig
                )
                .then(response => {
                    let index = 0;
                    response.data.forEach(company => {
                        index++;
                        company.index = index;
                    });
                    this.showLoader = false;

                    grid.ej2Instances.setProperties({ dataSource: response.data });
                    this.refreshGrid();
                    this.companies = response.data;
                })
                .catch((error) => {
                    console.log(error);
                    // this.$toast.open({
                    //     message: "unable to companies",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
    }
}
</script>

        