<template>
<modal name="editBranchModal" transition="pop-out" :width="modalWidth" :height="560">
  <div class="modal__header">
      <h4>Edit Branch</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>Branch Name</span>
                    <div class="">
                        <!-- <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" /> -->
                        <input type="text" placeholder="Branch name" class="ml-3" v-model="branch.name" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>Objective</span>
                    <div class="">
                        <!-- <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" /> -->
                        <input type="text" placeholder="Branch Objective" class="ml-3" v-model="branch.objective" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-6">
               <b-form-checkbox
                    id="checkbox-1"
                    v-model="branch.isZonalOffice"
                    name="checkbox-1"
                    value="true"
                    unchecked-value="false"
                    @change="toggleRequiredZonalOffice"
                    >
                    Is Zonal Office
                </b-form-checkbox>
            </div>
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>Zonal Office  Name</span>
                    <div class="">
                        <!-- <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" /> -->
                        <input 
                            type="text" 
                            ref="zonalOff" 
                            placeholder="Zonal office name" 
                            class="ml-3" 
                            v-model="branch.zonalOfficeName"
                            :required="branch.isZonalOffice ? true : false"
                            :disabled="branch.isZonalOffice ? false : true" />
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row mt-2">
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>Street</span>
                    <div class="">
                        <!-- <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" /> -->
                        <input type="text"  class="ml-3" v-model="branch.streetName" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>City</span>
                    <div class="">
                        <!-- <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" /> -->
                        <input type="text"  class="ml-3" v-model="branch.city" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>State</span>
                    <div class="">
                        <select ref="stateSelect" class="ml-3" v-model="branch.state" required @change="stateChanged($event)">
                            <option value="">Select State</option>
                            <option v-for="st in states" v-bind:key="st.name" v-bind:value="st.name">{{st.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>LGA</span>
                    <div class="">
                        <!-- <input type="text" class="ml-3" v-model="branch.lga" v-if="stateLgas.length <= 0" /> -->
                        <select class="ml-3" v-model="branch.lga" required  v-if="stateLgas.length <= 0">
                            <option value="">Select LGA</option>
                            <option v-for="_lga in lgas" v-bind:key="_lga.name" v-bind:value="_lga.name">{{_lga.name}}</option>
                        </select>
                        <select class="ml-3" v-model="branch.lga" required v-if="stateLgas.length > 0">
                            <option value="">Select LGA</option>
                            <option v-for="_lga in stateLgas" v-bind:key="_lga.name" v-bind:value="_lga.name">{{_lga.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <div class="text-center mt-4">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Update Branch
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 850;
import configObject from "@/config";

export default {
    name: 'editBranchModal',
    props:  ['branch'],
    data () {
        return {
        modalWidth: MODAL_WIDTH,
        states: [],
        lgas: [],
        stateLgas: [],
        isButtonDisabled:  false,
        showLoader: false,
        //branch: this.$store.getters.getBranchdetail,
        //isZonalOffice: false
        }
    },
    mounted(){
        console.log('Edit Branch Modal Mounted');
        this.getStates();
        this.getLgas();
        console.log('Branch INFO');
        if(this.branch.state && this.states){
            console.log('---> ' + this.branch.state);
            console.log(this.states);
            let _id = this.states.find(state => state.name === this.branch.state).id;
            console.log('Loaded with State, pull LGAs' + _id);
            this.getStateLga(_id);
        }
    },
    // computed: {
    //     stateLgas: {
    //         get: function(){
    //             console.log('Getter');
    //             return "";
    //         },
    //         set: function(){
    //             console.log('Setter');
    //         }
    //     }
    //     // console.log('Edit Branch Modal Computed');
    //     // console.log(this.branch);
    // },
    created () {
        this.modalWidth = window.innerWidth < MODAL_WIDTH
        ? MODAL_WIDTH / 2
        : MODAL_WIDTH;

        console.log('Edit Branch Modal Created');
        console.log(this.branch);
    },
    methods: {
        closeCreateBranchModal(){
            this.$modal.hide('editBranchModal');
        },
        validateDetails($event) {
            $event.preventDefault();
            if(!this.branch.name || !this.branch.objective || !this.branch.city || !this.branch.streetName || !this.branch.lga || !this.branch.state){
                this.$toasted.show("Fill in the missing Fields", {
                        theme: "bubble",
                        position: "top-center",
                        duration: 2000
                    });
            }else {
                this.editBranch();
            }
        },
        toggleRequiredZonalOffice() {
            this.branch.isZonalOffice = !this.branch.isZonalOffice;
        },
        sortFunc(prop){
            console.log('Sort by ' + prop);
            var sortOrder = 1;
            if(prop[0] === "-") {
                sortOrder = -1;
                prop = prop.substr(1);
            }
            return function (a,b) {
                /* next line works with strings and numbers, 
                * and you may want to customize it to your needs
                */
                var result = (a[prop] < b[prop]) ? -1 : (a[prop] > b[prop]) ? 1 : 0;
                return result * sortOrder;
            }
        },
        getStates(){
            this.axios.get(`${configObject.apiBaseUrl}/Setup/States`, configObject.authConfig)
            .then(resp => {
                this.states = resp.data.sort(this.sortFunc("name"));
                //this.state = resp.data[0].name
            });
        },
        getLgas(){
            this.axios.get(`${configObject.apiBaseUrl}/Setup/Lga`, configObject.authConfig)
            .then(resp => {
                this.lgas = resp.data.sort(this.sortFunc("name"));
            });
        },
        getStateLga(_stateId){            
            console.log('Fetching states >><< ' + _stateId);
            this.stateLgas = this.lgas.filter(a => a.stateId === _stateId).sort(this.sortFunc("name"));
            console.log(this.stateLgas);

            // this.axios.get(`${configObject.apiBaseUrl}/Setup/StateLga/${_stateId}`, configObject.authConfig)
            // .then(resp => {
            //     console.log(resp.data);
            //     this.stateLgas = resp.data;
            // });


        },
        stateChanged(){
            console.log("STATE CHANGED");
            console.log(this.state);
            let _id = this.states.find(state => state.name === this.branch.state).id;
            console.log(_id);
            this.getStateLga(_id);
        },
        editBranch() {
            let data = {
                name: this.branch.name,
                objective: this.branch.objective,
                zonalOfficeName: this.branch.zonalOfficeName,
                id:  this.branch.id,
                isZonalOffice: this.branch.isZonalOffice,
                address: {
                    "streetName": this.branch.streetName,
                    "city": this.branch.city,
                    "lga": this.branch.lga,
                    "state": this.branch.state,
                    "id" : this.branch.addressId,
                }
            };
            console.log(data);
            this.showLoader = true;
            this.isButtonDisabled = true;
            this.axios.put(`${configObject.apiBaseUrl}/Branch/Edit/${this.branch.id}`, data,  configObject.authConfig)
                .then(response => {
                    this.showLoader = false;
                    this.isButtonDisabled = true;
                    console.log(response.data);
                    this.closeCreateBranchModal();
                    this.$eventHub.$emit("refreshBranchTable");
                    this.$toast.success("Branch Updated successfully");
                    this.isButtonDisabled = false;
                    this.branch.streetName =    null;
                    this.branch.city = null
                    this.branch.lga = null
                    this.branch.statem= null;
                    this.branch.addressId = null;
                    })
                    .catch((error) => {
                        this.isButtonDisabled = false;
                        this.showLoader = false;
                        this.$toast.open({
                            message: "unable to edit branch",
                            type: "error",
                            duration: 4000
                        });
                        console.log(error);
                });
        },
    }
}
</script>

<style scoped>
.cas_input .input_wrapper {
    margin-bottom: 5px;
}
.cas_input select, .cas_input [type='text']{
    margin-bottom: 5px;
}
</style>