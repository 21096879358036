<template>
<modal name="branchDetail" transition="pop-out" :width="modalWidth" :height="700">
  <div class="modal__header">
      <h4>{{branch.name}}</h4>
  </div>
  <div class="create__branch__section__form mt-5">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-6">
                <div class="login__input" style="width: 100%">
                    <span>Branch Name</span>
                    <div class="input___group">
                        <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" />
                        <input type="text" placeholder="branch name" class="ml-3" v-model="branch.name" disabled="disabled"/>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="login__input" style="width: 100%">
                    <span>Objective</span>
                    <div class="input___group">
                        <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" />
                        <input type="text" placeholder="Objective" class="ml-3" v-model="branch.objective" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 form_row_margin">
            <div class="col-md-6">
                <div class="login__input" style="width: 100%">
                    <span>Zonal Office  Name</span>
                    <div class="input___group">
                        <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" />
                        <input type="text" placeholder="eben@dpr.com.ng" class="ml-3" v-model="branch.zonalOfficeName" disabled="disabled" />
                    </div>
                </div>
            </div>
        </div>
        <span class="row__wraapper__span">Branch Information</span>
      </div>
      
     <div class="row__wrapper mt-3">
         <div class="row mt-4 form_row_margin">
            <div class="col-md-6">
                <div class="login__input" style="width: 100%">
                    <span>Street</span>
                    <div class="input___group">
                        <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" />
                        <input type="text" placeholder="eben@dpr.com.ng" class="ml-3" v-model="branch.street" disabled="disabled" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="login__input" style="width: 100%">
                    <span>City</span>
                    <div class="input___group">
                        <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" />
                        <input type="text" placeholder="eben@dpr.com.ng" class="ml-3" v-model="branch.city" disabled="disabled" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4 form_row_margin">
            <div class="col-md-6">
                <div class="login__input" style="width: 100%">
                    <span>LGA</span>
                    <div class="input___group">
                        <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" />
                        <input type="text" placeholder="eben@dpr.com.ng" class="ml-3"  v-model="branch.lga" disabled="disabled"/>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="login__input" style="width: 100%">
                    <span>State</span>
                    <div class="input___group">
                        <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" />
                        <input type="text" placeholder="eben@dpr.com.ng" class="ml-3" v-model="branch.state" disabled="disabled"/>
                    </div>
                </div>
            </div>
        </div>
        <span class="row__wraapper__span">Branch Address</span>
     </div>
     <div class="text-center mt-4">
        <button class="creaet_branch_btn"
            @click="changeRoute('map_branch_to_unit')">
            Map Branch To Unit
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 850;
import configObject from "@/config";

export default {
  name: 'branchDetail',
  props:  ['branch'],
  data () {
    return {
      modalWidth: MODAL_WIDTH,
      isButtonDisabled:  false,
      showLoader: false,
    }
  },

  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

    console.log(configObject.authConfig);

  },
  methods: {
    changeRoute($routeName) {
        this.$router.push({name: $routeName , query: {
            id: this.branch.id
        }});
    },
    closeCreateBranchModal(){
        this.$modal.hide('editBranchModal');
    },
     validateDetails($event) {
        $event.preventDefault();
        if(!this.branch.name || !this.branch.objective || !this.branch.zonalOfficeName || !this.branch.city || !this.branch.street || !this.branch.lga || !this.branch.state){
            this.$toasted.show("Fill in the missing Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
        }else {
            this.editBranch();
        }
    },
    editBranch() {
        let data = {
            name: this.branch.name,
            objective: this.branch.objective,
            zonalOfficeName: this.branch.zonalOfficeName,
            id:  this.branch.id,
            address: {
                "streetName": this.branch.street,
                "city": this.branch.city,
                "lga": this.branch.lga,
                "state": this.branch.state,
                "id" : this.branch.addressId
            }
        };
        console.log(data);
        this.showLoader = true;
        this.isButtonDisabled = true;
        this.axios.put(`${configObject.apiBaseUrl}/Branch/Edit/${this.branch.id}`, data,  configObject.authConfig)
            .then(response => {
                this.showLoader = false;
                this.isButtonDisabled = true;
                console.log(response.data);
                this.closeCreateBranchModal();
                this.$eventHub.$emit("refreshBranchTable");
                this.$toast.success("Branch Updated successfully");
                this.isButtonDisabled = false;
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "unable to edit branch",
                        type: "error",
                        duration: 3000
                    });
                    console.log(error);
            });
    },
  }
}
</script>
