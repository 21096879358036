<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">

             <div class="welcome_message">
                 <div class="row">
                     <div class="col-6">
                        <h5 class=''>
                            Map Competencies to Designation <span class="greeeting__text">"{{designationName}}"</span>.
                            There are <span class="branches__count"> {{competencies.length}}</span> competencies available
                        </h5>
                     </div>
                     <div class="col-6 text-right">
                        <div>
                            <button class="creaet_branch_btn default" @click="backToDesignation"><i class="fa fa-arrow-left"></i> Back</button>
                            <button class="creaet_branch_btn" @click="mapcompetenciesToDesignation"
                                :disabled="isButtonDisabled ? true : null"
                                :style="[
                                isButtonDisabled
                                    ? { cursor: 'not-allowed' }
                                    : { cursor: 'pointer' }
                                ]">
                                <span class="">
                                    Map Competencies to Designation
                                    <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                                </span>
                            </button>
                        </div>
                     </div>
                 </div>
             </div>
             <section class="table__section mt-1 pb-5">
                <ejs-grid
                    ref="dataGrid"
                    :datasource=competencies
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <!-- <e-column headerText="#"  :headerTemplate="selectAllTemplate" :template="checkBoxTemplate" width="50px"></e-column> -->
                        <e-column headerText="#" :template="checkBoxTemplate" width="50px"></e-column>
                        <e-column field="name" headerText="Competency"></e-column>
                        <e-column :template="competencyLevelDropDownTemplate" headerText="Competency Level" width="150px"></e-column>
                        <e-column :template="deletedTemplate" width="120px" headerText="Status"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
            <div class="empty_branch__div mt-3" v-if="!competenciesFetched"> 
                <div class="text-center">
                    <h6 class="text-center"> Please wait while we get competencies</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex'
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

var competencyLevelSelectTemplate = Vue.component("competencyLevelSelectTemplate", {
    template:  '<select v-on:change="getLevel()" v-bind:data-cid="`${data.id}`" class="geLev" v-model="data.levelId">' +
                    '<option value="">Select Level</option>' +
                    '<option v-for="(cpLvl, i) in data.levels" v-bind:value="cpLvl.id" v-bind:key="cpLvl.id" ' +
                    '>{{cpLvl.level}}</option>'+                    
                '</select>',
    //template: '<ejs-button :content="`${data.ShipCountry}`"></ejs-button>',
    data() {
        return {
            data: {},
        };
    },  
    methods: {
        // selectAll: function(e) {
        //     this.$eventHub.$emit("selectAll", e);
        // },
        getLevel() {
            console.log('Level Change :: Competency = ' + this.data.id + '; Level = ' + this.data.levelId);
            this.$eventHub.$emit("emitDoLevelSelect", this.data.id, this.data.levelId);
        },
        
    }
});

export default {
    components: {
        MasterLayout,
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            showLoader: false,
            isButtonDisabled: false,
            // competencyId: null,
            // competencLevelId: null,
            // componentKey: 0,
            competencies: [],
            competencyLevels: [],
            competenciesFetched: false,
            newMappedReqArray: [],
            competenciesArray: [],
            selections: [],
            designationName: '',
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            checkBoxTemplate: function() {
                return {
                    template: Vue.component("columnTemplate", {
                        template: `<div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="data.selected" v-on:change="competencySelected($event, data.id)" checked />
                                        <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="!data.selected" v-on:change="competencySelected($event, data.id)" />
                                        <label class="custom-control-label" v-bind:for="data.id"></label>
                                    </div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            competencySelected: function(e, id) {
                                this.$eventHub.$emit("competenciesCheck", e, id);
                            }
                        }
                    })
                };
            },
            competencyLevelDropDownTemplate: function(e) {
                return {
                    template: competencyLevelSelectTemplate
                };
            },
            selectAllTemplate: function() {
                return {
                template: Vue.component("columnTemplate", {
                    template: `<div><input type="checkbox" v-on:change="_selectAll($event)"/></div>`,
                    data: function() {
                        return {
                            data: {}
                        };
                    },
                    methods: {
                    _selectAll: function(e) {
                        this.$eventHub.$emit("selectAll", e);
                    }
                    }
                })
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>`
                            + `<div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
                        data: function() {
                            return {
                                data: {} 
                            };
                        }
                    })
                }
            }
        }
    },
    created(){
        this.designationName = this.$route.query.name;
        this.getCompetencyLevels();
        this.$eventHub.$on("competenciesCheck", (event, id) => {
           this.createCompetencySelections(event, id);
        }); 

        this.$eventHub.$on("emitDoLevelSelect", (competencyId, compLvlId) => {
            this.doLevelSelect(competencyId, compLvlId);
        });
    },
    mounted() {
        console.log('Second CALL');
        this.getCompetencies();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    methods: {
        doLevelSelect(competencyId, compLvlId){
            // let cpId = parseInt(competencyId);
            // let checkCpIndex = this.$store.getters.getSelectedCompLevelsArray.findIndex(cps => cps.compId === cpId);
            let comp = this.competencies.findIndex(a => a.id === competencyId);
            //console.log('Index for 2 >> ' + comp);
            if(comp !== -1){
                if(compLvlId > 0){
                    this.competencies[comp].selected = true;
                    this.competencies[comp].levelId = compLvlId;
                }
                else{
                    this.competencies[comp].selected = false;
                    this.competencies[comp].levelId = 0;
                }
                this.refreshGrid();
                // let sel = this.selections.findIndex(a => a.id === cpId);
                // console.log(sel);
                // if(sel > -1){
                //     this.selections[sel].levelId = compLvlId;
                // }
                // else{
                //     this.selections.push({
                //         id: 0, levelId: compLvlId
                //     });
                // }
                // console.log(this.selections);
            }

            // if(checkCpIndex === -1){
            //     const payload = {
            //         compId: cpId,
            //         competencyLevelId: compLvlId
            //     }
            //     this.$store.commit("setSelectedCompLevelsArray" , payload)
            // }else {
            //     const payload = {
            //         compIndex: checkCpIndex, 
            //         compsProps: {
            //             compId: cpId,
            //             competencyLevelId: compLvlId
            //         }
            //     }
            //    this.$store.commit("updateCompsDropDownArray", payload);
            // }
        },
        createCompetencySelections(event, id){
            let comp = this.competencies.findIndex(a => a.id === id);
            if(comp !== -1){
                if (event.target.checked){
                    // Selected
                    this.competencies[comp].selected = true;
                    this.refreshGrid();
                }
                else{
                    // Unselected
                    this.competencies[comp].selected = false;
                    this.refreshGrid();
                }
            }
            // console.log('Selection >> ' + id);
            // let pick = this.selections.findIndex(d => d.id === id);
            // console.log('Index: ' + pick); // === null ? 'NO' : 'YES');
            // if (!event.target.checked) {
            //     console.log('Un-selected');
            //     if(pick !== -1){
            //         console.log('Before >>> ');
            //         console.log(this.selections);
            //         this.selections = this.selections.filter(d => d.id !== id);           
            //         console.log('After >>> ');
            //         console.log(this.selections);
            //     }
            //     // let newMappedReqArray = this.competenciesArray.filter(requirement => requirement.id !== reqInfo.id);
            //     // this.competenciesArray = newMappedReqArray;
            // } else {
            //     console.log('Selected');
            //     if(pick < 0){
            //         this.selections.push({
            //             id: id
            //         });
            //     }
            //     // this.competenciesArray.push({
            //     //     id: reqInfo.id
            //     // });
            // }
            // console.log(this.selections);
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        mapcompetenciesToDesignation(){
            this.showLoader = true;
            this.isButtonDisabled = true;
            let dataArray = [];
            this.competencies.forEach(c => {
                if(c.selected){
                    dataArray.push({
                        "competencyId": c.id,
                        "competencyLevelId": c.levelId
                    })
                }
            });
            if(dataArray.length < 1){
                this.$toast.open({
                    message: "Please select atleast one competency (and its level) before proceeding",
                    type: "error",
                    duration: 4000
                });
                this.showLoader = false;
                this.isButtonDisabled = false;
                return;
            }
            var check = dataArray.findIndex(a => a.competencyId === 0 || a.competencyLevelId === 0);
            //console.log(check);
            if(check >= 0){
                this.$toast.open({
                    message: "Please ensure that competency level is selected for all selected competency(ies)",
                    type: "error",
                    duration: 4000
                });
                this.showLoader = false;
                this.isButtonDisabled = false;
                return;
            }
            let data = {};
            data.designationId = parseInt(this.$route.query.id);
            data.competencies = dataArray;
            //console.log(data);

            let url = `${configObject.apiBaseUrl}/Designation/Competencies/Add`;
            this.axios.post(url, data, configObject.authConfig)
                .then((res) => {
                    console.log(res.data);
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    this.$toast.success("Competencies mapped to designation successfully!");
                    })
                    .catch((error) => {
                        this.isButtonDisabled = false;
                        this.showLoader = false;
                        this.$toast.open({
                            message: "Unable to map competecies to designation",
                            type: "error",
                            duration: 3000
                        });
                        console.log(error);
                });
        },
        mapcompetenciesToDesignation_old(reqId) {
            let compsArray = this.$store.getters.getSelectedCompLevelsArray;
            let newCompsArry = [];
            compsArray.forEach(cpArr => {
                newCompsArry.push({
                    "competencyId": parseInt(cpArr.compId),
                    "competencyLevelId": parseInt(cpArr.competencyLevelId)
                })
            })
            this.showLoader = true;
            this.isButtonDisabled = true;
            let mappedcompetenciesArrayToSend = [];
            this.competenciesArray.forEach((req) => {
                mappedcompetenciesArrayToSend.push(req.id);
            });
            let data = {};
            data.designationId = this.$route.query.id;
            data.competencies = newCompsArry;
            console.log(this.competenciesArray);
            console.log(data.competencies);
            
            if(this.competenciesArray.length < 1 &&  data.competencies.length < 1){
                this.$toast.open({
                    message: "Please check competency and  select competency level to add",
                    type: "error",
                    duration: 3000
                });
                this.showLoader = false;
                this.isButtonDisabled = false;
                return;
            }
            if(this.competenciesArray.length !== data.competencies.length){
                this.$toast.open({
                    message: "Please check competency and  select competency level to add",
                    type: "error",
                    duration: 3000
                });
                this.showLoader = false;
                this.isButtonDisabled = false;
                return;
            }else {
                let compIDs = [];
                this.competenciesArray.forEach(cp => {
                    compIDs.push(cp.id);
                })
                data.competencies.forEach(dcp => {
                    if(!compIDs.includes(dcp.competencyId)){
                         this.$toast.open({
                            message: "Please add competency Levels to the checked competencies",
                            type: "error",
                            duration: 3000
                        });
                        this.showLoader = false;
                        this.isButtonDisabled = false;
                        return;
                    }
                })
            }
            console.log(data);
            let url = `${configObject.apiBaseUrl}/Designation/Competencies/Add`;
            this.axios.post(url, data, configObject.authConfig)
                .then((res) => {
                    console.log(res.data);
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    this.$toast.success("Competencies mapped to designation successfully!");
                    })
                    .catch((error) => {
                        this.isButtonDisabled = false;
                        this.showLoader = false;
                        this.$toast.open({
                            message: "Unable to map competecies to designation",
                            type: "error",
                            duration: 3000
                        });
                        console.log(error);
                });
        },
        getCompetencies() {
            let grid = this.$refs.dataGrid;
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Competency/ForMapToDesignation/${this.$route.query.id}`,
                    configObject.authConfig
                )
                .then(response => {
                    //console.log(response.data);
                    response.data.forEach(element => {
                        element.levels = this.competencyLevels;
                        // if(element.selected){
                        //     this.selections.push({
                        //         id: element.id, levelId: element.levelId
                        //     });
                        // }
                    });
                    this.competencies = response.data;
                    this.competenciesFetched = true;
                    console.log(this.selections);
                    grid.ej2Instances.setProperties({ dataSource: this.competencies });
                    this.refreshGrid();
                })
                .catch((error) => {
                    console.log(error);
                    // this.$toast.open({
                    //     message: "unable to get competencies",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
            
        },
        getCompetencyLevels(){
            this.axios.get(`${configObject.apiBaseUrl}/Competency/Level`, configObject.authConfig)
            .then(response => {
                this.competencyLevels = response.data;
            })
            .catch(error => {
                console.log(error);
                this.$toast.open({
                    message: "Unable to load Competency Levels",
                    type: "error",
                    duration: 4000
                });
            });
        },
        backToDesignation(){
            this.$router.push({
                name: "designation_profile_detail",
                query: {
                    id: this.$route.query.id
                }
            });
        }
    }
}
</script>

        