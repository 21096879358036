<template>
<modal name="createCompetencyModal" transition="pop-out" :width="modalWidth" :height="370">
  <div class="modal__header">
      <h4>Add New Competency</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-12">
                <div class="" style="width: 100%">
                    <span>Name</span>
                    <div class="">
                        <input type="text" placeholder="Enter a competency name" class="ml-3" v-model="compName"  />
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="" style="width: 100%">
                    <span>Select competency group</span>
                    <div class="">
                        <!-- <b-form-select
                            v-model="competencyGroup"
                            id="perPageSelect"
                            size="sm"
                            ref="compGpr"
                            class="ml-3"
                            :options="competencyGroups">
                        </b-form-select> -->
                        <select ref="compGpr" class="ml-3" v-model="groupId">
                            <option value="">Select competency group</option>
                            <option :value="compGroup.id" v-for="(compGroup,i) in competencyGroups" :key="i"  required>{{compGroup.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row mt-3">
            <div class="col-md-12">
                <div class="" style="width: 100%">
                    <span>Select Competency Level</span>
                    <div class="">
                        <select ref="compLvl" class="ml-3" v-model="compLevel">
                            <option value="">Select competency Level</option>
                            <option :value="comLevel.id" v-for="(comLevel,i) in competencyLevels" :key="i"  required>{{comLevel.level}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div> -->
      </div>
      
      <div class="text-center mt-4">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Add Competency 
            <!-- <i class="fa fa-save"></i>
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span> -->
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 650;
import configObject from "@/config";

export default {
  name: 'createCompetencyModal',
  props: ['competencyGroups', 'groupId'],
  data () {
    return {
      modalWidth: MODAL_WIDTH,
      isButtonDisabled:  false,
      showLoader: false,
      //: null,
      competencyLevels: null,
      competencyGroup: null,
      compLevel: null,
      compName:null
    }
  },
  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

    //console.log(this.$store.getters.getBranchdetails);
    
    // this.getCompetencyGroups();
    // this.getCompetencyLevels();
  },
  methods: {
      validateDetails($event) {
        $event.preventDefault();
        if(!this.groupId || !this.compName){
            this.$toasted.show("Fill in the missing Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
        }else {
            this.createCompetency();
        }
    },
    closeModal(){
        this.$modal.hide('createCompetencyModal');
    },
    createCompetency() {
        let dataToSend = {
            name: this.compName,
            competencyGroupId: this.groupId,
            competencyLevelId: this.compLevel
        };
        console.log(dataToSend);
        this.showLoader = true;
        this.isButtonDisabled = true;
        this.axios.post(`${configObject.apiBaseUrl}/Competency/Add`, dataToSend, configObject.authConfig)
            .then(() => {
                this.showLoader = false;
                this.isButtonDisabled = false;
                this.closeModal();
                this.$eventHub.$emit("refreshCompetency");
                this.$toast.success("Competency created successfully");
                this.compName = null;
                this.competencyGroup = null;
                this.compLevel = null;
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    let msg = "Unable to create competency";
                    if(error.response.data.message){
                        msg = error.response.data.message;
                    }
                    this.$toast.open({
                        message: msg,
                        type: "error",
                        duration: 3000
                    });
                    console.log(error);
            });
    },
  }
}
</script>
