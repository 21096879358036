<template>
    <div>
        <MasterLayout>
            <div class="employees__assessment__wrapper">

                <div class="welcome_message">
                    <div class="row">
                        <div class="col-6">    
                            <h5 class=''><span class="greeeting__text"> Hi Admin,
                                </span> you have <span class="branches__count"> {{supervisors.length}} </span> Supervisors
                            </h5> 
                        </div>
                        <div class="col-6 text-right">
                            <div>
                                <button class="creaet_branch_btn" @click="openCreateModal">Add Supervisor</button>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="table__section mt-1 pb-5" v-show="supervisors.length > 0 || !showLoader">
                    <ejs-grid
                        ref="dataGrid"
                        :created="refreshGrid"
                        :allowPaging="true"
                        :allowSorting="true"
                        :pageSettings="pageSettings"
                        :toolbar="toolbar"
                        :searchSettings="search"
                        :allowExcelExport="true"
                        :allowPdfExport="true"
                        :toolbarClick="toolbarClick">
                        <e-columns>
                            <e-column field="index" headerText="#" width="60px"></e-column>
                            <e-column field="fullName" headerText="Full Name" width="150px"></e-column>
                            <e-column field="email" headerText="Email" ></e-column>
                            <e-column field="divisionName" headerText="Division" ></e-column>
                            <e-column field="branchName" headerText="Branch"></e-column>
                            <e-column field="unitName" headerText="Unit"></e-column>
                            <e-column :template="actionButtonsTemplate" width="150px" headerText="Actions"></e-column>
                        </e-columns>
                    </ejs-grid>
                </section>
                <div class="empty_branch__div mt-3" v-if="supervisors.length < 1 && !showLoader">
                    <div class="text-center">
                        <h6 class="text-center"> You are yet to create a Employee</h6>
                        <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                    </div>
                </div>
                <TableLoader v-show="showLoader"></TableLoader>
                
                <!-- <BatchEmployeeModal></BatchEmployeeModal>
                <EditEmployeeModal :employee="employee" :grades="grades"></EditEmployeeModal> -->
            </div>
        </MasterLayout>
        <CreateModal></CreateModal>
    </div>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/avatar.svg";
import CreateModal from '@/components/Modals/Supervisor/CreateModal.vue';
// import EditEmployeeModal from '@/components/Modals/Employee/editEmployeeModal.vue';
// import BatchEmployeeModal from '@/components/Modals/Employee/createBatchEmployeeModal.vue';
import TableLoader from '@/components/TableLoading.vue';
import configObject from "@/config";
import JQuery from "jquery";
import moment from "moment";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    title: 'All Supervisors',
    components: {
        MasterLayout,
        CreateModal,
        // BatchEmployeeModal,
        // EditEmployeeModal,
        TableLoader
    },
    provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            backgroundUrl,
            UserDP,
            componentKey: 0,
            supervisors: [],
            supervisor: {},
            divisions: [],
            branches: [],
            units: [],
            showLoader: false,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        
                        '<div class="btn-group btn-group-sm" role="group">'+
                            // '<button type="button" class="btn btn-primary" title="Edit" v-on:click="_editEmployee(data.id)">' +
                            //     '<i class="fa fa-edit" aria-hidden="true"></i>' +
                            // '</button>' +
                            '<button type="button" class="btn btn-danger" title="Delete" v-on:click="_deleteEmployeeSupervisor(data.id)">' +
                                '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _deleteEmployeeSupervisor: function(id) {
                                this.$eventHub.$emit("deleteSupervisor", id);
                            },
                            // _editEmployee: function(id) {
                            //     this.$eventHub.$emit("editEmployeeVM", id);
                            // },
                            _viewEmployee: function(id){
                                this.$eventHub.$emit("viewEmployeeVM", id);
                            },
                            _mapCompetencies: function(employeeId) {
                                this.$eventHub.$emit("mapCompetenciesVM", employeeId);
                            },
                        }
                    })
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>` +
                            `<div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            }
        }
    },
     mounted() {
        this.getSupervisors();
        //this.getGrades();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created() {
        // this.$eventHub.$on("editEmployeeVM", (id) => {
        //     this.editEmployee(id);
        // });
        this.$eventHub.$on("deleteSupervisor", (id) => {
            this.deleteSupervisor(id);
        });
        this.$eventHub.$on("viewEmployeeVM", (id) => {
            this.viewEmployee(id);
        });
        this.$eventHub.$on("refreshSupervisorTable", () => {
            this.getSupervisors();
        });
        this.$eventHub.$on("mapCompetenciesVM", (employeeId) => {
            this.mapCompetencies(employeeId);
        });
        
    },
    methods: {
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                    // this.$refs.dataGrid.getColumns()[6].visible = true;
                    this.$refs.dataGrid.getColumns()[6].visible = false;
                    this.$refs.dataGrid.pdfExport({
                        pageOrientation: 'Landscape'
                    });
                    this.$refs.dataGrid.pdfExport({
                        pageOrientation: 'Landscape',
                        fileName: 'Employees.pdf',
                        theme: {
                            header: {
                                bold: true,
                                fontColor: "#000000",
                                fontName: 'Calibri',
                                fontSize: 10
                            },
                            record: {
                                fontColor: '#000000',
                                fontName: "Calibri",
                                fontSize: 8
                            }
                        },
                        header: {
                            fromTop:0,
                            height: 130,
                            contents:  [{
                                type: "Text",
                                value:  'Employees',
                                position: {x:0, y: 50},
                                style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],                        
                        }
                    });
                    break;                
                case "Excel Export":
                    // this.$refs.dataGrid.getColumns()[6].visible = true;
                    this.$refs.dataGrid.getColumns()[6].visible = false;
                    this.$refs.dataGrid.excelExport({
                        fileName: 'Employees.xlsx',
                        theme: {
                            header: {
                                bold: true,
                                fontColor: "#000000",
                                fontName: 'Calibri',
                                fontSize: 10
                            },
                            record: {
                                fontColor: '#000000',
                                fontName: "Calibri",
                                fontSize: 8
                            }
                        },
                        header: {
                            fromTop:0,
                            height: 130,
                            contents:  [{
                                type: "Text",
                                value:  `Employees`,
                                position: {x:0, y: 50},
                                style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                                type: "Text",
                                value: "Thank You",
                                position: {x:0, y: 50},
                                style: {fontSize: 20}
                            }],                        
                        }
                    });
                    break;
            }
            // this.$refs.dataGrid.getColumns()[6].visible = false;
            this.$refs.dataGrid.getColumns()[6].visible = true;
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        openCreateModal(){
            this.$modal.show('createModal');
        },
        // openEditUnitModal(){
        //     this.$modal.show('editEmployeeModal');
        // },
        // editSupervisor(id) {
        //     let emp = this.supervisors.filter(e => e.id === id)[0];
        //     emp.oldEmail = emp.email;
        //     emp.employmentDate = moment(emp.employmentDate).format('YYYY-MM-DD');
        //     this.employee = emp;
        //     this.openEditUnitModal();
        // },
        deleteSupervisor(id) {
            var resp = confirm("Are you sure  you want to delete this employee");
            if (resp) {
                this.axios
                    .delete(
                        `${configObject.apiBaseUrl}/Employee/Supervisor/${id}`,
                        configObject.authConfig
                    )
                    .then(() => {
                        this.getSupervisors();
                        this.$toast.open({
                            message: "Supervisor deleted successfully!",
                            type: "success",
                            duration: 3000
                        });
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: "error",
                            duration: 3000
                        });
                    });
            }
        },
        getSupervisors() {
            this.showLoader = true;
            let grid = this.$refs.dataGrid;
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Employee/supervisors`,
                    configObject.authConfig
                )
                .then(response => {
                    let index = 0;
                    response.data.forEach(supv => {
                        index++;
                        supv.index = index;
                        supv.fullName = supv.firstname + " " + supv.surname
                    });

                    this.showLoader = false;

                    grid.ej2Instances.setProperties({ dataSource: response.data });
                    this.refreshGrid();
                    this.supervisors = response.data;
                })
                .catch((error) => {
                    // this.$toast.open({
                    //     message: "unable to load employees",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
        
    }
}
</script>

        