<template>
    <MasterLayout>
        <div class="employee__assessment__wrapper">
            <div class="welcome_message">
                 <div class="row">
                     <div class="col-8">    
                         <h5 class=''><span class="greeeting__text"> Configure Employee "{{employee.firstname}} {{employee.surname}}" Designation Roles</span>
                            <!-- </span> you have <span class="branches__count"> {{divisions.length}} </span> Divisions -->
                        </h5> 
                     </div>
                     <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn default" @click="backToEmployee"><i class="fa fa-arrow-left"></i> Back</button>
                            <button class="creaet_branch_btn" @click="saveSetup($event)">Save Setup</button>
                        </div>
                     </div>
                 </div>
             </div>
            <section class="table__section mt-1 pb-5" v-show="designations.length > 0">
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" width="60px" headerText="#"></e-column>
                        <e-column :template="radioTemplate" width="60px" headerText=""></e-column>
                        <e-column field="designationName" headerText="Designation"></e-column>
                        <e-column field="divisionName" headerText="Division"></e-column>
                        <e-column field="branchName" headerText="Branch"></e-column>
                        <e-column field="unitName" headerText="Unit"></e-column>
                        <e-column field="gradeName" headerText="Grade" width="100"></e-column>
                        <!-- <e-column :template="DeletedTemplate" width="150px" headerText="Status"></e-column> -->
                    </e-columns>
                </ejs-grid>
            </section>
        </div>
    </MasterLayout>
</template>

<script>

import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;

import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    components:{
        MasterLayout
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data(){
        return{
            employee: {},
            designations: [],
            designationId: 0,

            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            radioTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input current" name="Old_designationId" v-bind:id="data.id" v-if="data.selected" v-on:change="_changeDesignation(data.id)" checked />
                                    <input type="radio" class="custom-control-input" name="designationId" v-bind:id="data.id" v-if="!data.selected" v-on:change="_changeDesignation(data.id)" />
                                    <label class="custom-control-label" v-bind:for="data.id" v-if="data.selected"></label>
                                    <label class="custom-control-label new-val" v-if="!data.selected" v-bind:for="data.id"></label>
                                </div>
                                `,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _changeDesignation: function(id){
                                this.$eventHub.$emit("changeDesignation", id);
                            }
                        }
                    })
                }
            },
            
        }
    },
    created(){
        //this.employeeId = parseInt(this.$route.query.id);
        this.$eventHub.$on("changeDesignation", (id) => {
            this.selectionChange(id);
        });
    },
    mounted(){        
        this.getEmployeeSetup();
        //this.getDesignationProfiles();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    methods:{
        getEmployeeSetup() {
            let grid = this.$refs.dataGrid;
            this.axios
                .get(`${configObject.apiBaseUrl}/Designation/Profile/EmployeeSetup/${parseInt(this.$route.query.id)}`, configObject.authConfig)
                .then(response => {
                    this.employee = response.data.employee;
                    let index = 0;
                    response.data.designationProfiles.forEach(profile => {
                        index++;
                        profile.index = index;
                        if(profile.id === this.employee.designationProfileId){
                            profile.selected = true;
                        }
                        else{
                            profile.selected = false;
                        }
                    });
                    this.designations = response.data.designationProfiles;
                    //console.log(this.designations);
                    grid.ej2Instances.setProperties({ dataSource: this.designations });
                    this.refreshGrid();                    
                })
                .catch((error) => {
                    console.log(error);
                    
                });
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        saveSetup($event){
            $event.preventDefault();
            //console.log(this.designationId);
            if(!this.designationId || this.designationId <= 0){
                console.log('No designation Profile selected');
                this.$toast.open({
                    message: "No designation Profile selected. Please select one Profile to add to employee",
                    type: "error",
                    duration: 3000
                });
            }
            else{
                let selectedDesignation = this.designations.filter(a => a.id === this.designationId)[0];
                let conf = confirm(`Are you sure you want to assign "${this.employee.firstname} ${this.employee.surname}" as a "${selectedDesignation.designationName} (${selectedDesignation.branchName})"`);

                if(conf){
                    let data = {
                        employeeId: this.employee.id,
                        designationId: this.designationId
                    };
                    console.log(data);
                    
                    this.axios.post(`${configObject.apiBaseUrl}/Employee/Setup`, data, configObject.authConfig)
                    .then(() => {
                        this.$toast.open({
                            message: "Employee designation Profilec onfigured successfully",
                            type: "success",
                            duration: 4000
                        });
                        this.getEmployeeSetup();
                        //this.backToEmployee();
                    })
                    .catch((error) => {
                        console.log(error);                    
                    });
                }
            }
        },
        selectionChange(id){
            this.designationId = id;
        },        
        backToEmployee(){
            this.$router.push({name: 'employee_assessment', query: {
                id: this.employee.id
            }});
        }
    }
}
</script>
