<template>
    <MasterLayout>
        <div class="welcome_message">
            <div class="row">
                <div class="col-9">    
                    <h5 class='' v-if="loaded">
                        <!-- <span class="greeeting__text"> Hi Admin, </span> -->
                        Showing <span class="branches__count"> {{pageEmployees.length}} </span> 
                        <span v-if="pageEmployees.length > 1">results</span><span v-if="pageEmployees.length < 2">result</span> in 
                        <span class="branches__count">{{pageObject.branchName}}</span>, <span class="branches__count">{{pageObject.divisionName}}</span> division
                    </h5>
                    <h5 v-if="loading">Loading... please wait</h5>
                    <h5 v-if="id === null">Please select Division to search</h5>
                </div>
                <div class="col-3 text-right">
                    <button @click="backToPrevious()" class="creaet_branch_btn default"><i class="fa fa-arrow-left"></i> Back</button>
                    <!-- <div class="search_filter"> 
                        <ejs-dropdownlist id='dropdownlist' placeholder='Select a Divisions' 
                            v-model="id"
                            :allowFiltering='allowFiltering' 
                            :filtering='filtering' 
                            :dataSource='divisions' 
                            :fields='fields'></ejs-dropdownlist>
                        <button class="creaet_branch_btn" @click="findSuccessor">
                            Search <i class="fa fa-search"></i>
                        </button>
                        <div class="clear"></div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="employees__assessment__wrapper">
            <section class="table__section mt-1 pb-5 tall_table_header" >
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" headerText="#" width="60px"></e-column>
                        <e-column field="fullName" headerText="Employee Name"></e-column>
                        <e-column field="designationName" headerText="Designation"></e-column>
                        <e-column field="unitName" headerText="Unit Name"></e-column>
                        <!-- <e-column field="email" headerText="Email"></e-column> -->
                        <e-column :template="competencyTemplate" :headerTemplate="headerTemplate" headerText="Evaluation" width="300px"></e-column>
                        <e-column :template="actionButtonsTemplate" headerText="Action"  width="150px"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
        </div>
        <div class="employees__assessment__wrapper">

        </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    components: {
        MasterLayout,
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            id: null,
            branchId: null,
            division: {},
            divisions: [],
            pageObject:{},
            pageEmployees: [],
            branches: [],
            loaded: false,
            loading: false,
            showLoader: false,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            fields : { text: "name", value: "id" },
            allowFiltering: true,
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            `<a v-bind:href="'/employee_assessment?id=' + data.id" class="btn btn-xs btn-primary" title="Details">` +
                                'View Employee' +
                            '</a>' +
                            // '<button type="button" class="btn btn-danger" title="Delete" v-on:click="deleteUnit(data.id)" v-if="data.deleted === false">' +
                            //     '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                            // '</button>' +
                            // '<button type="button" class="btn btn-success" title="Re-Add" v-on:click="deleteUnit(data.id)" v-if="data.deleted === true">' +
                            //     '<i class="fa fa-plus" aria-hidden="true"></i>' +
                            // '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _details: function(id) {
                                this.$eventHub.$emit("detailsVM", id);
                            },
                        }
                    })
                };
            },
            progressBarTemplate: function(){
                return {
                     template: Vue.component("deletedTemplate", {
                        template:   '<div>'  +
                                        '<div id="progressbar">' +
                                            '<div class="progress__bar__wrapper">'+
                                                '<div class="inner__div"></div>'+
                                            '</div>' +
                                        '</div>'+
                                        '<div class="text-center">50%</div>' +
                                    '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            },
            competencyTemplate: function(){
                return {
                    template: Vue.component("competencyTemplate", {
                        template: '<div class="row">' + 
                                    `<div class="col-4"><div class="text-center">{{data.totalPoints}}</div></div>` +
                                    `<div class="col-4"><div class="text-center">{{data.totalRequired}}</div></div>` +
                                    `<div class="col-4"><div class="text-center">{{data.gap}} <i class="fa" v-bind:class="data.arrow"></i></div></div>` +
                                    '</div>',
                        data: function(){
                            return {
                                data: {}
                            }
                        },
                        methods:{

                        }
                    })
                }
            },
            headerTemplate: function(){
                return {
                    template: Vue.component("headerTemplate", {
                        template: '<div class="row">' +
                                    '<div class="col-12"><div class="text-center" style="border-bottom: 1px solid;">Competency</div></div>' + 
                                    '<div class="col-4"><div class="text-center">Basic</div></div>' +
                                    '<div class="col-4"><div class="text-center">Expected</div></div>' +
                                    '<div class="col-4"><div class="text-center">Gap</div></div>' +
                                    '</div>',
                        data: function(){
                            return {
                                data: {}
                            }
                        },
                        methods:{
                            
                        }
                    })
                }
            }
        }
    },
    mounted() {
        this.getData();
        //this.getDivisions();
        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created() {
        this.id = this.$route.query.id ? parseInt(this.$route.query.id) : null;
        this.branchId = this.$route.query.branchid ? parseInt(this.$route.query.branchid) : null;
        this.$eventHub.$on("detailsVM", (id) => {
            this.viewDetails(id);
        });
    },
    methods: {
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        getData(){
            let grid = this.$refs.dataGrid;
 
            if(this.id !== null && this.branchId !== null){
                this.loading = true;
                this.loaded = false;
                this.axios
                .get(`${configObject.apiBaseUrl}/GapAnalysis?divisionid=${this.id}&branchid=${this.branchId}`, configObject.authConfig)
                .then(response => {
                    let index = 0;
                    let empList = [];
                    response.data.profiles.forEach(a => {
                        a.employees.forEach(emp => {
                            index++;
                            emp.fullName = emp.firstname + ' ' + emp.surname;
                            let g= parseInt(emp.totalPoints) - parseInt(a.totalRequiredPoints);
                            emp.index = index;
                            emp.gap = Math.abs(g);
                            emp.totalRequired = a.totalRequiredPoints;
                            emp.arrow = g > 0 ? "fa-caret-up green" : g < 0 ? "fa-caret-down red" : "fa-minus gray"
                            empList.push(emp);
                        })
                    });
                    let data = {};
                    data.divisionId = response.data.divisionId;
                    data.divisionName = response.data.divisionName;
                    data.unitId = response.data.unitId;
                    data.unitName = response.data.unitName;
                    data.branchId = response.data.branchId;
                    data.branchName = response.data.branchName;

                    this.pageObject = data;
                    this.branches = response.data.profiles; //.sort(this.sortArrayReverse("totalGap", "designationName")); //response.data.employees;
                    this.pageEmployees = empList,

                    grid.ej2Instances.setProperties({ dataSource: this.pageEmployees });
                    this.refreshGrid();
                    this.loading = false;
                    this.loaded = true;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.loaded = false;

                    this.$toast.open({
                        message: "Unable to load data",
                        type: "error",
                        duration: 4000
                    });
                });
            }
        },
        backToPrevious(eid){
            this.$router.push({
                //name: 'gap_analysis', 
                name: 'division_gap_analysis', 
                query: {
                    id: this.id
                }
            });
        }
        // getDivisions(){
        //     this.axios.get(`${configObject.apiBaseUrl}/Divisions`, configObject.authConfig)
        //     .then(response => {
        //         this.divisions = response.data;
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         this.$toast.open({
        //             message: "Unable to load Divisions",
        //             type: "error",
        //             duration: 4000
        //         });
        //     });
        // },
        // findSuccessor(){
        //     if(this.id === NaN || this.id === 'NaN' || this.id === null){
        //         alert('Please select a Division to find');
        //     }
        //     else{
        //         this.getData();
        //     }
        // },
        // filtering: function(e) {
        //    var query = new Query();
        //     //frame the query based on search string with filter type.
        //     query = (e.text != "") ? query.where("designationName", "startswith", e.text, true) : query;
        //     //pass the filter data source, filter query to updateData method.
        //     e.updateData(this.divisions, query);
        // },
    }
}
</script>

<style scoped>
    
</style>