<template>
    <MasterLayout>
        <div class="employees__assessment__wrapper">

            <div class="welcome_message">
                <div class="row">
                    <div class="col-7">    
                        <h5 class=''><span class="greeeting__text"> Hi {{userInfo.firstName}},
                            </span> you have <span class="branches__count">{{myAssessments.length}}</span> <span v-if="myAssessments.length < 2">Assessment</span><span  v-if="myAssessments.length > 1">Training</span> and
                             <span class="branches__count">{{training.length}}</span> <span v-if="training.length < 2">Training</span><span  v-if="training.length > 1">Trainings</span>
                        </h5> 
                    </div>
                    <div class="col-5 text-right">
                        <div>
                            <button class="creaet_branch_btn" @click="changeRoute('step_one', 'Assessment')">Create Assessment <i class="fa fa-plus-circle" style="" aria-hidden="true" ></i></button>
                            <button class="creaet_branch_btn" @click="changeRoute('step_one', 'Training')">Record Training <i class="fa fa-plus-circle" style="" aria-hidden="true" ></i></button>
                        </div>
                    </div>
                </div>
            </div>
             
            <div style="width: 97%; margin: 15px auto">
                <b-card no-body style="padding-bottom: 35px;">
                    <b-tabs card class="employee_tabs" style="margin: 0;">
                        <b-tab active>
                            <template v-slot:title>
                                <span class="sm-pad">Assessments</span>
                            </template>
                            <b-card-text class="employee-tab-content">
                                <div class="tab-content-body">                                
                                    <b-card  no-body class="for-panel">
                                        <ejs-grid
                                            ref="assessmentGrid"
                                            :created="refreshAssessmentGrid"
                                            :allowPaging="true"
                                            :allowSorting="true"
                                            :pageSettings="pageSettings"
                                            :toolbar="toolbar"
                                            :searchSettings="search"
                                            :allowExcelExport="true"
                                            :allowPdfExport="true"
                                            :toolbarClick="toolbarClick1">
                                            <e-columns>
                                                <e-column field="index" headerText="#" width="60px"></e-column>
                                                <!-- <e-column field="trainingDate" headerText="Training Date" width="120px"></e-column> -->
                                                <e-column field="dateAdded" headerText="Date Recorded" width="120px"></e-column>
                                                <e-column field="employeeComment" headerText="Assessment Note"></e-column>
                                                <e-column field="status" :visible="false" headerText="Status" width="100px"></e-column>
                                                <e-column :template="aStatusTemplate" headerText="Status" width="100px"></e-column> 
                                                <e-column field="competencyCount" headerText="Competencies" width="120px"></e-column>
                                                <e-column field="documentCount" headerText="Documents" width="100px"></e-column>
                                                <e-column :template="assessmentActionButtonsTemplate" width="100px" headerText="Action"></e-column>
                                            </e-columns>
                                        </ejs-grid>
                                    </b-card>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab>
                            <template v-slot:title>
                                <span class="sm-pad">Trainings</span>
                            </template>
                            <b-card-text class="employee-tab-content">
                                <div class="tab-content-body">
                                    <b-card  no-body class="for-panel">
                                        <ejs-grid
                                            ref="trainingGrid"
                                            :created="refreshTrainingGrid"
                                            :allowPaging="true"
                                            :allowSorting="true"
                                            :pageSettings="pageSettings"
                                            :toolbar="toolbar"
                                            :searchSettings="search"
                                            :allowExcelExport="true"
                                            :allowPdfExport="true"
                                            :toolbarClick="toolbarClick2">
                                            <e-columns>
                                                <e-column field="index" headerText="#" width="60px"></e-column>
                                                <e-column field="trainingName" headerText="Training" width="150px"></e-column>
                                                <e-column field="trainingDate" headerText="Training Date" width="120px"></e-column>
                                                <e-column field="dateAdded" headerText="Date Recorded" width="120px"></e-column>
                                                <e-column field="employeeComment" width="200px" headerText="Comment"></e-column>
                                                <e-column field="status" :visible="false" headerText="Status" width="100px"></e-column>
                                                <e-column :template="aStatusTemplate" headerText="Status" width="100px"></e-column> 
                                                <e-column field="competencyCount" headerText="Competencies" width="120px" class="text-center"></e-column>
                                                <e-column field="documentCount" headerText="Documents" width="100px"></e-column>
                                                <e-column :template="actionButtonsTemplate" width="100px" headerText="Action"></e-column>
                                            </e-columns>
                                        </ejs-grid>
                                    </b-card>
                                </div>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>


            <!-- <div class="empty_branch__div mt-3" v-if="units.length < 1">
                <div class="text-center">
                    <h6 class="text-center"> You are yet to create a branch</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div> -->
            <!-- <ViewCompetencyModal :competency="competency"></ViewCompetencyModal>
            <JobRoleDetailsModal :jobRole="jobRole"></JobRoleDetailsModal> -->
            <!-- <employeeCompetencyDetails :empoyeeCompetency="empoyeeCompetency"></employeeCompetencyDetails> -->
        </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Employee/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
//import JobRoleDetailsModal from '@/components/Modals/RoleTasks/jobRoleDetailsModal.vue';
//import employeeCompetencyDetails from '@/components/Modals/Competency_modal/employeeCompetencyDetailsModal.vue';

// import CreateSelfAssessmentModal from '@/components/Modals/SelfAssessment/createSelfAssessment.vue';
// import editUnitModal from '@/components/Modals/Unit/editUnitModal.vue';
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;
import moment from 'moment';

//import ViewCompetencyModal from '@/components/Modals/Competency_modal/competencyDetailsModal.vue';


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    title: 'Self Assessments & Training',
    components: {
        MasterLayout,
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            level: null,
            backgroundUrl,
            UserDP,
            isButtonDisabled:  false,
            showLoader: false,
            componentKey: 0,
            competencies: null,
            competenciesArray: [],
            units: [],
            jobRoles: [],
            jobRole: {},
            employee: {},
            empoyeeCompetency: {},
            training: [],
            myAssessments: [],
            empAss: {},
            jobResps: [],
            jobResp: {},
            unit: {},
            competency: {},
            myCompetencies: [],
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            activeTab: "my competencies",
            competencyLevels: null,
            userInfo: localStorage.getItem("dcasUserDetails")
                ? JSON.parse(localStorage.getItem("dcasUserDetails"))
                : null,
            opt2: {

            },
            tableTheme: {
                header: {
                    bold: true,
                    fontColor: "#000000",
                    fontName: 'Calibri',
                    fontSize: 10
                },
                record: {
                    fontColor: '#000000',
                    fontName: "Calibri",
                    fontSize: 8
                }
            },
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-info" title="View Assessment" v-on:click="_view(data.id)">' +
                                '<i class="fa fa-eye" aria-hidden="true"></i>' +
                            '</button>' +
                            `<button type="button" class="btn btn-success" title="Continue" v-if="!data.submitted" v-on:click="_continue(data.id, 'Training')">` +
                                '<i class="fa fa-paper-plane" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-danger" title="Delete" v-if="!data.submitted" v-on:click="_delete(data.id)">' +
                                '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _delete: function(id) {
                                this.$eventHub.$emit("deleteSA", id);
                            },
                            _continue: function(id, typ) {
                                this.$eventHub.$emit("continueSA", id, typ);
                            },
                            _view: function(id) {
                                this.$eventHub.$emit("viewAssessmentVM", id);
                            },
                        }
                    })
                };
            },
            assessmentActionButtonsTemplate: function() {
                return {
                    template: Vue.component("assessmentActionButtonsTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            `<button type="button" class="btn btn-info" title="View Appraisal" v-on:click="_view(data.id, 'app')">` +
                                '<i class="fa fa-eye" aria-hidden="true"></i>' +
                            '</button>' +
                            `<button type="button" class="btn btn-success" title="Continue" v-if="!data.submitted" v-on:click="_continue(data.id, 'Assessment')">` +
                                '<i class="fa fa-paper-plane" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-danger" title="Delete" v-if="!data.submitted" v-on:click="_delete(data.id)">' +
                                '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _delete: function(id) {
                                this.$eventHub.$emit("deleteSA", id);
                            },
                            _continue: function(id, typ) {
                                this.$eventHub.$emit("continueSA", id, typ);
                            },
                            _view: function(id, typ) {
                                this.$eventHub.$emit("viewAssessmentVM", id, typ);
                            },
                        }
                    })
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            },
            aStatusTemplate(){
                return {
                    template: Vue.component("aStatusTemplate", {
                        template:
                        '<div>'+
                            `<b-badge pill variant="secondary" v-if="data.status === 'Pending'">{{data.status}}</b-badge>` +
                            `<b-badge pill variant="success" v-if="data.status === 'Approved'">{{data.status}}</b-badge>` +
                            `<b-badge pill variant="danger" v-if="data.status === 'Disapproved'">{{data.status}}</b-badge>` +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                };
            },
           
        }
    },
    mounted() {
        this.getEmployeeAssessments();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);        
        }       
    },
    created(){
        this.$eventHub.$on("deleteSA", (id) => {
            this.deleteAssessment(id);
        });

        this.$eventHub.$on("continueSA", (id, typ) => {
            this.continueAssessment(id, typ);
        });
        this.$eventHub.$on("viewAssessmentVM", (id, typ) => {
            this.viewAssessment(id);
        });
        

    },
    methods: {
        changeRoute($routeName, $typ){
            this.$router.push({name: $routeName, query:{
                typ: $typ
            }});
        },
        toolbarClick1(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.assessmentGrid.getColumns()[6].visible = false; 
                    this.$refs.assessmentGrid.pdfExport({
                        fileName: 'Approved Assessments.pdf',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  'Approved Assessments',
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
                case "Excel Export":
                    this.$refs.assessmentGrid.getColumns()[6].visible = false;
                    this.$refs.assessmentGrid.excelExport({
                        pageOrientation: 'Landscape',
                        fileName: 'Approved Assessments.xlsx',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  `Approved Assessments`,
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
            }
        },
        toolbarClick2(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.trainingGrid.getColumns()[5].visible = true;
                    this.$refs.trainingGrid.getColumns()[6].visible = false;
                    this.$refs.trainingGrid.getColumns()[9].visible = false;

                    this.$refs.trainingGrid.pdfExport(
                        {
                            pageOrientation: 'Landscape',
                            fileName: `Employee_${this.userInfo.firstName}_Assessments.pdf`,
                            theme: this.tableTheme,
                            header: {
                                fromTop:0,
                                height: 100,
                                contents:  [{
                                    type: "Text",
                                    value:  `Employee ${this.userInfo.firstName} ${this.userInfo.lastName} Assessments`,
                                    style: {fontSize: 20,},
                                    position: {x:0, y: 50},
                                    //fontName: 'Segoe UI'
                                }],
                            },
                            footer: {
                                fromBottom: 75,
                                height: 80,
                                contents:  [{
                                    type: "Text",
                                    value: "Thank You",
                                    position: {x:0, y: 50},
                                    style: {fontSize: 20}
                                }],
                            
                            }
                        }
                    );
                break;
                case "Excel Export":
                    this.$refs.trainingGrid.getColumns()[5].visible = true;
                    this.$refs.trainingGrid.getColumns()[6].visible = false;
                    this.$refs.trainingGrid.getColumns()[9].visible = false;
                    this.$refs.trainingGrid.excelExport({
                        pageOrientation: 'Landscape',
                        fileName: `Employee_${this.userInfo.firstName}_Assessments.xlsx`,
                        theme: {
                            header: {
                                bold: true,
                                fontColor: "#000000",
                                fontName: 'Calibri',
                                fontSize: 12
                            },
                            record: {
                                fontColor: '#000000',
                                fontName: "Calibri",
                                fontSize: 10
                            }
                        },
                        header: {
                            fromTop:0,
                            height: 100,
                            contents:  [{
                                type: "Text",
                                value:  `Employee ${this.userInfo.firstName} ${this.userInfo.lastName} Assessments`,
                                style: {fontSize: 20,},
                                position: {x:0, y: 50},
                                //fontName: 'Segoe UI'
                            }],
                        },
                        footer: {
                            fromBottom: 75,
                            height: 80,
                            contents:  [{
                                type: "Text",
                                value: "Thank You",
                                position: {x:0, y: 50},
                                style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
            }
        },
        refreshTrainingGrid(){
            this.$refs.trainingGrid.refresh();
        },
        refreshAssessmentGrid(){
            this.$refs.assessmentGrid.refresh();
        },
        getEmployeeAssessments(){
            let grid = this.$refs.trainingGrid;
            let grid2 = this.$refs.assessmentGrid;

            this.axios.get(`${configObject.apiBaseUrl}/Employee/SelfAssessments/${this.userInfo.employeeId}`, configObject.authConfig)
                .then(response => {
                    this.showLoader = false;
                    let index = 0, ii = 0;
                    let _allTrainings = [];
                    let _allAssessments = [];
                    console.log(response.data);
                    response.data.forEach(empAss => {                        
                        empAss.trainingDate = moment(empAss.trainingDate).format('DD-MMM-YYYY');
                        empAss.dateAdded = moment(empAss.dateAdded).format('DD-MMM-YYYY');                        

                        if(empAss.assessmentType.toLowerCase() === 'assessment'){
                            ii++;
                            empAss.index = ii;
                            _allAssessments.push(empAss);
                        }
                        else{
                            index++;
                            empAss.index = index;
                            _allTrainings.push(empAss);
                        }
                    });

                    this.myAssessments = _allAssessments;
                    this.training = _allTrainings;
                    //console.log(this.myAssessments);

                    grid2.ej2Instances.setProperties({ dataSource: this.myAssessments });
                    grid.ej2Instances.setProperties({ dataSource: this.training });
                    this.refreshTrainingGrid();
                    this.refreshAssessmentGrid();
                })
                .catch((error) => {
                    console.log('ERROR::: ' + error);
                    // this.$toast.open({
                    //     message: "unable to load employee assessments",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
        continueAssessment(id, _typ){
            this.$router.push({name: 'step_one', query: {
                id: id,
                typ: _typ
            }});
        },
        deleteAssessment(id){
            let grid = this.$refs.trainingGrid;
            let url = `${configObject.apiBaseUrl}/Employee/SelfAssessment/Delete/${id}/${this.userInfo.employeeId}`;
            this.axios.delete(url, configObject.authConfig)
            .then(response => {
                this.$toast.success("Assessment deleted Employee successfully!");
                // console.log(response.data);
                // console.log('Before :' + this.myAssessments.length)
                this.myAssessments = this.myAssessments.filter(e => e.id !== response.data.id);
                //console.log('After :' + this.myAssessments.length);
                grid.ej2Instances.setProperties({ dataSource: this.myAssessments });
                this.refreshTrainingGrid();   
            })
            .catch((error) => {
                console.log('ERROR::: ');
                console.log(error.response.data.message);
                this.$toast.open({
                    message: error.response.data.message, //"unable to load employee assessments",
                    type: "error",
                    duration: 4000
                });
            });
        },
        viewAssessment(id, _typ){
            this.$router.push({name: 'view_assessment', query: {
                id: id,
                empId: this.userInfo.employeeId,
                typ: _typ
            }});
        },
    }
}
</script>

<style scoped>
    td[aria-colindex='6'], td[aria-colindex='7']{
        text-align: center!important;
    }
</style>