<template>
    <modal name="contactModal" transition="pop-out" :width="modalWidth" :height="520">
        <div class="modal__header">
            <h4>Contact Us</h4>
        </div>
        <div class="cas_input">
            <div class="row__wrapper">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Name</span>
                            <div class="">
                                <input type="text" value="" v-model="contact.name" required ref="inputs" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Email</span>
                            <div class="">
                                <input type="email" value="" v-model="contact.email" /> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Phone Number</span>
                            <div class="">
                                <input type="text" value="" v-model="contact.phoneNumber" /> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Message</span>
                            <div class="">
                                <textarea type="text" value="" v-model="contact.message" rows="5"/> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-4">
                <button class="creaet_branch_btn"
                    @click="validateDetails($event)"
                    :disabled="isButtonDisabled ? true : null"
                    :style="[
                    isButtonDisabled
                        ? { cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                    ]">
                    Send
                </button>
            </div>
        </div>
    </modal>
</template>
<script>

const MODAL_WIDTH = 800;
import configObject from "@/config";

export default {
    name: 'contactModal',
    data () {
        return {
            contact: {
                name: '',
                email: '',
                phoneNumber: '',
                message: ''
            },
            modalWidth: MODAL_WIDTH,
            isButtonDisabled:  false,
            showLoader: false,
            empdate: null,
        }
    },
    created () {
        this.modalWidth = window.innerWidth < MODAL_WIDTH
        ? MODAL_WIDTH / 2
        : MODAL_WIDTH
    },
    methods: {
        closeModal(){
            this.$modal.hide('contactModal');
        },
        validEmail(email) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                return true;
            }
            return false;
        },
        validateDetails($event){
            $event.preventDefault();
            if(!this.contact.name) {
                this.$toasted.show("Please input your name", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
                return
            }
            if(!this.contact.email) {
                this.$toasted.show("Please input your email address", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
                return
            }
            if (!this.validEmail(this.contact.email)) {
                this.$toasted.show("Please input a valid email address", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
                return
            }
            if(!this.contact.phoneNumber) {
                this.$toasted.show("Please input your phone number", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
                return
            }
            if(!this.contact.message) {
                this.$toasted.show("Please input a message", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
                return
            }
            this.sendForm();
        },
        sendForm() {
            const data = {
                name: this.contact.name,
                email: this.contact.email,
                phoneNo: this.contact.phoneNumber,
                message: this.contact.message
            }
            this.axios
                .post(
                `${configObject.apiBaseUrl}/Contactus`,
                data,
                configObject.authConfig
                )
                .then(() => {
                    this.$toast.open({
                        message: "Successfully sent message",
                        type: "success",
                        duration: 3000
                    });
                    this.contact.name = ''
                    this.contact.email = ''
                    this.contact.phoneNumber = ''
                    this.contact.message = ''
                    this.closeModal()
                })
                .catch(error => {
                this.isButtonDisabled = false;
                this.showLoader = false;
                this.$toast.open({
                    message: "unable to send message",
                    type: "error",
                    duration: 3000
                });
                console.log(error);
                });
        }
    }
}
</script>


<style scoped>
.cas_input .input_wrapper {
    margin-bottom: 5px!important;
}
.cas_input textarea, .cas_input [type='text']{
    margin-bottom: 5px!important;
}
</style>
