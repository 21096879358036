<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">

             <div class="welcome_message">
                 <div class="row">
                     <div class="col-6">    
                        <h5 class=''>
                            Map Responsibilities to Designation <span class="greeeting__text">"{{designationName}}"</span>.
                            There are <span class="branches__count"> {{responsibilities.length}}</span> responsibilities available
                        </h5>
                     </div>
                     <div class="col-6 text-right">
                        <div>
                            <button class="creaet_branch_btn default" @click="backToDesignation"><i class="fa fa-arrow-left"></i> Back</button>
                            <button class="creaet_branch_btn" @click="mapResponsibiitiesToDeisgnation">Map Resposibities  To   Designation</button>
                            <span class="">
                                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                            </span>
                        </div>
                     </div>
                 </div>
             </div>
             <section class="table__section mt-1 pb-5">
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column headerText="#"  :headerTemplate="selectAllTemplate" :template="checkBoxTemplate" width="50px"></e-column>
                        <e-column field="name" headerText="Responsibiity"></e-column>
                        <e-column :template="deletedTemplate" headerText="Status" width="150px"></e-column>
                        <e-column field="" headerText="" width="30px"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
            <div class="empty_branch__div mt-3" v-if="respsFetched"> 
                <div class="text-center">
                    <h6 class="text-center"> Please wait while we get Resposibiities</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    components: {
        MasterLayout,
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            showLoader: false,
            backgroundUrl,
            UserDP,
            componentKey: 0,
            unit: {},
            respsFetched: false,
            newMappedRespArray: [],
            responsibilitiesArray: [],
            responsibilities: [],
            designationName: '',
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            checkBoxTemplate: function() {
                return {
                    template: Vue.component("columnTemplate", {
                        template: `<div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="data.selected" v-on:change="createMappedresponsibilitiesArray($event, data)" checked />
                            <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="!data.selected" v-on:change="createMappedresponsibilitiesArray($event, data)" />
                            <label class="custom-control-label" v-bind:for="data.id"></label>
                        </div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            createMappedresponsibilitiesArray: function(e, requirement) {
                                this.$eventHub.$emit("requirementsCheck", e, requirement);
                            }
                        }
                    })
                };
            },
            selectAllTemplate: function() {
                return {
                template: Vue.component("columnTemplate", {
                    template: `<div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="selectAll" v-on:change="_selectAll($event)"/>
                        <label class="custom-control-label" for="selectAll"></label></div>`,
                    data: function() {
                    return {
                        data: {}
                    };
                    },
                    methods: {
                    _selectAll: function(e) {
                        this.$eventHub.$emit("selectAll", e);
                    }
                    }
                })
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>
<div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            }
        }
    },
    mounted() {
        console.log('Second CALL');
        this.getResponsibiities();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created(){
        this.designationName = this.$route.query.name;

        this.$eventHub.$on("requirementsCheck", (event, requirement) => {
            this.createMappedresponsibilitiesArray(event, requirement);
        });
        this.$eventHub.$on("selectAll", event => {
            this.selectAll(event);
        });
    },
    methods: {
        selectAll(event) {
            event.preventDefault();
            let _this = this;
            if (event.target.checked) {
                $(":checkbox").each(function() {
                    this.checked = true;
                });
                
                this.responsibilities.forEach(unit => {
                    this.responsibilitiesArray.push({id: unit.id});
                })
            } else {
                $(":checkbox").each(function() {
                    this.checked = false;
                });
                _this.responsibilitiesArray = [];
            }
        },
         createMappedresponsibilitiesArray(event, reqInfo){
            if (!event.target.checked) {
                let newMappedRespArray = this.responsibilitiesArray.filter(
                    requirement => requirement.id !== reqInfo.id
                );
                this.responsibilitiesArray = newMappedRespArray;
            } else {
                this.responsibilitiesArray.push({
                    id: reqInfo.id
                });
            }
            console.log(this.responsibilitiesArray);
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        mapResponsibiitiesToDeisgnation(reqId) {
            this.showLoader = true;
            this.isButtonDisabled = true;
            let mappedResponsiblitiesArray = [];
            this.responsibilitiesArray.forEach((req) => {
                mappedResponsiblitiesArray.push(req.id);
            });
            let data = {
                "designationId": parseInt(this.$route.query.id),
                "responsibilityIds" : mappedResponsiblitiesArray
            }
            console.log(data);
            let url = `${configObject.apiBaseUrl}/Designation/Responsibilities/Add`;
            this.axios.post(url, data, configObject.authConfig)
                .then((res) => {
                    console.log()
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    this.$toast.success("Responsibity mapped to this designatiion successfully!");
                    })
                    .catch((error) => {
                        this.isButtonDisabled = false;
                        this.showLoader = false;
                        this.$toast.open({
                            message: "unable to map responsibilities to designation",
                            type: "error",
                            duration: 3000
                        });
                        console.log(error);
                });
        },
        getResponsibiities() {
           this.showLoader = true;
            let grid = this.$refs.dataGrid;
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Designation/ResponsibilitiesForMap/${this.$route.query.id}`,
                    configObject.authConfig
                )
                .then(response => {
                    this.showLoader = false;

                    grid.ej2Instances.setProperties({ dataSource: response.data });
                    this.refreshGrid();
                    this.responsibilities = response.data;
                })
                .catch((error) => {
                    console.log(error);
                    // this.$toast.open({
                    //     message: "unable to load responsibilities",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
        backToDesignation(){
            this.$router.push({
                name: "designation_profile_detail",
                query: {
                    id: this.$route.query.id
                }
            });
        }
    }
}
</script>

        