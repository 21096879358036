<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">
            <div class="welcome_message">
                <div class="row">
                    <div class="col-8">
                        <h5 class=''><span class="greeeting__text"> Hi {{userInfo.firstName}},
                            </span> Step <span class="branches__count">3</span> of 3: attach <span v-if="aType === 'ass'">Assessment</span><span v-if="aType === 'app'">Appraisal</span> evidence document(s)
                        </h5>
                    </div>
                    <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn default" @click="backToStepTwo"><i class="fa fa-arrow-left"></i> Back to Step 2</button>
                                            
                        </div>
                    </div>
                </div>
            </div>
            <section class="table__section mt-1 pb-5">
                <div class="cas_input mt-3">
                    <div class="row__wrapper push-up">
                        <b-container>
                            <b-row>
                                <b-col v-if="aType === 'Assessment'">
                                    <span>Evidences 1</span>
                                    <b-form-file
                                        class="mb-3"
                                        v-model="file1"
                                        placeholder="Choose a file or drop here"
                                        drop-placeholder="Drop file here..."
                                        accept="image/jpeg, image/png, image/gif, application/pdf"></b-form-file>
                                </b-col>
                                <b-col v-if="aType === 'Training'">
                                    <span>Evidences 1</span>
                                    <b-form-file
                                        class="mb-3"
                                        v-model="file1"
                                        :state="Boolean(file1)"
                                        placeholder="Choose a file or drop here"
                                        drop-placeholder="Drop file here..."
                                        accept="image/jpeg, image/png, image/gif, application/pdf"></b-form-file>
                                </b-col>
                                <b-col>
                                    <span>Evidences 2</span>
                                    <b-form-file
                                        class="mb-3"
                                        v-model="file2"
                                        placeholder="Choose a file or drop here"
                                        drop-placeholder="Drop file here..."
                                        accept="image/jpeg, image/png, image/gif, application/pdf"></b-form-file>
                                </b-col>
                                <b-col>
                                    <span>Evidences 3</span>
                                    <b-form-file
                                        class="mb-3"
                                        v-model="file3"                                    
                                        placeholder="Choose a file or drop here"
                                        drop-placeholder="Drop file here..."
                                        accept="image/jpeg, image/png, image/gif, application/pdf"></b-form-file>
                                </b-col>
                            </b-row>                         
                            <b-row class="mt-4">
                                <b-col offset="3" sm="6">
                                    <div class="text-center">
                                        <small>
                                            <i><strong>N.B.:</strong> <span v-if="aType === 'Training'">Please provide atleast one (1) evidence for this assessment and max of three (3). </span> 
                                            <span v-if="aType === 'Assessment'">Please upload evidence document if there is any or simply click Continue to complete this Appraisal. </span>Each file cannot be more than 4MB, and can only be a image file (.jpg, .png, .gif) or PDF (.pdf) file</i></small>
                                    </div>
                                    <div class="text-center mt-4">
                                        <button class="creaet_branch_btn" @click="saveAssessment"
                                            :disabled="isButtonDisabled ? true : null"
                                            :style="[
                                            isButtonDisabled
                                                ? { cursor: 'not-allowed' }
                                                : { cursor: 'pointer' }
                                            ]">
                                            Continue
                                            <span class="">
                                                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                                            </span><i class="fa fa-next" v-if="!showLoader"></i>
                                        </button>  
                                    </div>
                                </b-col>
                            </b-row>
                        </b-container>
                    </div>
                </div>
            </section>
        </div>
    </MasterLayout>
</template>

<script>
import Vue from "vue";
import MasterLayout from "@/views/Employee/MasterLayout.vue";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;

export default {
    title: 'Assessment/Appraisal - Step 3',
    components: {
        MasterLayout,
        
    },
    data(){
        return {
            showLoader: false,
            isButtonDisabled: false,
            file1: '',
            file2: '',
            file3: '',
            userInfo: localStorage.getItem("dcasUserDetails")
                ? JSON.parse(localStorage.getItem("dcasUserDetails"))
                : null,
            aType: '',
        }
    },
    created(){
        
        // this.$eventHub.$on("doLevelSelectVM", (event, id) => {
        //    this.doLevelSelect(event, id);
        // });
        
    },
    mounted(){
        this.aType = this.$route.query.typ;
    },
    methods:{
        
        saveAssessment(){
            //this.showLoader = true;
            this.isButtonDisabled = true;

            let formData = new FormData();
            formData.append('file1', this.file1);
            formData.append('file2', this.file2);
            formData.append('file3', this.file3);
            formData.append('assessmentId', parseInt(this.$route.query.id));
            formData.append('employeeId', parseInt(this.userInfo.employeeId));
            console.log(formData);

            let url = `${configObject.apiBaseUrl}/Employee/SelfAssessment/Step3`;
            this.axios.post(url, formData, configObject.authConfigForUpload) //data, configObject.authConfig)
                .then((resp) => {
                    console.log(resp.data);
                    if(resp.data.id > 0 && resp.data.submitted === true){
                        this.isButtonDisabled = false;
                        this.$toast.success("Self Assessement Saved and Submitted Succssfully!");
                        this.$router.push({
                            name: 'self_assessment'
                        });
                    }
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    //this.showLoader = false;
                    this.$toast.open({
                        message: "Unable to save self assessments",
                        type: "error",
                        duration: 3000
                    });
                    console.log(error);
                });
        
        },     
        backToStepTwo(){
            this.$router.push({
                name: 'step_two', 
                query: {
                    id: this.$route.query.id,
                    typ: this.$route.query.typ
                }
            });
        },
    }
}
</script>