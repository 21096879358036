<template>
<modal name="createCompetencyGroupModal" transition="pop-out" :width="modalWidth" :height="300">
  <div class="modal__header">
      <h4>Add Competency Group</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-12">
                <div class="" style="width: 100%">
                    <span>Name</span>
                    <div class="input___group">
                        <input type="text" placeholder="Competency group name" class="ml-3" v-model="name" />
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <div class="text-center mt-4">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Create Competency Group
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 650;
import configObject from "@/config";

export default {
  name: 'createCompetencyGroupModal',
  data () {
    return {
      modalWidth: MODAL_WIDTH,
      name: null,
      isButtonDisabled:  false,
      showLoader: false,
      branch: this.$store.getters.getBranchdetail
    }
  },
  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

    console.log(this.$store.getters.getBranchdetails);
    //this.getCompetencyGroups();
  },
  mounted(){
    console.log(configObject.authConfig);
  },
  methods: {
      validateDetails($event) {
        $event.preventDefault();
        if(!this.name){
            this.$toasted.show("Fill in the missing Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
        }else {
            this.createCompetencyGroup();
        }
    },
    closeCreateCompetecncyGroup(){
        this.$modal.hide('createCompetencyGroupModal');
    },
    createCompetencyGroup() {
        let dataToSend = {
            name: this.name,
        };
        this.showLoader = true;
        this.isButtonDisabled = true;
        this.axios.post(`${configObject.apiBaseUrl}/Competency/Group/Add`, dataToSend, configObject.authConfig)
            .then((res) => {
                console.log(res.data);
                this.showLoader = false;
                this.closeCreateCompetecncyGroup();
                this.isButtonDisabled = false;
                this.$eventHub.$emit("refreshCompetencyGroupTable");
                this.$toast.success("Competency Group Created Successfully");
                this.name = null;
                this.getCompetencyGroups();
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    let msg = "Unable to create competency group";
                    if(error.response.data.message){
                        msg = error.response.data.message;
                    }

                    this.$toast.open({
                        message: msg,
                        type: "error",
                        duration: 4000
                    });
                    console.log(error.response.data.message);
            });
    },
    
  }
}
</script>
