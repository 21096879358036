<template>
    <modal name="createModal" transition="pop-out" :width="modalWidth" :height="600">
        <div class="modal__header">
            <h4>Add New Supervisor</h4>
        </div>
        <div class="cas_input">
            <div class="row__wrapper">
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <ejs-dropdownlist id='dropdownlist' placeholder='Select a Employee from list'
                            v-model="id"
                            ref="dropDown"
                            :allowFiltering='allowFiltering' 
                            :dataSource='employees' 
                            :fields='fields'
                            :change='employeeSelected'></ejs-dropdownlist>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Firstname</span>
                            <div class="">
                                <input type="text" value="" v-model="employee.firstname" required ref="inputs" /> 
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Surname</span>
                            <div class="">
                                <input type="text" value="" v-model="employee.surname" /> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Email</span>
                            <div class="">
                                <input type="email" readonly value="" v-model="employee.email" /> 
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Division</span>
                            <div class="">
                                <input type="text" readonly value="" v-model="employee.divisionName" /> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Branch</span>
                            <div class="">
                                <input type="text" readonly value="" v-model="employee.branchName" /> 
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Unit</span>
                            <div class="">
                                <input type="text" readonly value="" v-model="employee.unitName" /> 
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Phone</span>
                            <div class="">
                                <input type="text" readonly value="" v-model="employee.phoneNumber" /> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Gender</span>
                            <div class="">
                                <input type="text" readonly value="" v-model="employee.gender" /> 
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Grade Level</span>
                            <div class="">
                                <input type="text" readonly value="" v-model="employee.gradeLevel" /> 
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="input_wrapper" style="width: 100%; margin-bottom: 0!important;">
                            <span>Employment Date</span>
                            <div class="">
                                <input type="date" readonly value="" style="margin-bottom: 0!important; width: 100%;" v-model="employee.employmentDate">
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
            <div class="text-center mt-3">
                <button class="creaet_branch_btn"
                    @click="validateDetails($event)"
                    :disabled="isButtonDisabled ? true : null"
                    :style="[
                    isButtonDisabled
                        ? { cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                    ]">
                    Add Supervisor <i class="fa fa-save"></i>
                    <span class="">
                        <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                    </span>
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
    const MODAL_WIDTH = 800;
    import configObject from "@/config";
    import moment from "moment";

    export default {
        name: 'createModal',
        data(){
            return {
                id: 0,
                employee: {},
                firstname: '',
                surname: '',
                email: '',
                phone: '',
                divisionName: '',
                branchName: '',
                unitName: '',
                gender: '',
                gradeLevel: '',
                employmentDate: '',
                employees: [],
                allowFiltering: true,
                isButtonDisabled: false,
                showLoader: false,
                fields : { text: "displayText", value: "id" },
            }
        },
        created(){
            this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
            this.cleanEntry();
        },
        mounted(){
            this.getEmployees()
        },
        methods: {
            closeCreateEmployeeModal(){
                this.$modal.hide('createEmployeeModal');
            },
            cleanEntry(){
                this.employee = {};
                // this.id = 0;
                // this.firstname = '';
                // this.surname = '';
                // this.email = '';
                // this.phone = '';
                // this.divisionName = '';
                // this.branchName = '';
                // this.unitName = '';
                // this.gender = '';
                // this.gradeLevel = '';
                // this.employmentDate = '';
            },
            getEmployees(){
                this.axios
                .get(
                    `${configObject.apiBaseUrl}/Employee/Full`,
                    configObject.authConfig
                )
                .then(response => {
                    console.log(response.data)
                    response.data.forEach(p => {
                        p.fullName = p.firstname + ' ' + p.surname;
                        p.displayText = `${p.fullName} [${p.divisionName} :: ${p.branchName}]`;
                    });
                    this.employees = response.data
                })
                .catch((error) => {
                    console.log('ERROR ::: ' + error);
                    this.$toast.open({
                        message: "unable to Employees",
                        type: "error",
                        duration: 4000
                    });
                });
            },
            employeeSelected: function(args){
                console.log('Employee Selected: ' + this.$refs.dropDown.ej2Instances.value);
                let empId = this.$refs.dropDown.ej2Instances.value;
                this.employee = this.employees.find(a => a.id === empId);
                this.employee.employmentDate = moment(this.employee.employmentDate).format('YYYY-MM-DD');
                console.log(this.employee);
            },
            validateDetails(){
                if(this.employee === null || this.employee.id <= 0){
                    this.$toasted.show("Please complete all Fields", {
                        theme: "bubble",
                        position: "top-center",
                        duration: 4000
                    });
                }
                else {
                    this.createSupervisor();
                }
            },
            createSupervisor(){
                this.designationId = parseInt(this.designationId)
                
                this.showLoader = true;
                this.isButtonDisabled = true;
                let formData = { 
                    email: this.employee.email,
                    DivisionId: this.employee.divisionId,
                    BranchId: this.employee.branchId,
                    UnitId: this.employee.unitId
                }

                this.axios.post(`${configObject.apiBaseUrl}/Employee/Supervisor`, formData, configObject.authConfig)
                .then(() => {
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    this.closeCreateModal();
                    this.$eventHub.$emit("refreshSupervisorTable");
                    this.$toast.open({
                        message: "Supervisor added successfully",
                        type: "success",
                        duration: 4000
                    });
                    this.cleanEntry();
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "Unable to create Supervisor",
                        type: "error",
                        duration: 4000
                    });
                    console.log(error);
                });
            },
            closeCreateModal(){
                this.$modal.hide('createModal');
            }
        }
    }
</script>