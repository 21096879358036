<template>
    <MasterLayout>
        
        <section class="login__section__Wrapper mt-5">
            <form @submit="validateSignUpForm($event)">
                <div class="login___section__container">
                    <div class="login__input" ref="email_input_div">
                        <span>Username</span>
                        <div class="input___group">
                            <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" />
                            <input type="email" placeholder="email" v-model="email" class="ml-3" @blur="captureFormEvents($event)" />
                        </div>
                    </div>
                    <div ref="email_error_message" class="error-validation text-center"></div>
                    <div class="login__input mt-4" ref="password_input_div">
                        <span>Password</span>
                        <div class="input___group">
                            <img src="@/assets/img/password-icon.svg" class="ml-3" width="20px" />
                            <input type="password" placeholder="password" class="ml-3"
                                    ref="pass__input" @blur="captureFormEvents($event)" v-model="password" />
                            <div>
                                <i class="fa fa-eye-slash" aria-hidden="true" @click="toggleShowPassword" v-if="showPassword"></i>
                                <i class="fa fa-eye" aria-hidden="true" @click="toggleShowPassword" v-if="!showPassword"></i>
                            </div>
                        </div>
                    </div>
                    <div ref="password_error_message" class="error-validation text-center"></div>
                </div>
                <div class="text-center mt-3 grey__text" style="cursor:pointer"><a @click="changeRoute('forgot_password')">Forget Password?</a></div>
                <div class="text-center mt-3">
                    <button type="submit" class="login__btn"
                            :disabled="isButtonDisabled ? true : null"
                            :style="[
                            isButtonDisabled
                                ? { cursor: 'not-allowed' }
                                : { cursor: 'pointer' }
                            ]">LOGIN
                            <span class="">
                                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                            </span>
                    </button>
                </div>
            </form>
            <div class="or__divider mt-5" v-if="showOffice365">
                <hr>
                <span>OR</span>
                <div class="mt-3 grey__text">
                    Continue With
                </div>
                <div class="mt-3">
                    <img src="@/assets/img/office-365.png" />
                </div>
            </div>
            <div style="margin: 0 auto: width: 95%" class="group-icons">
                <img src="@/assets/img/Group 139.png" />
            </div>
        </section>
    </MasterLayout>
</template>
<script>
    import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
    import MasterLayout from "@/views/Accounts/MasterLayout.vue";
    import configObject from "@/config";


export default {
    title: `Welcome`,
    components:{
        MasterLayout,
    },
    data() {
        return {
            portalName: '',
            showOffice365: false,
             isButtonDisabled: false,
             showLoader: false,
             showPassword: false,
             formIsValid: false,
             email: null,
             password: null,
             userInfo: localStorage.getItem("dcasUserDetails")
                ? JSON.parse(localStorage.getItem("dcasUserDetails"))
                : null,
            }
    },
    created() {
        console.log('Welcome to login');
        this.portalName = configObject.portalName;
        let userInfo = JSON.parse(localStorage.getItem("dcasUserDetails"));
        if (userInfo !== null && userInfo !== "null") {
            console.log('Roles => ' + userInfo.roles);
             if(userInfo.roles.includes('SuperAdmin')){
                this.$router.push({name: "company"});
            }
            else if(userInfo.roles.includes('Admin')){
                this.$router.push({name: "branch"});
            }
            else if(userInfo.roles.includes('Staff')){
                this.$router.push({name: "employee_dashboard"});
            }
        }
       
    },
    methods: {
        toggleShowPassword() {
            if(this.$refs.pass__input.type === 'password'){
                this.$refs.pass__input.type = 'text'
                this.showPassword = true;
            }else {
                this.$refs.pass__input.type = 'password';
                this.showPassword = false;
            }
        },
        validatePasword(password) {
            let validate = /^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,}$/;
            if (password.length > 6) {
                return true;
            }
            return validate.test(password);
        },
        validEmail(email) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        },
        login() {
            let dataToSend = {
                userName: this.email,
                password: this.password,
            };
            this.showLoader = true;
            this.isButtonDisabled = true;
            this.axios.post(`${configObject.apiBaseUrl}/Login`, dataToSend)
            .then(response => {
                this.showLoader = false;
                this.isButtonDisabled = true;
                console.log(response.data);
                this.response = response;
                let dcasUserDetails = response.data;
                this.$store.commit("setUserInfo" , dcasUserDetails);
                localStorage.setItem("dcasUserDetails", JSON.stringify(dcasUserDetails));
                let dt = Date.now();
                console.log('Logged in at: ' + dt + ' >>> Expected logout: ' + dt + (1000 * 60 * 60 * 24 * 5));
                localStorage.setItem("expiryTime", JSON.stringify(dt + (1000 * 60 * 60 * 24 * 5))); //3 mins // (432000 * 1000)));
                this.$store.commit("setTokenVal" , true);
                //console.log(dcasUserDetails.roles);

                if(dcasUserDetails.roles.includes('SuperAdmin')){
                    window.location = "/admin/dashboard";
                    //this.$router.push({name: "admin_dashboard"});
                }
                else if(dcasUserDetails.roles.includes('Admin')){
                    //this.$router.push({name: "admin_dashboard"});
                    window.location = "/admin/dashboard";
                    //console.log(dcasUserDetails.roles);
                    
                }
                else if(dcasUserDetails.roles.includes('Staff') && this.$store.state.tokenGotten){
                    console.log(this.$store.state.tokenGotten)             
                    console.log(dcasUserDetails.roles);
                    window.location = "/employee/dashboard";
                    //this.$router.push({name: "employee_dashboard"});
                }
                else {
                    this.$router.push({name: "login"});
                }
                
                this.$toast.success("Login Successful");
            })
            .catch((error) => {
                console.log(error);
                this.$toasted.show("INVALID login attempt", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
                this.isButtonDisabled = false;
                this.showLoader = false;
            });
        },
        changeRoute($routeName){
            this.$router.push({
                name: $routeName
            })
        },
        validateSignUpForm($event) {
            $event.preventDefault();
            if (!this.email || !this.password) {
                this.$toasted.show("Username & Password fields are required", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 3000
                });
                this.formIsValid = false;
            }
            if (this.formIsValid) {
                this.login();
            }
        },
        captureFormEvents($event) {
            if ($event.target.placeholder === "email") {
                if ($event.target.value === "") {
                    this.$refs.email_error_message.style.display = "block";
                    this.$refs.email_error_message.innerHTML = "Email field cannot be blank";
                    this.$refs.email_input_div.style.border = "1px solid red";
                    //this.$refs.email_input_div.classList = "red";
                    this.$refs.email_error_message.style.color = "red";
                    this.formIsValid = false;
                } else {
                    if (this.validEmail($event.target.value)) {
                        this.$refs.email_error_message.style.display = "none";
                        this.$refs.email_error_message.innerHTML = "";
                        this.$refs.email_input_div.style.border =
                            "1px solid rgb(9, 140, 40)";
                        this.formIsValid = true;
                    } else {
                        this.$refs.email_error_message.style.color = "red";
                        this.$refs.email_error_message.innerHTML = "Invalid Email format";
                        this.$refs.email_input_div.style.border = "1px solid red";
                        this.formIsValid = false;
                    }
                }
            }

            if ($event.target.placeholder === "password") {
                if ($event.target.value === "") {
                    this.$refs.password_error_message.style.display = "block";
                    this.$refs.password_error_message.innerHTML =
                        "Password field cannot be blank";
                    this.$refs.password_input_div.style.border = "1px solid red";
                    this.$refs.password_error_message.style.color = "red";
                    this.formIsValid = false;
                } else {
                    this.$refs.password_error_message.style.display = "none";
                    this.$refs.password_input_div.style.border = "1px solid rgb(9, 140, 40)";
                    this.formIsValid = true;
                }
            }
        }
    }
    
}
</script>
<style>
</style>