<template>
<modal name="createSubCompetencyModal" transition="pop-out" :width="modalWidth" :height="520">
  <div class="modal__header">
      <h4>Add New Sub Competency</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-12">
                <div class="" style="width: 100%">
                    <span>Competency Group</span>
                    <div class="">
                        <input type="text" disabled class="ml-3" v-model="groupName"  />
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="" style="width: 100%">
                    <span>Parent Competency Name</span>
                    <div class="">
                        <textarea type="text" disabled class="ml-3" v-model="compName" style="height: 120px"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="" style="width: 100%">
                    <span>Sub Competency Name</span>
                    <div class="">
                        <input type="text" placeholder="Enter sub competency name" class="ml-3" v-model="subCompName"  />
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="" style="width: 100%">
                    <span>Point</span>
                    <b-input-group append="%">
                        <input type="text" class="ml-3" v-model="point"  />
                    </b-input-group>
                </div>
            </div>
        </div>
      </div>
      
      <div class="text-center mt-2">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Save 
            <i class="fa fa-save" v-if="!showLoader"></i>
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 650;
import configObject from "@/config";

export default {
  name: 'createSubCompetencyModal',
  props: ['compId', 'compName', 'groupName', 'maxPoint'],
  data () {
    return {
      modalWidth: MODAL_WIDTH,
      isButtonDisabled:  false,
      showLoader: false,
      branch: this.$store.getters.getBranchdetail,
      subCompName: '',
      point: 0,
    }
  },
  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH
  },
  methods: {
      validateDetails($event) {
        $event.preventDefault();
        if(!this.compId || this.point <= 0 || !this.subCompName || this.subCompName.length <= 0){
            this.$toasted.show("Fill in the missing Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
        }else {
            let p = parseInt(this.point);
            if(Number.isInteger(p)){
                //console.log(p);
                if(p > this.maxPoint){
                    this.$toasted.show("You have " + this.maxPoint + "% point remaining. Point cannot be more than " + this.maxPoint, {
                        theme: "bubble",
                        position: "top-center",
                        duration: 4000
                    });
                }
                else{
                    this.point = p;
                    this.createSubCompetency();
                }
            }
            else{
                this.$toasted.show("Please enter a number into points", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
            }
        }
    },
    closeModal(){
        this.$modal.hide('createSubCompetencyModal');
    },
    createSubCompetency() {
        let dataToSend = {
            name: this.subCompName,
            parentCompetencyId: this.compId,
            point: parseInt(this.point)
        };
        console.log(dataToSend);
        this.showLoader = true;
        this.isButtonDisabled = true;
        this.axios.post(`${configObject.apiBaseUrl}/Competency/SubCompetency/Add`, dataToSend, configObject.authConfig)
            .then(() => {
                this.showLoader = false;
                this.isButtonDisabled = false;
                this.closeModal();
                this.$eventHub.$emit("refreshCompetency");
                this.$toast.success("Sub Competency created successfully");
                
                this.subCompName = '';
                this.point = 0;
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    let msg = "Unable to create Sub competency";
                    if(error.response.data.message){
                        msg = error.response.data.message;
                    }
                    this.$toast.open({
                        message: msg,
                        type: "error",
                        duration: 3000
                    });
                    console.log(error);
            });
    },
  }
}
</script>
