import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

console.log("STORE =>" + store);

// Accounts Routes
import Login from "../views/Accounts/Login/index";
import About from "../views/Accounts/About";
import HowItWorks from "../views/Accounts/How_it_works";
import configObject from "@/config";
import ForgotPassword from "../views/Accounts/forget_password/index";
import ResetPassword from "../views/Accounts/reset_password.vue";

// Admin  Routes
import EmployeeAssessment  from "../views/Admin/employee_assessment/index";
import EmployeeSetup from "../views/Admin/employee_assessment/setup";
import Branch  from "../views/Admin/Branch/index";
import Company  from "../views/Admin/Company/index";
import Units from '../views/Admin/Unit/index';
import Divisions from '../views/Admin/Divisions/index';
import EmployeesSelfAssessment from '../views/Admin/self_assessment/index'
import CompetencyLibrary from '../views/Admin/competency_library/index';
import CreateCompetency from '../views/Admin/competency_library/createCompetency';
import EditCompetency from '../views/Admin/competency_library/editCompetency';
import ViewCompetency from '../views/Admin/competency_library/viewCompetency';
import CompetencyGroup from '../views/Admin/competency_library/competencyGroup';
import mapDivisionsToUnit from "../views/Admin/map_division_to_units/index.vue";
import MapBranchToUnit from "../views/Admin/map_branch_to_unit/index.vue"; 
import MapDivisionToBranches from "../views/Admin/map_division_to_branches/index.vue";  
import MapCompetenciesToDesignation from "../views/Admin/map_competencies_to_designation/index.vue";  
import MapRequirementsToDesignation from "../views/Admin/map_requirements_to_designation/index.vue"; 
import MapCompetenciesToEmployee from "../views/Admin/map_competencies_to_employee/index.vue"; 
import MapResponsibilityToDesignation from "../views/Admin/map_responsiblities_to_designation/index.vue"; 
import MapRolesToDesignation from "../views/Admin/map_roles_to_designation/index.vue"; 
import LMSCourses from '../views/Admin/LMS/index.vue';
import LMS_Course from '../views/Admin/LMS/course.vue';
import Organogram from '../views/Admin/Organogram/index.vue';


// import Profile from "../views/Admin/Profile/index.vue"; 
import Settings from "../views/Admin/Settings/index.vue"; 

import AdminDashboard from "../views/Admin/Dashboard/index.vue";
import MeetRequirements from "../views/Admin/Dashboard/meetRequirements.vue";
import CloseToRequirements from "../views/Admin/Dashboard/closeToRequirements.vue";
import NotMeetRequirements from "../views/Admin/Dashboard/notMeetRequirements.vue";

// import GapAnalysis from "../views/Admin/gap_analysis/index.vue";
import GapAnalysisBranch from "../views/Admin/gap_analysis/gap_analysis_branch.vue";
import DivisionGapAnalysis from "../views/Admin/gap_analysis/index.vue";
import BranchGapAnalysis from "../views/Admin/gap_analysis/branch_gap_analysis.vue";
import SuccessionPlanning from "../views/Admin/succession_planning/index.vue";
import Employees from "../views/Admin/employees/index.vue";
import Supervisors from "../views/Admin/employees/Supervisors.vue";
import DesignationProfile from "../views/Admin/Designation_Profile/index.vue";
import DesigProfileDetail from "../views/Admin/Designation_Profile/desig.vue";
import EmployeeProfile from "../views/Employee/employee_profile";
import Designation from "../views/Admin/Designation_Profile/Designation.vue";
import Responsibilities from '../views/Admin/Responsibilities/index';

// Employees Routes
import SelfAssessment from "../views/Employee/self_assessment/index.vue";
import EmployeeDashboard from "../views/Employee/employee_profile/index.vue";
import RoleSetup from '../views/Admin/RoleSetup/index.vue';
import Grades from '../views/Admin/RoleSetup/grades.vue';
import CreateSelfAssessment from "../views/Employee/self_assessment/createAssessment.vue";
import Requirement from '../views/Admin/Requirement/index.vue';
import Grade from '../views/Admin/Grade/index.vue';
import StepOne from '../views/Employee/self_assessment/Step_one.vue';
import StepTwo from '../views/Employee/self_assessment/Step_two.vue';
import StepThree from '../views/Employee/self_assessment/Step_three.vue';
import ViewAssessment from '../views/Employee/self_assessment/ViewAssessment.vue';
import ProcessAssessment from "../views/Admin/employee_assessment/ProcessAssessment.vue";
import EmployeeSettings from "../views/Employee/Settings/index.vue";
import Profile from "../views/Employee/Profile/index.vue";
import LmsHome from '../views/Employee/LMS/index.vue';
import Courses from '../views/Employee/LMS/courselist.vue';
import Course from '../views/Employee/LMS/course.vue';
import DownlineApproval from '../views/Employee/Organogram/DownlineApproval.vue';
import ProcessDownline from '../views/Employee/self_assessment/ProcessDownline.vue';
// import UplineProcessAssessment from "../views/Employee/Organogram/ProcessAssessment.vue";


Vue.use(VueRouter);


let routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            guest: true
        }
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
            guest: true
        }
    },
    {
        path: '/how_it_works',
        name: 'how_it_works',
        component: HowItWorks,
        meta: {
            guest: true
        }
    },
    {
        path: '/limsaa',
        name: 'limsaa',
        component: LMSCourses,
        meta:{
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
    },
    {
        path: '/lms_course',
        name: 'lms_course',
        component: LMS_Course,
        meta:{
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
    },
    {
        path: '/organogram',
        name: 'organogram',
        component: Organogram,
        meta:{
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
    },
    {
        path: '/Lms/Home',
        name: 'lmshome',
        component: LmsHome,
        meta:{
            authorize: true,
            authRoles: ['Staff']
        }
    },
    {
        path: '/Courses',
        name: 'courses',
        component: Courses,
        meta:{
            authorize: true,
            authRoles: ['Staff']
        }
    },
    {
        path: '/Course',
        name: 'Course',
        component: Course,
        meta:{
            authorize: true,
            authRoles: ['Staff']
        }
    },
    {
        path: '/Downline_Approvals',
        name: 'downline_approvals',
        component: DownlineApproval,
        meta:{
            authorize: true,
            authRoles: ['Staff']
        }
    },
    {
        path: '/Process_Downline',
        name: 'process_downline',
        component: ProcessDownline,
        meta:{
            authorize: true,
            authRoles: ['Staff']
        }
    },
    // {
    //     path: '/Process_Approvals',
    //     name: 'process_approvals',
    //     component: UplineProcessAssessment,
    //     meta:{
    //         authorize: true,
    //         authRoles: ['Staff']
    //     }
    // },
    {
        path: '/grade',
        name: 'grade',
        component: Grade,
        meta:{
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
    },
    {
        path: '/process_assessment',
        name: 'process_assessment',
        component: ProcessAssessment,
        meta:{
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
    },
    {
        path: '/view_assessment',
        name: 'view_assessment',
        component: ViewAssessment,
        meta:{
            authorize: true,
            authRoles: ['Staff']
        }
    },
    {
        path: '/step_one',
        name: 'step_one',
        component: StepOne,
        meta:{
            authorize: true,
            authRoles: ['Staff']
        }
    },
    {
        path: '/step_two',
        name: 'step_two',
        component: StepTwo,
        meta:{
            authorize: true,
            authRoles: ['Staff']
        }
    },
    {
        path: '/step_three',
        name: 'step_three',
        component: StepThree,
        meta:{
            authorize: true,
            authRoles: ['Staff']
        }
    },
    {
        path: '/Profile',
        name: 'profile',
        component: Profile,
        meta: {
            authorize: true,
            authRoles: ['Staff']
        }
    },
    {
        path: '/Settings',
        name: 'settings',
        component: Settings,
        meta: {
            authorize: true,
            authRoles: ['Staff', 'Admin', 'SuperAdmin']
        }
    },
    {
        path: '/Employee_settings',
        name: 'employee_settings',
        component: EmployeeSettings,
        meta: {
            guest: false
        }
    },
    {
        path: '/Account/forgot_password',
        name: 'forgot_password',
        component: ForgotPassword,
        meta: {
            guest: true
        }
    },
    {
        path: '/Account/reset_password',
        name: 'reset_password',
        component: ResetPassword,
        meta: {
            guest: true
        }
    },
    {
        path: '/branch',
        name: 'branch',
        component: Branch,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
    },
    {
        path: '/admin/designation_profile_detail',
        name: 'designation_profile_detail',
        component: DesigProfileDetail,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
          }
      },
    {
      path: '/admin/dashboard',
      name: 'admin_dashboard',
      component: AdminDashboard,
      meta: { 
          authorize: true,
          authRoles: ['Admin', 'SuperAdmin']
        }
    },
    {
        path: '/admin/dashboard/meetRequirements',
        name: 'meet_requirements',
        component: MeetRequirements,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
          }
    },
    {
        path: '/admin/dashboard/closeToRequirements',
        name: 'close_to_requirements',
        component: CloseToRequirements,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
          }
    },
    {
        path: '/admin/dashboard/notMetRequirements',
        name: 'not_met_requirements',
        component: NotMeetRequirements,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
          }
    },
    {
        path: '/employee/dashboard',
        name: 'employee_dashboard',
        component: EmployeeDashboard,
        meta: { 
            authorize: true,
            authRoles: ['Staff']
        }
    },
    {
        path: '/create_self_assessment',
        name: 'create_self_assessment',
        component: CreateSelfAssessment,
        meta: { 
            authorize: true,
            authRoles: ['Staff']
        }
    },
    {
      path: '/employee/self_assessment',
      name: 'self_assessment',
      component: SelfAssessment,
      meta: { 
          authorize: true,
          authRoles: ['Staff']
      }
    },
    {
        path: '/map_responsibilities_to_deisgnation',
        name: 'map_responsibilities_to_deisgnation',
        component: MapResponsibilityToDesignation,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
    },
    {
        path: '/responsibilities',
        name: 'responsibilities',
        component: Responsibilities,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
      }, 
      {
          path: '/employee_assessment',
          name: 'employee_assessment',
          component: EmployeeAssessment,
          meta: { 
              authorize: true,
              authRoles: ['Admin', 'SuperAdmin']
          }
      },
      {
        path: '/requirement',
        name: 'requirement',
        component: Requirement,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
      }, 
      {
        path: '/designation',
        name: 'designation',
        component: Designation,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
      }, 
      {
          path: '/employee_setup',
          name: 'employee_setup',
          component: EmployeeSetup,
          meta: { 
              authorize: true,
              authRoles: ['Admin', 'SuperAdmin']
          }
      },
      {
          path: '/employees',
          name: 'employees',
          component: Employees,
          meta: { 
              authorize: true,
              authRoles: ['Admin', 'SuperAdmin']
          }
      },
      {
          path: '/supervisors',
          name: 'supervisors',
          component: Supervisors,
          meta: { 
              authorize: true,
              authRoles: ['Admin', 'SuperAdmin']
          }
      },
      {
          path: '/role_setup/Roles',
          name: 'role_setup',
          component: RoleSetup,
          meta: { 
              authorize: true,
              authRoles: ['Admin', 'SuperAdmin']
          }
      },
      {
        path: '/role_setup/Grades',
        name: 'grades',
        component: Grades,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
    },
      {
          path: '/succession_Planning',
          name: 'succession_Planning',
          component: SuccessionPlanning,
          meta: { 
              authorize: true,
              authRoles: ['Admin', 'SuperAdmin']
          }
      },
      {
          path: '/division_gap_analysis',
          name: 'division_gap_analysis',
          component: DivisionGapAnalysis,
          meta: { 
              authorize: true,
              authRoles: ['Admin', 'SuperAdmin']
          }
      },
      {
          path: '/gap_analysis/by_branch',
          name: 'gap_analysis_branch',
          component: GapAnalysisBranch,
          meta: { 
              authorize: true,
              authRoles: ['Admin', 'SuperAdmin']
          }
      },
      {
        path: '/branch_gap_analysis',
        name: 'branch_gap_analysis',
        component: BranchGapAnalysis,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
    },
      {
        path: '/map_branch_to_unit',
        name: 'map_branch_to_unit',
        component: MapBranchToUnit,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
      },
      {
        path: '/map_competencies_to_employee',
        name: 'map_competencies_to_employee',
        component: MapCompetenciesToEmployee,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
      },
      {
        path: '/map_roles_to_designation',
        name: 'map_roles_to_designation',
        component: MapRolesToDesignation,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
      },
      {
        path: '/map_competencies_to_designation',
        name: 'map_competencies_to_designation',
        component: MapCompetenciesToDesignation,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
      },
      {
        path: '/map_division_to_branches',
        name: 'map_division_to_branches',
        component: MapDivisionToBranches,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
      },
      {
        path: '/map_divisions_to_unit',
        name: 'map_divisions_to_unit',
        component: mapDivisionsToUnit,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
      },
      {
        path: '/map_requirements_to_designation',
        name: 'map_requirements_to_designation',
        component: MapRequirementsToDesignation,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
    },
      {
          path: '/competency_library',
          name: 'competency_library',
          component: CompetencyLibrary,
          meta: { 
              authorize: true,
              authRoles: ['Admin', 'SuperAdmin']
          }
      },
      {
        path: '/create_competency',
        name: 'create_competency',
        component: CreateCompetency,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
      },
      {
        path: '/edit_competency',
        name: 'edit_competency',
        component: EditCompetency,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
      },
      {
        path: '/view_competency',
        name: 'view_competency',
        component: ViewCompetency,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
      },
      {
        path: '/competency-group',
        name: 'competency_group',
        component: CompetencyGroup,
        meta: { 
            authorize: true,
            authRoles: ['Admin', 'SuperAdmin']
        }
      },
      {
          path: '/divisions',
          name: 'divisions',
          component: Divisions,
          meta: { 
              authorize: true,
              authRoles: ['Admin', 'SuperAdmin']
          }
      },  
      {
            path: '/employees_self_assessments',
            name: 'employees_self_assessments',
            component: EmployeesSelfAssessment,
            meta: { 
                authorize: true,
                authRoles: ['Admin', 'SuperAdmin']
            }
      }, 
      {
          path: '/employee_profile',
          name: 'employee_profile',
          component: EmployeeProfile,
          meta: { 
              authorize: true,
              authRoles: ['Admin', 'SuperAdmin']
          }
      },
      {
          path: '/units',
          name: 'units',
          component: Units,
          meta: { 
              authorize: true,
              authRoles: ['Admin', 'SuperAdmin']
          }
      },
      {
          path: '/designation_profile',
          name: 'designation_profile',
          component: DesignationProfile,
          meta: { 
              authorize: true,
              authRoles: ['Admin', 'SuperAdmin']
          }
      },
      {
          path: '/company',
          name: 'company',
          component: Company,
          meta: { 
              authorize: true,
              authRoles: ['SuperAdmin']
          }
      }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
    console.log('Going to => ' + to.fullPath);
    console.log(to);
    if(to.matched.some(rec => rec.meta.authorize)){
        // Authorized Pages will he handled here
        if(localStorage.getItem("dcasUserDetails") === null){
            // There is no logged in user, go back to Login (and redirect to intended page after successfull login)
            next({name: 'login', params: {nextUrl: to.fullPath}});
        }
        else{
            let user = JSON.parse(localStorage.getItem("dcasUserDetails"));
            //console.log(user);

            // Only Super Admin
            if(to.matched.some(rec => {
                    return (rec.meta.authRoles.includes('SuperAdmin') && !rec.meta.authRoles.includes('Admin'));
                })
            ){
                if(user.roles.includes('SuperAdmin'))
                {
                    next();
                }
                else if(user.roles.includes('Admin')){
                    next({ name: 'admin_dashboard'})
                }
                else if(user.roles.includes('Staff')){
                    next({name: 'employee_dashboard'});
                }

            }
            // Only Admin
            else if(to.matched.some(rec => {
                return (rec.meta.authRoles.includes('Admin') && !rec.meta.authRoles.includes('SuperAdmin'))
            })){
                if(user.roles.includes('Admin'))
                {
                    next();
                }
                else if(user.roles.includes('SuperAdmin'))
                {
                    next({name: 'admin_dashboard'});
                }            
                else if(user.roles.includes('Staff')){
                    next({name: 'employee_dashboard'});
                }
            }
            // SuperAdmin OR Admin
            else if(to.matched.some(rec => {
                return (rec.meta.authRoles.includes('SuperAdmin') || rec.meta.authRoles.includes('Admin'))
            })){
                if(user.roles.includes('Admin') || user.roles.includes('SuperAdmin'))
                {
                    next();
                }
                else if(user.roles.includes('Staff')){
                    next({name: 'employee_dashboard'});
                }
            }
            // Only Staff
            else if(to.matched.some(rec => {
                return (rec.meta.authRoles.includes('Staff') && !rec.meta.authRoles.includes('Admin') && !rec.meta.authRoles.includes('SuperAdmin'))
            })){
                if(user.roles.includes('Staff')) //)
                {
                    next();
                }
                else if(user.roles.includes('Admin')  || user.roles.includes('SuperAdmin')){
                    next({name: 'admin_dashboard'});
                }
            }
            else{
                next();
            }
        }
    }
    else if(to.matched.some(rec => rec.meta.guest)){
        //Guest Pages are Handled here e.g. Login
        console.log('Guest area');
        if(localStorage.getItem("dcasUserDetails") === null)
        {
            next();
        }
        else{
            let user = JSON.parse(localStorage.getItem("dcasUserDetails"));
            console.log('User exist');
            console.log(user.roles);
            if(user.roles.includes('SuperAdmin'))
            {
                console.log('this is SuperAdmin');
                next({ name: 'admin_dashboard'});
            }
            else if(user.roles.includes('Admin')){
                console.log('this is Admin');
                next({ name: 'admin_dashboard'})
            }
            else if(user.roles.includes('Staff')){
                console.log('this is Staff');
                next({name: 'employee_dashboard'});
            }
            else{
                next();
            }
        }
    }
    else{
      // Free pages are handled here
      next();
    }
});

export default router