<template>
    <MasterLayout>
        <div class="employees__assessment__wrapper">
             <div class="first__banner" :style="{
                backgroundImage: `linear-gradient(rgb(32, 28, 22 , 0.92), rgb(32, 28, 22 , 0.92)), url(${backgroundUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: '79% 50%'}">
                <div class="profile__pic__wrapper" >
                    <div class="profile__pic" :style="{
                        backgroundImage: `url(${userDetails.avatar})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'}">
                    </div>
                    <div class="user_name text-center text-white mt-3"> {{userDetails.first_name}} {{userDetails.last_name}}</div>
                    <div class="grey__text text-center text-white mt-2">
                        <span class="" style="">{{userDetails.email}}</span>
                    </div>
                    <div class="grey__text text-center text-white mt-2">
                        <span class="">{{userDetails.active}}</span>
                    </div>
                    <div class="grey__text text-center text-white mt-2">
                        <span>Language: </span>{{userDetails.language}}
                    </div>
                    
                </div>
            </div>
            <div class="first__tabs__Section reduced_width">
                <div class="sec_tab reduced_sec_tab">
                    <img src="@/assets/img/building_green.png" class="mt-3" width="24px"  />
                    <p class="mt-2 first__tabs__section__para">Level</p>
                    <h6 class="mt-2 theme__color first__tab__bolde__text text-center poppins" >{{userDetails.level}}</h6>
                </div>
                <div class="sec_tab reduced_sec_tab">
                    <img src="@/assets/img/building (1).png" class="mt-3" width="24px"  />
                    <p class="mt-2 first__tabs__section__para">Points</p>
                    <h6 class="mt-2 theme__color first__tab__bolde__text text-center poppins">{{userDetails.points}}</h6>
                </div>
                <div class="sec_tab reduced_sec_tab">
                    <img src="@/assets/img/office-desk.png" class="mt-3" width="24px"  />
                    <p class="mt-2 first__tabs__section__para">Status</p>
                    <h6 class="mt-2 theme__color first__tab__bolde__text text-center poppins">{{userDetails.status}}</h6>
                </div>
            </div>
        </div>

        <div class="row lmsUserDetails">
            <div class="col-4 ml-2 mt-5 mb-3">
                <div>
                    <button class="creaet_branch_btn" @click="gotoAllCourses">View all Courses</button>
                </div>
            </div>
        </div>



        <b-tabs card class="employee_tabs" style="margin: 0;" fill>
                <b-tab title="My Courses">
                    <b-card-text class="employee-tab-content">
                        <div class="tab-content-body">
                            <ejs-grid                                    
                                ref="dataGrid"
                                :created="refreshGrid"
                                :allowPaging="true"
                                :allowSorting="true"
                                :pageSettings="pageSettings"
                                :toolbar="toolbar"
                                :searchSettings="search"
                                :allowExcelExport="true"
                                :allowPdfExport="true"
                                :toolbarClick="toolbarClick"
                                v-show="courses.length > 0 && !showLoader">
                                <e-columns>
                                    <e-column field="index" headerText="#" width="60px"></e-column>
                                    <e-column field="name" headerText="Course Name" width="300"></e-column>
                                    <e-column field="role" headerText="Role" width="110"></e-column>
                                    <e-column field="completion_percentage" headerText="Completion (%)" width="120"></e-column>
                                    <e-column field="enrolled_on" headerText="Enrolled On" width="150"></e-column>
                                    <e-column field="completed_on" headerText="Completed On" width="150"></e-column>
                                    <e-column field="expired_on" headerText="Expired On" width="150"></e-column>
                                    <e-column field="" headerText="" width="10"></e-column>
                                </e-columns>
                            </ejs-grid>
                            <TableLoader v-show="showLoader"></TableLoader>
                            <div class="empty_branch__div mt-1 mb-3" v-if="courses.length < 1 && !showLoader" style="width: 90.65%; height: 350px;">
                                <div class="text-center">
                                    <h6 class="text-center">No Courses Yet</h6>
                                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                                </div>
                            </div>
                        </div>
                    </b-card-text>
                </b-tab>
                <b-tab title="My Certifications">
                    <b-card-text class="employee-tab-content">
                        <div class="tab-content-body">
                            <ejs-grid
                                ref="dataGrid2"
                                :created="refreshGrid2"
                                :allowPaging="true"
                                :allowSorting="true"
                                :pageSettings="pageSettings"
                                :toolbar="toolbar"
                                :searchSettings="search"
                                :allowExcelExport="true"
                                :allowPdfExport="true"
                                :toolbarClick="toolbarClick2"
                                v-show="certifications.length > 0 && !showLoader">
                                <e-columns>
                                    <e-column field="index" headerText="#" width="60px"></e-column>
                                    <e-column field="course_name" headerText="Course Name" width="250"></e-column>
                                    <e-column field="issued_date" headerText="Issued Date" width="110"></e-column>
                                    <e-column field="expiration_date" headerText="Expiration Date" width="120"></e-column>
                                    <e-column :template="downloadTemplate" headerText="Download Certificate" width="120"></e-column>
                                    <e-column :template="publicDownloadTemplate" headerText="View Certificate" width="120"></e-column>
                                    <e-column field="" headerText="" width="10"></e-column>
                                </e-columns>
                            </ejs-grid>     
                            <TableLoader v-show="showLoader"></TableLoader>                           
                            <div class="empty_branch__div mt-1 mb-3" v-if="certifications.length < 1 && !showLoader" style="width: 90.65%; height: 350px;">
                                <div class="text-center">
                                    <h6 class="text-center">No Certifications Yet</h6>
                                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                                </div>
                            </div>
                        </div>
                    </b-card-text>
                </b-tab>
                <b-tab title="My Badges">
                    <b-card-text class="employee-tab-content">
                        <div class="tab-content-body">
                            <ejs-grid
                                ref="dataGrid3"
                                :created="refreshGrid3"
                                :allowPaging="true"
                                :allowSorting="true"
                                :pageSettings="pageSettings"
                                :toolbar="toolbar"
                                :searchSettings="search"
                                :allowExcelExport="true"
                                :allowPdfExport="true"
                                :toolbarClick="toolbarClick3"
                                v-show="badges.length > 0 && !showLoader">
                                <e-columns>
                                    <e-column field="index" headerText="#" width="60px"></e-column>
                                    <e-column field="name" headerText="Name" width="150"></e-column>
                                    <e-column field="type" headerText="Type" width="110"></e-column>
                                    <e-column field="criteria" headerText="Criteria" width="180"></e-column>
                                    <e-column field="issued_on" headerText="Issued On" width="150"></e-column>
                                    <e-column field="" headerText="" width="10"></e-column>
                                </e-columns>
                            </ejs-grid>
                            <TableLoader v-show="showLoader"></TableLoader>
                            <div class="empty_branch__div mt-1 mb-3" v-show="badges.length < 1 && !showLoader" style="width: 90.65%; height: 350px;">
                                <div class="text-center">
                                    <h6 class="text-center">No Badges Yet</h6>
                                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                                </div>
                            </div>
                        </div>
                    </b-card-text>
                </b-tab>
            </b-tabs>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Employee/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import TableLoader from '@/components/TableLoading.vue';
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;

import { DataManager,Query,ODataV4Adaptor,Predicate } from "@syncfusion/ej2-data";
import {
    Page,
    Sort,
    Toolbar,
    Search,
    ExcelExport,
    PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    title: 'LIMSAA',
    components: {
        MasterLayout,
        TableLoader
    },
    provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            userCourseInfo:{},
            courses: [],
            certifications: [],
            badges: [],
            showLoader: false,
            backgroundUrl,
            userDetails: {},
            email: JSON.parse(localStorage.getItem("dcasUserDetails")).email,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            fields : { text: "designationName", value: "id" },
            allowFiltering: true,
            downloadTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        `<div>
                            <a :href="data.public_url" target="_blank">Download</a>
                        </div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                    })
                };
            },
            publicDownloadTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        `<div>
                            <a :href="data.download_url" target="_blank">View</a>
                        </div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                    })
                };
            },

        }
    },
    created() {
        this.$eventHub.$on("viewEmployeeVM", (id) => {
            this.viewEmployee(id);
        });
        this.id = this.$route.query.id ? parseInt(this.$route.query.id) : null;
    },
    mounted() {
        this.loadUserCouseInfo();
    },
    methods: {
        toolbarClick(args){
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport({
                    pageOrientation: 'Landscape',
                });
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        toolbarClick2(args){
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid2.pdfExport({
                    pageOrientation: 'Landscape',
                });
                break;
                case "Excel Export":
                this.$refs.dataGrid2.excelExport();
                break;
            }
        },
        toolbarClick3(args){
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid3.pdfExport({
                    pageOrientation: 'Landscape',
                });
                break;
                case "Excel Export":
                this.$refs.dataGrid3.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        refreshGrid2() {
            this.$refs.dataGrid2.refresh();
        },
        refreshGrid3() {
            this.$refs.dataGrid3.refresh();
        },
        loadUserCouseInfo(){
            this.showLoader = true;
            let grid = this.$refs.dataGrid;
            let grid2 = this.$refs.dataGrid2;
            let grid3 = this.$refs.dataGrid3;

            let url = `${configObject.apiBaseUrl}/LMS/User/ByEmail/jvine300@gmail.com`;
            this.axios.get(url, configObject.authConfig)
                .then(response => {
                    this.userDetails = response.data
                    let index = 0;
                    response.data.courses.forEach(course => {
                        index++;
                        course.index = index;
                    });
                    index = 0
                    response.data.certifications.forEach(certification => {
                        index++;
                        certification.index = index;
                    });
                    index = 0
                    response.data.badges.forEach(badge => {
                        index++;
                        badge.index = index;
                    });
                    this.courses = response.data.courses;
                    this.certifications = response.data.certifications;
                    this.badges = response.data.badges;
                    this.showLoader = false;

                    grid.ej2Instances.setProperties({ dataSource: this.courses });
                    grid2.ej2Instances.setProperties({ dataSource: response.data.certifications });
                    grid3.ej2Instances.setProperties({ dataSource: response.data.badges });
                    this.refreshGrid();
                    this.refreshGrid2();
                    this.refreshGrid3();
                })
                .catch((error) => {
                    this.loading = false;
                    this.loaded = false;
                });
        },
        gotoAllCourses(){
            this.$router.push({
                name: 'courses', 
            });
        }
    }
}
</script>

        