<template>
    <MasterLayout>
        <div class="welcome_message">
            <div class="row">
                <div class="col-7">    
                    <h5 class='' v-if="loaded && !isAdvanceSearch">
                        Showing <span class="branches__count"> {{employees.length}} </span> 
                        <span v-if="employees.length > 1">employees</span><span v-if="employees.length < 2">employee</span> found for the Role 
                        <span class="branches__count">{{designation.designationName}}</span>
                    </h5>
                    <h5 class='' v-if="loaded && isAdvanceSearch">
                        <div v-if="employees.length > 0">
                            Showing <span class="branches__count"> {{employees.length}} </span> 
                            <span v-if="employees.length > 1">employees</span><span v-if="employees.length < 2">employee</span> found for the Role 
                            <span class="branches__count">{{designation.designationName}}</span>
                        </div>
                        <div v-if="employees.length <= 0">
                            No result for your search
                        </div>
                    </h5>
                    <h5 v-if="loading">Loading... please wait</h5>
                    <h5 v-if="id === null">Please select designation for search</h5>
                </div>
                <div class="col-5 text-right mb-3">                    
                    <div class="search_filter">
                        <ejs-dropdownlist id='dropdownlist' placeholder='Select a Designation' 
                            v-model="id"
                            :allowFiltering='allowFiltering' 
                            :filtering='filtering' 
                            :dataSource='designations' 
                            :fields='fields'></ejs-dropdownlist>
                        
                    </div>
                    <div class="">
                        <b-dropdown split class="d-btn" @click="findSuccessor">
                            <template v-slot:button-content>
                                Search <i class="fa fa-search"></i>
                            </template>
                            <b-dropdown-item @click="openAdvanceArea()">Advance Search</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <SlideUpDown :duration="400" :active="showAdvanceSearch">
                <div class="row">
                    <div class="col-12">
                        <div class="adv-search">
                            <h5>Advance Search</h5>
                            <div class="row">
                                <div class="col-3">
                                    <b-form-group id="input-group-1" label="Designation *" label-for="input-1">
                                        <b-form-select id="input-1" v-model="advanceSearch.id" :options="designations" required></b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-3">
                                    <b-form-group id="input-group-2" label="Division" label-for="input-2">
                                        <b-form-select id="input-2" v-model="advanceSearch.divisionId" :options="divisions" required></b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-3">
                                    <b-form-group id="input-group-3" label="Unit" label-for="input-3">
                                        <b-form-select id="input-3" v-model="advanceSearch.unitId" :options="units" required></b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-3">
                                    <b-form-group id="input-group-4" label="Branch" label-for="input-4">
                                        <b-form-select id="input-4" v-model="advanceSearch.branchId" :options="branches" required></b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-3">
                                    <!-- <range-slider
                                        class="slider"
                                        min="10"
                                        max="1000"
                                        step="10"
                                        v-model="advanceSearch.min">
                                    </range-slider> -->
                                    <!-- <div>Min: {{advanceSearch.min}} = Max: {{advanceSearch.max}}</div> -->
                                </div>
                                <div class="col-3">
                                    <!-- <VueSlider attribute="price">
                                        <div slot-scope="{ currentRefinement, advanceSearch, refine }">
                                            <vue-slider
                                                :min="advanceSearch.min"
                                                :max="advanceSearch.max"
                                                :lazy="true"
                                                :value="toValue(currentRefinement, advanceSearch)"
                                                @change="refine({ min: $event[0], max: $event[1] })" />
                                        </div>
                                    </VueSlider> -->
                                </div>
                                <div class="col-12 text-center">
                                    <button class="creaet_branch_btn" @click="doAdvanceSearch">Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SlideUpDown>
            
        </div>
         <div class="employees__assessment__wrapper">
            <section class="table__section mt-1 pb-5" >
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" headerText="#" width="60px"></e-column>
                        <e-column :template="avatarTemplate" width="80px" headerText=""></e-column>
                        <e-column field="fullName" headerText="Name"></e-column>
                        <e-column field="designationName" headerText="Job Title"></e-column>
                        <e-column field="gradeLevel" headerText="Grade Lvl" width="100" ></e-column>
                        <e-column field="totalPoints" headerText="Basic" width="80"></e-column>
                        <e-column field="totalRequired" headerText="Expected" width="100"></e-column>
                        <e-column :template="progressBarTemplate" headerText="Evaluation" width="150"></e-column>
                        <!-- <e-column field="totalGap" headerText="Percentage (%)"  width="200px"></e-column> -->
                        <!-- <e-column field="fullName" headerText="Name" width="150px"></e-column>
                        <e-column field="designationName" headerText="Job Title" width="150px"></e-column>
                        <e-column :template="progressBarTemplate" headerText="Evaluation" width="200px"></e-column>
                        <e-column field="totalGap" headerText="Percentage (%)"  width="200px"></e-column> -->
                        <e-column :template="actionButtonsTemplate" headerText="Action"  width="150px"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import SlideUpDown from 'vue-slide-up-down';
// import RangeSlider from 'vue-range-slider'
// import 'vue-range-slider/dist/vue-range-slider.css'

// Type 2
// import VueSlider from 'vue-slider-component';
// import 'vue-slider-component/theme/default.css'

import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;

import { DataManager,Query,ODataV4Adaptor,Predicate } from "@syncfusion/ej2-data";
// import { SplitButtonPlugin  } from "@syncfusion/ej2-vue-splitbuttons";
// import { ButtonPlugin } from '@syncfusion/ej2-vue-buttons';

// Vue.use(ButtonPlugin);
// Vue.use(SplitButtonPlugin );

import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    title: 'Succession Planning Search',
    components: {
        MasterLayout,
        SlideUpDown,
        //RangeSlider,    //1
        //VueSlider
    },
    provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            id: null,
            designation: {},
            employees: [],
            showAdvanceSearch: false,
            advanceSearch: {
                divisionId: 0,
                unitId: 0,
                branchId: 0,
                competencyId: 0,
                min: 0,
                max: 100,
                id: 0
            },
            currentRefinement: { min: 0, max: 100 },
            loaded: false,
            loading: false,
            isAdvanceSearch: false,
            designations: [],
            divisions: [],
            units: [],
            branches: [],
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            fields : { text: "designationName", value: "id" },
            allowFiltering: true,
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-primary btn-xs" title="" v-on:click="_viewEmployee(data.id)">' +
                                'View Profile <i class="fa fa-eye" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _viewEmployee: function(id) {
                                console.log(id);
                                this.$eventHub.$emit("viewEmployeeVM", id);
                            }
                        }
                    })
                };
            },
            avatarTemplate: function() {
                return {
                     template: Vue.component("avatarTemplate", {
                        template:   '<div class="profile__pic__wrapper" style="height: 30px; width: 30px;">' +
                                        '<div class="profile__pic mini">' +
                                            '<img v-bind:src="`${data.passportUrl}`" />' +
                                        '</div>' +
                                    '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            },
            progressBarTemplate: function(){
                return {
                     template: Vue.component("progressBarTemplate", {
                        template:   '<div>'  +
                                        '<div id="progressbar" v-if="$data.data.totalGap >= 0 && $data.data.totalGap <= 25">' +
                                            `<div class="progress__bar__wrapper" style="background-color: red" v-bind:style="{ width: $data.data.totalGap+'%'}">` +
                                                '<div class="inner__div" style="border: 2px solid red;background-color: red;"></div>'+
                                            '</div>' +
                                        '</div>'+
                                        '<div id="progressbar" v-if="$data.data.totalGap > 25 && $data.data.totalGap <= 75">' +
                                            `<div class="progress__bar__wrapper" style="background-color: yellow" v-bind:style="{ width: $data.data.totalGap+'%'}">`+
                                                '<div class="inner__div"></div>'+
                                            '</div>' +
                                        '</div>'+
                                         '<div id="progressbar" v-if="$data.data.totalGap > 75 && $data.data.totalGap <= 100">' +
                                            `<div class="progress__bar__wrapper" style="background-color: green" v-bind:style="{  width: $data.data.totalGap+'%'}">`+
                                                '<div class="inner__div"></div>'+
                                            '</div>' +
                                        '</div>'+
                                        '<div id="progressbar" v-if="$data.data.totalGap > 100">' +
                                            `<div class="progress__bar__wrapper" style="width:100%;background-color: green">`+
                                                '<div class="inner__div"></div>'+
                                            '</div>' +
                                        '</div>'+
                                        '<div class="text-center">{{$data.data.totalGap}}%</div>' +
                                    '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            },

        }
    },
    created() {
        console.log('First Call');
        this.$eventHub.$on("viewEmployeeVM", (id) => {
            this.viewEmployee(id);
        });
        this.id = this.$route.query.id ? parseInt(this.$route.query.id) : null;
    },
    mounted() {
        console.log('Second Call');
        this.getData();
        this.getProfiles();
        this.getDivisions();
        this.getUnits();
        this.getBranches();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }

    },
    methods: {
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.dataGrid.getColumns()[1].visible = false;
                    this.$refs.dataGrid.getColumns()[6].visible = false;

                    this.$refs.dataGrid.pdfExport({
                        fileName: 'Succession Planning.pdf',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  'Succession Planning',
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
                case "Excel Export":
                    this.$refs.dataGrid.getColumns()[1].visible = false;
                    this.$refs.dataGrid.getColumns()[6].visible = false;
                    this.$refs.dataGrid.excelExport({
                        pageOrientation: 'Landscape',
                        fileName: 'Succession Planning.xlsx',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  `Succession Planning`,
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        getData(){
            let grid = this.$refs.dataGrid;
            this.isAdvanceSearch = false;

            if(this.id !== null){
                this.loading = true;
                this.loaded = false;
                let url = `${configObject.apiBaseUrl}/SuccessionPlan/${this.id}?id=${this.id}&divisionId=0&`
                    + `unitId=0&branchId=0&competencyId=0&min=0&max=0`;
                //     console.log(url);
                // this.axios
                // .get(url, configObject.authConfig)

                this.axios
                .get(url, configObject.authConfig)
                //.get(`${configObject.apiBaseUrl}/SuccessionPlan/${this.id}`, {id: this.id}, configObject.authConfig)
                .then(response => {
                    let index = 0;
                    let fullList = [];
                    response.data.employees.forEach(a => {
                        index++;
                        a.index = index;
                        a.fullName = a.firstname + ' ' + a.surname;
                        a.type = 1;
                        fullList.push(a);
                    });
                    //console.log('Adding the list to DataSource');
                    //console.log()
                    this.employees = fullList.sort(this.sortArrayReverse("totalGap", "designationName")); //response.data.employees;
                    //this.extraEmployees = response.data.extraEmployees;
                    this.designation = response.data.designation;

                    //console.log(this.designation);
                    this.employees.forEach(cur => {
                        if (cur.totalGap > 100) {
                            cur.totalGap = 100
                        }
                    })
                    grid.ej2Instances.setProperties({ dataSource: this.employees });
                    this.refreshGrid();
                    this.loading = false;
                    this.loaded = true;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.loaded = false;

                    // this.$toast.open({
                    //     message: "Unable to load ",
                    //     type: "error",
                    //     duration: 4000
                    // });
                });
            } 
        },
        getProfiles(){
            this.axios.get(`${configObject.apiBaseUrl}/Designation`, configObject.authConfig)
            .then(response => {
                response.data.forEach(a => {
                    a.value = a.id, 
                    a.text = a.designationName
                });

                //console.log(response.data);
                this.designations = response.data;
            })
            .catch((error) => {
                console.log(error);
                // this.$toast.open({
                //     message: "Unable to load Job Profiles",
                //     type: "error",
                //     duration: 4000
                // });
            });
        },
        getDivisions(){
            this.axios.get(`${configObject.apiBaseUrl}/Divisions`, configObject.authConfig)
            .then(response => {
                response.data.forEach(a => {
                    a.value = a.id, 
                    a.text = a.name
                });

                //console.log(response.data);
                this.divisions = response.data;
            })
            .catch((error) => {
                console.log(error);
                // this.$toast.open({
                //     message: "Unable to load Job Profiles",
                //     type: "error",
                //     duration: 4000
                // });
            });
        },
        getUnits(){
            this.axios.get(`${configObject.apiBaseUrl}/Unit`, configObject.authConfig)
            .then(response => {
                response.data.forEach(a => {
                    a.value = a.id, 
                    a.text = a.name
                });
                this.units = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getBranches(){
            this.axios.get(`${configObject.apiBaseUrl}/Branch`, configObject.authConfig)
            .then(response => {
                response.data.forEach(a => {
                    a.value = a.id, 
                    a.text = a.name
                });
                this.branches = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        findSuccessor(){
            if(this.id === NaN || this.id === 'NaN' || this.id === null){
                alert('Please select a Designation role to search from');
            }
            else{
                this.getData();
            }
        },
        filtering: function(e) {
           var query = new Query();
            //frame the query based on search string with filter type.
            query = (e.text != "") ? query.where("designationName", "startswith", e.text, true) : query;
            //pass the filter data source, filter query to updateData method.
            e.updateData(this.designations, query);
        },
        sortArray(property) {
            var sortOrder = 1;
            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a,b) {
                /* next line works with strings and numbers, 
                * and you may want to customize it to your needs
                */
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        },
        sortArrayReverse(property) {
            var sortOrder = 1;
            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a,b) {
                /* next line works with strings and numbers, 
                * and you may want to customize it to your needs
                */
                var result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        },
        sortArrayMultiple() {
            /*
            * save the arguments object as it will be overwritten
            * note that arguments object is an array-like object
            * consisting of the names of the properties to sort by
            */
            var props = arguments;
            return function (obj1, obj2) {
                var i = 0, result = 0, numberOfProperties = props.length;
                /* try getting a different result from 0 (equal)
                * as long as we have extra properties to compare
                */
                while(result === 0 && i < numberOfProperties) {
                    result = this.sortArray(props[i])(obj1, obj2);
                    i++;
                }
                return result;
            }
        },
        sortArrayMultipleReverse() {
            /*
            * save the arguments object as it will be overwritten
            * note that arguments object is an array-like object
            * consisting of the names of the properties to sort by
            */
            var props = arguments;
            //console.log(arguments);
            return function (obj1, obj2) {
                var i = 0, result = 0, numberOfProperties = props.length;
                /* try getting a different result from 0 (equal)
                * as long as we have extra properties to compare
                */
                while(result === 0 && i < numberOfProperties) {
                    console.log(props[i]);
                    console.log('Obj1 = ');
                    console.log(obj1);
                    console.log('Obj2 = '); 
                    console.log(obj2);
                    result = this.sortArrayReverse(props[i])(obj1, obj2);
                    i++;
                }
                return result;
            }
        },
        viewEmployee($id) {
            this.$router.push({name: 'employee_assessment', query: {
                id: $id
            }}).catch(err => {});
        },
        openAdvanceArea(){
            this.showAdvanceSearch = !this.showAdvanceSearch;
        },
        doAdvanceSearch(){
            console.log(this.advanceSearch);
            this.isAdvanceSearch = true;

            let grid = this.$refs.dataGrid;
            this.id = this.advanceSearch.id;
            this.loading = true;
            this.loaded = false;
            let url = `${configObject.apiBaseUrl}/SuccessionPlan/${this.advanceSearch.id}?id=${this.advanceSearch.id}&divisionId=${this.advanceSearch.divisionId}&`
                + `unitId=${this.advanceSearch.unitId}&branchId=${this.advanceSearch.branchId}&competencyId=${this.advanceSearch.competencyId}`
                + `&min=${this.advanceSearch.min}&max=${this.advanceSearch.max}`;
                console.log(url);
            this.axios
            .get(url, configObject.authConfig)
            .then(response => {
                this.loading = false;
                this.loaded = true;
                let index = 0;
                let fullList = [];
                response.data.employees.forEach(a => {
                    index++;
                    a.index = index;
                    a.fullName = a.firstname + ' ' + a.surname;
                    a.type = 1;
                    fullList.push(a);
                });
                
                this.employees = fullList.sort(this.sortArrayReverse("totalGap", "designationName"));
                this.designation = response.data.designation !== null ? response.data.designation : null;

                this.employees.forEach(cur => {
                    if (cur.totalGap > 100) {
                        cur.totalGap = 100
                    }
                })
                grid.ej2Instances.setProperties({ dataSource: this.employees });
                this.refreshGrid();
            })
            .catch((error) => {
                console.log(error);
                this.loading = false;
                this.loaded = false;
            });
        },
        toValue(value, range) {
            return [
                value.min !== null ? value.min : range.min,
                value.max !== null ? value.max : range.max,
            ];
        },
    }
}
</script>

        