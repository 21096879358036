var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-height":"100vh"}},[_c('div',{staticClass:"floating_btn",on:{"click":_vm.openContactModal}},[_vm._m(0)]),_c('div',{staticClass:"row login__wrapper no_margin",staticStyle:{"min-height":"100vh"}},[_c('div',{staticClass:"col-md-12 col-lg-6 no_padding"},[_c('div',{staticClass:"left_wrapper"},[_c('h3',{staticClass:"welcome_header"},[_vm._v("Welcome!")]),_c('div',{staticClass:"text-center mt-5"},[_c('img',{attrs:{"src":_vm.logo,"width":"100"}})]),_c('p',{staticClass:"text-center mt-4"},[_vm._v(_vm._s(_vm.portalName)+": Compentency Administrative System")]),_vm._t("default",function(){return [_c('router-view')]})],2)]),_c('div',{staticClass:"col-md-12 col-lg-6 no_padding"},[_c('div',{staticClass:"right_wrapper",style:({
                backgroundImage: `linear-gradient(rgb(9, 140, 40 , 0.92), rgb(9, 140, 40, 0.92)), url(${_vm.backgroundUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: '79% 50%' })},[_c('section',{staticClass:"top__bar no-style"}),_c('section',[_vm._m(1),_vm._m(2),_c('p',{staticClass:"text-center mt-4 dcas__para_text"},[_vm._v(" "+_vm._s(_vm.portalName)+" is a Human Capital Management System with content protection as an optimum value. ")]),_c('div',{staticClass:"mt-5 text-center btn_how"},[_c('button',{staticClass:"btn btn-primary sign_up_btn",attrs:{"type":"submit"},on:{"click":function($event){return _vm.changeRoute('how_it_works')}}},[_vm._v(" How it works ")])])]),_c('section',{staticClass:"footer_bar"},[_c('p',[_vm._v("Copyright © "+_vm._s(_vm.year)+" ")])])])])]),_c('ContactModal')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"floatinig_btn_inner_div"},[_c('div',{staticClass:"grouped_icons text-center"},[_c('i',{staticClass:"fa fa-envelope envelope",attrs:{"aria-hidden":"true"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"learn__more_welcome_header text-center"},[_vm._v(" Enterprise "),_c('br'),_vm._v(" Competency Administrative Sytem"),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 text-center"},[_c('img',{staticClass:"mr-3",attrs:{"src":require("@/assets/img/favorites (1).svg")}})])
}]

export { render, staticRenderFns }