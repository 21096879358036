import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import BootstrapVue from "bootstrap-vue";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Toasted from "vue-toasted";
import "@/plugins/vue-toast-notification";
import { GridPlugin } from "@syncfusion/ej2-vue-grids";
import { DropDownListPlugin } from "@syncfusion/ej2-vue-dropdowns";
import titleMixin from'./titleMixin';
import { registerLicense } from '@syncfusion/ej2-base';

Vue.prototype.$eventHub = new Vue();
import VueAxios from "vue-axios";

Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);

Vue.use(VueAxios, axios);

Vue.use(Toasted);

Vue.mixin(titleMixin);

import VModal from 'vue-js-modal'

Vue.use(VModal)

Vue.use(BootstrapVue);
// Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;

registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCeUx0R3xbf1xzZFFMZVlbQHRPMyBoS35RdUVlW31edHFQQmRfUE11');

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
