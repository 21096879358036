<template>
    <MasterLayout>
        <div class="row">
            <div class="col-md-12">
                <div id="competency_profile" class="mt-5">
                    <h3>How It Work</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque consequat id justo non hendrerit. Donec viverra auctor mauris at fringilla. Fusce vitae mauris tristique, tristique neque id, rutrum lorem. Quisque ut tortor vel sapien sagittis scelerisque. Nulla quis placerat lacus. Nam dignissim enim ac ipsum sagittis, vitae dapibus enim molestie. Aliquam et risus facilisis, varius massa vitae, volutpat risus. Aliquam erat volutpat. Nullam finibus sapien eget elit semper, sit amet tincidunt nulla tempor.</p>

                    <p>Etiam in nunc sed augue accumsan efficitur. Curabitur at vulputate augue, at tincidunt risus. In vitae urna volutpat, efficitur nulla vel, consectetur libero. Aliquam pharetra egestas ante ac pretium. Integer vel facilisis ex. Nunc aliquet lacinia nisi sed sagittis. Quisque a sagittis ex, nec pretium nisi. Maecenas vitae nunc felis. Fusce gravida eget nunc et condimentum. Maecenas non neque pretium velit malesuada mattis id id orci. Cras dictum ante ac ligula lobortis dictum. Nullam vel venenatis massa.</p>

                    <p>Donec a volutpat diam, sit amet posuere sapien. Donec a justo porta, rutrum nulla varius, semper purus. Praesent efficitur risus sed ligula cursus scelerisque a at quam. In arcu justo, molestie et orci non, dictum hendrerit velit. Vivamus accumsan, erat eu auctor viverra, justo mi malesuada tellus, mattis efficitur purus nibh nec augue. Phasellus aliquet, turpis eu interdum hendrerit, neque urna consectetur mauris, eget auctor nibh velit vel purus. In dictum sem augue, vitae tristique metus bibendum in. Nunc imperdiet tempus mi eu posuere. Aenean quis mi neque. Maecenas egestas nisi volutpat condimentum sodales. Morbi laoreet erat in est faucibus sagittis vitae in nibh. Suspendisse neque erat, bibendum sed nulla eget, venenatis tempor orci. Pellentesque hendrerit tempus ipsum quis auctor. Aliquam consequat mollis tortor, ac scelerisque magna ornare eu. Vivamus vestibulum velit nibh, cursus efficitur leo tempus sit amet. Aliquam erat volutpat.</p>

                    <ul>
                        <li>Praesent vehicula pretium ex et dictum. Vestibulum auctor, massa ac scelerisque volutpat, tortor ligula </li>
                        <li>tempor tellus, a cursus felis ex vel leo. Nulla facilisi. Sed ac facilisis lorem, at sollicitudin velit. </li>
                        <li>Nulla malesuada velit ut dolor tincidunt molestie. Ut risus justo, placerat non cursus vel, ultricies in risus. </li>
                        <li>Mauris imperdiet hendrerit ex a suscipit. Nulla in ipsum vel ex tincidunt congue. Donec tempor tincidunt elit sed </li>
                        <li>Morbi vel nulla ullamcorper, sagittis elit euismod, consequat ipsum. </li>
                        <li>Phasellus nec risus nibh. Mauris auctor lorem ac bibendum commodo. Fusce eleifend rutrum velit a pulvinar. </li>
                        <li>Nullam a consequat ex. Quisque condimentum tempor risus a varius. Class aptent taciti sociosqu ad litora torquent per</li>
                    </ul>
                    <p>Praesent sed massa pellentesque, blandit tortor in, accumsan mi. Sed magna augue, mattis vel ultricies ut, feugiat malesuada erat. Aenean sed eleifend erat. Sed tortor mauris, volutpat tincidunt quam quis, elementum sagittis mauris. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam ac tincidunt est. Aenean a eros iaculis, eleifend erat a, faucibus magna. Suspendisse fermentum auctor velit, nec sodales diam gravida a. Proin vulputate enim eget pretium efficitur. Curabitur ac metus cursus, varius nisi non, aliquet velit. Vestibulum facilisis fringilla nisi ac mollis. Ut id massa vestibulum, tincidunt enim consectetur, lacinia dolor.</p>

                </div>
            </div>
        </div>
    </MasterLayout>
</template>
<script>
    import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
    import configObject from "@/config";
    import MasterLayout from "@/views/Accounts/StaticLayout.vue";


export default {
    components:{
        MasterLayout,
    },
    data() {
        return {
            
        }
    },
    created() {
               
    },
    methods: {
    }
    
}
</script>
<style>
</style>