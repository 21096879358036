<template>
<modal name="createDivisionModal" transition="pop-out" :width="modalWidth" :height="400">
  <div class="modal__header">
      <h4>Add New Division</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
              <div class="col-md-12">
                  <div class="input_wrapper" style="">
                      <span>Division Name</span>
                      <div class="input___group">                          
                          <input type="text" placeholder="division name" class="ml-3" v-model="divisionName" />
                      </div>
                  </div>
              </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
                <div class="input_wrapper" style="">
                    <span>Division Objective</span>
                    <div class="">
                        <textarea type="text" placeholder="Enter Division objective" class="" v-model="objective" style="height: 115px;"></textarea>
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <div class="text-center mt-2">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Create Division
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 400;
import configObject from "@/config";

export default {
    name: 'createDivisionModal',
    data () {
        return {
        modalWidth: MODAL_WIDTH,
        divisionName: null,
        objective: null,
        isButtonDisabled:  false,
        showLoader: false,
        branch: this.$store.getters.getBranchdetail
        }
    },
    created () {
        this.modalWidth = window.innerWidth < MODAL_WIDTH
        ? MODAL_WIDTH / 2
        : MODAL_WIDTH

        console.log(this.$store.getters.getBranchdetails);
        
    },
    mounted(){
        console.log(configObject);
        console.log(configObject.authConfig);
    },
    methods: {
        validateDetails($event) {
            $event.preventDefault();
            if(!this.divisionName || !this.objective){
                this.$toasted.show("Fill in the missing Fields", {
                        theme: "bubble",
                        position: "top-center",
                        duration: 2000
                    });
            }else {
                this.createDivision();
            }
        },
        closeCreateBranchModal(){
            this.$modal.hide('createDivisionModal');
        },
        createDivision() {
            console.log("division create");
            let data = {
                name: this.divisionName,
                objective: this.objective,
            };
            this.showLoader = true;
            this.isButtonDisabled = true;
            this.axios.post(`${configObject.apiBaseUrl}/Divisions/Add`, data, configObject.authConfig)
                .then(() => {
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    this.$toast.success("Division created successfully");
                    this.closeCreateBranchModal();
                    this.$eventHub.$emit("refreshDivisionTable");
                    this.divisionName = null;
                    this.objective = null;
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "Unable to create division",
                        type: "error",
                        duration: 4000
                    });
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>
.cas_input .input_wrapper {
    margin-bottom: 5px!important;
}
.cas_input textarea, .cas_input [type='text']{
    margin-bottom: 5px!important;
}
</style>
