<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">

             <div class="welcome_message">
                 <div class="row">
                     <div class="col-6">    
                         <h5 class=''><span class="greeeting__text"> Hi Admin,
                            </span><span class="branches__count"> {{employees.length}} </span> {{employees.length > 1 ? 'Employees are' : 'Employee is'}} close to requirements
                        </h5> 
                     </div>
                 </div>
             </div>
             <section class="table__section mt-1 pb-5" v-show="employees.length > 0 || !showLoader">
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" headerText="#" width="40px"></e-column>
                        <e-column field="fullName" headerText="Full Name" width="70px"></e-column>
                        <e-column field="designationName" headerText="Designation Name" width="90px"></e-column>
                        <e-column field="status" headerText="Status" width="60px"></e-column>
                        <e-column field="expected" headerText="Expected" width="60px"></e-column>
                        <e-column field="basic" headerText="Basic" width="60px"></e-column>
                        <e-column field="" headerText="" width="10px"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
            <div class="empty_branch__div mt-3" v-if="employees.length < 1 && !showLoader">
                <div class="text-center">
                    <h6 class="text-center">No Employee is close to requirements</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
            <TableLoader v-show="showLoader"></TableLoader>
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import TableLoader from '@/components/TableLoading.vue';
import configObject from "@/config";
import JQuery from "jquery";
import moment from "moment";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    components: {
        MasterLayout,
        TableLoader
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            backgroundUrl,
            componentKey: 0,
            employees: [],
            grades: [],
            showLoader: false,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true }
        }
    },
     mounted() {
        this.getEmployees();
        // this.getGrades();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created() {
    },
    methods: {
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        getEmployees() {
            this.showLoader = true;
            let grid = this.$refs.dataGrid;
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Dashboard/CloseToRequirement`,
                    configObject.authConfig
                )
                .then(response => {
                    let index = 0;
                    response.data.forEach(employee => {
                        index++;
                        employee.index = index;
                        employee.fullName = employee.firstname + " " + employee.surname
                    });

                    console.log(response.data);
                    this.showLoader = false;

                    grid.ej2Instances.setProperties({ dataSource: response.data });
                    this.refreshGrid();
                    this.employees = response.data;
                })
                .catch((error) => {
                    console.log(error);
                    // this.$toast.open({
                    //     message: "unable to load employees",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        }
    }
}
</script>

        