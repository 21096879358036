
<template>
    <div style="min-height: 100vh;">
        <div class="floating_btn" @click="openContactModal">
            <div class="floatinig_btn_inner_div">
                <div class="grouped_icons text-center">
                    <!-- <img src="@/assets/img/mail.png" width="80%" /> -->
                    <i class="fa fa-envelope envelope" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="row login__wrapper no_margin" style="min-height: 100vh;">
            <div class="col-md-12 col-lg-6 no_padding">
                <div class="left_wrapper">
                    <h3 class="welcome_header">Welcome!</h3>
                    <div class="text-center mt-5">
                        <img v-bind:src="logo" width="100"  />
                    </div>
                    <p class="text-center mt-4">{{portalName}}: Compentency Administrative System</p>
                    <slot>
                        <router-view />
                    </slot>
                </div>
            </div>
            <div class="col-md-12 col-lg-6 no_padding">
                <div class="right_wrapper" :style="{
                    backgroundImage: `linear-gradient(rgb(9, 140, 40 , 0.92), rgb(9, 140, 40, 0.92)), url(${backgroundUrl})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: '79% 50%' }">
                    <section class="top__bar no-style">
                        <!-- <div class="top__bar__inner_div">
                            <ul>
                                <li class="mr-4"><a href="about#competency_profile">Competency Profile</a></li>
                                <li class="mr-4"><a href="about#benchmarking">Assessment &amp; Benchmarking</a></li>
                                <li><a href="about#report_analytics">Report Analytics</a></li>
                                <li class="mr-4"><a href="http://www.limsaa.com" target="_blank">LMS</a></li>
                            </ul>
                        </div> -->
                    </section>
                    <section>
                        <div class="learn__more_welcome_header text-center">
                            Enterprise <br />
                            Competency Administrative Sytem<br /><br />
                        </div>
                        <div class="mt-3 text-center">
                            <img src="@/assets/img/favorites (1).svg" class="mr-3" />
                        </div>
                        <p class=" text-center mt-4 dcas__para_text">
                            {{portalName}} is a Human Capital Management System with content protection as an optimum value.
                        </p>
                        <div class="mt-5 text-center btn_how">
                            <button type="submit" @click="changeRoute('how_it_works')"
                                    class="btn btn-primary sign_up_btn">
                               How it works
                            </button>
                        </div>
                    </section>

                    <section class="footer_bar">
                        <p>Copyright &copy; {{year}}
                        </p>
                    </section>
                </div>
            </div>
        </div>
        <ContactModal></ContactModal>
    </div>
</template>
<script>
    import Vue from "vue";
    import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
    import ContactModal from '@/components/Modals/Login/contactModal.vue';
    import configObject from "@/config";

    export default {
        components: {
            ContactModal
        },
        data() {
            return {
                year: new Date().getFullYear(),
                backgroundUrl,
                portalName: "",
                other: false,
                logo: '',
            }
        },
        created(){
            this.portalName = configObject.portalName;
            this.other = configObject.portalName.toLowerCase() === 'dcas' ? false : true;
            this.logo = configObject.portalLogo;
            console.log(this.logo);
        },  
        mounted(){
            //this.loadUser();
        },
        methods: {
            changeRoute($routeName){
                this.$router.push({
                    name: $routeName
                })
            },
            openContactModal(){
                this.$modal.show('contactModal');
            }
        }
    }
</script>