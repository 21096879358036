<template>
<modal name="editRoleTask" transition="pop-out" :width="modalWidth" :height="300">
  <div class="modal__header">
      <h5 class="text-white"> Edit Role Task</h5>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-12">
                <div class="" style="width: 100%">
                    <span>Role Task</span>
                    <div class="">
                        <input type="text" placeholder="Enter  Role task" class="ml-3" v-model="roleTask.task"  />
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <div class="text-center mt-4">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Update Role Task  <i class="fa fa-save ml-2"></i>
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 450;
import configObject from "@/config";

export default {
  name: 'editRoleTask',
  props:  ['roleTask'],
  data () {
    return {
      modalWidth: MODAL_WIDTH,
      isButtonDisabled:  false,
      showLoader: false,
    }
  },
  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

  },
  methods: {
      validateDetails($event) {
        $event.preventDefault();
        if(!this.roleTask.task){
            this.$toasted.show("Fill in the missing Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
        }else {
            this.editRoleTask();
        }
    },
    closeModal(){
        this.$modal.hide('editRoleTask');
    },
    editRoleTask() {
        let dataToSend = {
            task: this.roleTask.task,
            id: parseInt(this.roleTask.id),
            selected: false
        };
        this.showLoader = true;
        this.isButtonDisabled = true;
        let url = `${configObject.apiBaseUrl}​/RoleTask/Edit​/${this.roleTask.id}`;
        
        console.log(dataToSend);
        console.log('Editing ' + url);
        this.axios.put(`${configObject.apiBaseUrl}​/RoleTask/Edit/${this.roleTask.id}`, dataToSend,  configObject.authConfig)
            .then(response => {
                this.showLoader = false;
                this.isButtonDisabled = true;
                console.log(response.data);
                this.closeModal();
                this.$eventHub.$emit("refreshRoleTasksTable");
                this.$toast.success("Role Task edited successfully");
                this.isButtonDisabled = false;
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "unable to edit role task",
                        type: "error",
                        duration: 3000
                    });
                    console.log(error.response);
            });
    },
  }
}
</script>
