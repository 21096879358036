<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">

             <div class="welcome_message">
                 <div class="row">
                     <div class="col-8">    
                         <h5 class=''>Map Units to <span class="greeeting__text">{{branch.name}}
                            </span>. There are <span class="branches__count"> {{units.length}} </span> Units available
                        </h5> 
                     </div>
                     <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn default" @click="backToBranch"><i class="fa fa-arrow-left"></i> Back</button>
                            <button class="creaet_branch_btn" @click="mapBranchToUnit">
                                Map Units to Branch
                                <span class="">
                                    <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                                </span>
                            </button>
                            
                        </div>
                     </div>
                 </div>
             </div>
             <section class="table__section mt-1 pb-5">
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick"
                >
                    <e-columns>
                        <e-column headerText="" :headerTemplate="selectAllTemplate" :template="checkBoxTemplate" width="50"></e-column>
                        <e-column field="name" headerText="Unit Name"></e-column>
                        <e-column field="objective" headerText="Objective" ></e-column>
                        <e-column field="" headerText="" width="1px"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
            <div class="empty_branch__div mt-3" v-if="units.length < 1">
                <div class="text-center">
                    <h6 class="text-center"> You are yet to create a Unit</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    components: {
        MasterLayout,
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            showLoader: false,
            backgroundUrl,
            UserDP,
            componentKey: 0,
            units: [],
            unit: {},
            branch: {},
            newMappedUnitsArray: [],
            unitsArray: [],
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            // <e-column headerText="#" :headerTemplate="selectAllTemplate" :template="checkBoxTemplate"></e-column>
            checkBoxTemplate: function() {
                return {
                    template: Vue.component("columnTemplate", {
                        template: `<div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="data.selected" v-on:change="createMappedUnitsArray($event, data)" checked />
                            <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="!data.selected" v-on:change="createMappedUnitsArray($event, data)" />
                            <label class="custom-control-label" v-bind:for="data.id"></label>
                        </div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            createMappedUnitsArray: function(e, unit) {
                                this.$eventHub.$emit("unitsCheck", e, unit);
                            }
                        }
                    })
                };
            },
             selectAllTemplate: function() {
                return {
                template: Vue.component("columnTemplate", {
                    template: `<div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="selectAll" v-on:change="_selectAll($event)"/>
                        <label class="custom-control-label" for="selectAll"></label></div>`,
                    data: function() {
                    return {
                        data: {}
                    };
                    },
                    methods: {
                    _selectAll: function(e) {
                        this.$eventHub.$emit("selectAll", e);
                    }
                    }
                })
                };
            }
        }
    },
    mounted() {
        console.log('Second CALL');
        this.getUnits();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created() {
        console.log('First CALL');
        //this.getBranch();
        this.$eventHub.$on("deleteBranch", (branchId) => {
            this.deleteBranch(branchId);
        });
        this.$eventHub.$on("editBranch", (branchId) => {
            this.editBranch(branchId);
        });
        this.$eventHub.$on("refreshBranchTable", () => {
            this.getUnits();
        });
        this.$eventHub.$on("unitsCheck", (event, unit) => {
            this.createMappedUnitsArray(event, unit);
        });
        
        this.$eventHub.$on("selectAll", event => {
            this.selectAll(event);
        });
    },
    methods: {
        createMappedUnitsArray(event, unitInfo){
            if (!event.target.checked) {
                let newMappedUnitsArray = this.unitsArray.filter(
                    unit => unit.id !== unitInfo.id
                );
                this.unitsArray = newMappedUnitsArray;
            } else {
                this.unitsArray.push({
                    id: unitInfo.id
                });
            }
        },
        selectAll(event) {
            event.preventDefault();
            let _this = this;
            if (event.target.checked) {
                $(":checkbox").each(function() {
                    this.checked = true;
                });
                
                this.units.forEach(unit => {
                    this.unitsArray.push({id: unit.id});
                })
            } else {
                $(":checkbox").each(function() {
                    this.checked = false;
                });
                _this.unitsArray = [];
            }
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        toggleShowPassword() {
            if(this.$refs.pass__input.type === 'password'){
                this.$refs.pass__input.type = 'text'
                this.showPassword = true;
            }else {
                this.$refs.pass__input.type = 'password';
                this.showPassword = false;
            }
        },
        getBranch() {
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Branch/${this.$route.query.id}`,
                    configObject.authConfig
                )
                .then(response => {
                    this.branch = response.data;
                })
                .catch((error) => {
                    console.log('ERR:: ' + error);
                    // this.$toast.open({
                    //     message: "unable to load branch",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
        mapBranchToUnit() {
            console.log(configObject.authConfig);
            this.showLoader = true;
            this.isButtonDisabled = true;
            let mappedUnitsArrayToSend = [];
            let data = {}
            this.unitsArray.forEach((unit) => {
                mappedUnitsArrayToSend.push(unit.id);
            });
            data.listOfIds = mappedUnitsArrayToSend;
            console.log(data);
            //let unitIds = mappedUnitsArrayToSend.join();
            let url = `${configObject.apiBaseUrl}/Branch/MapToUnit/${parseInt(this.$route.query.id)}`;
            console.log(url);
            this.axios.post(url, data, configObject.authConfig)
                .then(() => {
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    this.$toast.success("Branched mapped to units successfully!");
                    })
                    .catch((error) => {
                        this.isButtonDisabled = false;
                        this.showLoader = false;
                        this.$toast.open({
                            message: "unable to map branch to units",
                            type: "error",
                            duration: 3000
                        });
                        console.log(error);
                });
        },
        getUnits() {
            let grid = this.$refs.dataGrid;
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Branch/UnitForMap/${this.$route.query.id}`,
                    configObject.authConfig
                )
                .then(response => {
                    console.log(response.data);
                    this.branch = response.data.branch;
                    response.data.units.forEach(unit => {
                        if(unit.selected){
                            this.unitsArray.push({
                                id: unit.id
                            });
                        }
                    });
                    this.units = response.data.units;
                    grid.ej2Instances.setProperties({ dataSource: this.units });
                    this.refreshGrid();
                })
                .catch((error) => {
                    console.log('Error >>> ' + error);
                    // this.$toast.open({
                    //     message: "Unable to load Unit(s) for mapping to branch.",
                    //     type: "error",
                    //     duration: 4000
                    // });
                });
            
        },
        backToBranch(){
            this.$router.push({
                name: 'branch'
            });
        }
    }
}
</script>

<style scoped>

</style>