<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">

             <div class="welcome_message">
                 <div class="row">
                     <div class="col-8">    
                         <h5 class=''><span class="greeeting__text"> Hi Admin,
                            </span> you have <span class="branches__count"> {{divisions.length}} </span> Divisions
                        </h5> 
                     </div>
                     <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn" @click="openCreateDivisionModal">Create Division</button>
                        </div>
                     </div>
                 </div>
             </div>
             <section class="table__section mt-1 pb-5" v-show="!showLoader">
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" width="60px" headerText="#"></e-column>
                        <e-column field="name" headerText="Division Name"></e-column>
                        <e-column field="objective" headerText="Objective"></e-column>                        
                        <e-column field="branches" headerText="Branches" width="80px" ></e-column>
                        <e-column field="units" headerText="Units" width="70px" ></e-column>
                        <e-column field="status"  :visible='false' headerText="Status"></e-column>
                        <e-column :template="deletedTemplate" width="150px" headerText="Status"></e-column>
                        <e-column :template="actionButtonsTemplate" width="150px" headerText="Actions"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
            <div class="empty_branch__div mt-3" v-if="divisions.length < 1 && !showLoader">
                <div class="text-center">
                    <h6 class="text-center"> You are yet to create a Division</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
             <table v-show="showLoader" style="margin: 0 auto; width: 95%">
                <tbody>
                    <tr>
                    <td class="td-1"><span></span></td>
                    <td class="td-2"><span></span></td>
                    <td class="td-3"><span></span></td>
                    <td class="td-4"></td>
                    <td class="td-5"><span></span></td>
                    </tr>
                    <tr>
                    <td class="td-1"><span></span></td>
                    <td class="td-2"><span></span></td>
                    <td class="td-3"><span></span></td>
                    <td class="td-4"></td>
                    <td class="td-5"><span></span></td>
                    </tr>
                    <tr>
                    <td class="td-1"><span></span></td>
                    <td class="td-2"><span></span></td>
                    <td class="td-3"><span></span></td>
                    <td class="td-4"></td>
                    <td class="td-5"><span></span></td>
                    </tr>
                    <tr>
                    <td class="td-1"><span></span></td>
                    <td class="td-2"><span></span></td>
                    <td class="td-3"><span></span></td>
                    <td class="td-4"></td>
                    <td class="td-5"><span></span></td>
                    </tr>
                    <tr>
                    <td class="td-1"><span></span></td>
                    <td class="td-2"><span></span></td>
                    <td class="td-3"><span></span></td>
                    <td class="td-4"></td>
                    <td class="td-5"><span></span></td>
                    </tr>
                    <tr>
                    <td class="td-1"><span></span></td>
                    <td class="td-2"><span></span></td>
                    <td class="td-3"><span></span></td>
                    <td class="td-4"></td>
                    <td class="td-5"><span></span></td>
                    </tr>
                    <tr>
                    <td class="td-1"><span></span></td>
                    <td class="td-2"><span></span></td>
                    <td class="td-3"><span></span></td>
                    <td class="td-4"></td>
                    <td class="td-5"><span></span></td>
                    </tr>
                    <tr>
                    <td class="td-1"><span></span></td>
                    <td class="td-2"><span></span></td>
                    <td class="td-3"><span></span></td>
                    <td class="td-4"></td>
                    <td class="td-5"><span></span></td>
                    </tr><tr>
                    <td class="td-1"><span></span></td>
                    <td class="td-2"><span></span></td>
                    <td class="td-3"><span></span></td>
                    <td class="td-4"></td>
                    <td class="td-5"><span></span></td>
                    </tr>
                </tbody>
                </table>
            <CreateDivisionModal></CreateDivisionModal>
            <EditDivisionModal :division ="division"></EditDivisionModal>
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
import CreateDivisionModal from '@/components/Modals/Division/createDivisionModal.vue';
import EditDivisionModal from '@/components/Modals/Division/editDivisionModal.vue';
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    components: {
        MasterLayout,
        CreateDivisionModal,
        EditDivisionModal
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            backgroundUrl,
            UserDP,
            componentKey: 0,
            divisions: [],
            division: {},
            showLoader: false,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-primary" title="Edit" v-on:click="_editDivision(data.id)">' +
                                '<i class="fa fa-edit" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-warning" title="Map Branches" v-on:click="_mapBranch(`map_division_to_branches`,data.id)">' +
                                '<i class="fa fa-refresh" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-info" title="Map Units" v-on:click="_mapUnit(`map_divisions_to_unit`, data.id)">' +
                                '<i class="fa fa-refresh" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-danger" title="Delete" v-on:click="_deleteDivision(data.id)" v-if="data.deleted === false">' +
                                '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-success" title="Re-Add" v-on:click="_reAddDivision(data.id)" v-if="data.deleted === true">' +
                                '<i class="fa fa-plus" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                        return {
                            data: {}
                        };
                        },
                        methods: {
                            _reAddDivision: function(divisionId) {
                                this.$eventHub.$emit("reAddDivisionM", divisionId);
                            },
                            _deleteDivision: function(divisionId) {
                                this.$eventHub.$emit("deleteDivisionM", divisionId);
                            },
                            _editDivision: function(divisionId) {
                                //console.log(divisionId);
                                this.$eventHub.$emit("editDivisionM", divisionId);
                            },
                            _mapUnit: function($routeName, $divId) {
                                //console.log("divId=>" + $divId);
                                this.$eventHub.$emit("mapUnitM", $routeName, $divId);
                            },
                            _mapBranch: function($routeName, $divId) {
                                //console.log("divId=>" + $divId);
                                this.$eventHub.$emit("mapBranchM", $routeName, $divId);
                            }
                        }
                    })
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>
<div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            }
        }
    },
     mounted() {
        this.getDivisions();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created() {
        this.$eventHub.$on("deleteDivisionM", (divisionId) => {
            this.deleteDivision(divisionId, 1);
        });
        this.$eventHub.$on("editDivisionM", (divisionId) => {
            this.editDivision(divisionId);
        });
        this.$eventHub.$on("refreshDivisionTable", () => {
            this.getDivisions();
        });
        this.$eventHub.$on("mapUnitM", ($routeName, $divId) => {
            //console.log($divId);
            this.changeRoute($routeName, $divId);
        });
        this.$eventHub.$on("mapBranchM", ($routeName, $divId) => {
            this.changeRoute($routeName, $divId);
        });
        this.$eventHub.$on("reAddDivisionM", (divisionId) => {
            this.deleteDivision(divisionId, 2);
        });
        
    },
    methods: {
        changeRoute($routeName, $divId) {
            this.$router.push(
                {
                    name: $routeName , 
                    query: {
                        id: $divId
                    }
                }
            )
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.dataGrid.getColumns()[5].visible = true;
                    this.$refs.dataGrid.getColumns()[6].visible = false;
                    this.$refs.dataGrid.getColumns()[7].visible = false;
                    this.$refs.dataGrid.pdfExport({
                        pageOrientation: 'Landscape',
                        fileName: 'Division.pdf',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  'Division',
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
                case "Excel Export":
                    this.$refs.dataGrid.getColumns()[5].visible = true;
                    this.$refs.dataGrid.getColumns()[6].visible = false;
                    this.$refs.dataGrid.getColumns()[7].visible = false;
                    this.$refs.dataGrid.excelExport({
                        fileName: 'Division.xlsx',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  `Division`,
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                });
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        forceRerender() {
            this.openEditDivisionModal();
            this.componentKey += 1; 
        },
        openCreateDivisionModal(){
            this.$modal.show('createDivisionModal');
        },
        openEditDivisionModal(){
            this.$modal.show('editDivisionModal');
        },
        editDivision(divisionId) {
            let divisionDetail = this.divisions.filter(division => division.id === divisionId)[0];
            //console.log(divisionDetail);
            this.division = divisionDetail;
            this.openEditDivisionModal();
        },
        deleteDivision(divisionId, typ) {
            console.log(divisionId);
            let conf = '';
            let fin = '';
            let msg = '';

            if(typ === 1){
                conf = 'Are you sure  you want to delete this division';
                fin = 'Division deleted successfully!';
                msg = 'Unable to delete division';
            }
            else{
                conf = 'Are you sure you want to re-add this division';
                fin = 'Division Re-Added successfully!';
                msg = 'Unable to re-add division';
            }

            var resp = confirm(conf);
            if (resp) {
                this.axios.delete(`${configObject.apiBaseUrl}/Divisions/Delete/${divisionId}`, configObject.authConfig)
                .then(() => {
                    this.getDivisions();
                    this.$toast.open({
                        message: fin,
                        type: "success",
                        duration: 3000
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.$toast.open({
                        message: msg,
                        type: "error",
                        duration: 3000
                    });
                });
            }
        },
        getDivisions() {
            this.showLoader = true;
            let grid = this.$refs.dataGrid;
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Divisions`,
                    configObject.authConfig
                )
                .then(response => {
                    let index = 0;
                    response.data.forEach(division => {
                        index++;
                        division.index = index;
                        division.status = division.deleted ? 'deleted' : 'active'
                    });
                    this.showLoader = false;
                    this.divisions = response.data;

                    grid.ej2Instances.setProperties({ dataSource: response.data });
                    this.refreshGrid();
                })
                .catch((error) => {
                    console.log('Error :: ' + error);
                    // this.$toast.open({
                    //     message: "unable to get divisions",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
    }
}
</script>

        