<template>
    <MasterLayout>
        <div class="employees__assessment__wrapper">

            <div class="welcome_message">
                <div class="row">
                    <div class="col-8">    
                        <h5 class=''><span class="greeeting__text"> Hi {{userInfo.firstName}},
                            </span> there {{assessmentsLength == 1 ? 'is' : 'are'}} <span class="branches__count"> {{assessmentsLength}} </span> {{assessmentsLength == 1 ? 'Assessment' : 'Assessments'}}
                        </h5> 
                    </div>
                    <div class="col-4 text-right">
                    <!-- <div>
                        <button class="creaet_branch_btn" @click="changeRoute('step_one')">Create Assessment <i class="fa fa-plus-circle ml-3" style="color: white; font-size: 18px" aria-hidden="true" ></i></button>
                    </div> -->
                    </div>
                </div>
            </div>
             
            <div style="width: 97%; margin: 15px auto">
                <b-card no-body style="padding-bottom: 35px;">
                    <b-tabs card class="employee_tabs" style="margin: 0;">
                        <b-tab active>
                            <template v-slot:title>
                                <span class="sm-pad">All Assessments/Trainings</span>
                            </template>
                            <b-card-text class="employee-tab-content">
                                <div class="tab-content-body">
                                    <b-card  no-body class="for-panel">
                                        <ejs-grid
                                            ref="employeeAssessmentGrid"
                                            :created="refreshEmployeeAssessmentGrid"
                                            :allowPaging="true"
                                            :allowSorting="true"
                                            :pageSettings="pageSettings"
                                            :toolbar="toolbar"
                                            :searchSettings="search"
                                            :allowExcelExport="true"
                                            :allowPdfExport="true"
                                            :toolbarClick="toolbarClick1">
                                            <e-columns>
                                                <e-column field="index" headerText="#" width="60px"></e-column>
                                                <e-column field="name" headerText="Name" width="150px"></e-column>
                                                <e-column field="type" headerText="Type" width="120px"></e-column>
                                                <e-column field="trainingDate" headerText="Training Date" width="120px"></e-column>
                                                <e-column field="dateAdded" headerText="Date Recorded" width="120px"></e-column>
                                                <!-- <e-column field="employeeComment" width="200px" headerText="Comment"></e-column> -->
                                                <e-column field="status" :visible='false' headerText="Status" width="100px"></e-column>
                                                <e-column :template="aStatusTemplate" headerText="Status" width="100px"></e-column> 
                                                <e-column field="competencyCount" headerText="Competencies" width="120px" class="text-right"></e-column>
                                                <e-column field="documentCount" headerText="Documents" width="100px"></e-column>
                                                <e-column :template="assessmentActionTemplate" width="80px" headerText="Actions"></e-column>
                                                <!-- <e-column width="50px" headerText=""></e-column> -->
                                            </e-columns>
                                        </ejs-grid>
                                    </b-card>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab>
                            <template v-slot:title>
                                <span class="sm-pad">Approved</span>
                            </template>
                            <b-card-text class="employee-tab-content">
                                <div class="tab-content-body">                                
                                    <b-card  no-body class="for-panel">
                                        <ejs-grid
                                            ref="approvedAssessmentGrid"
                                            :created="refreshApprovedAssessmentGrid"
                                            :allowPaging="true"
                                            :allowSorting="true"
                                            :pageSettings="pageSettings"
                                            :toolbar="toolbar"
                                            :searchSettings="search"
                                            :allowExcelExport="true"
                                            :allowPdfExport="true"
                                            :toolbarClick="toolbarClick2">
                                            <e-columns>
                                                <e-column field="index" headerText="#" width="60px"></e-column>
                                                <e-column field="name" headerText="Name" width="120px"></e-column>
                                                <e-column field="type" headerText="Type" width="150px"></e-column>
                                                <e-column field="trainingDate" headerText="Training Date" width="120px"></e-column>
                                                <e-column field="dateAdded" headerText="Date Recorded" width="120px"></e-column>
                                                <!-- <e-column field="employeeComment" width="200px" headerText="Comment"></e-column> -->
                                                <e-column field="competencyCount" headerText="Competencies" width="120px" class="text-right"></e-column>
                                                <e-column field="documentCount" headerText="Documents" width="100px"></e-column>
                                                <e-column :template="assessmentActionTemplate" width="80px" headerText="Actions"></e-column>
                                                <!-- <e-column width="50px" headerText=""></e-column> -->
                                            </e-columns>
                                        </ejs-grid>
                                    </b-card>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab>
                            <template v-slot:title>
                                <span class="sm-pad">Disapproved</span>
                            </template>
                            <b-card-text class="employee-tab-content">
                                <div class="tab-content-body">
                                    <b-card  no-body class="for-panel">
                                        <ejs-grid
                                            ref="disapprovedAssessmentGrid"
                                            :created="refreshDisapprovedAssessmentGrid"
                                            :allowPaging="true"
                                            :allowSorting="true"
                                            :pageSettings="pageSettings"
                                            :toolbar="toolbar"
                                            :searchSettings="search"
                                            :allowExcelExport="true"
                                            :allowPdfExport="true"
                                            :toolbarClick="toolbarClick3">
                                            <e-columns>
                                                <e-column field="index" headerText="#" width="60px"></e-column>
                                                <e-column field="name" headerText="Name" width="120px"></e-column>
                                                <e-column field="type" headerText="Type" width="150px"></e-column>
                                                <e-column field="trainingDate" headerText="Training Date" width="120px"></e-column>
                                                <e-column field="dateAdded" headerText="Date Recorded" width="120px"></e-column>
                                                <!-- <e-column field="employeeComment" width="200px" headerText="Comment"></e-column> -->
                                                <e-column field="competencyCount" headerText="Competencies" width="120px" class="text-right"></e-column>
                                                <e-column field="documentCount" headerText="Documents" width="100px"></e-column>
                                                <e-column :template="assessmentActionTemplate" width="80px" headerText="Actions"></e-column>
                                                <!-- <e-column width="50px" headerText=""></e-column> -->
                                            </e-columns>
                                        </ejs-grid>
                                    </b-card>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab>
                            <template v-slot:title>
                                <span class="sm-pad">Pending</span>
                            </template>
                            <b-card-text class="employee-tab-content">
                                <div class="tab-content-body">
                                    <b-card  no-body class="for-panel">
                                        <ejs-grid
                                            ref="pendingAssessmentGrid"
                                            :created="refreshPendingAssessmentGrid"
                                            :allowPaging="true"
                                            :allowSorting="true"
                                            :pageSettings="pageSettings"
                                            :toolbar="toolbar"
                                            :searchSettings="search"
                                            :allowExcelExport="true"
                                            :allowPdfExport="true"
                                            :toolbarClick="toolbarClick4">
                                            <e-columns>
                                                <e-column field="index" headerText="#" width="60px"></e-column>
                                                <e-column field="name" headerText="Name" width="120px"></e-column>
                                                <e-column field="type" headerText="Type" width="150px"></e-column>
                                                <e-column field="trainingDate" headerText="Training Date" width="120px"></e-column>
                                                <e-column field="dateAdded" headerText="Date Recorded" width="120px"></e-column>
                                                <!-- <e-column field="employeeComment" width="200px" headerText="Comment"></e-column> -->
                                                <e-column field="competencyCount" headerText="Competencies" width="120px" class="text-right"></e-column>
                                                <e-column field="documentCount" headerText="Documents" width="100px"></e-column>
                                                <e-column :template="assessmentActionTemplate" width="80px" headerText="Actions"></e-column>
                                                <!-- <e-column width="50px" headerText=""></e-column> -->
                                            </e-columns>
                                        </ejs-grid>
                                    </b-card>
                                </div>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>


            <!-- <div class="empty_branch__div mt-3" v-if="units.length < 1">
                <div class="text-center">
                    <h6 class="text-center"> You are yet to create a branch</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div> -->
            <!-- <ViewCompetencyModal :competency="competency"></ViewCompetencyModal>
            <JobRoleDetailsModal :jobRole="jobRole"></JobRoleDetailsModal> -->
            <!-- <employeeCompetencyDetails :empoyeeCompetency="empoyeeCompetency"></employeeCompetencyDetails> -->
        </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
//import JobRoleDetailsModal from '@/components/Modals/RoleTasks/jobRoleDetailsModal.vue';
//import employeeCompetencyDetails from '@/components/Modals/Competency_modal/employeeCompetencyDetailsModal.vue';

// import CreateSelfAssessmentModal from '@/components/Modals/SelfAssessment/createSelfAssessment.vue';
// import editUnitModal from '@/components/Modals/Unit/editUnitModal.vue';
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;
import moment from 'moment';

//import ViewCompetencyModal from '@/components/Modals/Competency_modal/competencyDetailsModal.vue';


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    components: {
        MasterLayout,
        // ViewCompetencyModal,
        // JobRoleDetailsModal,
        //employeeCompetencyDetails
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            level: null,
            assessmentsLength: 0,
            backgroundUrl,
            UserDP,
            isButtonDisabled:  false,
            showLoader: false,
            componentKey: 0,
            competencies: null,
            competenciesArray: [],
            units: [],
            jobRoles: [],
            jobRole: {},
            employee: {},
            empoyeeCompetency: {},
            approvedAssessments: [],
            disapprovedAssessments: [],
            pendingAssessments: [],
            myAssessments: [],
            empAss: {},
            jobResps: [],
            jobResp: {},
            unit: {},
            competency: {},
            myCompetencies: [],
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            activeTab: "my competencies",
            competencyLevels: null,
            userInfo: localStorage.getItem("dcasUserDetails")
                ? JSON.parse(localStorage.getItem("dcasUserDetails"))
                : null,
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-success" title="Continue" v-if="!data.submitted" v-on:click="_continue(data.id)">' +
                                    '<i class="fa fa-paper-plane" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-danger" title="Delete" v-if="!data.submitted" v-on:click="_delete(data.id)">' +
                                '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _delete: function(id) {
                                this.$eventHub.$emit("deleteSA", id);
                            },
                            _continue: function(id) {
                                //console.log(id);
                                this.$eventHub.$emit("continueSA", id);
                            },
                            viewCompetency: function(compId) {
                                console.log(compId);
                                this.$eventHub.$emit("viewCompetency", compId);
                            },
                        }
                    })
                };
            },
            assessmentActionTemplate(){
                return {
                    template: Vue.component("assessmentActionTemplate", {
                        template:
                        `<div class="btn-group btn-group-sm" role="group">
                            <button type="button" class="btn btn-info btn-xs" title="Process Assessment" v-on:click="_processAssessment(data.id, data.employeeId)">
                                <i class="fa fa-eye" aria-hidden="true"></i>
                            </button>
                        </div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _processAssessment(assId, empId) {
                                this.$eventHub.$emit("processAssessmentVM", assId, empId);
                            },
                        }
                    })
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            },
            aStatusTemplate(){
                return {
                    template: Vue.component("aStatusTemplate", {
                        template:
                        '<div>'+
                            `<b-badge pill variant="secondary" v-if="data.status === 'Pending'">{{data.status}}</b-badge>` +
                            `<b-badge pill variant="success" v-if="data.status === 'Approved'">{{data.status}}</b-badge>` +
                            `<b-badge pill variant="danger" v-if="data.status === 'Disapproved'">{{data.status}}</b-badge>` +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                };
            },
        }
    },
    mounted() {
        this.getEmployeesAssessments();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);        
        }       
    },
    created(){
        this.$eventHub.$on("deleteSA", (id) => {
            this.deleteAssessment(id);
        });

        this.$eventHub.$on("processAssessmentVM", (assId, empId) => {
            this.processAssessment(assId, empId);
        });

        this.$eventHub.$on("continueSA", (id) => {
            this.continueAssessment(id);
        });
    },
    methods: {
        changeRoute($routeName){
            this.$router.push({name: $routeName});
        },
        toolbarClick1(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.employeeAssessmentGrid.getColumns()[6].visible = true;
                    this.$refs.employeeAssessmentGrid.getColumns()[7].visible = false;
                    this.$refs.employeeAssessmentGrid.getColumns()[10].visible = false;
                    this.$refs.employeeAssessmentGrid.getColumns()[11].visible = false;

                    this.$refs.employeeAssessmentGrid.pdfExport({
                        pageOrientation: 'Landscape',
                        fileName: 'All Assessment.pdf',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  'All Assessment',
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
                case "Excel Export":
                    this.$refs.employeeAssessmentGrid.getColumns()[6].visible = true;
                    this.$refs.employeeAssessmentGrid.getColumns()[7].visible = false;
                    this.$refs.employeeAssessmentGrid.getColumns()[10].visible = false;
                    this.$refs.employeeAssessmentGrid.getColumns()[11].visible = false;
                    this.$refs.employeeAssessmentGrid.excelExport({
                        pageOrientation: 'Landscape',
                        fileName: 'All Assessment.xlsx',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  "All Assessment",
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
            }
        },
        toolbarClick2(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.approvedAssessmentGrid.getColumns()[8].visible = false;

                    this.$refs.approvedAssessmentGrid.pdfExport({
                        pageOrientation: 'Landscape',
                        fileName: 'Approved Assessments.pdf',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  'Approved Assessments',
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
                case "Excel Export":
                    this.$refs.approvedAssessmentGrid.getColumns()[8].visible = false;

                    this.$refs.approvedAssessmentGrid.excelExport({
                        pageOrientation: 'Landscape',
                        fileName: 'Approved Assessments.xlsx',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  `Approved Assessments`,
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
            }
        },
        toolbarClick3(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.disapprovedAssessmentGrid.getColumns()[8].visible = false;

                    this.$refs.disapprovedAssessmentGrid.pdfExport({
                        pageOrientation: 'Landscape',
                        fileName: 'Disapproved Assessments.pdf',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  'Disapproved Assessments',
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
                case "Excel Export":
                    this.$refs.disapprovedAssessmentGrid.getColumns()[8].visible = false;

                    this.$refs.disapprovedAssessmentGrid.excelExport({
                        pageOrientation: 'Landscape',
                        fileName: 'Disapproved Assessments.xlsx',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  `Disapproved Assessments`,
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
            }
        },
        toolbarClick4(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.pendingAssessmentGrid.getColumns()[8].visible = false;
                    this.$refs.pendingAssessmentGrid.getColumns()[9].visible = false;

                    this.$refs.pendingAssessmentGrid.pdfExport({
                        pageOrientation: 'Landscape',
                        fileName: 'Pending Assessments.pdf',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  'Pending Assessments',
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
                case "Excel Export":
                    this.$refs.pendingAssessmentGrid.getColumns()[8].visible = false;
                    this.$refs.pendingAssessmentGrid.getColumns()[9].visible = false;

                    this.$refs.pendingAssessmentGrid.excelExport({
                        pageOrientation: 'Landscape',
                        fileName: 'Pending Assessments.xlsx',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  `Pending Assessments`,
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
            }
        },
        processAssessment(assId, empId){
            localStorage.setItem('assessmentFrom', JSON.stringify('employees_self_assessments'))
            this.$router.push({
                name: 'process_assessment',
                query: {
                    id: assId,
                    emp: empId
                }
            });
        },
        refreshEmployeeAssessmentGrid(){
            this.$refs.employeeAssessmentGrid.refresh();
        },
        refreshApprovedAssessmentGrid(){
            this.$refs.approvedAssessmentGrid.refresh();
        },
        refreshDisapprovedAssessmentGrid(){
            this.$refs.disapprovedAssessmentGrid.refresh();
        },
        refreshPendingAssessmentGrid(){
            this.$refs.pendingAssessmentGrid.refresh();
        },
        getEmployeesAssessments(){
            let grid = this.$refs.employeeAssessmentGrid;
            let grid2 = this.$refs.approvedAssessmentGrid;
            let grid3 = this.$refs.disapprovedAssessmentGrid;
            let grid4 = this.$refs.pendingAssessmentGrid;

            this.axios.get(`${configObject.apiBaseUrl}/Employee/SelfAssessments`, configObject.authConfig)
                .then(response => {
                    this.assessmentsLength = response.data.length
                    this.showLoader = false;
                    let index = 0, approvedIndex = 0, disapprovedIndex = 0, pendingIndex = 0;
                    let _approvedAssessments = [];
                    let _disapprovedAssessments = [];
                    let _pendingAssessments = [];
                    let _allAssessments = [];

                    response.data.forEach(empAss => {
                        index++;
                        empAss.index = index;
                        empAss.trainingDate = moment(empAss.trainingDate).format('DD-MMM-YYYY');
                        empAss.dateAdded = moment(empAss.dateAdded).format('DD-MMM-YYYY');
                        empAss.name = `${empAss.firstName} ${empAss.surname}`;
                        empAss.type = empAss.assessmentType === "Training" ? "Training" : "Assessment";
                        // empAss.type = empAss.assessmentType === 'Appraisal' ? 'Assessment' : 'Training';
                        _allAssessments.push(empAss);

                        if(empAss.status.toLowerCase() === 'approved'){
                            approvedIndex++;
                            _approvedAssessments.push({...empAss, index: approvedIndex});
                        }
                        if(empAss.status.toLowerCase() === 'disapproved'){
                            disapprovedIndex++;
                            _disapprovedAssessments.push({...empAss, index: disapprovedIndex});
                        }
                        if(empAss.status.toLowerCase() === 'pending'){
                            pendingIndex++;
                            _pendingAssessments.push({...empAss, index: pendingIndex});
                        }
                    });

                    this.myAssessments = _allAssessments;
                    this.approvedAssessments = _approvedAssessments;
                    this.disapprovedAssessments = _disapprovedAssessments;
                    this.pendingAssessments = _pendingAssessments;

                    grid.ej2Instances.setProperties({ dataSource: this.myAssessments });
                    grid2.ej2Instances.setProperties({ dataSource: this.approvedAssessments });
                    grid3.ej2Instances.setProperties({ dataSource: this.disapprovedAssessments });
                    grid4.ej2Instances.setProperties({ dataSource: this.pendingAssessments });
                    this.refreshEmployeeAssessmentGrid();
                    this.refreshApprovedAssessmentGrid();
                    this.refreshDisapprovedAssessmentGrid();
                    this.refreshPendingAssessmentGrid();
                })
                .catch((error) => {
                    console.log('ERROR::: ' + error);
                    // this.$toast.open({
                    //     message: "unable to load employee assessments",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
        continueAssessment(id){
            this.$router.push({name: 'step_one', query: {
                id: id
            }});
        },
        deleteAssessment(id){
            let grid = this.$refs.employeeAssessmentGrid;
            let url = `${configObject.apiBaseUrl}/Employee/SelfAssessment/Delete/${id}/${this.userInfo.employeeId}`;
            this.axios.delete(url, configObject.authConfig)
            .then(response => {
                this.$toast.success("Assessment deleted Employee successfully!");
                // console.log(response.data);
                // console.log('Before :' + this.myAssessments.length)
                this.myAssessments = this.myAssessments.filter(e => e.id !== response.data.id);
                //console.log('After :' + this.myAssessments.length);
                grid.ej2Instances.setProperties({ dataSource: this.myAssessments });
                this.refreshEmployeeAssessmentGrid();   
                this.refreshApprovedAssessmentGrid();   
                this.refreshDisapprovedAssessmentGrid();   
                this.refreshPendingAssessmentGrid();   
            })
            .catch((error) => {
                console.log('ERROR::: ');
                console.log(error.response.data.message);
                this.$toast.open({
                    message: error.response.data.message, //"unable to load employee assessments",
                    type: "error",
                    duration: 4000
                });
            });
        }
    }
}
</script>

<style scoped>
    td[aria-colindex='6'], td[aria-colindex='7']{
        text-align: center!important;
    }
</style>