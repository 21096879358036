<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">

             <div class="welcome_message">
                 <div class="row">
                     <div class="col-6">    
                        <h5 class=''>
                            Map Requirements to Designation <span class="greeeting__text">"{{designationName}}"</span>.
                            There are <span class="branches__count"> {{requirements.length}}</span> requirements available
                        </h5>
                     </div>
                     <div class="col-6 text-right">
                        <div>
                            <button class="creaet_branch_btn default" @click="backToDesignation"><i class="fa fa-arrow-left"></i> Back</button>
                            <button class="creaet_branch_btn" @click="mapRequirementsToDesignation">
                                Map Requirements To Designation
                                <span class="">
                                    <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                                </span>
                            </button>                            
                        </div>
                     </div>
                 </div>
             </div>
             <section class="table__section mt-1 pb-5">
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column headerText="#" :template="checkBoxTemplate" width="80px"></e-column>
                        <e-column field="task" headerText="Requirement"></e-column>
                        <e-column :template="reqTypesDropDownTemplate" headerText="Requirement Type" width="250px"></e-column>
                        <e-column field="" headerText="" width="20px"></e-column>
                        <!-- <e-column :template="deletedTemplate" width="150px" headerText="Status"></e-column> -->
                    </e-columns>
                </ejs-grid>
            </section>
            <div class="empty_branch__div mt-3" v-if="!requirementsFetched"> 
                <div class="text-center">
                    <h6 class="text-center"> Please wait while we get requirements</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

var reqTypesSelectTemplate = Vue.component("reqTypesSelectTemplate", {
    template:  '<select v-on:change="getSelection($data.id)" v-bind:data-rid="`${data.id}`" class="geLev" v-model="data.requirementTypeId">' +
                    '<option value="">Select Level</option>' +
                    '<option v-for="req in data.requirementTypes"  v-bind:value="req.id" v-bind:key="req.id">{{req.typeName}}</option>' +
                '</select>',
    data() {
        return {
            data: {},
        };
    },  
    methods: {
        getSelection() {
            console.log('ReqType Change :: Req = ' + this.data.id + '; Type = ' + this.data.requirementTypeId);
            this.$eventHub.$emit("emitDoReqSelect", this.data.id, this.data.requirementTypeId);
        },
        
    }
});

export default {
    components: {
        MasterLayout,
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            showLoader: false,
            backgroundUrl,
            UserDP,
            componentKey: 0,
            activeTab: "competency",
            requirements: [],
            requirementsFetched: false,
            newMappedReqArray: [],
            requirementsArray: [],
            requirementTypes: [],
            designationName: '',
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            checkBoxTemplate: function() {
                return {
                    template: Vue.component("columnTemplate", {
                        template: `<div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="data.selected" v-on:change="_createMappedRequirementsArray($event, data.id)" checked />
                            <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="!data.selected" v-on:change="_createMappedRequirementsArray($event, data.id)" />
                            <label class="custom-control-label" v-bind:for="data.id"></label>
                        </div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _createMappedRequirementsArray: function(e, reqId) {
                                this.$eventHub.$emit("requirementsCheck", e, reqId);
                            }
                        }
                    })
                };
            },
            selectAllTemplate: function() {
                return {
                    template: Vue.component("columnTemplate", {
                        template: `<div><input type="checkbox" v-on:change="selectAll($event)"/></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            selectAll: function(e) {
                                this.$eventHub.$emit("selectAll", e);
                            }
                        }
                    })
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>`
                            + `<div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            },
            reqTypesDropDownTemplate: function(e) {
                return {
                    template: reqTypesSelectTemplate
                };
            }
        }
    },
    created(){
        this.designationName = this.$route.query.name;
        
        this.getRequirementTypes();
        this.$eventHub.$on("requirementsCheck", (event, reqId) => {
            this.createMappedRequirementsArray(event, reqId);
        });

        this.$eventHub.$on("emitDoReqSelect", (reqId, typeId) => {
            this.getType(reqId, typeId);
        });
    },
    mounted() {
        console.log('Second CALL');
        this.getRequirements();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    methods: {
        getType(reqId, typeId){
            console.log('Changing Type');
            let req = this.requirements.findIndex(a => a.id === reqId);
            
            if(req !== -1){
                if(typeId > 0){
                    this.requirements[req].selected = true;
                    this.requirements[req].requirementTypeId = typeId;
                }
                else{
                    this.requirements[req].selected = false;
                    this.requirements[req].requirementTypeId = 0;
                }
                console.log(this.requirements[req]);
                this.refreshGrid();
            }
        },
        createMappedRequirementsArray(event, reqId){
            let _reqId = this.requirements.findIndex(a => a.id === reqId);
            if(_reqId !== -1){
                if (event.target.checked){
                    // Selected
                    this.requirements[_reqId].selected = true;
                }
                else{
                    // Unselected
                    this.requirements[_reqId].selected = false;
                }
                this.refreshGrid();
            }
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        mapRequirementsToDesignation(){
            this.showLoader = true;
            this.isButtonDisabled = true;
            let dataArray = [];
            this.requirements.forEach(c => {
                if(c.selected){
                    dataArray.push({
                        "RequirementId": c.id,
                        "RequirementTypeId": c.requirementTypeId
                    })
                }
            });
            console.log(dataArray);
            if(dataArray.length < 1){
                this.$toast.open({
                    message: "Please select atleast one Requirement (and its Requirement Type) before proceeding",
                    type: "error",
                    duration: 4000
                });
                this.showLoader = false;
                this.isButtonDisabled = false;
                return;
            }

            var check = dataArray.findIndex(a => a.RequirementId === 0 || a.RequirementTypeId === 0);
            if(check >= 0){
                this.$toast.open({
                    message: "Please ensure that Requirement Type is selected for all selected Requirement(s)",
                    type: "error",
                    duration: 4000
                });
                this.showLoader = false;
                this.isButtonDisabled = false;
                return;
            }
            
            let data = {}
            data.requirements = dataArray;
            data.designationId = parseInt(this.$route.query.id);
            
            let url = `${configObject.apiBaseUrl}/Designation/Requirements/Add`;
            this.axios.post(url, data, configObject.authConfig)
                .then((res) => {
                    console.log(res.data);
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    this.$toast.success("Requirements mapped to Designatiion successfully!");
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "Unable to map Requirements. Please try again",
                        type: "error",
                        duration: 4000
                    });
                    console.log(error);
                });
        },
        mapRequirementsToDesignation_old(reqId) {
            let reqArray = this.$store.getters.getSelectedReqTypesArray;
            let newReqArry = [];
            reqArray.forEach(arr => {
                newReqArry.push({
                    "RequirementId": parseInt(arr.RequirementId),
                    "RequirementTypeId": parseInt(arr.RequirementTypeId)
                })
            });

            this.showLoader = true;
            this.isButtonDisabled = true;
            let mappedrequirementsArrayToSend = [];
            this.requirementsArray.forEach((req) => {
                mappedrequirementsArrayToSend.push(req.id);
            });
            let data = {}
            data.requirements = reqArray;
            data.designationId = parseInt(this.$route.query.id);
            
            if(this.requirementsArray.length < 1 &&  data.requirements.length < 1){
                this.$toast.open({
                    message: "Please add atleast one Requirement before proceeding",
                    type: "error",
                    duration: 4000
                });
                this.showLoader = false;
                this.isButtonDisabled = false;
                return;
            }
            if(this.requirementsArray.length !== data.requirements.length){
                this.$toast.open({
                    message: "Requirement Type for one or more of the selected Requirement(s) is missing.",
                    type: "error",
                    duration: 3000
                });
                this.showLoader = false;
                this.isButtonDisabled = false;
                return;
            }else {
                let rIDs = [];
                this.requirementsArray.forEach(a => {
                    rIDs.push(a.id);
                })
                data.requirements.forEach(dcp => {
                    if(!rIDs.includes(dcp.RequirementId)){
                         this.$toast.open({
                            message: "Please add competency Levels to the checked competencies",
                            type: "error",
                            duration: 3000
                        });
                        this.showLoader = false;
                        this.isButtonDisabled = false;
                        return;
                    }
                })
            }
            console.log(data);
            let url = `${configObject.apiBaseUrl}/Designation/Requirements/Add`;
            this.axios.post(url, data, configObject.authConfig)
                .then((res) => {
                    console.log(res.data);
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    this.$toast.success("Requirementrs mapped to this designatiion successfully!");
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "An Error Occured",
                        type: "error",
                        duration: 4000
                    });
                    console.log(error);
                });
        },
        getRequirements() {
            let grid = this.$refs.dataGrid;
            this.axios
                .get(`${configObject.apiBaseUrl}/Designation/RequirementsForMap/${this.$route.query.id}`, configObject.authConfig)
                .then(response => {
                    response.data.forEach(element => {
                        element.requirementTypes = this.requirementTypes;
                    });
                    this.requirements = response.data;
                    this.requirementsFetched = true;
                    //console.log(this.requirements);
                    grid.ej2Instances.setProperties({ dataSource: this.requirements });
                    this.refreshGrid();
                })
                .catch((error) => {
                    console.log(error);
                    // this.$toast.open({
                    //     message: "An Error Occured please check your internet connection",
                    //     type: "error",
                    //     duration: 4000
                    // });
                });
            
        },
        backToDesignation(){
            // this.$router.push({
            //     name: 'designation_profile'
            // });
            this.$router.push({
                name: "designation_profile_detail",
                query: {
                    id: this.$route.query.id
                }
            })
        },
        getRequirementTypes(){
            this.axios.get(`${configObject.apiBaseUrl}/Requirement/Types`, configObject.authConfig)
            .then(response => {
                this.requirementTypes = response.data;
            })
            .catch(error => {
                console.log(error);
                // this.$toast.open({
                //     message: "An Error Occured please check your internet connection",
                //     type: "error",
                //     duration: 4000
                // });
            });
        }
    }
}
</script>

        