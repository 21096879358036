<template>
    <modal name="editDesignationModal" transition="pop-out" :width="modalWidth" :height="300">
        <div class="modal__header">
            <h4>Edit Designation</h4>
        </div>
        <div class="cas_input">
            <div class="row__wrapper">
                <div class="row">
                    <div class="col-md-12">
                        <div class="" style="width: 100%">
                            <span>Designation</span>
                            <div class="">
                                <input type="text" v-model="designation.designationName" required v-if="designation !== null" />
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            
            <div class="text-center mt-4">
                <button class="creaet_branch_btn"
                    @click="validateDetails($event)"
                    :disabled="isButtonDisabled ? true : null"
                    :style="[
                    isButtonDisabled
                        ? { cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                    ]">
                    Update
                    <span class="">
                        <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                    </span>
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
const MODAL_WIDTH = 400;
import configObject from "@/config";

export default {
    name: 'editDesignationModal',
    props:  ['designation'],
    data () {
        return {
            modalWidth: MODAL_WIDTH,
            isButtonDisabled:  false,
            showLoader: false,
        }
    },

  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH;
  },
  methods: {
    closeEditDesignationModal(){
        this.$modal.hide('editDesignationModal');
    },
    validateDetails($event) {
        $event.preventDefault();
        if(!this.designation.designationName){
            this.$toasted.show("Fill in the missing Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
        }else {
            this.editDesignation();
        }
    },
    editDesignation() {
        let data = {
            designationName: this.designation.designationName,
            id: this.designation.id
        };
        console.log(data);
        this.showLoader = true;
        this.isButtonDisabled = true;
        this.axios.put(`${configObject.apiBaseUrl}/Designation/Edit/${this.designation.id}`, data,  configObject.authConfig)
            .then(() => {
                this.showLoader = false;
                this.isButtonDisabled = false;
                //console.log(response.data);
                this.closeEditDesignationModal();
                this.$eventHub.$emit("refreshDesignationTable");
                this.$toast.success("Designation Updated successfully");
                this.isButtonDisabled = false;
                this.designation.designationName = null
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "unable to edit designation",
                        type: "error",
                        duration: 4000
                    });
                    console.log(error);
            });
    },
  }
}
</script>
