<template>
<modal name="createCompanyModal" transition="pop-out" :width="modalWidth" :height="550"> 
  <div class="modal__header">
      <h4>Add New Company</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-10">
                <div class="input_wrapper" style="width: 100%">
                    <span>Company Name</span>
                    <div class="input___group">                        
                        <input type="text" placeholder="company name" class="ml-3" v-model="name" />
                    </div>
                </div>
            </div>
            <div class="col-md-10">
                <div class="input_wrapper" style="width: 100%">
                    <span>Objective</span>
                    <div class="input___group">                        
                        <textarea type="text" placeholder="objective" class="ml-3" v-model="objective"></textarea>
                    </div>
                </div> 
            </div>

            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>Street</span>
                    <div class="input___group">                        
                        <input type="text" placeholder="street" class="ml-3" v-model="street" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>City</span>
                    <div class="input___group">                        
                        <input type="text" placeholder="city" class="ml-3" v-model="city" />
                    </div>
                </div>
            </div>

             <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>State</span>
                    <div class="input___group">
                        <select class="ml-3" v-model="state" required @change="stateChanged($event)">
                            <option value="">Select State</option>
                            <option v-for="st in states" v-bind:key="st.name" v-bind:value="st.name">{{st.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>LGA</span>
                    <div class="input___group">
                        <select class="ml-3" v-model="lga" required>
                            <option value="">Select LGA</option>
                            <option v-for="_lga in stateLgas" v-bind:key="_lga.name" v-bind:value="_lga.name">{{_lga.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <div class="text-center mt-2">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Create Company
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 850;
import configObject from "@/config";

export default {
  name: 'createBranchModal',
  data () {
    return {
      modalWidth: MODAL_WIDTH,
      name: null,
      objective: null,
      city: null,
      street: null,
      lga: null,
      state: null,
      isButtonDisabled:  false,
      showLoader: false,
      states: [],
      lgas: [],
      stateLgas: []
    }
  },
    created () {
        this.modalWidth = window.innerWidth < MODAL_WIDTH
        ? MODAL_WIDTH / 2
        : MODAL_WIDTH;

        this.getStates();
        this.getLgas();
    },
    methods: {
        validateDetails($event) {
            $event.preventDefault();
            if(!this.name || !this.objective ||  !this.city || !this.street || !this.lga || !this.state){
                this.$toasted.show("Fill in the missing Fields", {
                        theme: "bubble",
                        position: "top-center",
                        duration: 2000
                    });
            }else {
                this.createCompany();
            }
        },
        closeCreateBranchModal(){
            this.$modal.hide('createCompanyModal');
        },
        createCompany() {
            let dataToSend = {
                name: this.name,
                objective: this.objective,
                zonalOfficeName: this.zonalOfficeName,
                address: {
                    "streetName": this.street,
                    "city": this.city,
                    "lga": this.lga,
                    "state": this.state,
                }
            };
            this.showLoader = true;
            this.isButtonDisabled = true;
            this.axios.post(`${configObject.apiBaseUrl}/Company/Add`, dataToSend, configObject.authConfig)
                .then(() => {
                    this.showLoader = false;
                    this.closeCreateBranchModal();
                    this.$eventHub.$emit("refreshCompanyTable");
                    this.$toast.success("Company created successfully");
                    this.isButtonDisabled = false;
                    this.street = null;
                    this.city = null;
                    this.state = null;
                    this.lga = null;
                    })
                    .catch((error) => {
                        this.isButtonDisabled = false;
                        this.showLoader = false;
                        this.$toast.open({
                            message: "unable to create company",
                            type: "error",
                            duration: 3000
                        });
                        console.log(error);
                });
        },
         getStates(){
            this.axios.get(`${configObject.apiBaseUrl}/Setup/States`, configObject.authConfig)
            .then(resp => {
                this.states = resp.data;
                this.state = resp.data[0].name
                //console.log("STATES" + JSON.stringify(resp.data));
            });
        },
        getLgas(){
            this.axios.get(`${configObject.apiBaseUrl}/Setup/Lga`, configObject.authConfig)
            .then(resp => {
                this.lgas = resp.data;
                this.lga = resp.data[0].name
                this.stateLgas = resp.data[0];
                //console.log(this.stateLgas);
                //console.log("LGAs" + JSON.stringify(resp.data));
            });
        },
        stateChanged(){
            let _id = this.states.find(state => state.name === this.state).id;
            let _stateLgas = [];
            this.lgas.forEach((lga) => {                
                if(lga.stateId === _id){
                    _stateLgas.push(lga);
                }
            });
            this.stateLgas =  _stateLgas;
            //console.log(_stateLgas);
        },
    }
}
</script>
