<template>
  <MasterLayout>
    <div class="employees__assessment__wrapper">
      <div class="welcome_message">
        <div class="row">
          <div class="col-8">
            <h5 class="">
              <span class="greeeting__text"> Hi Admin, </span> you have
              <span class="branches__count"> {{ branches.length }} </span>
              branches
            </h5>
          </div>
          <div class="col-4 text-right">
            <div>
              <button class="creaet_branch_btn" @click="openCreateBranchModal">
                Create Branch
              </button>
            </div>
          </div>
        </div>
      </div>
      <section class="table__section mt-1 pb-5" v-show="branches.length > 0 || !showLoader">
        <ejs-grid ref="dataGrid" :created="refreshGrid" :allowPaging="true" :allowSorting="true"
          :pageSettings="pageSettings" :toolbar="toolbar" :searchSettings="search" :allowExcelExport="true"
          :allowPdfExport="true" :toolbarClick="toolbarClick">
          <e-columns>
            <e-column field="index" headerText="#" width="60px"></e-column>
            <e-column field="name" width="150px" headerText="Branch Name"></e-column>
            <e-column field="objective" width="150px" headerText="Objective"></e-column>
            <e-column field="zonalOffice" width="150px" headerText="Zonal Office Name"></e-column>
            <e-column field="showAddress" width="250px" :visible="false" headerText="Address"></e-column>
            <e-column :template="addressTemplate" headerText="Address" width="250px"></e-column>
            <e-column field="divisions" headerText="Divisions" width="80px"></e-column>
            <e-column field="units" headerText="Units" width="55px"></e-column>
            <e-column field="status" :visible="false" headerText="Status"></e-column>
            <e-column :template="deletedTemplate" headerText="Status" width="100px"></e-column>
            <e-column :template="actionButtonsTemplate" headerText="Actions" width="120px"></e-column>
          </e-columns>
        </ejs-grid>
      </section>
      <div class="empty_branch__div mt-3" v-if="branches.length < 1 && !showLoader">
        <div class="text-center">
          <h6 class="text-center">You are yet to create a branch</h6>
          <img src="@/assets/img/document.png" class="mt-3" width="130px" />
        </div>
      </div>
      <table v-show="showLoader" style="margin: 0 auto; width: 95%">
        <tbody>
          <tr>
            <td class="td-1"><span></span></td>
            <td class="td-2"><span></span></td>
            <td class="td-3"><span></span></td>
            <td class="td-4"></td>
            <td class="td-5"><span></span></td>
          </tr>
          <tr>
            <td class="td-1"><span></span></td>
            <td class="td-2"><span></span></td>
            <td class="td-3"><span></span></td>
            <td class="td-4"></td>
            <td class="td-5"><span></span></td>
          </tr>
          <tr>
            <td class="td-1"><span></span></td>
            <td class="td-2"><span></span></td>
            <td class="td-3"><span></span></td>
            <td class="td-4"></td>
            <td class="td-5"><span></span></td>
          </tr>
          <tr>
            <td class="td-1"><span></span></td>
            <td class="td-2"><span></span></td>
            <td class="td-3"><span></span></td>
            <td class="td-4"></td>
            <td class="td-5"><span></span></td>
          </tr>
          <tr>
            <td class="td-1"><span></span></td>
            <td class="td-2"><span></span></td>
            <td class="td-3"><span></span></td>
            <td class="td-4"></td>
            <td class="td-5"><span></span></td>
          </tr>
          <tr>
            <td class="td-1"><span></span></td>
            <td class="td-2"><span></span></td>
            <td class="td-3"><span></span></td>
            <td class="td-4"></td>
            <td class="td-5"><span></span></td>
          </tr>
          <tr>
            <td class="td-1"><span></span></td>
            <td class="td-2"><span></span></td>
            <td class="td-3"><span></span></td>
            <td class="td-4"></td>
            <td class="td-5"><span></span></td>
          </tr>
          <tr>
            <td class="td-1"><span></span></td>
            <td class="td-2"><span></span></td>
            <td class="td-3"><span></span></td>
            <td class="td-4"></td>
            <td class="td-5"><span></span></td>
          </tr>
          <tr>
            <td class="td-1"><span></span></td>
            <td class="td-2"><span></span></td>
            <td class="td-3"><span></span></td>
            <td class="td-4"></td>
            <td class="td-5"><span></span></td>
          </tr>
        </tbody>
      </table>
      <CreateBranchModal></CreateBranchModal>
      <EditBranchModal :branch="branch"></EditBranchModal>
      <BranchDetail :branch="branch"></BranchDetail>
    </div>
  </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
import CreateBranchModal from "@/components/Modals/Branch/createBranchModal.vue";
import EditBranchModal from "@/components/Modals/Branch/editBranchModal.vue";
import BranchDetail from "@/components/Modals/Branch/branchDetail.vue";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;

import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-vue-grids";

export default {
  title: "Branches",
  components: {
    MasterLayout,
    CreateBranchModal,
    EditBranchModal,
    BranchDetail,
  },
  provide: {
    grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport],
  },
  data() {
    return {
      backgroundUrl,
      UserDP,
      showLoader: false,
      componentKey: 0,
      branches: [],
      branch: {},
      pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
      toolbar: ["ExcelExport", "PdfExport", "Search"],
      search: { operator: "contains", ignoreCase: true },
      actionButtonsTemplate: function () {
        return {
          template: Vue.component("actionButtonsTemplate", {
            template:
              '<div class="btn-group btn-group-sm" role="group">' +
              '<button type="button" class="btn btn-info" title="Map to Unit"  v-on:click="mapUnit(data.id)" v-if="data.deleted === false">' +
              '<i class="fa fa-refresh" aria-hidden="true" style=""></i>' +
              "</button>" +
              '<button type="button" class="btn btn-primary" title="Edit" v-on:click="_editBranch(data.id)" v-if="data.deleted === false">' +
              '<i class="fa fa-edit" aria-hidden="true"></i>' +
              "</button>" +
              '<button type="button" class="btn btn-danger" title="Delete" v-on:click="_deleteBranch(data.id)" v-if="data.deleted === false">' +
              '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
              "</button>" +
              '<button type="button" class="btn btn-success" title="Re-Add" v-on:click="_reAddeBranch(data.id)" v-if="data.deleted === true">' +
              '<i class="fa fa-plus" aria-hidden="true"></i>' +
              "</button>" +
              "</div>",
            data: function () {
              return {
                data: {},
              };
            },
            methods: {
              _deleteBranch: function (branchId) {
                this.$eventHub.$emit("deleteBranchModel", branchId, 0);
              },
              _reAddeBranch: function (branchId) {
                this.$eventHub.$emit("deleteBranchModel", branchId, 1);
              },
              _editBranch: function (branchId) {
                this.$eventHub.$emit("editBranchModel", branchId);
              },
              viewBranchDetails: function (branchId) {
                console.log(branchId);
                this.$eventHub.$emit("viewBranchDetails", branchId);
              },
              mapUnit: function ($branchId) {
                console.log("branchId=>" + $branchId);
                this.$eventHub.$emit("changeRoute", $branchId);
              },
            },
          }),
        };
      },
      deletedTemplate: function () {
        return {
          template: Vue.component("deletedTemplate", {
            template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>
<div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
            data: function () {
              return {
                data: {},
              };
            },
          }),
        };
      },
      addressTemplate: function () {
        return {
          template: Vue.component("addressTemplate", {
            template: `<div><span class="">{{data.streetName}}<br />{{data.city}}, {{data.lga}} L.G.A. <br />{{data.state}}</span></div>`,
            data: function () {
              return {
                data: {},
              };
            },
          }),
        };
      },
    };
  },
  mounted() {
    this.getBranches();

    $(".e-input").keyup(function (e) {
      searchFun(e);
    });
    function searchFun(event) {
      var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
      var value = event.target.value;
      grid.search(value);
    }
  },
  created() {
    this.$eventHub.$on("deleteBranchModel", (branchId, typ) => {
      this.deleteBranch(branchId, typ);
    });
    this.$eventHub.$on("editBranchModel", (branchId) => {
      this.editBranch(branchId);
    });
    this.$eventHub.$on("refreshBranchTable", () => {
      this.getBranches();
    });
    this.$eventHub.$on("viewBranchDetails", (branchId) => {
      this.viewBranchDetails(branchId);
    });

    this.$eventHub.$on("changeRoute", ($branchId) => {
      this.gotoMapping($branchId);
    });
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      if (from.name === "login") {
        window.location.reload();
      }
    });
  },
  methods: {
    toolbarClick(args) {
      switch (args.item.text) {
        case "PDF Export":
          this.$refs.dataGrid.getColumns()[4].visible = true;
          this.$refs.dataGrid.getColumns()[5].visible = false;
          this.$refs.dataGrid.getColumns()[8].visible = true;
          this.$refs.dataGrid.getColumns()[9].visible = false;
          this.$refs.dataGrid.getColumns()[10].visible = false;

          this.$refs.dataGrid.pdfExport({
            pageOrientation: "Landscape",
            fileName: "Branch.pdf",
            theme: {
              header: {
                bold: true,
                fontColor: "#000000",
                fontName: "Calibri",
                fontSize: 10,
              },
              record: {
                fontColor: "#000000",
                fontName: "Calibri",
                fontSize: 8,
              },
            },
            header: {
              fromTop: 0,
              height: 130,
              contents: [
                {
                  type: "Text",
                  value: "Branch",
                  position: { x: 0, y: 50 },
                  style: { fontSize: 20, textAlign: "center" },
                },
              ],
            },
            footer: {
              fromBottom: 130,
              height: 130,
              contents: [
                {
                  type: "Text",
                  value: "Thank You",
                  position: { x: 0, y: 50 },
                  style: { fontSize: 20 },
                },
              ],
            },
          });
          break;
        case "Excel Export":
          this.$refs.dataGrid.getColumns()[4].visible = true;
          this.$refs.dataGrid.getColumns()[5].visible = false;
          this.$refs.dataGrid.getColumns()[8].visible = true;
          this.$refs.dataGrid.getColumns()[9].visible = false;
          this.$refs.dataGrid.getColumns()[10].visible = false;
          this.$refs.dataGrid.excelExport({
            fileName: "Branch.xlsx",
            theme: {
              header: {
                bold: true,
                fontColor: "#000000",
                fontName: "Calibri",
                fontSize: 10,
              },
              record: {
                fontColor: "#000000",
                fontName: "Calibri",
                fontSize: 8,
              },
            },
            header: {
              fromTop: 0,
              height: 130,
              contents: [
                {
                  type: "Text",
                  value: `Branch`,
                  position: { x: 0, y: 50 },
                  style: { fontSize: 20, textAlign: "center" },
                },
              ],
            },
            footer: {
              fromBottom: 130,
              height: 130,
              contents: [
                {
                  type: "Text",
                  value: "Thank You",
                  position: { x: 0, y: 50 },
                  style: { fontSize: 20 },
                },
              ],
            },
          });
          break;
      }
    },
    refreshGrid() {
      this.$refs.dataGrid.refresh();
    },
    forceRerender() {
      this.openEditBranchModal();
      this.componentKey += 1;
    },
    openCreateBranchModal() {
      this.$modal.show("createBranchModal");
    },
    openEditBranchModal() {
      this.$modal.show("editBranchModal");
    },
    gotoMapping($branchId) {
      this.$router.push({
        name: "map_branch_to_unit",
        query: {
          id: $branchId,
        },
      });
    },
    openBranchDetail() {
      this.$modal.show("branchDetail");
    },
    viewBranchDetails(branchId) {
      let branchDetail = this.branches.filter(
        (branch) => branch.id === branchId
      )[0];
      this.branch = branchDetail;
      this.$store.commit("setBranchDetails", branchDetail);
      localStorage.setItem("branch", JSON.stringify(branchDetail));
      this.openBranchDetail();
    },
    editBranch(branchId) {
      let branchDetail = this.branches.filter(
        (branch) => branch.id === branchId
      )[0];
      this.branch = branchDetail;
      //console.log(this.branch);
      // this.$store.commit("setBranchDetails", branchDetail);
      // localStorage.setItem("branch", JSON.stringify(branchDetail));
      this.openEditBranchModal();
    },
    deleteBranch(branchId, typ) {
      let resp = false;
      if (typ === 0) {
        resp = confirm("Are you sure you want to delete this branch");
      } else {
        resp = confirm("Are you sure you want to Re-Add this branch");
      }

      if (resp) {
        this.axios
          .delete(
            `${configObject.apiBaseUrl}/Branch/Delete/${branchId}`,
            configObject.authConfig
          )
          .then(() => {
            this.getBranches();
            let msg = "";

            if (typ === 0) {
              msg = "Branch Deleted successfully!";
            } else {
              msg = "Branch Re-Added successfully!";
            }
            this.$toast.open({
              message: msg,
              type: "success",
              duration: 4000,
            });
          })
          .catch((error) => {
            console.log(error);
            this.$toast.open({
              message: "Unable to complete action on branch",
              type: "error",
              duration: 4000,
            });
          });
      }
    },
    getBranches() {
      console.log("Get branch");
      this.showLoader = true;
      let grid = this.$refs.dataGrid;

      this.axios
        .get(`${configObject.apiBaseUrl}/Branch`, configObject.authConfig)
        .then((response) => {
          console.log(response);
          let index = 0;
          response.data.forEach((branch) => {
            index++;
            branch.index = index;
            branch.zonalOffice = branch.isZonalOffice
              ? branch.zonalOfficeName
              : "";
            branch.status = branch.deleted ? "deleted" : "active";
            branch.showAddress = `${branch.streetName} ${branch.city}, ${branch.lga} L.G.A. ${branch.state}`;
          });
          this.showLoader = false;
          grid.ej2Instances.setProperties({ dataSource: response.data });
          this.refreshGrid();
          this.branches = response.data;
        })
        .catch((error) => {
          console.log(error);
          // this.$toast.open({
          //     message: "unable to load branches",
          //     type: "error",
          //     duration: 3000
          // });
        });
    },
  },
};
</script>
