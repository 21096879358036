<template>
<modal name="createBranchModal" transition="pop-out" :width="modalWidth" :height="560">
  <div class="modal__header">
      <h4>Add New Branch</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
        <div class="row">
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>Branch Name</span>
                    <div class="">                        
                        <input type="text" placeholder="branch name" class="ml-3" v-model="branchName" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>Objective</span>
                    <div class="">
                        
                        <input type="text" placeholder="Objective" class="ml-3" v-model="objective" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="isZonalOffice"
                        name="checkbox-1"
                        value="true"
                        unchecked-value="false"
                        @change="toggleRequiredZonalOffice"
                        >
                        Is Zonal Office
                    </b-form-checkbox>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>Zonal Office  Name</span>
                    <div class="">
                        <input 
                            type="text" 
                            ref="zonalOff" 
                            placeholder="zonal office" 
                            class="ml-3" 
                            v-model="zonalOfficeName"
                            :required="isZonalOffice ? true : false"
                            :disabled="isZonalOffice ? false : true" />
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="row mt-2">
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>Address</span>
                    <div class="">                        
                        <input type="text" placeholder="e.g. 100, my street name" class="ml-3" v-model="street" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>City</span>
                    <div class="">
                        <input type="text" placeholder="city" class="ml-3" v-model="city" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>State</span>
                    <div class="">
                        <select ref="stateSelect" class="ml-3" v-model="state" required @change="stateChanged($event)">
                            <option value="">Select State</option>
                            <option v-for="st in states" v-bind:key="st.name" >{{st.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>LGA</span>
                    <div class="">
                        <select class="ml-3" v-model="lga" required>
                            <option value="">Select LGA</option>
                            <option v-for="_lga in stateLgas" v-bind:key="_lga.name" v-bind:value="_lga.name">{{_lga.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      
      <div class="text-center mt-4">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Create Branch
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 850;
import configObject from "@/config";

export default {
  name: 'createBranchModal',
  data () {
    return {
      modalWidth: MODAL_WIDTH,
      branchName: null,
      objective: null,
      zonalOfficeName: null,
      city: null,
      street: null,
      lga: null,
      state: null,
      states: [],
      lgas: [],
      stateLgas: [],
      isButtonDisabled:  false,
      showLoader: false,
      branch: this.$store.getters.getBranchdetail,
      isZonalOffice: false
    }
  },
  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

    
  },
  mounted(){
    this.getStates();
    this.getLgas();
  },
  methods: {
      validateDetails($event) {
        $event.preventDefault();
        if(!this.branchName || !this.objective || !this.city || !this.street || !this.lga || !this.state){
            this.$toasted.show("Fill in the missing Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
        }else {
            this.createBranch();
        }
    },
    
    closeCreateBranchModal(){
        this.$modal.hide('createBranchModal');
    },
    toggleRequiredZonalOffice() {
        this.isZonalOffice = !this.isZonalOffice;
    },
    getStates(){
        this.axios.get(`${configObject.apiBaseUrl}/Setup/States`, configObject.authConfig)
        .then(resp => {
            this.states = resp.data;
            this.state = resp.data[0].name
        });
    },
    getLgas(){
        this.axios.get(`${configObject.apiBaseUrl}/Setup/Lga`, configObject.authConfig)
        .then(resp => {
            this.lgas = resp.data;
        });
    },
    stateChanged(){
        console.log("STATE CHANGED");
        console.log(this.state);
        let _id = this.states.find(state => state.name === this.state).id;
        let _stateLgas = [];
        console.log(this.lgas);
        this.lgas.forEach((lga) => {                
            if(lga.stateId === _id){
                _stateLgas.push(lga);
            }
        });
        this.stateLgas =  _stateLgas;
    },
    createBranch() {
        let dataToSend = {
            name: this.branchName,
            objective: this.objective,
            zonalOfficeName: this.zonalOfficeName === '' ? "NIL" : this.zonalOfficeName,
            isZonalOffice: this.isZonalOffice,
            address: {
                "streetName": this.street,
                "city": this.city,
                "lga": this.lga,
                "state": this.state
            }
        };
        console.log(dataToSend);
        this.showLoader = true;
        this.isButtonDisabled = true;
        this.axios.post(`${configObject.apiBaseUrl}/Branch/Add`, dataToSend, configObject.authConfig)
            .then(() => {
                this.showLoader = false;
                this.isButtonDisabled = false;
                this.closeCreateBranchModal();
                this.$eventHub.$emit("refreshBranchTable");
                this.$toast.success("Branch created successfully");
                this.street = null;
                this.lga = null;
                this.state = null;
                this.branchName = null;
                this.objective = null;
                this.zonalOfficeName = null;
                this.city = null;
                this.isZonalOffice = false;
                
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "unable to create branch",
                        type: "error",
                        duration: 3000
                    });
                    console.log(error);
            });
    },
  }
}
</script>

<style scoped>
.cas_input .input_wrapper {
    margin-bottom: 5px;
}
.cas_input select, .cas_input [type='text']{
    margin-bottom: 5px;
}
</style>