<template>
    <MasterLayout>
        <div class="welcome_message">
            <div class="row">
                <div class="col-7">    
                    <h5 class=''>
                        LMS Courses
                        <!-- Showing <span class="branches__count"> {{employees.length}} </span> 
                        <span v-if="employees.length > 0">employees</span><span v-if="employees.length < 2">employee</span> found for the Role 
                        <span class="branches__count">{{designation.designationName}}</span> -->
                    </h5>
                </div>
                <div class="col-5 text-right">
                    <button class="creaet_branch_btn default" @click="backToUser"><i class="fa fa-arrow-left"></i> Back</button>
                </div>
            </div>
        </div>
         <div class="employees__assessment__wrapper">
            <section class="table__section mt-1 pb-5" >
                <ejs-grid
                    v-show="!showLoader"
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" headerText="#" width="60px"></e-column>
                        <e-column :template="avatarTemplate" width="80px" headerText=""></e-column>
                        <e-column field="name" headerText="Course Name"></e-column>
                        <e-column field="code" headerText="Course Code" width="110"></e-column>
                        <e-column field="price" headerText="Price" width="120"></e-column>
                        <e-column field="certification" headerText="Certification" width="110"></e-column>
                        <e-column :template="actionButtonsTemplate" headerText="Action"  width="150px"></e-column>
                    </e-columns>
                </ejs-grid>

                <TableLoader v-show="showLoader"></TableLoader>
            </section>
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Employee/MasterLayout.vue";
import TableLoader from '@/components/TableLoading.vue';
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;

import { DataManager,Query,ODataV4Adaptor,Predicate } from "@syncfusion/ej2-data";
import {
    Page,
    Sort,
    Toolbar,
    Search,
    ExcelExport,
    PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    title: 'LIMSAA',
    components: {
        MasterLayout,
        TableLoader
    },
    provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            courses: [],
            showLoader: false,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            fields : { text: "designationName", value: "id" },
            allowFiltering: true,
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-primary btn-xs" title="" v-on:click="_viewEmployee(data.id)">' +
                                'View Course <i class="fa fa-eye" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _viewEmployee: function(id) {
                                console.log(id);
                                this.$eventHub.$emit("viewEmployeeVM", id);
                            }
                        }
                    })
                };
            },
            avatarTemplate: function() {
                return {
                     template: Vue.component("avatarTemplate", {
                        template:   '<div class="profile__pic__wrapper" style="height: 30px; width: 30px;">' +
                                        '<div class="profile__pic mini">' +
                                            '<img v-bind:src="`${data.avatar}`" />' +
                                        '</div>' +
                                    '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            },

        }
    },
    created() {
        this.$eventHub.$on("viewEmployeeVM", (id) => {
            this.viewEmployee(id);
        });
        this.id = this.$route.query.id ? parseInt(this.$route.query.id) : null;
    },
    mounted() {
        console.log('Second Call');
        this.loadCourses();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    methods: {
        viewEmployee(id) {
            this.$router.push({
                name: "Course",
                query: {
                    id
                }
            })
        },
        toolbarClick(args){
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport({
                    pageOrientation: 'Landscape',
                });
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        loadCourses(){
            this.showLoader = true;
            let grid = this.$refs.dataGrid;

            let url = `${configObject.apiBaseUrl}/LMS`;
            this.axios
                .get(url, configObject.authConfig)
                .then(response => {
                    let index = 0;
                    response.data.forEach(course => {
                        index++;
                        course.index = index;
                        course.price = `₦ ${course.price.replace('&#36;', '')}`;
                    });
                    this.courses = response.data;
                    this.showLoader = false;

                    grid.ej2Instances.setProperties({ dataSource: this.courses });
                    this.refreshGrid();
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.loaded = false;
                });
        },
        backToUser(){
            this.$router.push({
                name: 'lmshome'
            });
        }
    }
}
</script>

        