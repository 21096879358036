<template>
    <MasterLayout>
        <div class="employees__assessment__wrapper">
            <div class="welcome_message">
                <div class="row">
                    <div class="col-8">    
                        <h5 class=''><span class="greeeting__text"> Hi Admin,
                        </span> you have <span class="branches__count"> {{grades.length}} </span> Grades
                    </h5> 
                    </div>
                    <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn" @click="openAddGradeModal()">
                                Add New Grade
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            <section class="table__section mt-1 pb-5" v-show="grades.length > 0">
                <ejs-grid
                    ref="gradeGrid"
                    :created="refreshGradeGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" headerText="#" width="60px"></e-column>
                        <e-column field="gradeName" headerText="Grade"></e-column>
                        <e-column :template="deletedTemplate" width="80px" headerText="Status"></e-column>
                        <e-column :template="gradeActionsButtons" width="100px" headerText="Actions"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
            <TableLoading v-show="showLoader"></TableLoading>  

            <div class="empty_branch__div mt-1" v-if="grades.length < 1">
                <div class="text-center">
                    <h6 class="text-center">Please wait while loading Grades</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
        </div>
    </MasterLayout>
</template>

<script>

import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import TableLoading from '@/components/TableLoading.vue'; 
import configObject from "@/config";

import {
  Page,
  Sort,
  Search,
  ExcelExport,
  PdfExport,
  Toolbar
} from "@syncfusion/ej2-vue-grids";

export default {
    components: {
        MasterLayout,
        'TableLoading': TableLoading
    },
    provide:{
        grid: [Page, Sort, Search, Toolbar, ExcelExport, PdfExport]
    },
    data(){
        return{
            grades: [],
            grade: null,
            showLoader: false,            
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            gradeActionsButtons: function() {
                return {
                    template: Vue.component("roleTaskButton", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-primary" title="Edit" v-on:click="_editGrade(data.id)">' +
                                '<i class="fa fa-edit" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-danger" title="Delete" v-on:click="_deleteGrade(data.id)" v-if="data.deleted === false">' +
                                '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-success" title="Re-Add" v-on:click="_readdGrade(data.id)" v-if="data.deleted === true">' +
                                '<i class="fa fa-plus" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _deleteGrade: function(gradeId) {
                                this.$eventHub.$emit("deleteGradeVM", gradeId, 0);
                            },
                            _readdGrade: function(gradeId) {
                                this.$eventHub.$emit("deleteGradeVM", gradeId, 1);
                            },
                            _editGrade: function(gradeId) {
                                console.log(gradeId);
                                this.$eventHub.$emit("editGrade", gradeId);
                            },
                        }
                    })
                };
            },
        }
    },
    created(){

    },
    mounted(){
        this.getGrades();
        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    methods:{    
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },    
        getGrades(){
            let grid = this.$refs.gradeGrid;
            this.showLoader = true;
            this.axios
                .get(`${configObject.apiBaseUrl}/Grades`, configObject.authConfig)
                .then(response => {
                    this.showLoader = false;
                    console.log(response.data);
                    let index = 0;
                    response.data.forEach(grade => {
                        index++;
                        grade.index = index;
                    });
                    this.grades = response.data;
                    grid.ej2Instances.setProperties({ dataSource: this.grades });
                    this.refreshGradeGrid();
                })
                .catch((error) => {
                    console.log(error);
                    // this.$toast.open({
                    //     message: "unable to load grades",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
    }
}
</script>