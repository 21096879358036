<template>
  <div id="app">
    <transition>
      <router-view />
    </transition>
  </div>
</template>

<script>



export default {
  computed: {
    checkNavbarMenu() {
      return this.$store.getters.getMobileNavDrawer;
    }
  }
};
</script>

<style lang="scss">
  @import url("https://cdn.syncfusion.com/ej2/material.css");
  @import "@/assets/sass/styles.scss";
</style>
