<template>
    <MasterLayout>
        <div class="cas_input">
            <div class="row__wrapper">
                <div class="row">
                    <div class="col-6">
                        <h5 class=''>
                            Viewing Competency
                        </h5>
                    </div>
                    <div class="col-6 text-right">
                        <div>
                            <button class="creaet_branch_btn default" @click="backToCompetencies"><i class="fa fa-arrow-left mr-2"></i>Back</button>
                            <router-link :to="{ name: 'edit_competency', query: {...$route.query}}" class="creaet_branch_btn router_link_decoration" @click="backToCompetencies">Edit Competency</router-link>
                        </div>
                    </div>
                    <div class="col-12"><hr /></div>
                    
                    <div class="col-md-6">
                        <div class="" style="width: 100%">
                            <span>Name</span>
                            <div class="mt-1">
                                <input type="text" class="ml-3" v-model="compName" readonly />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="" style="width: 100%">
                            <span>Competency group</span>
                            <div class=" mt-1">
                                <input type="text" class="ml-3" :value="compGroupValue" readonly />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-4">
                        <div class="" style="width: 100%">
                            <span class="" style="display: block;">Description</span>
                            <div class=" mt-4">
                                <div class="view_competency" ref="view_desc">
                                </div>
                                
                            <!-- <span class="mb-2" style="display: block;">Description</span>
                            <div class=" mt-1">
                                <div class="html-viewer" v-html="description"></div>-->
                                <!-- <textarea type="text" class="" v-model="description"
                                    style="height: 300px;"
                                    placeholder="Enter Descriptions here"></textarea> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-4">
                        <div class="" style="width: 100%">
                            <span class="">Divisions</span>
                            <div class="mt-4" style="max-height: 300px; overflow-y: auto;" v-if="divisions.length && !loading">
                                <ul class="list-group">
                                    <li class="mt-1 list-group-item" v-for="(division,i) in selectedDivisions" :key="i" style="line-height:30px;">{{division.name}}</li>
                                </ul>
                                <!-- <div class="mt-1" v-if="divisions.length && !loading">
                                <ol class="list-group">
                                    <li class="list-group-item" v-for="(division,i) in divisions" :key="i"><span style="width: 25px; display: inline-block;">{{i + 1}}:</span> {{division.text}}</li>
                                </ol> -->
                            </div>
                            <div v-if="!divisions.length && !loading" class="mt-4 view_competency">
                                There is no available division
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MasterLayout>
</template>

<script>

import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


export default {
    title: 'View Competency',
    components: {
        MasterLayout
    },
    created() {
        if (!this.$route.query.id) {
            this.$router.push({name: 'competency_library' })
        }
    },
    mounted() {
        this.getCompetency()
        this.getDivisions()
        this.getCompetencyGroups()
    },
    data() {
        return {
            loading: true,
            compName: null,
            competencyGroups: [],
            groupId: '',
            divisions: [],
            selectedDivisions: [],
            compGroupValue: '',
        }
    },
    methods: {
        getCompetency() {
            this.axios.get(`${configObject.apiBaseUrl}/Competency/${this.$route.query.id}`, configObject.authConfig)
                .then(response => {
                    this.compName = response.data.name
                    this.groupId = response.data.competencyGroupId
                    this.$refs["view_desc"].innerHTML = response.data.description;
                    this.selectedDivisions = response.data.divisionIds
                    this.loading = false
                    this.getCompGroup()
                    this.getDivisionIds()
                })
                .catch(error => {
                });
        },
        getCompetencyGroups() {
            this.axios.get(`${configObject.apiBaseUrl}/Competency/Group`, configObject.authConfig)
            .then(response => {
                this.competencyGroups = response.data;
                this.getCompGroup()
            })
            .catch((error) => {
            });
        },
        getDivisions() {
            this.axios.get(`${configObject.apiBaseUrl}/Divisions`, configObject.authConfig)
                .then(response => {
                    this.divisions = response.data
                    this.loading = false
                    this.getDivisionIds()
                })
                .catch(error => {
                });
        },
        getCompGroup() {
            if (this.competencyGroups.length && this.groupId) {
                this.compGroupValue = this.competencyGroups.find(cur => cur.id == this.groupId).name
            }
        },
        getDivisionIds() {
            if (this.divisions.length && this.selectedDivisions.length) {
                this.selectedDivisions = this.divisions.filter(cur => this.selectedDivisions.includes(cur.id))
            }
        },
        backToCompetencies() {
            this.$router.push({ name: 'competency_library' })
        }
    }
}
</script>

<style>
    .divisions_checkbox .custom-checkbox {
        width: 50%;
        margin-right: 0;
        word-break: break-all;
        margin-bottom: 8px;
    }
</style>