<template>
    <MasterLayout>
        <div class="employees__assessment__wrapper">
             <div class="first__banner" :style="{
                backgroundImage: `linear-gradient(rgb(32, 28, 22 , 0.92), rgb(32, 28, 22 , 0.92)), url(${backgroundUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: '79% 50%'}">
                <a title="Back to Courses List" @click="backToCourses" class="icon____div set_up_icon" style="background:rgb(242, 255, 245); width: 50px; height:50px; left: 60px;">
                    <i class="fa fa-arrow-left" aria-hidden="true" style="color:#069E2A;font-size: 25px;"></i>
                </a>
                <div class="profile__pic__wrapper" >
                    <div class="profile__pic" :style="{
                        backgroundImage: `url(${courseDetails.big_avatar})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'}">
                    </div>
                    <div class="user_name text-center text-white mt-3"> {{courseDetails.name}}</div>
                    <div class="grey__text text-center text-white mt-2">
                        <span class="" style="">{{courseDetails.code}}</span>
                    </div>
                    <div class="grey__text text-center text-white mt-2">
                        <span class="">{{courseDetails.active}}</span>
                    </div>
                    <div class="grey__text text-center text-white mt-2">
                        <span>Certification: </span>{{courseDetails.certification}}
                    </div>
                    
                </div>
            </div>
            <div class="first__tabs__Section">
                <div class="sec_tab">
                    <img src="@/assets/img/building_green.png" class="mt-3" width="24px"  />
                    <p class="mt-2 first__tabs__section__para">Price</p>
                    <h6 class="mt-2 theme__color first__tab__bolde__text text-center poppins" >{{courseDetails.price}}</h6>
                </div>
                <div class="sec_tab">
                    <img src="@/assets/img/building (1).png" class="mt-3" width="24px"  />
                    <p class="mt-2 first__tabs__section__para">Time Limit</p>
                    <h6 class="mt-2 theme__color first__tab__bolde__text text-center poppins">{{courseDetails.time_limit}}</h6>
                </div>
                <div class="sec_tab">
                    <img src="@/assets/img/office-desk.png" class="mt-3" width="24px"  />
                    <p class="mt-2 first__tabs__section__para">Creation Date</p>
                    <h6 class="mt-2 theme__color first__tab__bolde__text text-center poppins">{{courseDetails.creation_date}}</h6>
                </div>
                <div class="sec_tab">
                    <img src="@/assets/img/grade.png" class="mt-3" width="24px"  />
                    <p class="mt-2 first__tabs__section__para">Certification Duration</p>
                    <h6 class="mt-2 theme__color first__tab__bolde__text text-center poppins">{{courseDetails.certification_duration}}</h6>
                </div>
                <div class="sec_tab">
                    <img src="@/assets/img/grade.png" class="mt-3" width="24px"  />
                    <p class="mt-2 first__tabs__section__para">Last Updated On</p>
                    <h6 class="mt-2 theme__color first__tab__bolde__text text-center poppins">{{courseDetails.last_update_on}}</h6>
                </div>
            </div>
        </div>
        <div class="course_description">
            <h3>Description</h3>
            <p>{{courseDetails.description}}</p>
        </div>
        <div class="welcome_message">
                 <div class="row">
                     <div class="col-12">    
                         <h5 class=''><span class="greeeting__text"> Hi {{userInfo.firstName}},
                            </span> there are <span class="branches__count"> {{usersCount}} </span> {{usersCount > 1 ? 'users' : 'user'}} of this course.
                        </h5> 
                     </div>
                 </div>
             </div>
         <div class="employees__assessment__wrapper">
            <section class="table__section mt-1 pb-5" >
                <ejs-grid
                    v-show="!showLoader"
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" headerText="#" width="60px"></e-column>
                        <e-column field="name" width="150px" headerText="Name"></e-column>
                        <e-column field="role" width="150px" headerText="Role"></e-column>
                        <e-column field="enrolled_on" headerText="Enrolled On" width="180"></e-column>
                        <e-column field="completed_on" headerText="Completed On" width="180"></e-column>
                        <e-column field="completion_percentage" headerText="Completion Percentage" width="180"></e-column>
                        <e-column filed="expired_on" headerText="Expired On"  width="180px"></e-column>
                    </e-columns>
                </ejs-grid>

                <TableLoader v-show="showLoader"></TableLoader>
            </section>
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Employee/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import TableLoader from '@/components/TableLoading.vue';
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;

import { DataManager,Query,ODataV4Adaptor,Predicate } from "@syncfusion/ej2-data";
import {
    Page,
    Sort,
    Toolbar,
    Search,
    ExcelExport,
    PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    title: 'LIMSAA',
    components: {
        MasterLayout,
        TableLoader
    },
    provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            showLoader: false,
            usersCount: 0,
            backgroundUrl,
            courseDetails: {},
            userInfo: localStorage.getItem("dcasUserDetails")
                ? JSON.parse(localStorage.getItem("dcasUserDetails"))
                : null,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            fields : { text: "designationName", value: "id" },
            allowFiltering: true,
        }
    },
    created() {
        this.$eventHub.$on("viewEmployeeVM", (id) => {
            this.viewEmployee(id);
        });
        this.id = this.$route.query.id ? parseInt(this.$route.query.id) : null;
    },
    mounted() {
        this.loadCourses();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    methods: {
        toolbarClick(){
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        backToCourses(){
            this.$router.push({
                name: 'courses'
            });
        },
        loadCourses(){
            this.showLoader = true;
            let grid = this.$refs.dataGrid;

            let url = `${configObject.apiBaseUrl}/LMS/Course/${this.$route.query.id}`;
            this.axios
                .get(url, configObject.authConfig)
                .then(response => {
                    let index = 0;
                    response.data.price = `₦ ${response.data.price.replace('&#36;', '')}`;
                    this.courseDetails = response.data
                    response.data.users.forEach(user => {
                        index++;
                        user.index = index;
                    });
                    this.usersCount = response.data.users.length
                    this.showLoader = false;

                    grid.ej2Instances.setProperties({ dataSource: response.data.users });
                    this.refreshGrid();
                })
                .catch((error) => {
                    console.log(error);
                    this.showLoader = false;
                });
        }
    }
}
</script>

        