<template>
<modal name="editRespModal" transition="pop-out" :width="modalWidth" :height="300">
  <div class="modal__header">
      <h4>Edit Responsibility</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-12">
                <div class="" style="width: 100%">
                    <span>Responsibility Name</span>
                    <div class="">
                        <input type="text" placeholder="" class="ml-3" v-model="responsibility.name"  />
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <div class="text-center mt-4">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Update Responsibility  <i class="fa fa-save ml-2"></i>
            <span class="">
                <img src="@/assets/img/git_loader.gif" class="ml-2" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 450;
import configObject from "@/config";

export default {
  name: 'editRespModal',
  props:  ['responsibility'],
  data () {
    return {
      modalWidth: MODAL_WIDTH,
      unitName: null,
      objective: null,
      isButtonDisabled:  false,
      showLoader: false,
      branch: this.$store.getters.getBranchdetail,
      gradeName: null
    }
  },
  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

  },
  methods: {
      validateDetails($event) {
        $event.preventDefault();
        if(!this.responsibility.name){
            this.$toasted.show("Fill in the missing Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
        }else {
            this.editResp();
        }
    },
    closeModal(){
        this.$modal.hide('editRespModal');
    },
    editResp() {
        let data = {
            name: this.responsibility.name,
            id: parseInt(this.responsibility.id),
        };
        console.log(data);
        this.showLoader = true;
        this.isButtonDisabled = true;
        this.axios.put(`${configObject.apiBaseUrl}/Responsibilities/Edit/${this.responsibility.id}`, data,  configObject.authConfig)
            .then(response => {
                this.showLoader = false;
                this.isButtonDisabled = true;
                this.closeModal();
                this.$eventHub.$emit("refreshResponsilitiesTable");
                this.$toast.success("Responsibiity Updated successfully");
                this.isButtonDisabled = false;
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "unable to edit responsibility",
                        type: "error",
                        duration: 3000
                    });
                    console.log(error);
            });
    },
  }
}
</script>
