<template>
<modal name="editGradeModal" transition="pop-out" :width="modalWidth" :height="300">
  <div class="modal__header">
      <h4>Edit Grade</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-12">
                <div class="" style="width: 100%">
                    <span>Grade Name</span>
                    <div class="">
                        <input type="text" placeholder="Enter the Grade name" class="ml-3" v-model="grade.gradeName"  />
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <div class="text-center mt-4">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Update Grade  <i class="fa fa-save ml-2"></i>
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 450;
import configObject from "@/config";

export default {
  name: 'editGradeModal',
  props:  {
    grade: {
        type: Object,
        default: function() {
            return {};
        }
    }
  },
  data () {
    return {
      modalWidth: MODAL_WIDTH,
      unitName: null,
      objective: null,
      isButtonDisabled:  false,
      showLoader: false,
      branch: this.$store.getters.getBranchdetail,
      gradeName: null
    }
  },
  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

  },
  methods: {
      validateDetails($event) {
        $event.preventDefault();
        if(!this.grade.gradeName){
            this.$toasted.show("Fill in the missing Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
        }else {
            this.editGrade();
        }
    },
    closeModal(){
        this.$modal.hide('editGradeModal');
    },
    editGrade() {
        let data = {
            gradeName: this.grade.gradeName,
            id: this.grade.id,
        };
        console.log(data);
        this.showLoader = true;
        this.isButtonDisabled = true;
        this.axios.put(`${configObject.apiBaseUrl}/Grades/Edit/${this.grade.id}`, data,  configObject.authConfig)
            .then(response => {
                this.showLoader = false;
                this.isButtonDisabled = true;
                console.log(response.data);
                this.closeModal();
                this.$eventHub.$emit("refreshGradesTable");
                this.$toast.success("Grade Updated successfully");
                this.isButtonDisabled = false;
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "Unable to edit grade",
                        type: "error",
                        duration: 3000
                    });
                    console.log(error);
            });
    },
  }
}
</script>
