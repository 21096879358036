<template>
    <div>
        <MasterLayout>
            <div class="employees__assessment__wrapper">

                <div class="welcome_message">
                    <div class="row">
                        <div class="col-6">    
                            <h5 class=''><span class="greeeting__text"> Hi Admin,
                                </span> you have <span class="branches__count"> {{competencyGroups.length}} </span> Competency Groups
                            </h5> 
                        </div>
                        <div class="col-6 text-right">
                            <div>
                                <button class="creaet_branch_btn" @click="openAddCompetencyGroupModal">Add Group</button>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="table__section mt-1 pb-5" v-show="competencyGroups.length > 0 && !showLoader">
                    <ejs-grid
                        ref="dataGrid"
                        :created="refreshGrid"
                        :allowPaging="true"
                        :allowSorting="true"
                        :pageSettings="pageSettings"
                        :toolbar="toolbar"
                        :searchSettings="search"
                        :allowExcelExport="true"
                        :allowPdfExport="true"
                        :toolbarClick="toolbarClick">
                        <e-columns>
                            <e-column field="index" headerText="#" width="60px"></e-column>
                            <e-column field="name" headerText="Name" width="300px"></e-column>
                            <e-column :template="actionButtonsTemplate" width="150px" headerText="Actions"></e-column>
                        </e-columns>
                    </ejs-grid>
                </section>
                <div class="empty_branch__div mt-3" v-if="competencyGroups.length < 1 && !showLoader">
                    <div class="text-center">
                        <h6 class="text-center"> You are yet to create a competency group</h6>
                        <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                    </div>
                </div>
                <TableLoader v-show="showLoader"></TableLoader>
                
                
            </div>
        </MasterLayout>
        <CreateCompetencyGroup></CreateCompetencyGroup>
        <EditGroupModal :group="group"></EditGroupModal> 
        <UsedGroupModal :usedGroup="usedGroup"></UsedGroupModal> 
    </div>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import CreateCompetencyGroup from "@/components/Modals/Competency_group/createCompetencyGroup.vue";
import EditGroupModal from "@/components/Modals/Competency_group/editCompetencyGroup.vue";
import UsedGroupModal from "@/components/Modals/Competency_group/usedGroups.vue";
import TableLoader from '@/components/TableLoading.vue';
import configObject from "@/config";
import JQuery from "jquery";
import moment from "moment";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    title: 'All Competency Groups',
    components: {
        MasterLayout,
        TableLoader,
        CreateCompetencyGroup,
        EditGroupModal,
        UsedGroupModal
    },
    provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            backgroundUrl,
            competencyGroups: [],
            group: {},
            usedGroup: {},
            showLoader: false,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-primary" v-on:click="_editGroup(data.id, data)">' +
                                'Edit' +
                            '</button>' +
                            '<button type="button" class="btn btn-danger ml-2" v-on:click="_deleteGroup(data.id)">' +
                                'Delete' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _editGroup: function(id, data) {
                                this.$eventHub.$emit("editGroup", id, data);
                            },
                            _deleteGroup: function(id) {
                                this.$eventHub.$emit("deleteGroup", id);
                            },
                        }
                    })
                };
            }
        }
    },
     mounted() {
        this.getCompetencyGroups();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created() {
        this.$eventHub.$on("editGroup", (id, data) => {
            this.openEditGroupModal(id, data);
        });
        this.$eventHub.$on("deleteGroup", (id) => {
            this.deleteGroup(id);
        });
        this.$eventHub.$on("updateGroupTable", () => {
            this.getCompetencyGroups();
        });
        this.$eventHub.$on("refreshCompetencyGroupTable", () => {
            this.getCompetencyGroups();
        });
    },
    beforeDestroy() {
        this.$eventHub.$off("deleteGroup")
        this.$eventHub.$off("editGroup")
        this.$eventHub.$off("updateGroupTable")
        this.$eventHub.$off("refreshCompetencyGroupTable")
    },
    methods: {
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                    // this.$refs.dataGrid.getColumns()[6].visible = true;
                    this.$refs.dataGrid.getColumns()[6].visible = false;
                    this.$refs.dataGrid.pdfExport({
                        pageOrientation: 'Landscape'
                    });
                    this.$refs.dataGrid.pdfExport({
                        pageOrientation: 'Landscape',
                        fileName: 'Competency Groups.pdf',
                        theme: {
                            header: {
                                bold: true,
                                fontColor: "#000000",
                                fontName: 'Calibri',
                                fontSize: 10
                            },
                            record: {
                                fontColor: '#000000',
                                fontName: "Calibri",
                                fontSize: 8
                            }
                        },
                        header: {
                            fromTop:0,
                            height: 130,
                            contents:  [{
                                type: "Text",
                                value:  'Employees',
                                position: {x:0, y: 50},
                                style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],                        
                        }
                    });
                    break;                
                case "Excel Export":
                    // this.$refs.dataGrid.getColumns()[6].visible = true;
                    this.$refs.dataGrid.getColumns()[6].visible = false;
                    this.$refs.dataGrid.excelExport({
                        fileName: 'Employees.xlsx',
                        theme: {
                            header: {
                                bold: true,
                                fontColor: "#000000",
                                fontName: 'Calibri',
                                fontSize: 10
                            },
                            record: {
                                fontColor: '#000000',
                                fontName: "Calibri",
                                fontSize: 8
                            }
                        },
                        header: {
                            fromTop:0,
                            height: 130,
                            contents:  [{
                                type: "Text",
                                value:  `Employees`,
                                position: {x:0, y: 50},
                                style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                                type: "Text",
                                value: "Thank You",
                                position: {x:0, y: 50},
                                style: {fontSize: 20}
                            }],                        
                        }
                    });
                    break;
            }
            // this.$refs.dataGrid.getColumns()[6].visible = false;
            this.$refs.dataGrid.getColumns()[6].visible = true;
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        openAddCompetencyGroupModal() {
            this.$modal.show("createCompetencyGroupModal");
        },
        openEditGroupModal(id, data){
            this.group = data;
            this.$modal.show("editGroupModal");
        },
        openUsedCompetencyGroupModal(data) {
            this.usedGroup = data
            this.$modal.show("usedCompetencyGroupModal");
        },
        deleteGroup(id) {
            var resp = confirm("Are you sure  you want to delete this competency group");
            if (resp) {
                this.axios
                    .delete(
                        `${configObject.apiBaseUrl}/Competency/Group/Delete/${id}`,
                        configObject.authConfig
                    )
                    .then(() => {
                        this.getCompetencyGroups();
                        this.$toast.open({
                            message: "Successfully deleted competency group!",
                            type: "success",
                            duration: 3000
                        });
                    })
                    .catch(error => {
                        if (error.response.status == 400) {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: "error",
                                duration: 3000
                            });
                            this.openUsedCompetencyGroupModal(error.response.data.competenciesAttachedToGroup)
                            return
                        }
                        this.$toast.open({
                            message: error.response.data.message,
                            type: "error",
                            duration: 3000
                        });
                    });
            }
        },
        getCompetencyGroups() {
            this.showLoader = true;
            let grid = this.$refs.dataGrid;
            this.axios.get(`${configObject.apiBaseUrl}/Competency/Group`, configObject.authConfig)
            .then(response => {
                let index = 0;
                response.data.sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase()
                    ? -1
                    : a.name.toLowerCase() > b.name.toLowerCase()
                    ? 1
                    : 0
                );
                response.data.forEach((cur, i) => {
                    index++;
                    cur.index = index;
                });
                this.competencyGroups = response.data;
                grid.ej2Instances.setProperties({ dataSource: response.data });
                this.refreshGrid();
                this.showLoader = false;
            })
            .catch((error) => {
                this.showLoader = false;
            });
        },
        
    }
}
</script>

        