
<template>
    <div style="min-height: 100vh;">
        <div class="floating_btn">
            <div class="floatinig_btn_inner_div">
                <div class="grouped_icons text-center">
                    <img src="@/assets/img/logo.png" width="80%" />
                </div>
            </div>
        </div>
        <div class="row login__wrapper no_margin" style="min-height: 100vh;">
            
            <div class="col-md-12 no_padding">
                <div class="right_wrapper" style="background-color: #FFF;">
                <!-- <div class="right_wrapper" :style="{
                    backgroundImage: `linear-gradient(rgb(9, 140, 40 , 0.92), rgb(9, 140, 40, 0.92)), url(${backgroundUrl})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: '79% 50%' }"> -->
                    <section class="top__bar">
                        <div class="container">
                            <div class="pull-left" @click="changeRoute('login')" style="cursor: pointer;">
                                <div class="pull-left" style="border-radius: 50%; width: 60px; height: 60px; overflow: hidden; box-shadow: 0 3px 5px #000;">
                                    <img src="@/assets/img/logo.png" style="height: 60px; width: 60px;" />
                                </div>
                                <h4 class="pull-left" style="color: #fff; margin: 15px 0 0 10px; text-shadow: 0 2px 2px #000;">{{portalName}}</h4>
                            </div>
                            <div class="pull-right">
                                <div class="top__bar__inner_div">
                                    <ul>
                                        <li class=""><a href="#competency_profile">Competency Profile</a></li>
                                        <li class=""><a href="#benchmarking">Assessment &amp; Benchmarking</a></li>
                                        <li><a href="#report_analytics">Report Analytics</a></li>
                                        <li class=""><a href="http://www.limsaa.com" target="_blank">LMS</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="container">
                        <slot>
                            <router-view />
                        </slot>
                    </div>
                    <section class="footer_bar">
                        <p>Copyright &copy; {{year}}
                        </p>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
    import configObject from "@/config";

    export default {
        data() {
            return {
                year: new Date().getFullYear(),
                backgroundUrl,
                portalName: '',
            }
        },
        mounted(){
            this.loadUser();
            this.portalName = configObject.portalName;
        },
        methods: {
            changeRoute($routeName){
                this.$router.push({
                    name: $routeName
                })
            },
        }
    }
</script>