<template>
    <modal name="processAssessmentModal" transition="pop-out" :width="modalWidth" :height="390">
        <div class="modal__header">
            <h4>Process Assessment</h4>
        </div>
        <div class="cas_input">
            <div class="row__wrapper">
                <div class="row">
                    <div class="col-md-12">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Comment</span>
                            <div class="">
                                <textarea type="text" value="" v-model="comment" required style="margin: 0px!important;"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <b-form-group label="Approval Option">
                            <b-form-radio-group
                                id="btn-radios-2"
                                v-model="approvalStatus"
                                :options="approvalOptions"
                                buttons
                                button-variant="outline-success"
                                size="sm"
                                name="radio-btn-outline"></b-form-radio-group>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="text-center mt-4">
                <button class="creaet_branch_btn"
                    @click="validateDetails($event)"
                    :disabled="isButtonDisabled ? true : null"
                    :style="[
                    isButtonDisabled
                        ? { cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                    ]">
                    Submit <i class="fa fa-save" v-if="!showLoader"></i>
                    <span class=""  v-if="showLoader">
                        <img src="@/assets/img/git_loader.gif" width="25px"/>
                    </span>
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
const MODAL_WIDTH = 500;
import configObject from "@/config";

export default {
    name: 'processAssessmentModal',
    props:  ['assessment'],
    data(){
        return {
            showLoader: false,
            isButtonDisabled: false,
            approvalStatus: '',
            approvalOptions: [
                { text: 'Approve', value: 'approve' },
                { text: 'Disapprove', value: 'disapprove' },
            ],
            comment: '',
        }
    },
    created(){
        this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
        this.cleanEntry();
    },
    mounted(){
        
    },
    methods: {
        validateDetails($event){
            $event.preventDefault();
            //alert(`Approval Status = ${this.approvalStatus}; Comment = ${this.comment}`);
            if(!this.comment || !this.comment)
            {
                this.$toast.open({
                    message: "Please select the Approval option (Approve/Disapprove) and enter the comment to continue",
                    type: "error",
                    duration: 8000
                });
                
            }else {
                let conf = confirm(`Are you sure you want to ${this.approvalStatus.toUpperCase()} this assessment?`);
                if(conf){
                    this.updateAssessment();
                }
                //
            }
        },
        closeCreateEmployeeModal(){
            this.$modal.hide('processAssessmentModal');
        },
        updateAssessment(){
            console.log('Acting on Assessment...');
            
            this.showLoader = true;
            this.isButtonDisabled = true;

            let formData = {};
            formData.id = this.assessment.id;
            formData.comment = this.comment;
            formData.action = this.approvalStatus;
            
            console.log(formData);
            this.axios.post(`${configObject.apiBaseUrl}/Employee/SelfAssessment/Action`, formData, configObject.authConfig)
                .then(() => {
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    this.cleanEntry();
                    this.closeCreateEmployeeModal();
                    this.$eventHub.$emit("refreshEmployeeAssessment");
                    this.$toast.open({
                        message: `Employee Assessment ${this.approvalStatus.toUpperCase()} and saved successfully`,
                        type: "success",
                        duration: 5000
                    });
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    let msg = '';
                    let secs = 5000;
                    console.log(error.response);
                    if(error.response.data){
                        msg = error.response.data.msg;
                        secs = 10000;
                    }
                    else{
                        msg = "Unable to complete the Employee assessment processing.";
                    }
                    this.$toast.open({
                        message: msg,
                        type: "error",
                        duration: secs
                    });
                    console.log(error);
                });
        },
        cleanEntry(){
            this.comment = null;
            this.approvalStatus = '';
        },
    }
}
</script>