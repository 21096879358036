<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">
            <div class="welcome_message">
                <div class="row">
                    <div class="col-8">
                        <h5 class=''><span class="greeeting__text"> Hi {{userInfo.firstName}},
                            </span> Step <span class="branches__count">2</span> of 3: select Competency(ies) for this <span v-if="aType === 'Training'">training</span>
                            <span v-if="aType === 'Assessment'">assessment</span>
                        </h5> 
                    </div>
                    <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn default" @click="backToStepOne"><i class="fa fa-arrow-left"></i> Back to Step 1</button>
                                              
                        </div>
                    </div>
                </div>
            </div>
            <section class="table__section mt-1 pb-5">
                <div class="col-12 mt-5">
                    <div class="search_input" ref>
                        <div class="search_input_wrapper">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group row" style="margin-left: 15px;">
                                        <label class="col-form-label" style="padding-top: 11px;">Filter By:</label>
                                        <div class="col-6">
                                            <select class="geLev" v-model="filterBy" v-on:change="changeCompList()" style="margin-top: 5px;">
                                                <option value="1">My Division Competencies</option>
                                                <option value="2">All Competencies</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input_group pull-right">
                                        <input type="email" placeholder="search..." class="" v-model="search" v-on:keyup="filterList()" />
                                        <i class="fa fa-search" aria-hidden="true" style=""></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-table class="no-style"  striped hover :items="competencies" :fields="compGroupHeader" v-show="!showLoader" 
                        sort-icon-left responsive="sm"
                        :current-page="currentPage"
                        :per-page="perPage">
                        <template v-slot:cell(index)="data">
                            <button type="button" class="btn btn-primary btn-xs" @click="competencyClicked($event, data.item.id)">Select</button>
                            
                        </template>
                        <template v-slot:cell(name)="data">
                            <span class="text-left">{{ data.item.name }}</span>
                        </template>

                        <template v-slot:cell(CompetencyLevel)="data">
                            <select v-on:change="doLevelSelect(data.item.id, data.item.levelId)" v-bind:data-cid="`${data.item.id}`" class="geLev" v-model="data.item.levelId">
                                <option value="">Select Level</option>
                                <option v-for="(cpLvl) in data.item.levels" v-bind:value="cpLvl.id" v-bind:key="cpLvl.id">{{cpLvl.level}}</option>
                            </select>
                        </template>
                    </b-table>
                    <div class="pagination-wrapper" v-show="!showLoader">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            size="md"
                            class="my-0"
                        ></b-pagination>

                        <div class="pagination-select">
                            <b-form-select
                                v-model="perPage"
                                id="perPageSelect"
                                size="sm"
                                :options="options">
                            </b-form-select>
                            <span>Items per page</span>
                        </div>
                        
                    </div>
                    <TableLoading v-show="showLoader"></TableLoading>
                </div>
                <div class="col-12 mt-5">
                    <div class="welcome_message">
                        <h5>Selected Competencies</h5>
                    </div>
                    <b-table class="no-style"  striped hover :items="selectedCompetencies" 
                        :fields="compSelectedHeader" v-show="!showLoader" 
                        sort-icon-left responsive="sm"
                        :current-page="currentPage"
                        :per-page="perPage">
                        <template v-slot:cell(index)="data">
                            <button type="button" class="btn btn-danger btn-xs" @click="competencyRemoved($event, data.item.id)">Remove</button>
                        </template>
                        <template v-slot:cell(name)="data">
                            <span class="text-left">{{ data.item.name }}</span>
                        </template>

                        <template v-slot:cell(CompetencyLevel)="data">
                            <select v-on:change="doLevelSelect(data.item.id, data.item.levelId)" v-bind:data-cid="`${data.item.id}`" class="geLev" v-model="data.item.levelId">
                                <option value="">Select Level</option>
                                <option v-for="(cpLvl) in data.item.levels" v-bind:value="cpLvl.id" v-bind:key="cpLvl.id">{{cpLvl.level}}</option>
                            </select>
                        </template>
                    </b-table>
                    <div class="text-center">
                        <button class="creaet_branch_btn" @click="saveAssessment"
                            :disabled="isButtonDisabled ? true : null"
                            :style="[
                            isButtonDisabled
                                ? { cursor: 'not-allowed' }
                                : { cursor: 'pointer' }
                            ]">
                            Continue
                            <span class="">
                                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                            </span><i class="fa fa-next" v-if="!showLoader"></i>
                        </button>
                    </div>
                    <TableLoading v-show="showLoader"></TableLoading>
                </div>

                <!-- <TableLoading v-show="showLoader"></TableLoading> -->
            </section>
        </div>
    </MasterLayout>
</template>

<script>
import Vue from "vue";
import MasterLayout from "@/views/Employee/MasterLayout.vue";
import TableLoading from '@/components/TableLoading.vue';
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;

// import {
//   Page,
//   Sort,
//   Toolbar,
//   Search,
//   ExcelExport,
//   PdfExport
// } from "@syncfusion/ej2-vue-grids";

export default {
    title: 'Assessment/Appraisal - Step 2',
    components: {
        MasterLayout,
        TableLoading
    },
    // provide: {
    //     grid: [Page, Sort, Toolbar, Search ] //, ExcelExport, PdfExport]
    // },
    data(){
        return {
            aType: '',
            showLoader: false,
            isButtonDisabled: false,
            competencies: [],
            AllCompetencies: [],
            DivCompetencies: [],
            selectedCompetencies: [],
            competencyLevels: [],
            compGroupHeader: [
                {
                    key: 'index',
                    label: '#',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Competency',
                    class: 'text-left',
                    sortable: true
                },
                {
                    key: 'group',
                    label: 'Group Name',
                    sortable: true
                },
                // {
                //     key: 'CompetencyLevel',
                //     label: 'Competency Level',
                //     class: 'text-center',
                // }
            ],
            compSelectedHeader: [
                {
                    key: 'index',
                    label: '#',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Competency',
                    class: 'text-left',
                    sortable: true
                },
                {
                    key: 'group',
                    label: 'Group Name',
                    sortable: true
                },
                {
                    key: 'CompetencyLevel',
                    label: 'Competency Level',
                    class: 'text-center',
                }
            ],
            currentPage: 1,
            lowestIndex: null,
            highestIndex: null,
            selected: "Items per page",
            options: [25, 50, 100, 500],
            perPage: 25,
            totalRows: 1,
            userInfo: localStorage.getItem("dcasUserDetails")
                ? JSON.parse(localStorage.getItem("dcasUserDetails"))
                : null,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            //toolbar: ["Search"], // ["ExcelExport", "PdfExport", "Search"],
            //search: { operator: "contains", ignoreCase: true },       ==> Used by ejs
            search: "",
            filterBy: 1,
            checkBoxTemplate: function() {
                return {
                    template: Vue.component("columnTemplate", {
                        template: `<div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="data.selected" v-on:change="competencySelected($event, data.id)" checked />
                                        <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="!data.selected" v-on:change="competencySelected($event, data.id)" />
                                        <label class="custom-control-label" v-bind:for="data.id"></label>
                                    </div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            competencySelected: function(e, id) {
                                this.$eventHub.$emit("competenciesCheck", e, id);
                            }
                        }
                    })
                };
            },
            competencyLevelDropDownTemplate: function() {
                return {
                template: Vue.component("columnTemplate", {
                        template: '<select v-on:change="getLevel()" v-bind:data-cid="`${data.id}`" class="geLev" v-model="data.levelId">' +
                                '<option value="">Select Level</option>' +
                                '<option v-for="(cpLvl, i) in data.levels" v-bind:value="cpLvl.id" v-bind:key="cpLvl.id" ' +
                                '>{{cpLvl.level}}</option>'+
                                '</select>',
                    data() {
                        return {
                            data: {}
                        };
                    },                   
                    created(){
                    },
                    methods: {
                        selectAll: function(e) {
                            this.$eventHub.$emit("selectAll", e);
                        },
                        getLevel() {
                            console.log('Level Change :: Competency = ' + this.data.id + '; Level = ' + this.data.levelId);
                            this.$eventHub.$emit("doLevelSelectVM", this.data.id, this.data.levelId);
                        },
                    }
                })
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            }
        }
    },
    created(){
        this.getCompetencyLevels(); 
        this.$eventHub.$on("doLevelSelectVM", (event, id) => {
           this.doLevelSelect(event, id);
        });
        this.$eventHub.$on("competenciesCheck", (event, id) => {
           this.competencyClicked(event, id);
        });
        
    },
    mounted(){
        this.aType = this.$route.query.typ;
        this.getCompetencies();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    methods:{
        refreshGrid(){
            this.$refs.dataGrid.refresh();
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        filterList(){
            if(this.filterBy == 1){
                // Div Competencies
                this.competencies = this.DivCompetencies.filter((cp,i) => cp.name.toLowerCase().includes(this.search.toLowerCase()) 
                    || cp.group.toLowerCase().includes(this.search.toLowerCase()));
            }
            else{
                // All Competencies
                this.competencies = this.AllCompetencies.filter((cp,i) => cp.name.toLowerCase().includes(this.search.toLowerCase()) 
                    || cp.group.toLowerCase().includes(this.search.toLowerCase()));
            }
            
        },
        changeCompList(){
            if(this.filterBy == 1){
                this.competencies = this.DivCompetencies;
            }
            else{
                this.competencies = this.AllCompetencies;
            }
        },
        competencyClicked(event, id){
            let comp = this.competencies.findIndex(a => a.id === id);
            console.log('Competency Selected ' + id);
            if(comp !== -1){
                // Selected
                let _comp = this.competencies.find(a => a.id === id);
                //_comp.selected = true;
                this.selectedCompetencies.push(_comp);

                console.log('Competency Index ' + comp);
                this.competencies = this.competencies.filter(x => x.id !== id); //.selected = true;
                this.AllCompetencies = this.AllCompetencies.filter(x => x.id !== id);
                this.DivCompetencies = this.DivCompetencies.filter(x => x.id !== id);
                
                // if (event.target.clicked){
                    
                    
                // }
                // else{
                //     // Unselected
                //     this.competencies[comp].selected = false;
                //     //this.refreshGrid();
                // }
            }
        },
        competencyRemoved(event, id){
            let comp = this.selectedCompetencies.findIndex(a => a.id === id);
            console.log('Competency UnSelected ' + id);
            if(comp !== -1){
                let _comp = this.selectedCompetencies.find(a => a.id === id);
                if(_comp.employeeDivision){
                    this.DivCompetencies.push(_comp);
                }
                this.AllCompetencies.push(_comp);
                this.changeCompList();

                console.log('Competency Index to remove ' + comp);
                this.selectedCompetencies = this.selectedCompetencies.filter(x => x.id !== id);
            }
        },
        doLevelSelect(competencyId, compLvlId){
            let comp = this.competencies.findIndex(a => a.id === competencyId);            
            console.log('Level Changed [Comp Level Id: ' + compLvlId + '] [Comp ID: ' + competencyId + ']');

            if(comp !== -1){
                if(compLvlId > 0){
                    this.competencies[comp].selected = true;
                    this.competencies[comp].levelId = compLvlId;
                }
                else{
                    this.competencies[comp].selected = false;
                    this.competencies[comp].levelId = 0;
                }
                //this.refreshGrid();
            }
        },
        saveAssessment(){
            //this.showLoader = true;
            this.isButtonDisabled = true;
            //let comps = this.selectedCompetencies;
            let comps = [];
            this.selectedCompetencies.forEach(element => {
                comps.push({
                    "competencyId": element.id,
                    "competencyLevelId": element.levelId
                });
            });

            if(comps.filter(a => a.competencyLevelId <= 0)[0]){
                this.$toasted.show("Please select Competency Level for all selected competencies", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
            }
            else{
                let conf = confirm('Are you sure you want to proceed with ' + comps.length + ' competencies selected?');
                if(conf === true){            
                    let sa = {};
                    sa.assessmentId = parseInt(this.$route.query.id);
                    sa.employeeId = parseInt(this.userInfo.employeeId);
                    sa.Competencies = comps;
                    console.log(sa);

                    let url = `${configObject.apiBaseUrl}/Employee/SelfAssessment/Step2`;
                    this.axios.post(url, sa, configObject.authConfig) //data, configObject.authConfig)
                    .then((res) => {
                        console.log(res.data);
                        if(res.data.id > 0){
                            this.isButtonDisabled = false;
                            this.$toast.success(`${this.aType === 'ass' ? 'Training' : 'Assessment'} Saved Succssfully!`);
                            this.$router.push({
                                name: 'step_three',
                                query: {
                                    id: this.$route.query.id,
                                    typ: this.aType
                                }
                            });
                        }
                    })
                    .catch((error) => {
                        this.isButtonDisabled = false;
                        //this.showLoader = false;
                        this.$toast.open({
                            message: `Unable to save ${this.aType === 'ass' ? 'Training' : 'Assessment'}, please try again`,
                            type: "error",
                            duration: 4000
                        });
                        console.log(error);
                    });
                }
            }
            this.isButtonDisabled = false;
        },
        getCompetencyLevels(){
            this.axios.get(`${configObject.apiBaseUrl}/Competency/Level`, configObject.authConfig)
            .then(response => {
                this.competencyLevels = response.data;
            })
            .catch(error => {
                console.log(error);
                // this.$toast.open({
                //     message: "Unable to load Competency Levels",
                //     type: "error",
                //     duration: 4000
                // });
            });
        },
        getCompetencies() {
            let grid = this.$refs.dataGrid;
            this.showLoader = true;
            let _competencies = [];
            let _divCompetencies = [];
            this.axios.get(`${configObject.apiBaseUrl}/Competency/ForMapToEmployee/${this.userInfo.employeeId}?assessmentId=${parseInt(this.$route.query.id)}`, configObject.authConfig)
                .then(response => {
                    this.showLoader = false;
                    console.log(response.data.competencies);
                    response.data.competencies.forEach(element => {
                        if(!element.deleted)
                        {
                            element.levels = this.competencyLevels;
                            //element.selected = false;
                            if(element.selected){
                                this.selectedCompetencies.push(element);
                            }
                            else{
                                _competencies.push(element);
                                if(element.employeeDivision){
                                    _divCompetencies.push(element);
                                }
                            }
                        }
                    });
                    //ff
                    console.log(response.data);
                    this.competencies = _divCompetencies;
                    this.AllCompetencies = _competencies;
                    this.DivCompetencies = _divCompetencies;
                    //grid.ej2Instances.setProperties({ dataSource: this.competencies });
                    this.refreshGrid();
                })
                .catch((error) => {
                    this.showLoader = false;
                    console.log(error);
                    // this.$toast.open({
                    //     message: "unable to get competencies",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
            
        },        
        backToStepOne(){
            this.$router.push({
                name: 'step_one', 
                query: {
                    id: this.$route.query.id,
                    typ: this.$route.query.typ
                }
            });
        },
    }
}
</script>