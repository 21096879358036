<template>
    <MasterLayout>
        <div class="employees__assessment__wrapper">

            <div class="welcome_message">
                <div class="row">
                    <div class="col-7">    
                        <h5 class=''><span class="greeeting__text">Downline Assessment/Trainings
                            </span> <!-- you have <span class="branches__count"> {{profiles.length}} </span> Profiles -->
                        </h5>
                        
                            <!-- Showing <span class="branches__count"> {{employees.length}} </span> 
                            <span v-if="employees.length > 0">employees</span><span v-if="employees.length < 2">employee</span> found for the Role 
                            <span class="branches__count">{{designation.designationName}}</span> -->
                            
                    </div>
                    <div class="col-5 text-right">
                        
                    </div>
                </div>
            </div>

            <section class="table__section mt-1 pb-5" v-show="approvals.length > 0 || !showLoader">
                 <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick"
                    :dataSource="approvals">
                    <e-columns>
                        <e-column field="index" headerText="#" width="60px"></e-column>
                        <e-column field="name" headerText="Name" width="150px"></e-column>
                        <e-column field="type" headerText="Type" width="120px"></e-column>
                        <!-- <e-column field="trainingDate" headerText="Training Date" width="120px"></e-column> -->
                        <e-column field="dateAdded" headerText="Date" width="120px"></e-column>
                        <!-- <e-column field="employeeComment" width="200px" headerText="Comment"></e-column> -->
                        <e-column field="status" :visible='false' headerText="Status" width="100px"></e-column>
                        <e-column :template="aStatusTemplate" headerText="Status" width="100px"></e-column> 
                        <e-column field="competencyCount" headerText="Competencies" width="120px" class="text-right"></e-column>
                        <e-column field="documentCount" headerText="Documents" width="100px"></e-column>
                        <e-column :template="actionButtonsTemplate" width="80px" headerText="Actions"></e-column>
                        <!-- <e-column width="50px" headerText=""></e-column> -->
                    </e-columns>
                </ejs-grid>
            </section>
            <div class="empty_branch__div mt-3" v-if="approvals.length < 1 && !showLoader">
                <div class="text-center">
                    <h6 class="text-center"> There are no Approvals waiting from any Downlines</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
        </div>

    </MasterLayout>
</template>
<script>

import Vue from "vue";
import MasterLayout from "@/views/Employee/MasterLayout.vue";
import TableLoading from '@/components/TableLoading.vue'; 
import configObject from "@/config";
import JQuery from "jquery";
import moment from "moment"
let $ = JQuery;

import {
    Page,
    Sort,
    Toolbar,
    Search,
    ExcelExport,
    PdfExport
} from "@syncfusion/ej2-vue-grids";


export default {
    name: 'Downlines',
    components:{
        MasterLayout,
    },
    provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data () {
        return {
            showLoader: false,
            approvals: [],
            pageSettings: { pageSizes: [25, 50, 100, 200], pageCount: 25 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            isSupervisor: !localStorage.getItem("dcasUserDetails")
            ? false 
            : JSON.parse(localStorage.getItem("dcasUserDetails")).roles.includes('Supervisor') ? true : false,
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        `<div class="btn-group btn-group-sm" role="group">
                            <button type="button" class="btn btn-info btn-xs" title="Process Assessment" v-on:click="_processAssessment(data.id, data.employeeId)">
                                <i class="fa fa-eye" aria-hidden="true"></i>
                            </button>
                        </div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _processAssessment(assId, empId) {
                                this.$eventHub.$emit("processAssessmentVM", assId, empId);
                            },
                        }
                    })
                };
            },            
            aStatusTemplate(){
                return {
                    template: Vue.component("aStatusTemplate", {
                        template:
                        '<div><div v-if="data.supervisor !== true">'+
                            `<b-badge pill variant="secondary" v-if="data.uplineAction === 'Pending'">{{data.uplineAction}}</b-badge>` +
                            `<b-badge pill variant="success" v-if="data.uplineAction === 'Approved'">{{data.uplineAction}}</b-badge>` +
                            `<b-badge pill variant="danger" v-if="data.uplineAction === 'Not Approved'">{{data.uplineAction}}</b-badge>` +
                        '</div>' +
                        '<div v-if="data.supervisor === true">'+
                            `<b-badge pill variant="secondary" v-if="data.supervisorAction === 'Pending'">{{data.supervisorAction}}</b-badge>` +
                            `<b-badge pill variant="success" v-if="data.supervisorAction === 'Approved'">{{data.supervisorAction}}</b-badge>` +
                            `<b-badge pill variant="danger" v-if="data.supervisorAction === 'Not Approved'">{{data.supervisorAction}}</b-badge>` +
                        '</div></div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                };
            },
        }
    },
    mounted() {
        //this.getProfiles();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created () {
        this.loadApprovals();
        this.$eventHub.$on("processAssessmentVM", (assId, empId) => {
            this.processAssessment(assId, empId);
        });
    },
    methods: {
        toolbarClick(){

        },
        loadApprovals(){
            this.showLoader = true;
            //let grid = this.$refs.dataGrid;
            this.axios.get(`${configObject.apiBaseUrl}/Employee/UplineSelfAssessments`, configObject.authConfig)
                .then(response => {
                    let index = 0;
                    response.data.forEach(a => {
                        index++;
                        a.index = index;
                        a.dateAdded = moment(a.dateAdded).format('DD-MMM-YYYY');
                        a.name = `${a.firstName} ${a.surname}`;
                        a.type = a.assessmentType === "Training" ? "Training" : "Assessment";
                        a.supervisor = this.isSupervisor;
                    });
                    console.log(response.data);
                    this.approvals = response.data;
                    this.showLoader = false;

                    //grid.ej2Instances.setProperties({ dataSource: this.approvals });
                    this.refreshGrid();
                })
                .catch((error) => {
                    this.showLoader = false;
                    console.log('ERROR ::: ' + error);
                    this.$toast.open({
                        message: "unable to load profiles",
                        type: "error",
                        duration: 3000
                    });
                });
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        processAssessment(assId, empId){
            this.$router.push({
                name: 'process_downline',
                query: {
                    id: assId,
                    emp: empId
                }
            });
        },
    }
}
</script>