<template>
<modal name="usedCompetencyGroupModal" transition="pop-out" :width="modalWidth" :height="300">
  <div class="modal__header">
      <h4>List of dependent Competencies</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-12">
                <div class="" style="width: 100%">
                    <!-- <span>List of competencies using this group</span> -->
                    <div style="display: flex; flex-direction: column;">
                        <a :href="`${baseUrl}/edit_competency?id=${group.id}`" target="_blank" class="input___group" style="text-decoration: none;" v-for="(group, i) in usedGroup" :key="i">
                            {{group.name}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 650;
import configObject from "@/config";

export default {
  name: 'usedCompetencyGroupModal',
  props: ['usedGroup'],
  data () {
    return {
      modalWidth: MODAL_WIDTH,
      name: null,
      isButtonDisabled:  false,
      showLoader: false,
    }
  },
  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

    console.log(this.$store.getters.getBranchdetails);
  },
  mounted(){
  },
  computed: {
      baseUrl() {
          return window.location.origin
      }
  },
  methods: {
    closeCreateCompetecncyGroup(){
        this.$modal.hide('usedCompetencyGroupModal');
    },
    
  }
}
</script>
