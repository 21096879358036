<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">

            <div class="welcome_message">
                <div class="row">
                    <div class="col-8">    
                        <h5 class=''><span class="greeeting__text"> Hi Admin,</span>
                    </h5> 
                    </div>
                    <div class="col-4 text-right">
                    <div>
                        <button class="creaet_branch_btn default" @click="backToAssessment"><i class="fa fa-arrow-left"></i> Back</button>
                        <button class="creaet_branch_btn" @click="saveAssessment"
                            :disabled="isButtonDisabled ? true : null"
                            :style="[
                            isButtonDisabled
                                ? { cursor: 'not-allowed' }
                                : { cursor: 'pointer' }
                            ]">
                            Save Assessment 
                            <span class="">
                                <img src="@/assets/img/git_loader.gif" v-if="wait"  width="25px"/>
                            </span><i class="fa fa-save ml-2" v-if="!wait"></i>
                        </button>                        
                    </div>
                    </div>
                </div>
            </div>
            <section class="table__section mt-1 pb-5">
                <div class="cas_input mt-3">
                    <div class="row__wrapper">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="" style="width: 100%">
                                            <span>Training Name</span>
                                            <div class="">
                                                <input type="text" placeholder="Enter Training name here" v-model="trainingName"  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="" style="width: 100%">
                                            <span>Training Date</span>
                                            <div class="">
                                                <input type="date" placeholder="" v-model="trainingDate"  />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="" style="width: 100%">
                                            <span>General Commments</span>
                                            <div class="">
                                                <textarea type="text" placeholder="Enter general comment here" class="" v-model="comment"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="" style="width: 100%">
                                    <span>Assessment Evidences</span>
                                    <div class="">
                                         <input type="file" name="file1" id="file1" ref="file1" class="mb-2" required />
                                        <input type="file" name="file2" id="file2" ref="file2" class="mb-2"  />
                                        <input type="file" name="file3" id="file3" ref="file3" class="mb-2" />
                                        <!-- <input type="file" name="file1" id="file1" ref="file1" class="mb-2" required v-on:change="handleFileUpload($event, 'f1')"/>
                                        <input type="file" name="file2" id="file2" ref="file2" class="mb-2" v-on:change="handleFileUpload($event, 'f2')"/>
                                        <input type="file" name="file3" id="file3" ref="file3" class="mb-2" v-on:change="handleFileUpload($event, 'f3')"/> -->
                                        <!-- <b-form-file
                                            class="mb-3"
                                            v-model="file1"
                                            size="sm"
                                            :state="Boolean(file1)"
                                            placeholder="Choose a file or drop here"
                                            drop-placeholder="Drop file here..."
                                            accept="image/jpeg, image/png, image/gif, application/pdf"></b-form-file> -->

                                        <!-- <b-form-file
                                            class="mb-3"
                                            v-model="file2"
                                            size="sm"                                            
                                            placeholder="Choose a file or drop here"
                                            drop-placeholder="Drop file here..."
                                            accept="image/jpeg, image/png, image/gif, application/pdf"></b-form-file>
                                        <b-form-file
                                            class="mb-3"
                                            v-model="file3"
                                            size="sm"
                                            placeholder="Choose a file or drop here"
                                            drop-placeholder="Drop file here..."
                                            accept="image/jpeg, image/png, image/gif, application/pdf"></b-form-file> -->
                                            <br />
                                        <small><i>Please provide atleast one (1) evidence for this assessment and max of three (3). Each file cannot be more than 4MB, and can only be a image file (.jpg, .png) or PDF (.pdf) file</i></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div class="mt-3" v-show="competencies.length > 0">
                    <ejs-grid
                        ref="dataGrid"
                        :created="refreshGrid"
                        :allowPaging="true"
                        :allowSorting="true"
                        :pageSettings="pageSettings"
                        :toolbar="toolbar"
                        :searchSettings="search"
                        :allowExcelExport="true"
                        :allowPdfExport="true"
                        :toolbarClick="toolbarClick">
                        <e-columns>
                            <e-column headerText="#" :template="checkBoxTemplate" width="50px"></e-column>
                            <e-column field="name" headerText="Competency" width="400"></e-column>
                            <e-column :template="competencyLevelDropDownTemplate" headerText="Competency Level" width="120px"></e-column>
                            <e-column :template="deletedTemplate" width="50px" headerText="Deleted"></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
                <TableLoading v-show="showLoader"></TableLoading>
            </section>
            <div class="empty_branch__div mt-3" v-show="competencies.length < 1"> 
                <div class="text-center">
                    <h6 class="text-center"> Please wait while we get competencies</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import TableLoading from '@/components/TableLoading.vue';
import MasterLayout from "@/views/Employee/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    components: {
        MasterLayout,
        TableLoading
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            showLoader: false,
            isButtonDisabled: false,
            competenciesFetched: false,
            wait: false,
            backgroundUrl,
            UserDP,
            competencyId: null,
            competencLevelId: null,
            trainingDate: null,
            trainingName: null,
            comment: null,
            newMappedReqArray: [],
            competenciesArray: [],
            competencyLevels: [],
            competencies: [],
            file1: '',
            file2: null,
            file3: null,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            userInfo: localStorage.getItem("dcasUserDetails")
                ? JSON.parse(localStorage.getItem("dcasUserDetails"))
                : null,
            checkBoxTemplate: function() {
                return {
                    template: Vue.component("columnTemplate", {
                        template: `<div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="data.selected" v-on:change="competencySelected($event, data.id)" checked />
                                        <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="!data.selected" v-on:change="competencySelected($event, data.id)" />
                                        <label class="custom-control-label" v-bind:for="data.id"></label>
                                    </div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            competencySelected: function(e, id) {
                                this.$eventHub.$emit("competenciesCheck", e, id);
                            }
                        }
                    })
                };
            },
            competencyLevelDropDownTemplate: function() {
                return {
                template: Vue.component("columnTemplate", {
                    // template:  `<select v-on:change="getLevel($data.data.id)" v-model="level">
                    //                <option v-bind:value="cpLvl.id" v-for="(cpLvl, i) in competencyLevels" v-bind:key="i">{{cpLvl.level}}</option>
                    //             </select>` + 
                        template: '<select v-on:change="getLevel()" v-bind:data-cid="`${data.id}`" class="geLev" v-model="data.levelId">' +
                                '<option value="">Select Level</option>' +
                                '<option v-for="(cpLvl, i) in data.levels" v-bind:value="cpLvl.id" v-bind:key="cpLvl.id" ' +
                                '>{{cpLvl.level}}</option>'+
                                '</select>',
                    data() {
                        return {
                            // competencyLevels: [],
                            // level: "",
                            // selectedCompDetailsArray: [],
                            data: {}
                        };
                    },
                   
                    created(){
                        //this.getCompetencyLevels();
                    },
                    methods: {
                        selectAll: function(e) {
                            this.$eventHub.$emit("selectAll", e);
                        },
                        getLevel() {
                            console.log('Level Change :: Competency = ' + this.data.id + '; Level = ' + this.data.levelId);
                            this.$eventHub.$emit("doLevelSelectVM", this.data.id, this.data.levelId);
                        },
                        // getLevel(competencyId) {
                        //     this.$eventHub.$emit("getLevel", competencyId, this.level);
                        // },
                        // getCompetencyLevels() {
                        //     let __this = this;
                        //     this.axios
                        //         .get(
                        //         `${configObject.apiBaseUrl}/Competency/Level`,
                        //         configObject.authConfig
                        //         )
                        //         .then(response => {
                        //             __this.competencyLevels = response.data;
                        //         })
                        //         .catch(error => {
                        //             console.log(error);
                        //             this.$toast.open({
                        //                 message: "unable to lpoad competency levels",
                        //                 type: "error",
                        //                 duration: 3000
                        //             });
                        //         });
                        // }
                    }
                })
                };
            },
            selectAllTemplate: function() {
                return {
                template: Vue.component("columnTemplate", {
                    template: `<div><input type="checkbox" v-on:change="selectAll($event)"/></div>`,
                    data: function() {
                        return {
                            data: {}
                        };
                    },
                    methods: {
                    selectAll: function(e) {
                        this.$eventHub.$emit("selectAll", e);
                    }
                    }
                })
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            }
        }
    },
    created(){
        this.getCompetencyLevels();
        this.$eventHub.$on("competenciesCheck", (event, id) => {
           this.createMappedcompetenciesArray(event, id);
        }); 

        // this.$eventHub.$on("getLevel", (competencyId, compLvlId) => {
        //     this.getLevel(competencyId, compLvlId);
        // });

        this.$eventHub.$on("doLevelSelectVM", (compId, compLvId) => {
            this.doLevelSelect(compId, compLvId);
        })
    },
    mounted() {
        console.log('Second CALL');
        this.getCompetencies();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    methods: {
        getLevel_(competencyId,compLvlId){
            let cpId = parseInt(competencyId);
            let checkCpIndex = this.$store.getters.getSelectedCompLevelsArray.findIndex(cps => cps.compId === cpId);
            
            if(checkCpIndex === -1){
                const payload = {
                    compId: cpId,
                    competencyLevelId: compLvlId
                }
                this.$store.commit("setSelectedCompLevelsArray" , payload)
            }else {
                const payload = {
                    compIndex: checkCpIndex, 
                    compsProps: {
                        compId: cpId,
                        competencyLevelId: compLvlId
                    }
                }
               this.$store.commit("updateCompsDropDownArray", payload);
            }
        },
        doLevelSelect(competencyId, compLvlId){
            let comp = this.competencies.findIndex(a => a.id === competencyId);
            
            if(comp !== -1){
                if(compLvlId > 0){
                    this.competencies[comp].selected = true;
                    this.competencies[comp].levelId = compLvlId;
                }
                else{
                    this.competencies[comp].selected = false;
                    this.competencies[comp].levelId = 0;
                }
                this.refreshGrid();
            }
        },
        createMappedcompetenciesArray(event, id){
            // if (!event.target.checked) {
            //     let newMappedReqArray = this.competenciesArray.filter(requirement => requirement.id !== reqInfo.id);
            //     this.competenciesArray = newMappedReqArray;
            // } else {
            //     this.competenciesArray.push({
            //         id: reqInfo.id
            //     });
            // }

            let comp = this.competencies.findIndex(a => a.id === id);
            if(comp !== -1){
                if (event.target.checked){
                    // Selected
                    this.competencies[comp].selected = true;
                    this.refreshGrid();
                }
                else{
                    // Unselected
                    this.competencies[comp].selected = false;
                    this.refreshGrid();
                }
            }
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        saveAssessment(){
            this.wait = true;
            this.isButtonDisabled = true;
            let dataArray = [];
            this.competencies.forEach(c => {
                if(c.selected){
                    dataArray.push({
                        "competencyId": c.id,
                        "competencyLevelId": c.levelId
                    })
                }
            });
            if(dataArray.length < 1){
                this.$toast.open({
                    message: "Please select atleast one competency (and its level) before proceeding",
                    type: "error",
                    duration: 4000
                });
                this.wait = false;
                this.isButtonDisabled = false;
                return;
            }
            var check = dataArray.findIndex(a => a.competencyId === 0 || a.competencyLevelId === 0);
            //console.log(check);
            if(check >= 0){
                this.$toast.open({
                    message: "Please ensure that competency level is selected for all selected competency(ies)",
                    type: "error",
                    duration: 4000
                });
                this.wait = false;
                this.isButtonDisabled = false;
                return;
            }
            // let data = {};
            // data.designationId = parseInt(this.$route.query.id);
            // data.competencies = dataArray;
            let sa = {};
            sa.Id = 0;
            sa.employeeId = this.userInfo.employeeId;
            sa.employeeComment = this.comment;
            sa.trainingName = this.trainingName;
            sa.trainingDate = this.trainingDate;
            sa.status = 'Pending';
            sa.competencies = dataArray;

            // var data = {};
            // data.SelfAssessment = sa;
            // data.file1 = this.file1;
            // data.file2 = this.file2;
            // data.file3 = this.file3;

            //let formData = new FormData();
            //formData.append('SelfAssessment', data);
            // formData.append('employeeId', this.userInfo.employeeId);
            // formData.append("employeeComment", this.comment);
            // formData.append("trainingName", this.trainingName);
            // formData.append("trainingDate", this.trainingDate);
            // //formData.append("status", "pending");
            // formData.append("competencies", dataArray);
            // formData.append("file1", file1);
            // formData.append("file2", file2);
            // formData.append("file3", file3);
            // formData.append("documents", [
            //         this.file1,
            //         this.file2,
            //         this.file3
            //     ]);
            console.log(sa);

            let url = `${configObject.apiBaseUrl}/Employee/SelfAssessment/Add`;
            this.axios.post(url, sa, configObject.authConfigForUpload) //data, configObject.authConfig)
                .then((res) => {
                    console.log(res.data);
                    this.wait = false;
                    this.isButtonDisabled = false;
                    this.$toast.success("Self Assessement Saved Succssfully!");
                    })
                    .catch((error) => {
                        this.isButtonDisabled = false;
                        this.wait = false;
                        this.$toast.open({
                            message: "unable to save self assessments",
                            type: "error",
                            duration: 3000
                        });
                        console.log(error);
                });
        },
        saveAssessment_old() {
            let compsArray = this.$store.getters.getSelectedCompLevelsArray;
            let newCompsArry = [];
            compsArray.forEach(cpArr => {
                newCompsArry.push({
                    "competencyId": parseInt(cpArr.compId),
                    "competencyLevelId": parseInt(cpArr.competencyLevelId)
                })
            })
            this.showLoader = true;
            this.isButtonDisabled = true;
            let mappedcompetenciesArrayToSend = [];
            this.competenciesArray.forEach((req) => {
                mappedcompetenciesArrayToSend.push(req.id);
            });
            let data = {
                selfAssessment: {
                     "employeeId": this.userInfo.employeeId,
                     "employeeComment": this.comment,
                     "competencies": newCompsArry,
                     "status": "not approved"
                }
            };
            if(this.competenciesArray.length < 1 &&  data.selfAssessment.competencies.length < 1){
                this.$toast.open({
                    message: "Please check competency and  select competency level to add",
                    type: "error",
                    duration: 3000
                });
                this.showLoader = false;
                this.isButtonDisabled = false;
                return;
            }
            if(this.competenciesArray.length !== data.selfAssessment.competencies.length){
                this.$toast.open({
                    message: "Please check competency and  select competency level to add",
                    type: "error",
                    duration: 3000
                });
                this.showLoader = false;
                this.isButtonDisabled = false;
                return;
            }else {
                let compIDs = [];
                this.competenciesArray.forEach(cp => {
                    compIDs.push(cp.id);
                })
                data.selfAssessment.competencies.forEach(dcp => {
                    if(!compIDs.includes(dcp.competencyId)){
                         this.$toast.open({
                            message: "Please add competency Levels to the checked competencies",
                            type: "error",
                            duration: 3000
                        });
                        this.showLoader = false;
                        this.isButtonDisabled = false;
                        return;
                    }
                })
            }
            console.log(data);
            console.log(this.competenciesArray);
            
            let formData = new FormData();
            formData.append('file', this.file);
            let url = `${configObject.apiBaseUrl}/Employee/SelfAssessment/Add`;
            this.axios.post(url, formData, configObject.authConfigForUpload) //data, configObject.authConfig)
                .then((res) => {
                    console.log(res.data);
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    this.$toast.success("Self Assessement Saved Succssfully!");
                    })
                    .catch((error) => {
                        this.isButtonDisabled = false;
                        this.showLoader = false;
                        this.$toast.open({
                            message: "unable to save self assessments",
                            type: "error",
                            duration: 3000
                        });
                        console.log(error);
                });
        },        
        getCompetencyLevels(){
            this.axios.get(`${configObject.apiBaseUrl}/Competency/Level`, configObject.authConfig)
            .then(response => {
                this.competencyLevels = response.data;
            })
            .catch(error => {
                console.log(error);
                // this.$toast.open({
                //     message: "Unable to load Competency Levels",
                //     type: "error",
                //     duration: 4000
                // });
            });
        },
        getCompetencies() {
            let grid = this.$refs.dataGrid;
            this.showLoader = true;
            this.axios.get(`${configObject.apiBaseUrl}/Competency/ForMapToEmployee/${this.userInfo.employeeId}`, configObject.authConfig)
                // .get(
                //     `${configObject.apiBaseUrl}/Competency`,
                //     configObject.authConfig
                // )
                .then(response => {
                    this.showLoader = false;
                    response.data.forEach(element => {
                        element.levels = this.competencyLevels;
                        element.selected = false;
                        element.levelId = 0;
                    });
                    //console.log(response.data);
                    this.competencies = response.data;
                    grid.ej2Instances.setProperties({ dataSource: this.competencies });
                    this.refreshGrid();
                })
                .catch((error) => {
                    this.showLoader = false;
                    console.log(error);
                    // this.$toast.open({
                    //     message: "unable to get competencies",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
            
        },
        
        handleFileUpload(sender, fl){
            console.log(fl);
            var validExts = new Array(".pdf", ".png", ".jpg");
            var file = sender.target.value;
            let fileExt = file.substring(file.lastIndexOf('.'));
            let extIndex = validExts.indexOf(fileExt);
            console.log('Ext = ' + fileExt + '; Ext Index = ' + extIndex);
            if (extIndex < 0) {
                sender.target.value = '';
                alert('Invalid file selected, valid files are of "' + validExts.toString() + '" types.');
                return false;
            }
            else {
                if(fl === 'f1'){
                    this.file1 = sender.target.value;
                }
                else if(fl === 'f2'){
                    this.file2 = sender.target.value;
                }
                else if(fl === 'f3'){
                    this.file3 = sender.target.value;
                }
                return true;
            }

        },
        backToAssessment(){
            this.$router.push({
                name: 'self_assessment'
            });
        }
    }
}
</script>

        