<template>
    <MasterLayout>
        <div class="profile__section__outer_wrapper" style="margin-top
        :0">
      <div class="account__header__banner">
        <div
          class="account_settings_banner "
          :style="[
            {
              backgroundImage: `linear-gradient(rgb(12, 4, 31 , 0.7), rgb(12, 4, 31, 0.7)), url(${backgroundUrl})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }
          ]">
          <div class="banner__text_section">
            <h3 class="banner__text">Change Password</h3>
          </div>
        </div>
        <div class="account_Settings_profile_details_section_row">
          <div class="account_Settings_profile_details_section_first_row">
                <img v-bind:src="'' + avatar"  class="profile__pic" />
                <!-- <img src="@/assets/img/profile_pic.png" class="profile__pic" /> -->
          </div>
          <div class="account_Settings_profile_details_section_second_row">
            <h5 class="profile__detail__header mt-2 ml-3">
              {{userName}}<br />
              <small>{{email}}</small>
            </h5>
          </div>
        </div>
      </div>
      <div class="account_Settings_profile_details_section">
        <div class="profile__form_section">
          <div class="row">
            <div class="col-md-6 profile__edit_form_section" style="display: flex;
                align-items: center; justify-content
                : center" :style="[
                {
                  backgroundImage: `linear-gradient(rgb(9, 140, 40 , 0.7), rgb(9, 140, 40 , 0.7)), url(${backgroundUrl})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }
              ]">

          <div>
              <h3 class="text-white text-center" style="color: #fff !important;font-size: 20px;line-height: 1.7;">Fill in the Fields in the right <br>
              section to change your pasword <br></h3>
          </div>
            
            </div>
            <div class="col-md-6 left-section  change_password__form__section">
              <transition name="" mode="out-in" appear>
                <section class="profile__change_password_form_section">
                  <div>
                    <transition name="fade" mode="out-in" appear>
                      <h3 class="login_header text-center">
                        Change your Password
                      </h3>
                    </transition>
                  </div>
                  <div class="form_section mt-3">
                     <section class="login__section__Wrapper mt-5">
                        <form @submit="validateSignUpForm($event)">
                            <div class="login___section__container" style='width: 100%'>
                              
                                <div class="login__input mt-4" ref="old_password_input_div">
                                    <span>Old Password</span>
                                    <div class="input___group">
                                        <img src="@/assets/img/password-icon.svg" class="ml-3" width="20px" />
                                        <input type="password" placeholder="Old Password" class="ml-3"
                                               ref="old_pass__input"  v-model="oldPass" />
                                        <div>
                                            <i class="fa fa-eye-slash" aria-hidden="true" @click="toggleOldPassword" v-if="showOldPassword"></i>
                                            <i class="fa fa-eye" aria-hidden="true" @click="toggleOldPassword" v-if="!showOldPassword"></i>
                                        </div>
                                    </div>
                                </div>
                                <div ref="old_password_error_message" class="error-validation text-center mt-4"></div>
                                <div class="login__input mt-5" ref="new_password_input_div">
                                    <span>New Password</span>
                                    <div class="input___group">
                                        <img src="@/assets/img/password-icon.svg" class="ml-3" width="20px" />
                                        <input type="password" placeholder="New password" class="ml-3"
                                               ref="new_pass__input"  v-model="newPass" />
                                        <div>
                                            <i class="fa fa-eye-slash" aria-hidden="true" @click="toggleNewPassword" v-if="showNewPassword"></i>
                                            <i class="fa fa-eye" aria-hidden="true" @click="toggleNewPassword" v-if="!showNewPassword"></i>
                                        </div>
                                    </div>
                                </div>
                             
                                    <div class="login__input mt-5" ref="old_password_input_div">
                                    <span>Confirm Password</span>
                                    <div class="input___group">
                                        <img src="@/assets/img/password-icon.svg" class="ml-3" width="20px" />
                                        <input type="password" placeholder="New password" class="ml-3"
                                               ref="conf_pass__input" v-model="confPass" />
                                        <div>
                                            <i class="fa fa-eye-slash" aria-hidden="true" @click="toggleConfPassword" v-if="showConfPassword"></i >
                                            <i class="fa fa-eye" aria-hidden="true" @click="toggleConfPassword" v-if="!showConfPassword"></i>
                                        </div>
                                    </div>
                                </div>
                                <div ref="old_password_error_message" class="error-validation text-center"></div>
                            </div>
                          
                            <div class="text-center mt-5">
                                <button type="submit" class="login__btn"
                                        @click="changePassword($event)"
                                        :disabled="isButtonDisabled ? true : null"
                                        :style="[
                                        isButtonDisabled
                                            ? { cursor: 'not-allowed' }
                                            : { cursor: 'pointer' }
                                        ]">Submit
                                        <span class="">
                                            <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                                        </span>
                                </button>
                            </div>
                        </form>
                    </section>
                  </div>
                </section>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Employee/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
import CreateUnitModal from '@/components/Modals/Unit/createUnitModal.vue';
import editUnitModal from '@/components/Modals/Unit/editUnitModal.vue';
import configObject from "@/config";
import Avatar from "@/assets/img/avatar.svg";
import JQuery from "jquery";

let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    components: {
        MasterLayout,
        CreateUnitModal,
        editUnitModal,
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            backgroundUrl,
            UserDP,
            componentKey: 0,
            units: [],
            unit: {},
            showLoader: false,
            oldPass: null,
            newPass: null,
            confPass: null,
            userName: null,
            email: null,
            isButtonDisabled: false,
            showOldPassword: false,
            showNewPassword: false,
            showConfPassword: false,
            avatar: null
        }
    },
   mounted() {
       this.loadUser();
   },
    methods: {
        loadUser(){
            if(localStorage.getItem("dcasUserDetails"))
            {
                let userInfo = JSON.parse(localStorage.getItem("dcasUserDetails"));
                this.userName = `${userInfo.firstName} ${userInfo.lastName}`;
                this.email = ` ${userInfo.email}`;
                this.avatar = userInfo.avatar;
            }
        },
        
         changePassword($event) {
            $event.preventDefault();
            if (!this.oldPass || !this.newPass || !this.confPass) {
                this.$toasted.show("Fill in the missing Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
               return;
            }else {
                if(this.newPass !== this.confPass){
                     this.$toasted.show("New Password must match Confirmed Password", {
                        theme: "bubble",
                        position: "top-center",
                        duration: 2000
                    });
                    return;
                }
                 let dataToSend = {
                    oldPassword: this.oldPass,
                    newPassword: this.newPass,
                    confirmPassword: this.confPass
                }
                console.log(dataToSend);
                this.showLoader = true;
                this.isButtonDisabled = true;
                this.axios.post(`${configObject.apiBaseUrl}/Account/ChangePassword`, dataToSend, configObject.authConfig)
                .then(response => {
                    console.log(response.data);
                    this.$toast.success("Password Change  Successful");
                    this.showLoader = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.$toasted.show("Request Failed", {
                        theme: "bubble",
                        position: "top-center",
                        duration: 4000
                    });
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                });
            }
           
        },
        toggleOldPassword() {
            if(this.$refs.old_pass__input.type === 'password'){
                this.$refs.old_pass__input.type = 'text'
                this.showOldPassword = true;
            }else {
                this.$refs.old_pass__input.type = 'password';
                this.showOldPassword = false;
            }
        },
         toggleNewPassword() {
            if(this.$refs.new_pass__input.type === 'password'){
                this.$refs.new_pass__input.type = 'text'
                this.showNewPassword = true;
            }else {
                this.$refs.new_pass__input.type = 'password';
                this.showNewPassword = false;
            }
        },
         toggleConfPassword() {
            if(this.$refs.conf_pass__input.type === 'password'){
                this.$refs.conf_pass__input.type = 'text'
                this.showConfPassword = true;
            }else {
                this.$refs.conf_pass__input.type = 'password';
                this.showConfPassword = false;
            }
        },
       
    }
}
</script>

        