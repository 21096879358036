<template>
    <MasterLayout>
        <div class="cas_input">
            <div class="row__wrapper">
                <div class="row">
                    <div class="col-6">
                        <h5 class=''>
                            <span class="greeeting__text"> Hi Admin,</span> Editing Competency
                        </h5>
                    </div>
                    <div class="col-6 text-right">
                        <div>
                            <button class="creaet_branch_btn default" @click="backToCompetencies"><i class="fa fa-arrow-left mr-2"></i>Back</button>
                        </div>
                    </div>
                    <div class="col-12"><hr /></div>
                    <div class="col-md-6">
                        <div class="" style="width: 100%">
                            <span>Name</span>
                            <div class="mt-1">
                                <input type="text" placeholder="Enter a competency name" class="ml-3" v-model="compName"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="" style="width: 100%">
                            <span>Select competency group</span>
                            <div class=" mt-1">
                                <select ref="compGpr" class="ml-3" v-model="groupId">
                                    <option disabled value="selectCompetencyGroup">Select competency group</option>
                                    <option :value="compGroup.id" v-for="(compGroup,i) in competencyGroups" :key="i"  required>{{compGroup.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-4">
                        <div class="" style="width: 100%">
                            <span class="mb-2" style="display: block;">Description <small><i>(Max 4000 characters)</i></small></span>
                            <div class=" mt-1">
                                <vue-editor v-model="description" placeholder="Enter Descriptions here" :editorToolbar="customToolbar"></vue-editor>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-4">
                        <div class="" style="width: 100%">
                            <span class="">Divisions</span>
                            <div class="mt-1" v-if="divisions.length && !loading">
                                <b-form-checkbox
                                    v-model="allSelected"
                                    aria-describedby="flavours"
                                    aria-controls="flavours"
                                    @change="toggleAll"
                                >
                                    {{ allSelected ? 'Un-select All' : 'Select All' }}
                                </b-form-checkbox>
                                <b-form-checkbox-group 
                                    v-model="selectedDivisions"
                                    :options="divisions"
                                    class="mt-3 divisions_checkbox"
                                >
                                </b-form-checkbox-group>
                            </div>
                            <div v-if="!divisions.length && !loading" class="mt-4">
                                There is no available division
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="text-center mt-4">
                <button class="creaet_branch_btn"
                    @click="editCompetency"
                    :disabled="isButtonDisabled ? true : null"
                    :style="[
                    isButtonDisabled
                        ? { cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                    ]">
                    Edit Competency 
                </button>
            </div>
        </div>
    </MasterLayout>
</template>

<script>

import Vue from "vue";
import { VueEditor } from "vue2-editor";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


export default {
    title: 'Create Competency',
    components: {
        MasterLayout,
        VueEditor
    },
    mounted() {
        this.getCompetency()
        this.getDivisions()
        this.getCompetencyGroups()
    },
    data() {
        return {
            loading: true,
            allSelected: false,
            compName: null,
            competencyGroups: [],
            groupId: 'selectCompetencyGroup',
            description: '',
            divisions: [],
            selectedDivisions: [],
            isButtonDisabled:  false,
            customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }]]

        }
    },
    watch: {
      selectedDivisions(newVal, oldVal) {
        // Handle changes in individual flavour checkboxes
        if (newVal.length === 0) {
          this.allSelected = false
        } else if (newVal.length === this.divisions.length) {
          this.allSelected = true
        } else {
          this.allSelected = false
        }
      },
      divisions(newVal, oldVal) {
        if (this.selectedDivisions.length && this.selectedDivisions.length == newVal.length) {
            this.allSelected = true
        } 
      }
    },
    methods: {
        getCompetency() {
            this.axios.get(`${configObject.apiBaseUrl}/Competency/${this.$route.query.id}`, configObject.authConfig)
                .then(response => {
                    this.compName = response.data.name
                    this.groupId = response.data.competencyGroupId
                    this.description = response.data.description
                    this.selectedDivisions = response.data.divisionIds
                    this.loading = false
                })
                .catch(error => {
                });
        },
        getCompetencyGroups() {
            this.axios.get(`${configObject.apiBaseUrl}/Competency/Group`, configObject.authConfig)
            .then(response => {
                response.data.sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase()
                    ? -1
                    : a.name.toLowerCase() > b.name.toLowerCase()
                    ? 1
                    : 0
                );
                this.competencyGroups = response.data;
            })
            .catch((error) => {
                console.log(error);
                this.$toast.open({
                    message: "Unable to load competency groups",
                    type: "error",
                    duration: 3000
                });
            });
        },
        getDivisions() {
            this.axios.get(`${configObject.apiBaseUrl}/Divisions`, configObject.authConfig)
                .then(response => {
                    response.data.sort((a, b) =>
                        a.name.toLowerCase() < b.name.toLowerCase()
                        ? -1
                        : a.name.toLowerCase() > b.name.toLowerCase()
                        ? 1
                        : 0
                    );
                    this.divisions = response.data.map(cur => {
                        return {
                            text: cur.name,
                            value: cur.id
                        }
                    })
                    this.loading = false
                })
                .catch(error => {
                });
        },
        toggleAll(checked) {
            this.selectedDivisions = checked ? this.divisions.map(cur => cur.value) : []
            console.log(this.selectedDivisions)
        },
        editCompetency() {
            if(!this.compName){
                this.$toasted.show("Fill in the competency name", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
                return
            }

            if(!this.groupId || this.groupId == 'selectCompetencyGroup'){
                this.$toasted.show("Select a competency group", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
                return
            }
            if(!this.description){
                this.$toasted.show("Fill in the description", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
                return
            }
            if(!this.selectedDivisions.length){
                this.$toasted.show("Select at least one division", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
                return
            }

            let dataToSend = {
                name: this.compName,
                competencyGroupId: this.groupId,
                description: this.description,
                divisionIds: this.selectedDivisions,
                id: +this.$route.query.id
            };
            console.log(dataToSend);

            this.isButtonDisabled = true;
            this.axios.put(`${configObject.apiBaseUrl}/Competency/Edit/${this.$route.query.id}`, dataToSend, configObject.authConfig)
                .then(() => {
                        this.isButtonDisabled = false;
                        this.$toast.open({
                            message: 'Successfully edited competency',
                            type: "success",
                            duration: 3000
                        });
                        this.$router.push({name: 'competency_library'})
                    })
                    .catch((error) => {
                        console.log(error)
                        console.log(error.response)
                        this.isButtonDisabled = false;
                        let msg = "Unable to edit competency";
                        if(error.response.data.message){
                            msg = error.response.data.message;
                        }
                        this.$toast.open({
                            message: msg,
                            type: "error",
                            duration: 3000
                        });
                });
        },
        backToCompetencies() {
            this.$router.push({ name: 'competency_library' })
        }
    }
}
</script>

<style>
    .divisions_checkbox .custom-checkbox {
        width: 50%;
        margin-right: 0;
        word-break: break-all;
        margin-bottom: 8px;
    }
</style>