<template>
    <MasterLayout>
        <div class="employees__assessment__wrapper">
            <div class="first__banner" style="background: rgb(222, 254, 229); min-height: 180px; padding: 0px;">
                <div class="row">
                    <div class="col-md-3">
                        <div class="profile__pic__wrapper" >
                            <div class="profile__pic" :style="{
                                backgroundImage: `url(${employee.passportUrl})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                                }">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="user_name mt-3 ">
                                    <h3 class="employee__name">{{employee.firstname}} {{employee.surname}} </h3>
                                </div>
                                <p class="mt-3 employee__dashboard__profession">{{employee.designationName}}</p>
                                <p class="mt-3 employee__name">Job Grade
                                    <span class="job_grade__btn ml-3">{{employee.gradeLevel}}</span>
                                </p>
                            </div>
                            <div class="col-md-6">
                                <div class="row mt-3">
                                    <div class="col-md-6 text-center">
                                        <p style="margin-bottom: 5px;"><i class="fa fa-envelope-o" aria-hidden="true" style="color:rgb(9, 140, 40);"></i></p>
                                        <p><span class="mt-3 phone__text" >{{employee.email}}</span></p>
                                    </div>
                                    <div class="col-md-6 text-center">
                                        <p style="margin-bottom: 5px;"><i class="fa fa-phone" aria-hidden="true" style="color:rgb(9, 140, 40);"></i></p> 
                                        <p style="margin-bottom: 5px;"><span class="mt-3 phone__text">{{employee.phoneNumber}}</span></p>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="text-right">
                                            <p class="mt-3 mr-3 employee__name">
                                                Employee ID
                                                <span class="job_grade__btn ml-3">{{employee.employeeId}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="first__tabs__Section__employee__dashboard" style="width: 100%">
                            <div class="tab__1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 167.79 206" class="mt-3">
                                    <path id="building" d="M207.145,192.71h-13.29V29.9a6.645,6.645,0,0,0-6.645-6.645H137.371V6.645A6.645,6.645,0,0,0,130.726,0h-46.1A6.645,6.645,0,0,0,77.98,6.645V23.258h-5.4A6.645,6.645,0,0,0,65.935,29.9V192.71H52.645a6.645,6.645,0,1,0,0,13.29h154.5a6.645,6.645,0,1,0,0-13.29ZM91.27,13.29h32.81v9.968H91.27ZM116.6,192.71V164.468h26.581V192.71Zm39.871,0V164.468h2.907a6.645,6.645,0,0,0,0-13.29H100.407a6.645,6.645,0,0,0,0,13.29h2.907V192.71H79.226V36.548H180.564V192.71ZM120.343,56.069a6.645,6.645,0,0,1-6.645,6.645h-7.476a6.645,6.645,0,0,1,0-13.29H113.7A6.645,6.645,0,0,1,120.343,56.069Zm39.456,0a6.645,6.645,0,0,1-6.645,6.645h-7.476a6.645,6.645,0,0,1,0-13.29h7.476A6.645,6.645,0,0,1,159.8,56.069ZM120.343,80.988a6.645,6.645,0,0,1-6.645,6.645h-7.476a6.645,6.645,0,0,1,0-13.29H113.7A6.645,6.645,0,0,1,120.343,80.988Zm39.456,0a6.645,6.645,0,0,1-6.645,6.645h-7.476a6.645,6.645,0,0,1,0-13.29h7.476A6.645,6.645,0,0,1,159.8,80.988Zm-39.456,24.919a6.645,6.645,0,0,1-6.645,6.645h-7.476a6.645,6.645,0,0,1,0-13.29H113.7A6.645,6.645,0,0,1,120.343,105.907Zm39.456,0a6.645,6.645,0,0,1-6.645,6.645h-7.476a6.645,6.645,0,0,1,0-13.29h7.476A6.645,6.645,0,0,1,159.8,105.907Zm-39.456,24.919a6.645,6.645,0,0,1-6.645,6.645h-7.476a6.645,6.645,0,0,1,0-13.29H113.7A6.645,6.645,0,0,1,120.343,130.827Zm39.456,0a6.645,6.645,0,0,1-6.645,6.645h-7.476a6.645,6.645,0,0,1,0-13.29h7.476A6.645,6.645,0,0,1,159.8,130.827Z" transform="translate(-46)" fill="rgb(9, 140, 40)"/>
                                </svg>
                                <p class="mt-2 first__tabs__section__para">Branch</p>
                                <h6 class="mt-2 theme__color">{{employee.branchName}}</h6>
                            </div>
                            <div class="tab__2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="mt-3" viewBox="0 0 204.502 203.999">
                                    <g id="company" transform="translate(0 -0.113)">
                                        <path id="Path_209" data-name="Path 209" d="M155.148,55.054V46.177h-6.581V33.016H135.406V9.984h-6.581V33.016h-6.581V.113h-6.581v32.9h-6.581V9.984H102.5V33.016H89.342V46.177H82.761V92.242H27.547L24.257,105.4H0l13.161,92.129H.5v6.581h204V41.893ZM197.922,69.21h-9.871V53.094l9.871-2.633ZM10.408,131.726l-.941-6.581H23.535v6.581Zm19.708-6.581H43.277v6.581H30.116Zm92.129-6.581H109.084v-6.581h13.161Zm-19.742,0H89.342v-6.581H102.5Zm-19.742,0H69.6v-6.581H82.761Zm-19.742,0H49.858v-6.581H63.019Zm-19.742,0H30.116v-6.581H43.277Zm6.581,6.581H63.019v6.581H49.858Zm19.742,0H82.761v6.581H69.6Zm19.742,0H102.5v6.581H89.342Zm19.742,0h13.161v6.581H109.084Zm5.861-19.742-3.29-13.161H102.5V52.758h46.064V197.532H128.825V105.4Zm40.2-29.613h9.871V88.951h-9.871Zm16.452,0h9.871V88.951H171.6Zm0-6.581V57.48l9.871-2.633V69.21Zm16.452,6.581h9.871V88.951h-9.871ZM165.019,59.233V69.21h-9.871V61.866ZM95.922,39.6h46.064v6.581H95.922ZM89.342,52.758h6.581V92.242H89.342ZM32.687,98.822h73.828l1.645,6.581H31.041Zm-9.151,13.161v6.581H8.528l-.94-6.581ZM11.349,138.306h110.9v59.226h-6.581V171.209H89.342v26.323H82.761V171.209H56.439v26.323H49.858V171.209H23.535v26.323H19.808Zm97.734,59.226H95.922V177.79h13.161Zm-32.9,0H63.019V177.79H76.18Zm-32.9,0H30.116V177.79H43.277Zm111.871,0v-102h42.774v102Zm0,0" fill="#098c28"/>
                                        <path id="Path_210" data-name="Path 210" d="M57.223,352.113H63.8v6.581H57.223Zm0,0" transform="translate(-33.687 -207.226)" fill="#098c28"/>
                                        <path id="Path_211" data-name="Path 211" d="M105.223,352.113H111.8v6.581h-6.581Zm0,0" transform="translate(-61.946 -207.226)" fill="#098c28"/>
                                        <path id="Path_212" data-name="Path 212" d="M153.223,352.113H159.8v6.581h-6.581Zm0,0" transform="translate(-90.204 -207.226)" fill="#098c28"/>
                                        <path id="Path_213" data-name="Path 213" d="M201.223,352.113H207.8v6.581h-6.581Zm0,0" transform="translate(-118.462 -207.226)" fill="#098c28"/>
                                        <path id="Path_214" data-name="Path 214" d="M249.223,352.113H255.8v6.581h-6.581Zm0,0" transform="translate(-146.72 -207.226)" fill="#098c28"/>
                                        <path id="Path_215" data-name="Path 215" d="M57.223,384.113H63.8v6.581H57.223Zm0,0" transform="translate(-33.687 -226.065)" fill="#098c28"/>
                                        <path id="Path_216" data-name="Path 216" d="M105.223,384.113H111.8v6.581h-6.581Zm0,0" transform="translate(-61.946 -226.065)" fill="#098c28"/>
                                        <path id="Path_217" data-name="Path 217" d="M153.223,384.113H159.8v6.581h-6.581Zm0,0" transform="translate(-90.204 -226.065)" fill="#098c28"/>
                                        <path id="Path_218" data-name="Path 218" d="M201.223,384.113H207.8v6.581h-6.581Zm0,0" transform="translate(-118.462 -226.065)" fill="#098c28"/>
                                        <path id="Path_219" data-name="Path 219" d="M249.223,384.113H255.8v6.581h-6.581Zm0,0" transform="translate(-146.72 -226.065)" fill="#098c28"/>
                                        <path id="Path_220" data-name="Path 220" d="M265.223,144.113H271.8v6.581h-6.581Zm0,0" transform="translate(-156.139 -84.774)" fill="#098c28"/>
                                        <path id="Path_221" data-name="Path 221" d="M297.223,144.113H303.8v6.581h-6.581Zm0,0" transform="translate(-174.978 -84.774)" fill="#098c28"/>
                                        <path id="Path_222" data-name="Path 222" d="M329.223,144.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -84.774)" fill="#098c28"/>
                                        <path id="Path_223" data-name="Path 223" d="M265.223,176.113H271.8v6.581h-6.581Zm0,0" transform="translate(-156.139 -103.613)" fill="#098c28"/>
                                        <path id="Path_224" data-name="Path 224" d="M297.223,176.113H303.8v6.581h-6.581Zm0,0" transform="translate(-174.978 -103.613)" fill="#098c28"/>
                                        <path id="Path_225" data-name="Path 225" d="M329.223,176.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -103.613)" fill="#098c28"/>
                                        <path id="Path_226" data-name="Path 226" d="M393.223,248.113H399.8v6.581h-6.581Zm0,0" transform="translate(-231.494 -146)" fill="#098c28"/>
                                        <path id="Path_227" data-name="Path 227" d="M425.223,248.113H431.8v6.581h-6.581Zm0,0" transform="translate(-250.333 -146)" fill="#098c28"/>
                                        <path id="Path_228" data-name="Path 228" d="M457.223,248.113H463.8v6.581h-6.581Zm0,0" transform="translate(-269.172 -146)" fill="#098c28"/>
                                        <path id="Path_229" data-name="Path 229" d="M393.223,280.113H399.8v6.581h-6.581Zm0,0" transform="translate(-231.494 -164.839)" fill="#098c28"/>
                                        <path id="Path_230" data-name="Path 230" d="M425.223,280.113H431.8v6.581h-6.581Zm0,0" transform="translate(-250.333 -164.839)" fill="#098c28"/>
                                        <path id="Path_231" data-name="Path 231" d="M457.223,280.113H463.8v6.581h-6.581Zm0,0" transform="translate(-269.172 -164.839)" fill="#098c28"/>
                                        <path id="Path_232" data-name="Path 232" d="M393.223,368.113H399.8v6.581h-6.581Zm0,0" transform="translate(-231.494 -216.646)" fill="#098c28"/>
                                        <path id="Path_233" data-name="Path 233" d="M425.223,368.113H431.8v6.581h-6.581Zm0,0" transform="translate(-250.333 -216.646)" fill="#098c28"/>
                                        <path id="Path_234" data-name="Path 234" d="M457.223,368.113H463.8v6.581h-6.581Zm0,0" transform="translate(-269.172 -216.646)" fill="#098c28"/>
                                        <path id="Path_235" data-name="Path 235" d="M393.223,400.113H399.8v6.581h-6.581Zm0,0" transform="translate(-231.494 -235.484)" fill="#098c28"/>
                                        <path id="Path_236" data-name="Path 236" d="M425.223,400.113H431.8v6.581h-6.581Zm0,0" transform="translate(-250.333 -235.484)" fill="#098c28"/>
                                        <path id="Path_237" data-name="Path 237" d="M457.223,400.113H463.8v6.581h-6.581Zm0,0" transform="translate(-269.172 -235.484)" fill="#098c28"/>
                                        <path id="Path_238" data-name="Path 238" d="M297.223,208.113H303.8v6.581h-6.581Zm0,0" transform="translate(-174.978 -122.452)" fill="#098c28"/>
                                        <path id="Path_239" data-name="Path 239" d="M329.223,208.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -122.452)" fill="#098c28"/>
                                        <path id="Path_240" data-name="Path 240" d="M329.223,240.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -141.291)" fill="#098c28"/>
                                        <path id="Path_241" data-name="Path 241" d="M329.223,272.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -160.129)" fill="#098c28"/>
                                        <path id="Path_242" data-name="Path 242" d="M329.223,304.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -178.968)" fill="#098c28"/>
                                        <path id="Path_243" data-name="Path 243" d="M329.223,336.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -197.807)" fill="#098c28"/>
                                        <path id="Path_244" data-name="Path 244" d="M329.223,368.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -216.646)" fill="#098c28"/>
                                    </g>
                                </svg>
                                <p class="mt-2 first__tabs__section__para">Division</p>
                                <h6 class="mt-2 theme__color first__tab__bolde__text">{{employee.divisionName}}</h6>
                            </div>
                            <div class="tab__3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="mt-3" viewBox="0 0 204.502 203.999">
                                    <g id="company" transform="translate(0 -0.113)">
                                        <path id="Path_209" data-name="Path 209" d="M155.148,55.054V46.177h-6.581V33.016H135.406V9.984h-6.581V33.016h-6.581V.113h-6.581v32.9h-6.581V9.984H102.5V33.016H89.342V46.177H82.761V92.242H27.547L24.257,105.4H0l13.161,92.129H.5v6.581h204V41.893ZM197.922,69.21h-9.871V53.094l9.871-2.633ZM10.408,131.726l-.941-6.581H23.535v6.581Zm19.708-6.581H43.277v6.581H30.116Zm92.129-6.581H109.084v-6.581h13.161Zm-19.742,0H89.342v-6.581H102.5Zm-19.742,0H69.6v-6.581H82.761Zm-19.742,0H49.858v-6.581H63.019Zm-19.742,0H30.116v-6.581H43.277Zm6.581,6.581H63.019v6.581H49.858Zm19.742,0H82.761v6.581H69.6Zm19.742,0H102.5v6.581H89.342Zm19.742,0h13.161v6.581H109.084Zm5.861-19.742-3.29-13.161H102.5V52.758h46.064V197.532H128.825V105.4Zm40.2-29.613h9.871V88.951h-9.871Zm16.452,0h9.871V88.951H171.6Zm0-6.581V57.48l9.871-2.633V69.21Zm16.452,6.581h9.871V88.951h-9.871ZM165.019,59.233V69.21h-9.871V61.866ZM95.922,39.6h46.064v6.581H95.922ZM89.342,52.758h6.581V92.242H89.342ZM32.687,98.822h73.828l1.645,6.581H31.041Zm-9.151,13.161v6.581H8.528l-.94-6.581ZM11.349,138.306h110.9v59.226h-6.581V171.209H89.342v26.323H82.761V171.209H56.439v26.323H49.858V171.209H23.535v26.323H19.808Zm97.734,59.226H95.922V177.79h13.161Zm-32.9,0H63.019V177.79H76.18Zm-32.9,0H30.116V177.79H43.277Zm111.871,0v-102h42.774v102Zm0,0" fill="#098c28"/>
                                        <path id="Path_210" data-name="Path 210" d="M57.223,352.113H63.8v6.581H57.223Zm0,0" transform="translate(-33.687 -207.226)" fill="#098c28"/>
                                        <path id="Path_211" data-name="Path 211" d="M105.223,352.113H111.8v6.581h-6.581Zm0,0" transform="translate(-61.946 -207.226)" fill="#098c28"/>
                                        <path id="Path_212" data-name="Path 212" d="M153.223,352.113H159.8v6.581h-6.581Zm0,0" transform="translate(-90.204 -207.226)" fill="#098c28"/>
                                        <path id="Path_213" data-name="Path 213" d="M201.223,352.113H207.8v6.581h-6.581Zm0,0" transform="translate(-118.462 -207.226)" fill="#098c28"/>
                                        <path id="Path_214" data-name="Path 214" d="M249.223,352.113H255.8v6.581h-6.581Zm0,0" transform="translate(-146.72 -207.226)" fill="#098c28"/>
                                        <path id="Path_215" data-name="Path 215" d="M57.223,384.113H63.8v6.581H57.223Zm0,0" transform="translate(-33.687 -226.065)" fill="#098c28"/>
                                        <path id="Path_216" data-name="Path 216" d="M105.223,384.113H111.8v6.581h-6.581Zm0,0" transform="translate(-61.946 -226.065)" fill="#098c28"/>
                                        <path id="Path_217" data-name="Path 217" d="M153.223,384.113H159.8v6.581h-6.581Zm0,0" transform="translate(-90.204 -226.065)" fill="#098c28"/>
                                        <path id="Path_218" data-name="Path 218" d="M201.223,384.113H207.8v6.581h-6.581Zm0,0" transform="translate(-118.462 -226.065)" fill="#098c28"/>
                                        <path id="Path_219" data-name="Path 219" d="M249.223,384.113H255.8v6.581h-6.581Zm0,0" transform="translate(-146.72 -226.065)" fill="#098c28"/>
                                        <path id="Path_220" data-name="Path 220" d="M265.223,144.113H271.8v6.581h-6.581Zm0,0" transform="translate(-156.139 -84.774)" fill="#098c28"/>
                                        <path id="Path_221" data-name="Path 221" d="M297.223,144.113H303.8v6.581h-6.581Zm0,0" transform="translate(-174.978 -84.774)" fill="#098c28"/>
                                        <path id="Path_222" data-name="Path 222" d="M329.223,144.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -84.774)" fill="#098c28"/>
                                        <path id="Path_223" data-name="Path 223" d="M265.223,176.113H271.8v6.581h-6.581Zm0,0" transform="translate(-156.139 -103.613)" fill="#098c28"/>
                                        <path id="Path_224" data-name="Path 224" d="M297.223,176.113H303.8v6.581h-6.581Zm0,0" transform="translate(-174.978 -103.613)" fill="#098c28"/>
                                        <path id="Path_225" data-name="Path 225" d="M329.223,176.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -103.613)" fill="#098c28"/>
                                        <path id="Path_226" data-name="Path 226" d="M393.223,248.113H399.8v6.581h-6.581Zm0,0" transform="translate(-231.494 -146)" fill="#098c28"/>
                                        <path id="Path_227" data-name="Path 227" d="M425.223,248.113H431.8v6.581h-6.581Zm0,0" transform="translate(-250.333 -146)" fill="#098c28"/>
                                        <path id="Path_228" data-name="Path 228" d="M457.223,248.113H463.8v6.581h-6.581Zm0,0" transform="translate(-269.172 -146)" fill="#098c28"/>
                                        <path id="Path_229" data-name="Path 229" d="M393.223,280.113H399.8v6.581h-6.581Zm0,0" transform="translate(-231.494 -164.839)" fill="#098c28"/>
                                        <path id="Path_230" data-name="Path 230" d="M425.223,280.113H431.8v6.581h-6.581Zm0,0" transform="translate(-250.333 -164.839)" fill="#098c28"/>
                                        <path id="Path_231" data-name="Path 231" d="M457.223,280.113H463.8v6.581h-6.581Zm0,0" transform="translate(-269.172 -164.839)" fill="#098c28"/>
                                        <path id="Path_232" data-name="Path 232" d="M393.223,368.113H399.8v6.581h-6.581Zm0,0" transform="translate(-231.494 -216.646)" fill="#098c28"/>
                                        <path id="Path_233" data-name="Path 233" d="M425.223,368.113H431.8v6.581h-6.581Zm0,0" transform="translate(-250.333 -216.646)" fill="#098c28"/>
                                        <path id="Path_234" data-name="Path 234" d="M457.223,368.113H463.8v6.581h-6.581Zm0,0" transform="translate(-269.172 -216.646)" fill="#098c28"/>
                                        <path id="Path_235" data-name="Path 235" d="M393.223,400.113H399.8v6.581h-6.581Zm0,0" transform="translate(-231.494 -235.484)" fill="#098c28"/>
                                        <path id="Path_236" data-name="Path 236" d="M425.223,400.113H431.8v6.581h-6.581Zm0,0" transform="translate(-250.333 -235.484)" fill="#098c28"/>
                                        <path id="Path_237" data-name="Path 237" d="M457.223,400.113H463.8v6.581h-6.581Zm0,0" transform="translate(-269.172 -235.484)" fill="#098c28"/>
                                        <path id="Path_238" data-name="Path 238" d="M297.223,208.113H303.8v6.581h-6.581Zm0,0" transform="translate(-174.978 -122.452)" fill="#098c28"/>
                                        <path id="Path_239" data-name="Path 239" d="M329.223,208.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -122.452)" fill="#098c28"/>
                                        <path id="Path_240" data-name="Path 240" d="M329.223,240.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -141.291)" fill="#098c28"/>
                                        <path id="Path_241" data-name="Path 241" d="M329.223,272.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -160.129)" fill="#098c28"/>
                                        <path id="Path_242" data-name="Path 242" d="M329.223,304.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -178.968)" fill="#098c28"/>
                                        <path id="Path_243" data-name="Path 243" d="M329.223,336.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -197.807)" fill="#098c28"/>
                                        <path id="Path_244" data-name="Path 244" d="M329.223,368.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -216.646)" fill="#098c28"/>
                                    </g>
                                </svg>
                                <p class="mt-2 first__tabs__section__para">Unit</p>
                                <h6 class="mt-2 theme__color first__tab__bolde__text">{{employee.branchName}}</h6>
                            </div>
                            <div class="tab__4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="mt-3" viewBox="0 0 204.502 203.999">
                                    <g id="company" transform="translate(0 -0.113)">
                                        <path id="Path_209" data-name="Path 209" d="M155.148,55.054V46.177h-6.581V33.016H135.406V9.984h-6.581V33.016h-6.581V.113h-6.581v32.9h-6.581V9.984H102.5V33.016H89.342V46.177H82.761V92.242H27.547L24.257,105.4H0l13.161,92.129H.5v6.581h204V41.893ZM197.922,69.21h-9.871V53.094l9.871-2.633ZM10.408,131.726l-.941-6.581H23.535v6.581Zm19.708-6.581H43.277v6.581H30.116Zm92.129-6.581H109.084v-6.581h13.161Zm-19.742,0H89.342v-6.581H102.5Zm-19.742,0H69.6v-6.581H82.761Zm-19.742,0H49.858v-6.581H63.019Zm-19.742,0H30.116v-6.581H43.277Zm6.581,6.581H63.019v6.581H49.858Zm19.742,0H82.761v6.581H69.6Zm19.742,0H102.5v6.581H89.342Zm19.742,0h13.161v6.581H109.084Zm5.861-19.742-3.29-13.161H102.5V52.758h46.064V197.532H128.825V105.4Zm40.2-29.613h9.871V88.951h-9.871Zm16.452,0h9.871V88.951H171.6Zm0-6.581V57.48l9.871-2.633V69.21Zm16.452,6.581h9.871V88.951h-9.871ZM165.019,59.233V69.21h-9.871V61.866ZM95.922,39.6h46.064v6.581H95.922ZM89.342,52.758h6.581V92.242H89.342ZM32.687,98.822h73.828l1.645,6.581H31.041Zm-9.151,13.161v6.581H8.528l-.94-6.581ZM11.349,138.306h110.9v59.226h-6.581V171.209H89.342v26.323H82.761V171.209H56.439v26.323H49.858V171.209H23.535v26.323H19.808Zm97.734,59.226H95.922V177.79h13.161Zm-32.9,0H63.019V177.79H76.18Zm-32.9,0H30.116V177.79H43.277Zm111.871,0v-102h42.774v102Zm0,0" fill="#098c28"/>
                                        <path id="Path_210" data-name="Path 210" d="M57.223,352.113H63.8v6.581H57.223Zm0,0" transform="translate(-33.687 -207.226)" fill="#098c28"/>
                                        <path id="Path_211" data-name="Path 211" d="M105.223,352.113H111.8v6.581h-6.581Zm0,0" transform="translate(-61.946 -207.226)" fill="#098c28"/>
                                        <path id="Path_212" data-name="Path 212" d="M153.223,352.113H159.8v6.581h-6.581Zm0,0" transform="translate(-90.204 -207.226)" fill="#098c28"/>
                                        <path id="Path_213" data-name="Path 213" d="M201.223,352.113H207.8v6.581h-6.581Zm0,0" transform="translate(-118.462 -207.226)" fill="#098c28"/>
                                        <path id="Path_214" data-name="Path 214" d="M249.223,352.113H255.8v6.581h-6.581Zm0,0" transform="translate(-146.72 -207.226)" fill="#098c28"/>
                                        <path id="Path_215" data-name="Path 215" d="M57.223,384.113H63.8v6.581H57.223Zm0,0" transform="translate(-33.687 -226.065)" fill="#098c28"/>
                                        <path id="Path_216" data-name="Path 216" d="M105.223,384.113H111.8v6.581h-6.581Zm0,0" transform="translate(-61.946 -226.065)" fill="#098c28"/>
                                        <path id="Path_217" data-name="Path 217" d="M153.223,384.113H159.8v6.581h-6.581Zm0,0" transform="translate(-90.204 -226.065)" fill="#098c28"/>
                                        <path id="Path_218" data-name="Path 218" d="M201.223,384.113H207.8v6.581h-6.581Zm0,0" transform="translate(-118.462 -226.065)" fill="#098c28"/>
                                        <path id="Path_219" data-name="Path 219" d="M249.223,384.113H255.8v6.581h-6.581Zm0,0" transform="translate(-146.72 -226.065)" fill="#098c28"/>
                                        <path id="Path_220" data-name="Path 220" d="M265.223,144.113H271.8v6.581h-6.581Zm0,0" transform="translate(-156.139 -84.774)" fill="#098c28"/>
                                        <path id="Path_221" data-name="Path 221" d="M297.223,144.113H303.8v6.581h-6.581Zm0,0" transform="translate(-174.978 -84.774)" fill="#098c28"/>
                                        <path id="Path_222" data-name="Path 222" d="M329.223,144.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -84.774)" fill="#098c28"/>
                                        <path id="Path_223" data-name="Path 223" d="M265.223,176.113H271.8v6.581h-6.581Zm0,0" transform="translate(-156.139 -103.613)" fill="#098c28"/>
                                        <path id="Path_224" data-name="Path 224" d="M297.223,176.113H303.8v6.581h-6.581Zm0,0" transform="translate(-174.978 -103.613)" fill="#098c28"/>
                                        <path id="Path_225" data-name="Path 225" d="M329.223,176.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -103.613)" fill="#098c28"/>
                                        <path id="Path_226" data-name="Path 226" d="M393.223,248.113H399.8v6.581h-6.581Zm0,0" transform="translate(-231.494 -146)" fill="#098c28"/>
                                        <path id="Path_227" data-name="Path 227" d="M425.223,248.113H431.8v6.581h-6.581Zm0,0" transform="translate(-250.333 -146)" fill="#098c28"/>
                                        <path id="Path_228" data-name="Path 228" d="M457.223,248.113H463.8v6.581h-6.581Zm0,0" transform="translate(-269.172 -146)" fill="#098c28"/>
                                        <path id="Path_229" data-name="Path 229" d="M393.223,280.113H399.8v6.581h-6.581Zm0,0" transform="translate(-231.494 -164.839)" fill="#098c28"/>
                                        <path id="Path_230" data-name="Path 230" d="M425.223,280.113H431.8v6.581h-6.581Zm0,0" transform="translate(-250.333 -164.839)" fill="#098c28"/>
                                        <path id="Path_231" data-name="Path 231" d="M457.223,280.113H463.8v6.581h-6.581Zm0,0" transform="translate(-269.172 -164.839)" fill="#098c28"/>
                                        <path id="Path_232" data-name="Path 232" d="M393.223,368.113H399.8v6.581h-6.581Zm0,0" transform="translate(-231.494 -216.646)" fill="#098c28"/>
                                        <path id="Path_233" data-name="Path 233" d="M425.223,368.113H431.8v6.581h-6.581Zm0,0" transform="translate(-250.333 -216.646)" fill="#098c28"/>
                                        <path id="Path_234" data-name="Path 234" d="M457.223,368.113H463.8v6.581h-6.581Zm0,0" transform="translate(-269.172 -216.646)" fill="#098c28"/>
                                        <path id="Path_235" data-name="Path 235" d="M393.223,400.113H399.8v6.581h-6.581Zm0,0" transform="translate(-231.494 -235.484)" fill="#098c28"/>
                                        <path id="Path_236" data-name="Path 236" d="M425.223,400.113H431.8v6.581h-6.581Zm0,0" transform="translate(-250.333 -235.484)" fill="#098c28"/>
                                        <path id="Path_237" data-name="Path 237" d="M457.223,400.113H463.8v6.581h-6.581Zm0,0" transform="translate(-269.172 -235.484)" fill="#098c28"/>
                                        <path id="Path_238" data-name="Path 238" d="M297.223,208.113H303.8v6.581h-6.581Zm0,0" transform="translate(-174.978 -122.452)" fill="#098c28"/>
                                        <path id="Path_239" data-name="Path 239" d="M329.223,208.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -122.452)" fill="#098c28"/>
                                        <path id="Path_240" data-name="Path 240" d="M329.223,240.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -141.291)" fill="#098c28"/>
                                        <path id="Path_241" data-name="Path 241" d="M329.223,272.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -160.129)" fill="#098c28"/>
                                        <path id="Path_242" data-name="Path 242" d="M329.223,304.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -178.968)" fill="#098c28"/>
                                        <path id="Path_243" data-name="Path 243" d="M329.223,336.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -197.807)" fill="#098c28"/>
                                        <path id="Path_244" data-name="Path 244" d="M329.223,368.113H335.8v6.581h-6.581Zm0,0" transform="translate(-193.817 -216.646)" fill="#098c28"/>
                                    </g>
                                </svg>
                                <p class="mt-2 first__tabs__section__para">Employment Date</p>
                                <h6 class="mt-2 theme__color  first__tab__bolde__text">{{employee.employmentDate}}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         
        <section class="competency__chart__section my-3 mx-3" style="height: auto;">
             <div class="row">
                 <div class="col-md-8" style="padding-right: 0px">
                     <div class="competency__chart" style="height: 100%;">
                        <VueApexCharts width="550" style="margin:0 auto; width: 100%" type="radialBar" :options="options" :series="series"></VueApexCharts>
                     </div>
                 </div>
                 <div class="col-md-4">
                     <b-card title="Reporting to" class="mb-3">
                         <hr />
                        <h5 class="text-muted">{{myUpline.name}}</h5>
                        <b-card-text>
                            {{myUpline.designation}} ({{myUpline.grade}})<br />
                            {{myUpline.division}}
                        </b-card-text>
                     </b-card>
                     <b-card sub-title="Gap Analysis">
                        <b-card-text>
                            <div class='row'>
                                <div class="col-md-2">
                                    <div class="star_div">
                                        <div>
                                            <img src="@/assets/img/Group 194.png" width="25px" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-10 align-items-center">
                                    <div class="gap_analysis_list_header_section">
                                        <h3 class="gap_analysis_list_header">Facilitates Quality standard Assessment</h3>
                                    </div>
                                </div>
                            </div>
                            <div class='row mt-4'>
                                <div class="col-md-2">
                                    <div class="star_div">
                                        <div>
                                            <img src="@/assets/img/Group 194.png" width="25px" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-10 align-items-center">
                                    <div class="gap_analysis_list_header_section">
                                        <h3 class="gap_analysis_list_header">Facilitates Quality standard Assessment</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="list_item__base_btn_wrapper mt-4">
                                <button class="list_item__base_btn">Gap = Expected  - Base</button>
                            </div>
                        </b-card-text>
                     </b-card>
                 </div>
             </div>
        </section>
        
        <section class="my-3 mx-3 emp-db" style="background-color: #fff; padding: 20px 10px;">
            <b-tabs card class="employee_tabs" style="margin: 0;" fill>
                <b-tab title="My Competencies" active>
                    <b-card-text class="employee-tab-content">
                        <div class="tab-content-body">
                            <vueper-slides                            
                                class="no-shadow"
                                :visible-slides="4"
                                slide-multiple
                                :gap="0"
                                :slide-ratio="1 / 4"
                                :dragging-distance="200"
                                :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }">
                                <vueper-slide  v-for="(compGroup, i) in compGroups" :key="i">
                                    <template v-slot:content>
                                        <div class="second__tabs__Section" @click="checkActiveTab(compGroup.name)">
                                            <div class="tab__2 "  style="flex:0 0  100%" :class="[activeGroupName === compGroup.name ? 'active___tab' : null]" >
                                                <div class="myround" >
                                                    <p> {{compGroup.abbr}} </p>
                                                </div>
                                                <p class="mt-2 text-center  poppins">{{compGroup.name}}</p>
                                            </div>
                                        </div>
                                    </template>
                                </vueper-slide>
                            </vueper-slides>
                            
                            <b-card  no-body class="for-panel">
                                <ejs-grid                                        
                                    ref="dataGrid"
                                    :created="refreshGrid"
                                    :allowPaging="true"
                                    :allowSorting="true"
                                    :pageSettings="pageSettings"
                                    :toolbar="toolbar"
                                    :searchSettings="search"
                                    :allowExcelExport="true"
                                    :allowPdfExport="true"
                                    :toolbarClick="toolbarClick">
                                    <e-columns>
                                        <!-- <e-column field="index" headerText="#" width="80"></e-column> -->
                                        <e-column field="competency" headerText="Competency"></e-column>
                                        <e-column field="point" headerText="Current" width="110" textAlign="center"></e-column>
                                        <e-column field="required" headerText="Expected" width="110" textAlign="center"></e-column>
                                        <e-column :template="progressBarTemplate" width="120px" headerText="Gap Analysis" textAlign="center"></e-column>
                                        <e-column :template="emloyeeActionButtonsTemplate" width="110px" headerText="Actions"></e-column>
                                    </e-columns>
                                </ejs-grid>
                            </b-card>
                        </div>
                    </b-card-text>
                </b-tab>
                <b-tab title="Job Roles">
                    <b-card-text class="employee-tab-content">
                        <div class="tab-content-body">
                            <ejs-grid                                    
                                ref="jobRolesGrid"
                                :created="refreshJobRoles"
                                :allowPaging="true"
                                :allowSorting="true"
                                :pageSettings="pageSettings"
                                :toolbar="toolbar"
                                :searchSettings="search"
                                :allowExcelExport="true"
                                :allowPdfExport="true"
                                :toolbarClick="toolbarClick2"
                                v-show="jobRoles.length > 0">
                                <e-columns>
                                    <e-column field="index" headerText="#" width="50"></e-column>
                                    <e-column field="task" headerText="Job Roles"></e-column>
                                    <e-column width="30px" headerText="Actions"></e-column>
                                </e-columns>
                            </ejs-grid>
                            <div class="empty_branch__div mt-1 mb-3" v-if="jobRoles.length < 1" style="width: 90.65%; height: 350px;">
                                <div class="text-center">
                                    <h6 class="text-center">No Job Roles defined yet</h6>
                                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                                </div>
                            </div>
                        </div>
                    </b-card-text>
                </b-tab>
                <b-tab title="Responsibilities">
                    <b-card-text class="employee-tab-content">
                        <div class="tab-content-body">
                            <ejs-grid
                                ref="jobRespsGrid"
                                :created="refreshJobRespsGrid"
                                :allowPaging="true"
                                :allowSorting="true"
                                :pageSettings="pageSettings"
                                :toolbar="toolbar"
                                :searchSettings="search"
                                :allowExcelExport="true"
                                :allowPdfExport="true"
                                :toolbarClick="toolbarClick3"
                                v-show="jobResps.length > 0">
                                <e-columns>
                                    <e-column field="index" headerText="#" width="50px"></e-column>
                                    <e-column field="responsibilityName" headerText="Responsibility"></e-column>
                                    <e-column  width="50px" headerText=""></e-column>
                                </e-columns>
                            </ejs-grid>                                
                            <div class="empty_branch__div mt-1 mb-3" v-if="jobResps.length < 1" style="width: 90.65%; height: 350px;">
                                <div class="text-center">
                                    <h6 class="text-center">No Responsibility defined yet</h6>
                                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                                </div>
                            </div>
                        </div>
                    </b-card-text>
                </b-tab>
                <b-tab title="Requirements">
                    <b-card-text class="employee-tab-content">
                        <div class="tab-content-body">
                            <ejs-grid
                                ref="jobReqGrid"
                                :created="refreshJobReqGrid"
                                :allowPaging="true"
                                :allowSorting="true"
                                :pageSettings="pageSettings"
                                :toolbar="toolbar"
                                :searchSettings="search"
                                :allowExcelExport="true"
                                :allowPdfExport="true"
                                :toolbarClick="toolbarClick4"
                                v-show="jobReqs.length > 0">
                                <e-columns>
                                    <e-column field="index" headerText="#" width="50px"></e-column>
                                    <e-column field="task" headerText="Requirement"></e-column>
                                    <e-column field="typeName" headerText="Requirement Type" width="350px"></e-column>
                                    <e-column  width="50px" headerText=""></e-column>
                                </e-columns>
                            </ejs-grid>
                            <div class="empty_branch__div mt-1 mb-3" v-show="jobReqs.length < 1" style="width: 90.65%; height: 350px;">
                                <div class="text-center">
                                    <h6 class="text-center">No Job Requirement defined yet</h6>
                                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                                </div>
                            </div>
                        </div>
                    </b-card-text>
                </b-tab>
                <b-tab title="Job History">
                    <b-card-text class="employee-tab-content">
                        <div class="tab-content-body">
                            <ejs-grid
                                ref="jobHistoryGrid"
                                :created="refreshJobHistoryGrid"
                                :allowPaging="true"
                                :allowSorting="true"
                                :pageSettings="pageSettings"
                                :toolbar="toolbar"
                                :searchSettings="search"
                                :allowExcelExport="true"
                                :allowPdfExport="true"
                                :toolbarClick="toolbarClick5"
                                v-show="jobHistories.length > 0">
                                <e-columns>
                                    <e-column field="index" headerText="#" width="50px"></e-column>
                                    <e-column field="newJobRole" headerText="New Job Role"></e-column>
                                    <e-column field="previousJobRole" headerText="Previous Job Role"></e-column>
                                    <e-column field="date" headerText="Date Changed"></e-column>
                                    <e-column width="50px" headerText=""></e-column>
                                </e-columns>
                            </ejs-grid>
                            <div class="empty_branch__div mt-1 mb-3" v-show="jobHistories.length < 1" style="width: 90.65%; height: 350px;">
                                <div class="text-center">
                                    <h6 class="text-center">No Job history yet</h6>
                                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                                </div>
                            </div>
                        </div>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </section>
        
        <ViewCompetencyModal :competency="competency"></ViewCompetencyModal>
        <JobRoleDetailsModal :jobRole="jobRole"></JobRoleDetailsModal>
        <employeeCompetencyDetails :empoyeeCompetency="empoyeeCompetency"></employeeCompetencyDetails>
    </MasterLayout>
</template>
<script> 

import VueApexCharts from 'vue-apexcharts';
import Vue from "vue";
import MasterLayout from "@/views/Employee/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/avatar.svg";
import configObject from "@/config";
import JobRoleDetailsModal from '@/components/Modals/RoleTasks/jobRoleDetailsModal.vue';
import ViewCompetencyModal from '@/components/Modals/Competency_modal/competencyDetailsModal.vue';
import employeeCompetencyDetails from '@/components/Modals/Competency_modal/employeeCompetencyDetailsModal.vue';
import moment from 'moment';

import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

import JQuery from "jquery";
let $ = JQuery;

export default {
    title: 'Dashboard',
    components: {
        MasterLayout,
        VueperSlides, VueperSlide,
        employeeCompetencyDetails,
        ViewCompetencyModal,
        VueApexCharts,
        JobRoleDetailsModal,
    },
    provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
             backgroundUrl,
             avatar: null,
             userInfo: localStorage.getItem("dcasUserDetails")
                ? JSON.parse(localStorage.getItem("dcasUserDetails"))
                : null,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            backgroundUrl,
            id: 0,
            competencies: [],
            competency: {},
            jobRoles: [],
            jobRole: {},
            employee: {},
            jobResps: [],
            jobResp: {},
            jobReqs: [],
            jobHistories: [],
            prevRoute: null,
            token: this.$store.state.tokenGotten,
            empoyeeCompetency: {},
            myCompetencies :null,
            newCompetencies: [],
            listOfCompetencies: null,
            compGroups: null,
            activeGroupName: null,
            myUpline: {},
            activeTab: "employee competencies",
            series: [0],
            options: {
                chart: {
                    height: 400,
                    type: 'radialBar',
                    toolbar: {
                    show: true
                    }
                },
                plotOptions: {
                    radialBar: {
                        //   startAngle: -135,
                        //   endAngle: 225,
                        hollow: {
                            margin: 0,
                            size: '80%',
                            background: '#fff',
                            image: undefined,
                            imageOffsetX: 0,
                            imageOffsetY: 0,
                            position: 'front',
                            dropShadow: {
                                enabled: true,
                                top: 3,
                                left: 0,
                                blur: 4,
                                opacity: 0.24
                            }
                        },
                        track: {
                            background: '#fff',
                            strokeWidth: '67%',
                            margin: 0, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: -3,
                                left: 0,
                                blur: 4,
                                opacity: 0.35
                            }
                        },              
                        dataLabels: {
                            show: true,
                            name: {
                                offsetY: -10,
                                show: true,
                                color: '#888',
                                fontSize: '17px'
                            },
                            value: {
                                formatter: function(val) {
                                    return parseInt(val);
                                },
                                color: '#111',
                                fontSize: '36px',
                                show: true,
                            }
                        }
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors: ['#ABE5A1'],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 40]
                    }
                },
                stroke: {
                    lineCap: 'round'
                },
                labels: ['Total Gap Percentage'],
                },
                progressBarTemplate: function(){
                    return {
                        template: Vue.component("progressBarTemplate", {
                            template:   '<div>'  +
                                            '<div id="progressbar" v-if="$data.data.gapAnalysis >= 0 && $data.data.gapAnalysis <= 25">' +
                                                `<div class="progress__bar__wrapper" style="background-color: red" v-bind:style="{ width: $data.data.gapAnalysis+'%'}">` +
                                                    '<div class="inner__div" style="border: 2px solid red;background-color: red;"></div>'+
                                                '</div>' +
                                            '</div>'+
                                            '<div id="progressbar" v-if="$data.data.gapAnalysis >= 25 && $data.data.gapAnalysis <= 75">' +
                                                '<div class="progress__bar__wrapper" style="background-color: yellow" v-bind:style="{ width: $data.data.gapAnalysis}">'+
                                                    '<div class="inner__div"></div>'+
                                                '</div>' +
                                            '</div>'+
                                            '<div id="progressbar" v-if="$data.data.gapAnalysis > 75 && $data.data.gapAnalysis <= 100">' +
                                                `<div class="progress__bar__wrapper" style="background-color: green" v-bind:style="{  width: $data.data.gapAnalysis+'%'}">`+
                                                    '<div class="inner__div"></div>'+
                                                '</div>' +
                                            '</div>'+
                                            '<div id="progressbar" v-if="$data.data.gapAnalysis > 100">' +
                                                `<div class="progress__bar__wrapper" style="width:100%;background-color: green">`+
                                                    '<div class="inner__div"></div>'+
                                                '</div>' +
                                            '</div>'+
                                            '<div class="text-center">{{$data.data.gapAnalysis}}%</div>' +
                                        '</div>',
                            data() {
                                return {
                                };
                            },
                        })
                    }
                },
                jobRespsActionButtonsTemplate: function() {
                    return {
                        template: Vue.component("actionButtonsTemplate", {
                            template:
                            '<div class="btn-group btn-group-sm" role="group">'+
                                '<button type="button" class="btn btn-info" title="View" v-on:click="viewJobResp(data.id)">' +
                                        '<i class="fa fa-eye" aria-hidden="true"></i>' +
                                '</button>' +
                            '</div>',
                            data: function() {
                                return {
                                    data: {}
                                };
                            },
                            methods: {
                                viewJobResp: function(jobRespId) {
                                    this.$eventHub.$emit("viewJobResp", jobRespId);
                                },
                            }
                        })
                    };
                },
                jobRolesActionButtonsTemplate: function() {
                    return {
                        template: Vue.component("actionButtonsTemplate", {
                            template:
                            '<div class="btn-group btn-group-sm" role="group">'+
                                '<button type="button" class="btn btn-info" title="View" v-on:click="viewJobRole(data.id)">' +
                                        '<i class="fa fa-eye" aria-hidden="true"></i>' +
                                '</button>' +
                            '</div>',
                            data: function() {
                                return {
                                    data: {}
                                };
                            },
                            methods: {
                                viewJobRole: function(jobRoleId) {
                                    this.$eventHub.$emit("viewJobRole", jobRoleId);
                                },
                            }
                        })
                    };
                },
            documentViewTemplate: function() {
                return {
                    template: Vue.component("documentViewTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-success" title="Edit" v-on:click="editUnit(data.id)">' +
                                '<i class=" fa fa-eye" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            deleteCompetency: function(compId) {
                                this.$eventHub.$emit("deleteCompetency", compId);
                            },
                            editUnit: function(compId) {
                                console.log(compId);
                                this.$eventHub.$emit("editUnit", compId);
                            }
                        }
                    })
                };
            },
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-info" title="View" v-on:click="viewCompetency(data.id)">' +
                                    '<i class="fa fa-eye" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            deleteCompetency: function(compId) {
                                this.$eventHub.$emit("deleteCompetency", compId);
                            },
                            editUnit: function(compId) {
                                console.log(compId);
                                this.$eventHub.$emit("editUnit", compId);
                            },
                            viewCompetency: function(compId) {
                                console.log(compId);
                                this.$eventHub.$emit("viewCompetency", compId);
                            },
                        }
                    })
                };
            },
            emloyeeActionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                             '<button type="button" class="btn btn-info" title="View" v-on:click="viewemployeeCompetency(data.id)">' +
                                    '<i class="fa fa-eye" aria-hidden="true"></i>' +
                                '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            viewemployeeCompetency: function(compId) {
                                this.$eventHub.$emit("viewemployeeCompetency", compId);
                            },
                        }
                    })
                };
            },
        }
    },
    mounted() {
         
        this.getEmployee();
        this.getUpline();

        console.log(this.prevRoute);
        
    },
    created(){
        this.id = parseInt(this.$route.query.id);
        this.$eventHub.$on("deleteCompetency", (compId) => {
            this.deleteCompetency(compId);
        });
        this.$eventHub.$on("viewCompetency", (compId) => {
            this.viewCompetency(compId);
        });
        this.$eventHub.$on("viewemployeeCompetency", (compId) => {
            this.viewemployeeCompetency(compId);
        });
        this.$eventHub.$on("viewJobRole", (jobRoleId) => {
            this.viewJobRole(jobRoleId);
        });
        this.$eventHub.$on("viewJobResp", (jobRespId) => {
            this.viewJobResp(jobRespId);
        });
        if(this.prevRoute === "login"){
            window.location.reload();
        }

        console.log(this.prevRoute);
    },
    methods: {
        viewemployeeCompetency(compId) {
            let compDetail = this.newCompetencies.filter(competency => competency.id === compId)[0];
            this.empoyeeCompetency = compDetail;
            this.openEmployeeCompDetail();
        },
        viewCompetency(compId) {
            let compDetail = this.listOfCompetencies.filter(competency => competency.id === compId)[0];
            this.competency = compDetail;
            this.opencompDetail();
        },
        opencompDetail(){
            this.$modal.show("competencyDetailsModal");
        },
        openEmployeeCompDetail(){
            this.$modal.show("employeeCompetencyDetailsModal");
        },
        changeTab($tabName){
            this.activeTab = $tabName;
        },
        deleteCompetency(compId) {
            console.log(compId);
            var resp = confirm("Are you sure  you want to delete this competency");
            if (resp) {
                this.axios
                    .delete(
                        `${configObject.apiBaseUrl}/Competency/Delete/${compId}`,
                        configObject.authConfig
                    )
                    .then(() => {
                        this.getCompetencies();
                        this.$toast.open({
                            message: "Competency deleted successfully!",
                            type: "success",
                            duration: 3000
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        this.$toast.open({
                            message: "unable to delete competency",
                            type: "error",
                            duration: 3000
                        });
                    });
            }
        },
        checkActiveTab($activeGroupName){
            this.activeGroupName = $activeGroupName;
            this.getNewCompetencies($activeGroupName);
        }, 
        getNewCompetencies($activeGroupName){
            let _tableCompetencies = [];
            this.myCompetencies.find(comp => {
                if(comp.group === $activeGroupName) {
                    _tableCompetencies.push(comp);
                }
            });
            this.newCompetencies = _tableCompetencies;
            this.newCompetencies.forEach(cur => {
                if (cur.gapAnalysis > 100) {
                    cur.gapAnalysis = 100
                }
            })
            this.getEmployeeCompetencies();
        },
        getEmployee(){
            this.axios.get(`${configObject.apiBaseUrl}/Employee/${this.userInfo.employeeId}`, configObject.authConfig)
            .then(response => {
                let emp = response.data;

                let index = 0;
                if(emp.designationResponsibilities !== null){
                    emp.designationResponsibilities.forEach(r => {
                        index++;
                        r.index = index;
                    });
                }
                this.jobResps = emp.designationResponsibilities;

                index = 0;
                if(emp.designationRoles !== null){
                    emp.designationRoles.forEach(r => {
                        index++;
                        r.index = index;
                    });
                }
                this.jobRoles = emp.designationRoles;

                index = 0;
                if(emp.designationRequirements !== null){
                    emp.designationRequirements.forEach(r => {
                        index++;
                        r.index = index;
                    });
                }
                this.jobReqs = emp.designationRequirements;
                
                index = 0;
                if(emp.jobHistories !== null){
                    emp.jobHistories.forEach(r => {
                        index++;
                        r.index = index;
                        r.date = moment(r.dateAdded).format('DD-MMM-YYYY');
                    });
                }
                this.jobHistories = emp.jobHistories;

                this.getJobRoles();
                this.getResps();
                this.getJobReqs();
                this.getJobHistories();

                if(response.data.passportUrl === null || response.data.passportUrl.length < 0){
                    response.data.passportUrl = UserDP;
                }

                response.data.employmentDate = moment(response.data.employmentDate).format('DD-MMM-YYYY');
                this.employee = response.data;
                let seriesArr = [];
                seriesArr.push(response.data.totalGap > 100 ? 100 : response.data.totalGap);
                this.series = seriesArr;
                //console.log(response.data);

                index = 0;
                if(emp.myCompetencies !== null){
                    emp.myCompetencies.forEach(r => {
                        index++;
                        r.index = index;
                    });
                }
                this.myCompetencies = emp.myCompetencies;
                let newGroups = [];
                response.data.myCompetencies.forEach(competency => {
                    let findGroup = newGroups.find(ng => competency.group === ng.name);
                    if(!findGroup) {
                        newGroups.push({
                            id: competency.id,
                            name: competency.group,
                            abbr: competency.group.split(' ').map((item, index) => {
                                if(index <= 1) return item[0].toUpperCase();
                            }).join('')
                        });
                    }
                });
                
                this.activeGroupName  = response.data.myCompetencies[0].group;
                this.getNewCompetencies(response.data.myCompetencies[0].group);
                this.compGroups = newGroups;
                this.getEmployeeCompetencies();
            })
            .catch((error) => {
                console.log('ERROR::: ' + error);
                // this.$toast.open({
                //     message: "unable to load employee competencies",
                //     type: "error",
                //     duration: 3000
                // });
            });
        },
        getUpline(){
            this.axios.get(`${configObject.apiBaseUrl}/Employee/Upline/${this.userInfo.employeeId}`, configObject.authConfig)
            .then(response => {
                this.myUpline = response.data;
            })
            .catch(error => {
                console.log('ERROR::: ' + error);
            });
        },
        getCompetencies(){
            this.showLoader = true;
            let grid = this.$refs.listOfCompetenciesGrid;
            this.axios
                .get(`${configObject.apiBaseUrl}/Competency`, configObject.authConfig)
                .then(response => {
                    console.log(response.data);
                    this.listOfCompetencies = response.data;
                    grid.ej2Instances.setProperties({ dataSource: response.data});
                    this.refreshListOfCompetenciesGrid();
                })
                .catch((error) => {
                    console.log('ERROR::: ' + error);
                    // this.$toast.open({
                    //     message: "unable to load competencies",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
        getEmployeeCompetencies() {
            this.showLoader = true;
            let grid = this.$refs.dataGrid;
            grid.ej2Instances.setProperties({ dataSource: this.newCompetencies});
            this.refreshGrid();
        },
        viewJobResp(jobRespId) {
            let jobRespDetail = this.jobRoles.filter(jobResp => jobResp.id === jobRespId)[0];
            this.jobResp = jobRespDetail;
            console.log(jobDetail);
            this.openJobRespDetailModal();
        },
        viewJobRole(jobRoleId) {
            let jobDetail = this.jobRoles.filter(jobRole => jobRole.id === jobRoleId)[0];
            this.jobRole = jobDetail;
            console.log(jobDetail);
            this.openJobRoleDetail();
        },
        openJobRoleDetail(){
            this.$modal.show("jobRoleDetail");
        },
        openJobRespDetailModal(){
            this.$modal.show("jobRespDetailModal");
        },
        getJobRoles(){
            let grid = this.$refs.jobRolesGrid;
            grid.ej2Instances.setProperties({ dataSource: this.jobRoles});
            this.refreshJobRoles();
        },
        getResps(){
            let grid = this.$refs.jobRespsGrid;
            grid.ej2Instances.setProperties({ dataSource: this.jobResps});
            this.refreshJobRespsGrid();
        },
        getJobReqs(){
            let grid = this.$refs.jobReqGrid;
            grid.ej2Instances.setProperties({ dataSource: this.jobReqs});
            this.refreshJobReqGrid();
        },
        getJobHistories(){
            let grid = this.$refs.jobHistoryGrid;
            grid.ej2Instances.setProperties({ dataSource: this.jobHistories});
            this.refreshJobHistoryGrid();
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        toolbarClick2(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.jobRolesGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.jobRolesGrid.excelExport();
                break;
            }
        },
        toolbarClick3(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.jobRespsGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.jobRespsGrid.excelExport();
                break;
            }
        },
        toolbarClick4(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.jobReqGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.jobReqGrid.excelExport();
                break;
            }
        },
        toolbarClick5(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.jobHistoryGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.jobHistoryGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        refreshListOfCompetenciesGrid(){
            this.$refs.listOfCompetenciesGrid.refresh();
        },
        refreshJobRespsGrid(){
            this.$refs.jobRespsGrid.refresh();
        },
        refreshJobRoles(){
            this.$refs.jobRolesGrid.refresh();
        },
        refreshJobReqGrid(){
            this.$refs.jobReqGrid.refresh();
        },
        refreshJobHistoryGrid(){
            this.$refs.jobHistoryGrid.refresh();
        },
    }
}
</script>

