<template>
    <MasterLayout>
        <div class="welcome_message">
            <div class="row">
                <div class="col-7">    
                    <h5 class='' v-if="loaded">
                        <!-- <span class="greeeting__text"> Hi Admin, </span> -->
                        Showing <span class="branches__count"> {{pageList.length}} </span> 
                        <span v-if="pageList.length > 0">branches</span><span v-if="pageList.length < 2">branches</span> found in 
                        <span class="branches__count">{{pageObject.divisionName}}</span>
                    </h5>
                    <h5 v-if="loading">Loading... please wait</h5>
                    <h5 v-if="id === null">Please select Division to search</h5>
                </div>
                <div class="col-5 text-right">
                    <div class="search_filter"> 
                        <ejs-dropdownlist id='dropdownlist' placeholder='Select a Division' 
                            v-model="id"
                            :allowFiltering='allowFiltering' 
                            :filtering='filtering' 
                            :dataSource='divisions' 
                            :fields='fields'></ejs-dropdownlist>
                        <button class="creaet_branch_btn" @click="findSuccessor">
                            Search <i class="fa fa-search"></i>
                        </button>
                        <div class="clear"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="employees__assessment__wrapper">
            <section class="table__section mt-1 pb-5 tall_table_header" >
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" headerText="#" width="60px"></e-column>
                        <e-column field="branchName" headerText="Branch Name"></e-column>
                        <e-column field="unitName" headerText="Unit Name"></e-column>
                        <e-column field="designationName" headerText="Designation Name"></e-column>
                        <e-column field="employeeAveragePoint" :visible='false' headerText="Basic Competency"></e-column>
                        <e-column field="totalRequiredPoints" :visible='false' headerText="Expected Competency"></e-column>
                        <e-column field="gap" :visible='false' headerText="Gap Competency"></e-column>
                        <e-column :template="competencyTemplate" :headerTemplate="headerTemplate" headerText="Evaluation" width="300px"></e-column>
                        <e-column :template="actionButtonsTemplate" headerText="Action"  width="150px"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
        </div>
        <div class="employees__assessment__wrapper">

            <!-- <div class="empty_branch__div mt-3" v-if="units.length < 1 && !showLoader">
                <div class="text-center">
                    <h6 class="text-center"> You are yet to create a branch</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div> -->
            
            <!-- <CreateUnitModal></CreateUnitModal>
            <editUnitModal :unit ="unit"></editUnitModal> -->
        </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    title: 'Division Gap Analysis',
    components: {
        MasterLayout,
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            id: null,
            division: {},
            divisions: [],
            pageObject:{},
            pageList: [],
            loaded: false,
            loading: false,
            showLoader: false,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            fields : { text: "name", value: "id" },
            allowFiltering: true,
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-primary" title="Details" v-on:click="_details(data.branchId)">' +
                                'Details <i class="fa fa-eye" aria-hidden="true"></i>' +
                            '</button>' +
                            // '<button type="button" class="btn btn-danger" title="Delete" v-on:click="deleteUnit(data.id)" v-if="data.deleted === false">' +
                            //     '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                            // '</button>' +
                            // '<button type="button" class="btn btn-success" title="Re-Add" v-on:click="deleteUnit(data.id)" v-if="data.deleted === true">' +
                            //     '<i class="fa fa-plus" aria-hidden="true"></i>' +
                            // '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _details: function(id) {
                                this.$eventHub.$emit("detailsVM", id);
                            },
                            editUnit: function(unitId) {
                                console.log(unitId);
                                this.$eventHub.$emit("editUnit", unitId);
                            }
                        }
                    })
                };
            },
            progressBarTemplate: function(){
                return {
                     template: Vue.component("deletedTemplate", {
                        template:   '<div>'  +
                                        '<div id="progressbar">' +
                                            '<div class="progress__bar__wrapper">'+
                                                '<div class="inner__div"></div>'+
                                            '</div>' +
                                        '</div>'+
                                        '<div class="text-center">50%</div>' +
                                    '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            },
            competencyTemplate: function(){
                return {
                    template: Vue.component("competencyTemplate", {
                        template: '<div class="row">' + 
                                    `<div class="col-4"><div class="text-center">{{data.employeeAveragePoint}}</div></div>` +
                                    `<div class="col-4"><div class="text-center">{{data.totalRequiredPoints}}</div></div>` +
                                    `<div class="col-4"><div class="text-center">{{data.gap}} <i class="fa" v-bind:class="data.arrow"></i></div></div>` +
                                    '</div>',
                        data: function(){
                            return {
                                data: {}
                            }
                        },
                        methods:{

                        }
                    })
                }
            },
            headerTemplate: function(){
                return {
                    template: Vue.component("headerTemplate", {
                        template: '<div class="row">' +
                                    '<div class="col-12"><div class="text-center" style="border-bottom: 1px solid;">Competency</div></div>' + 
                                    '<div class="col-4"><div class="text-center">Basic</div></div>' +
                                    '<div class="col-4"><div class="text-center">Expected</div></div>' +
                                    '<div class="col-4"><div class="text-center">Gap</div></div>' +
                                    '</div>',
                        data: function(){
                            return {
                                data: {}
                            }
                        },
                        methods:{
                            
                        }
                    })
                }
            }
        }
    },
    mounted() {
        this.getData();
        this.getDivisions();
        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created() {
        this.id = this.$route.query.id ? parseInt(this.$route.query.id) : null;
        this.$eventHub.$on("detailsVM", (id) => {
            this.viewDetails(id);
        });
    },
    methods: {
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.dataGrid.getColumns()[3].visible = true;
                    this.$refs.dataGrid.getColumns()[4].visible = true;
                    this.$refs.dataGrid.getColumns()[5].visible = true;
                    this.$refs.dataGrid.getColumns()[6].visible = false;
                    this.$refs.dataGrid.getColumns()[7].visible = false;

                    this.$refs.dataGrid.pdfExport({
                        pageOrientation: 'Landscape',
                        fileName: 'Division Gap Analysis.pdf',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  `Division Gap Analysis`,
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
                case "Excel Export":
                    this.$refs.dataGrid.getColumns()[3].visible = true;
                    this.$refs.dataGrid.getColumns()[4].visible = true;
                    this.$refs.dataGrid.getColumns()[5].visible = true;
                    this.$refs.dataGrid.getColumns()[6].visible = false;
                    this.$refs.dataGrid.getColumns()[7].visible = false;
                this.$refs.dataGrid.excelExport({
                        fileName: 'Division Gap Analysis.xlsx',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  `Division Gap Analysis`,
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                });
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        getData(){
            let grid = this.$refs.dataGrid;
 
            if(this.id !== null){
                this.loading = true;
                this.loaded = false;
                this.axios
                .get(`${configObject.apiBaseUrl}/GapAnalysis?divisionid=${this.id}`, configObject.authConfig)
                .then(response => {
                    let index = 0;
                    //let fullList = [];
                    response.data.profiles.forEach(a => {
                        index++;
                        let g= parseInt(a.employeeAveragePoint) - parseInt(a.totalRequiredPoints);
                        a.index = index;
                        a.gap = Math.abs(g);
                        a.arrow = g > 0 ? "fa-caret-up green" : g < 0 ? "fa-caret-down red" : "fa-minus gray"
                        //fullList.push(a);
                    });
                    let data = {};
                    data.divisionId = response.data.divisionId;
                    data.divisionName = response.data.divisionName;
                    data.unitId = response.data.unitId;
                    data.unitName = response.data.unitName;
                    data.branchId = response.data.branchId;
                    data.branchName = response.data.branchName;

                    this.pageObject = data;
                    this.pageList = response.data.profiles; //.sort(this.sortArrayReverse("totalGap", "designationName")); //response.data.employees;
                    //this.designation = response.data.designation;

                    grid.ej2Instances.setProperties({ dataSource: this.pageList });
                    this.refreshGrid();
                    this.loading = false;
                    this.loaded = true;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.loaded = false;

                    this.$toast.open({
                        message: "Unable to load data",
                        type: "error",
                        duration: 4000
                    });
                });
            }
        },
        getDivisions(){
            this.axios.get(`${configObject.apiBaseUrl}/Divisions`, configObject.authConfig)
            .then(response => {
                this.divisions = response.data;
            })
            .catch((error) => {
                console.log(error);
                // this.$toast.open({
                //     message: "Unable to load Divisions",
                //     type: "error",
                //     duration: 4000
                // });
            });
        },
        findSuccessor(){
            if(this.id === NaN || this.id === 'NaN' || this.id === null){
                alert('Please select a Division to find');
            }
            else{
                this.getData();
            }
        },
        filtering: function(e) {
           var query = new Query();
            //frame the query based on search string with filter type.
            query = (e.text != "") ? query.where("designationName", "startswith", e.text, true) : query;
            //pass the filter data source, filter query to updateData method.
            e.updateData(this.divisions, query);
        },
        viewDetails(bid){
            this.$router.push({
                name: 'gap_analysis_branch', 
                query: {
                    id: this.id,
                    branchid: bid
                }
            });
        }
    }
}
</script>

<style scoped>
    
</style>