import Vue from "vue";
import Vuex from "vuex";
import Router from "@/router/index";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mobileNavDrawer: false,
    userInfo: null,
    branchDetailsObj: null,
    selectedCompLevelsArray: [],
    selectedReqTypesArray: [],
    tokenGotten: false,
    employeeGapAnalysis: []
  },
  getters: {
    getEmployeeGapAnalysis(state){
      return state.employeeGapAnalysis;
    },
    getUserToken: (state) => {
      return state.tokenGotten;
    },
    getUserInfo(state) {
      return state.userInfo;
    },
    getMobileNavDrawer(state) {
      return state.mobileNavDrawer;
    },
    getBranchdetails(state) {
      return state.branchDetailsObj;
    },
    getSelectedCompLevelsArray(state){
      return state.selectedCompLevelsArray;
    },
    getSelectedReqTypesArray(state){
      return state.selectedReqTypesArray;
    },
  },
  mutations: {
    setGapAnalysis(state, payload){
      state.employeeGapAnalysis = payload;
    },
    setTokenVal(state, payload){
      state.tokenGotten = payload;
    },
    updateCompsDropDownArray(state, payload){
      state.selectedCompLevelsArray[payload.compIndex] = payload.compsProps;
    },
    updateCompsDropDownArray(state, payload){
      state.selectedCompLevelsArray[payload.compIndex] = payload.compsProps;
    },
    updateReqDropDownArray(state, payload){
      state.selectedReqTypesArray[payload.reqIndex] = payload.reqProps;
    },
    toggleMobileNavDrawer(state) {
      state.mobileNavDrawer = !state.mobileNavDrawer;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setBranchDetails(state,  payload){
      state.branchDetailsObj = payload;
    },
    setSelectedCompLevelsArray(state, payload){
      state.selectedCompLevelsArray.push(payload);
    },
    setSelectedReqTypesArray(state, payload){
      state.selectedReqTypesArray.push(payload);
    }
  },
  actions: {
    logout() {
      localStorage.clear();
      Router.push({name: 'login'});
      //window.location.reload();
    },
  },
  modules: {}
});

