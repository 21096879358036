<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">

             <div class="welcome_message">
                 <div class="row">
                    <div class="col-8">    
                        <h5 class=''>
                            Map branches to Division <span class="greeeting__text">"{{division.name}}"</span>.
                            There are <span class="branches__count"> {{branches.length}}</span> branches available
                        </h5> 
                    </div>
                    <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn default" @click="backToDivision"><i class="fa fa-arrow-left"></i> Back</button>
                            <button class="creaet_branch_btn" @click="mapDivisionToBranches" :disabled="isButtonDisabled ? true : null">
                                Map Branches to Division
                                <span class="">
                                    <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                                </span>
                            </button>                            
                        </div>
                     </div>
                 </div>
             </div>
             <section class="table__section mt-1 pb-5">
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column headerText="#" :headerTemplate="selectAllTemplate" :template="checkBoxTemplate" width="60px"></e-column>
                        <e-column field="name" headerText="Branch Name"></e-column>
                        <e-column field="objective" headerText="Objective" ></e-column>
                        <e-column field="" headerText="" width="1px"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
            <div class="empty_branch__div mt-3" v-if="branches.length < 1">
                <div class="text-center">
                    <h6 class="text-center"> Please wait while we fetch Units</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
// import CheckBoxComponent from '@/components/CheckBoxComponent.vue';
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    components: {
        MasterLayout
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            showLoader: false,
            isButtonDisabled: false,
            backgroundUrl,
            UserDP,
            componentKey: 0,
            units: [],
            unit: {},
            branchesArray: [],
            branches: [],
            divisions: [],
            division: {},
            divisionsArray: [],
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            checkBoxTemplate: function() {
                return {
                    template: Vue.component("columnTemplate", {
                        template: `<div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input chkBox" v-bind:id="data.id" v-if="data.selected" v-on:change="_createMappedbranchesArray($event, data)" checked />
                            <input type="checkbox" class="custom-control-input chkBox" v-bind:id="data.id" v-if="!data.selected" v-on:change="_createMappedbranchesArray($event, data)" />
                            <label class="custom-control-label" v-bind:for="data.id"></label>
                        </div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _createMappedbranchesArray: function(e, branch) {
                                //console.log(branch);
                                this.$eventHub.$emit("branchesCheck", e, branch);
                            }
                        }
                    })
                };
            },
            selectAllTemplate: function() {
                return {
                    template: Vue.component("columnTemplate", {
                        template: `<div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="selectAll" v-on:change="_selectAll($event)"/>
                            <label class="custom-control-label" for="selectAll"></label></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _selectAll: function(e) {
                                this.$eventHub.$emit("selectAllVM", e);
                            }
                        }
                    })
                };
            }
        }
    },
    mounted() {
        console.log('Second CALL');
        this.getBranches();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created() {
        console.log('First CALL');
        //this.getDivision();
        this.$eventHub.$on("deleteBranch", (branchId) => {
            this.deleteBranch(branchId);
        });
        this.$eventHub.$on("editBranch", (branchId) => {
            this.editBranch(branchId);
        });
        this.$eventHub.$on("branchesCheck", (event, branch) => {
            this.createMappedbranchesArray(event, branch);
        });
         this.$eventHub.$on("selectAllVM", event => {
            this.selectAll(event);
        });
    },
    methods: {
        selectAll(event) {
            event.preventDefault();
            let _this = this;
            if (event.target.checked) {
                $(":checkbox").each(function() {
                    this.checked = true;
                });
                
                this.branches.forEach(unit => {
                    this.branchesArray.push({id: unit.id});
                })
            } else {
                $(":checkbox").each(function() {
                    this.checked = false;
                });
                _this.branchesArray = [];
            }
        },
        createMappedbranchesArray(event, branchInfo){
            if (!event.target.checked) {
                // !Checked: Remove
                let newMappedbranchesArray = this.branchesArray.filter(
                    branch => branch.id !== branchInfo.id
                );
                this.branchesArray = newMappedbranchesArray;
                $("#selectAll").prop('checked', false);
            } else {
                // Checked: Adding
                this.branchesArray.push({
                    name: branchInfo.name,
                    id: branchInfo.id
                });
                if($(".chkBox:not(:checked)").length <= 0){
                    //console.log('all selected');
                    $("#selectAll").prop('checked', true);
                };
            }
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        getDivision() {
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Divisions/FullDetail/${this.$route.query.id}`,
                    configObject.authConfig
                )
                .then(response => {
                    console.log("DIVISION=>" + JSON.stringify(response.data));
                    this.division = response.data;
                })
                .catch((error) => {
                    console.log(error);
                    // this.$toast.open({
                    //     message: "unable to load divisions",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
        mapDivisionToBranches() {
            console.log(configObject.authConfig);
            this.showLoader = true;
            this.isButtonDisabled = true;
            let mappedbranchesArrayToSend = [];
            let data = {}
            this.branchesArray.forEach((branch) => {
                mappedbranchesArrayToSend.push(branch.id);
            });
            data.listOfIds = mappedbranchesArrayToSend;
            console.log(data);
            let url = `${configObject.apiBaseUrl}/Divisions/MapToBranch/${parseInt(this.$route.query.id)}`;
            console.log(url);   
            this.axios.post(url, data, configObject.authConfig)
                .then((res) => {
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    this.$toast.success(`Branches mapped to Division successfully!`);
                    console.log(res.data);
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "An Error Occured",
                        type: "error",
                        duration: 4000
                    });
                    console.log(error);
                });
        },
        getBranches() {
            let grid = this.$refs.dataGrid;
            
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Divisions/BranchForMap/${this.$route.query.id}`,
                    configObject.authConfig
                )
                .then(response => {
                     response.data.branches.forEach(branch => {
                        if(branch.selected){
                            this.branchesArray.push({
                                id: branch.id
                            });
                        }
                    });
                    this.branches = response.data.branches;
                    this.division = response.data.division;
                    //console.log(this.branches);
                    grid.ej2Instances.setProperties({ dataSource: this.branches });
                    this.refreshGrid();
                })
                .catch((error) => {
                    console.log(error);
                    // this.$toast.open({
                    //     message: "Unable to load Branched for mapping to Division",
                    //     type: "error",
                    //     duration: 4000
                    // });
                });
        },
        backToDivision(){
            this.$router.push({
                name: 'divisions'
            });
        }
    }
}
</script>

        