<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">
             <div class="first__banner" :style="{
                backgroundImage: `linear-gradient(rgb(32, 28, 22 , 0.92), rgb(32, 28, 22 , 0.92)), url(${backgroundUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: '79% 50%'}">
                <a title="Back to Employee List" @click="backToEmployee" class="icon____div set_up_icon" style="background:rgb(242, 255, 245); width: 50px; height:50px; left: 60px;">
                    <i class="fa fa-arrow-left" aria-hidden="true" style="color:#069E2A;font-size: 25px;"></i>
                </a>
                <!-- <a title="Go Back" onClick="window.history.go(-1); return false;" class="icon____div set_up_icon" style="background:rgb(242, 255, 245); width: 50px; height:50px; left: 60px;">
                    <i class="fa fa-arrow-left" aria-hidden="true" style="color:#069E2A;font-size: 25px;"></i>
                </a> -->
                <div class="profile__pic__wrapper" >
                    <div class="profile__pic" :style="{
                        backgroundImage: `url(${employee.passportUrl})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'}">
                    </div>
                    <div class="user_name text-center text-white mt-3"> {{employee.firstname}} {{employee.surname}}</div>
                    <!-- <p class="grey__text text-center text-white mt-3">}</p> -->
                    <div class="grey__text text-center text-white mt-2">
                        <span class="" style="">{{employee.designationName}}</span>
                    </div>
                    <div class="grey__text text-center text-white mt-2">
                        <span class="">{{employee.employeeId}}</span>
                    </div>
                    <div class="grey__text text-center text-white mt-2">
                        <img src="@/assets/img/mail.png" class="mr-2" width="20px" /><span class="mr-5"> {{employee.email}}</span>
                        <img src="@/assets/img/call-white.png" class="mr-2" width="20px" /> {{employee.phoneNumber}}
                    </div>
                    
                </div>
                <a title="Account Setup" @click="setupEmployee()" class="icon____div set_up_icon" style="background:rgb(242, 255, 245); width: 50px; height:50px ">
                    <i class="fa fa-cogs" aria-hidden="true" style="color:#069E2A;font-size: 25px;"></i>
                </a>
            </div>
            <div class="first__tabs__Section">
                <div class="sec_tab">
                    <img src="@/assets/img/building_green.png" class="mt-3" width="24px"  />
                    <p class="mt-2 first__tabs__section__para">Unit</p>
                    <h6 class="mt-2 theme__color first__tab__bolde__text text-center poppins" >{{employee.unitName}}</h6>
                </div>
                <div class="sec_tab">
                    <img src="@/assets/img/building (1).png" class="mt-3" width="24px"  />
                    <p class="mt-2 first__tabs__section__para">Branch</p>
                    <h6 class="mt-2 theme__color first__tab__bolde__text text-center poppins">{{employee.branchName}}</h6>
                </div>
                <div class="sec_tab">
                    <img src="@/assets/img/office-desk.png" class="mt-3" width="24px"  />
                    <p class="mt-2 first__tabs__section__para">Division</p>
                    <h6 class="mt-2 theme__color first__tab__bolde__text text-center poppins">{{employee.divisionName}}</h6>
                </div>
                <div class="sec_tab">
                    <img src="@/assets/img/grade.png" class="mt-3" width="24px"  />
                    <p class="mt-2 first__tabs__section__para">Job Grade</p>
                    <h6 class="mt-2 theme__color first__tab__bolde__text text-center poppins">{{employee.gradeLevel}}</h6>
                </div>
                <div class="sec_tab link" v-on:click="viewSuccessionPlan()">
                    <img src="@/assets/img/growth.png" class="mt-3" width="24px"  />
                    <p class="mt-2 first__tabs__section__para">View Succession Plan</p>
                    <!-- <h5 class="mt-2 theme__color first__tab__bolde__text text-center poppins">Succession Plan</h5> -->
                </div>
            </div>
        </div>
        <div class="" style="">
            <b-card no-body>
                <b-tabs card class="employee_tabs" fill>
                    <b-tab title="Competencies" active>
                        <b-card-text class="employee-tab-content">
                            <div class="tab-content-body" v-show="myCompetencies.length > 0">
                                <vueper-slides                            
                                    class="no-shadow"
                                    :visible-slides="4"
                                    slide-multiple
                                    :gap="0"
                                    :slide-ratio="1 / 4"
                                    :dragging-distance="200"
                                    :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }">
                                    <vueper-slide  v-for="(compGroup, i) in competencyGroups" :key="i">
                                        <template v-slot:content>
                                            <div class="second__tabs__Section" @click="checkActiveTab(compGroup.name)">
                                                <div class="tab__2 "  style="flex:0 0  100%" :class="[activeGroupName === compGroup.name ? 'active___tab' : null]" >
                                                    <div class="myround" >
                                                        <p> {{compGroup.abbr}} </p>
                                                    </div>
                                                    <p class="mt-2 text-center  poppins">{{compGroup.name}}</p>
                                                </div>
                                            </div>
                                        </template>
                                    </vueper-slide>
                                </vueper-slides>

                                <b-card  no-body class="for-panel">
                                    <ejs-grid                                        
                                        ref="dataGrid"
                                        :created="refreshGrid"
                                        :allowPaging="true"
                                        :allowSorting="true"
                                        :pageSettings="pageSettings"
                                        :toolbar="toolbar"
                                        :searchSettings="search"
                                        :allowExcelExport="true"
                                        :allowPdfExport="true"
                                        :toolbarClick="toolbarClick">
                                        <e-columns>
                                            <!-- <e-column field="index" headerText="#" width="80"></e-column> -->
                                            <e-column field="competency" headerText="Competency"></e-column>
                                            <e-column field="point" headerText="Current" width="110" textAlign="center"></e-column>
                                            <e-column field="required" headerText="Expected" width="110" textAlign="center"></e-column>
                                            <e-column :template="progressBarTemplate" width="120px" headerText="Gap Analysis" textAlign="center"></e-column>
                                            <e-column :template="viewCompetencyTemplate" width="110px" headerText="Actions"></e-column>
                                            
                                            <!-- <e-column field="competency" headerText="Competency" width="800"></e-column>
                                            <e-column :template="progressBarTemplate" width="150px" headerText="Gap Analysis"></e-column>
                                            <e-column :template="viewCompetencyTemplate" width="150px" headerText="Actions"></e-column> -->
                                        </e-columns>
                                    </ejs-grid>
                                    <!-- </b-card-body> -->
                                </b-card>
                            </div>
                            <div class="tab-content-body" v-if="myCompetencies.length < 1">
                                <div class="empty_branch__div mt-1 mb-3" style="width: 90.65%; height: 350px;">
                                    <div class="text-center">
                                        <h6 class="text-center">No Competencies yet</h6>
                                        <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                                    </div>
                                </div>
                            </div>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Job Roles">
                        <b-card-text class="employee-tab-content">
                            <div class="tab-content-body">
                                <ejs-grid                                    
                                    ref="jobRolesGrid"
                                    :created="refreshJobRoles"
                                    :allowPaging="true"
                                    :allowSorting="true"
                                    :pageSettings="pageSettings"
                                    :toolbar="toolbar"
                                    :allowExcelExport="true"
                                    :allowPdfExport="true"
                                    :searchSettings="search"
                                    :toolbarClick="toolbarClick"
                                    v-show="jobRoles.length > 0">
                                    <e-columns>
                                        <e-column field="index" headerText="#" width="50"></e-column>
                                        <e-column field="task" headerText="Job Roles"></e-column>
                                        <e-column width="30px" headerText="Actions"></e-column>
                                    </e-columns>
                                </ejs-grid>
                                <div class="empty_branch__div mt-1 mb-3" v-if="jobRoles.length < 1" style="width: 90.65%; height: 350px;">
                                    <div class="text-center">
                                        <h6 class="text-center">No Job Roles defined yet</h6>
                                        <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                                    </div>
                                </div>
                            </div>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Training">
                        <b-card-text class="employee-tab-content">
                            <div class="tab-content-body">
                                <ejs-grid                   
                                    ref="emeployeeAssessmentGrid"
                                    :created="refreshAssessments"
                                    :allowPaging="true"
                                    :allowSorting="true"
                                    :pageSettings="pageSettings"
                                    :toolbar="toolbar"
                                    :searchSettings="search"
                                    :allowExcelExport="true"
                                    :allowPdfExport="true"
                                    :toolbarClick="toolbarClick"
                                    v-show="assessments.length > 0">
                                    <e-columns>
                                        <e-column field="index" headerText="#" width="60px"></e-column>
                                        <e-column field="trainingName" headerText="Training" width=""></e-column>
                                        <e-column field="trainingDate" headerText="Training Date" width=""></e-column>
                                        <e-column field="employeeComment" headerText="Employee Comment"></e-column>  
                                        <e-column field="competencyCount" headerText="Competencies"  width="120px"></e-column> 
                                        <e-column field="documentCount" headerText="Documents" width="120px"></e-column> 
                                        <e-column :template="aStatusTemplate" headerText="Status" width="120px"></e-column> 
                                        <e-column :template="assessmentActionTemplate" width="80px" headerText="Actions"></e-column>
                                    </e-columns>
                                </ejs-grid>
                                <div class="empty_branch__div mt-1 mb-3" v-show="assessments.length < 1" style="width: 90.65%; height: 350px;">
                                    <div class="text-center">
                                        <h6 class="text-center">No Assessment yet</h6>
                                        <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                                    </div>
                                </div>
                            </div>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Assessments">
                        <b-card-text class="employee-tab-content">
                            <div class="tab-content-body">
                                <ejs-grid                   
                                    ref="appraisalGrid"
                                    :created="refreshAppraisals"
                                    :allowPaging="true"
                                    :allowSorting="true"
                                    :pageSettings="pageSettings"
                                    :toolbar="toolbar"
                                    :searchSettings="search"
                                    :allowExcelExport="true"
                                    :allowPdfExport="true"
                                    :toolbarClick="toolbarClick"
                                    v-show="appraisals.length > 0">
                                    <e-columns>
                                        <e-column field="index" headerText="#" width="60px"></e-column>
                                        <!-- <e-column field="trainingName" headerText="Training" width=""></e-column> -->
                                        <e-column field="trainingDate" headerText="Date Submitted" width="140"></e-column>
                                        <e-column field="employeeComment" headerText="Assessment Note"></e-column>  
                                        <e-column field="competencyCount" headerText="Competencies"  width="120px"></e-column> 
                                        <e-column field="documentCount" headerText="Documents" width="120px"></e-column> 
                                        <e-column :template="aStatusTemplate" headerText="Status" width="120px"></e-column> 
                                        <e-column :template="appraisalActionTemplate" width="80px" headerText="Actions"></e-column>
                                    </e-columns>
                                </ejs-grid>
                                <div class="empty_branch__div mt-1 mb-3" v-show="appraisals.length < 1" style="width: 90.65%; height: 350px;">
                                    <div class="text-center">
                                        <h6 class="text-center">No Appraisals yet</h6>
                                        <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                                    </div>
                                </div>
                            </div>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Responsibilities">
                        <b-card-text class="employee-tab-content">
                            <div class="tab-content-body">
                                <ejs-grid
                                    ref="jobRespsGrid"
                                    :created="refreshJobRespsGrid"
                                    :allowPaging="true"
                                    :allowSorting="true"
                                    :pageSettings="pageSettings"
                                    :toolbar="toolbar"
                                    :searchSettings="search"
                                    :allowExcelExport="true"
                                    :allowPdfExport="true"
                                    :toolbarClick="toolbarClick"
                                    v-show="jobResps.length > 0">
                                    <e-columns>
                                        <e-column field="index" headerText="#" width="50px"></e-column>
                                        <e-column field="responsibilityName" headerText="Responsibility"></e-column>
                                        <e-column  width="50px" headerText=""></e-column>
                                    </e-columns>
                                </ejs-grid>
                                <div class="empty_branch__div mt-1 mb-3" v-show="jobResps.length < 1" style="width: 90.65%; height: 350px;">
                                    <div class="text-center">
                                        <h6 class="text-center">No Job Responsibilities yet</h6>
                                        <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                                    </div>
                                </div>
                            </div>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Requirements">
                        <b-card-text class="employee-tab-content">
                            <div class="tab-content-body">
                                <ejs-grid
                                    ref="jobReqGrid"
                                    :created="refreshJobReqGrid"
                                    :allowPaging="true"
                                    :allowSorting="true"
                                    :pageSettings="pageSettings"
                                    :toolbar="toolbar"
                                    :searchSettings="search"
                                    :allowExcelExport="true"
                                    :allowPdfExport="true"
                                    :toolbarClick="toolbarClick"
                                    v-show="jobReqs !== undefined && jobReqs.length > 0">
                                    <e-columns>
                                        <e-column field="index" headerText="#" width="50px"></e-column>
                                        <e-column field="task" headerText="Requirement"></e-column>
                                        <e-column field="typeName" headerText="Requirement Type" width="350px"></e-column>
                                        <e-column  width="50px" headerText=""></e-column>
                                    </e-columns>
                                </ejs-grid>
                                <div class="empty_branch__div mt-1 mb-3" v-show="jobReqs === undefined || jobReqs.length < 1" style="width: 90.65%; height: 350px;">
                                    <div class="text-center">
                                        <h6 class="text-center">No Job Requirements yet</h6>
                                        <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                                    </div>
                                </div>
                            </div>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Job History">
                        <b-card-text class="employee-tab-content">
                            <div class="tab-content-body">
                                <ejs-grid
                                    ref="jobHistoryGrid"
                                    :created="refreshJobHistoryGrid"
                                    :allowPaging="true"
                                    :allowSorting="true"
                                    :pageSettings="pageSettings"
                                    :toolbar="toolbar"
                                    :searchSettings="search"
                                    :allowExcelExport="true"
                                    :allowPdfExport="true"
                                    :toolbarClick="toolbarClick"
                                    v-show="jobHistories.length > 0">
                                    <e-columns>
                                        <e-column field="index" headerText="#" width="50px"></e-column>
                                        <e-column field="newJobRole" headerText="New Job Role"></e-column>
                                        <e-column field="previousJobRole" headerText="Previous Job Role"></e-column>
                                        <e-column field="date" headerText="Date Changed"></e-column>
                                        <e-column width="50px" headerText=""></e-column>
                                    </e-columns>
                                </ejs-grid>
                                <div class="empty_branch__div mt-1 mb-3" v-show="jobHistories.length < 1" style="width: 90.65%; height: 350px;">
                                    <div class="text-center">
                                        <h6 class="text-center">No Job history yet</h6>
                                        <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                                    </div>
                                </div>
                            </div>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>

        <section class="table__section  mb-5">
            
            
             
            
            <ViewCompetencyModal :competency="competency"></ViewCompetencyModal>
            <EmployeeCompetencyDetailsModal :empoyeeCompetency="empoyeeCompetency"></EmployeeCompetencyDetailsModal>
        </section>

    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
//import UserDP from "@/assets/img/username-icon.svg";
import UserDP from "@/assets/img/avatar.svg";
import configObject from "@/config";
import ViewCompetencyModal from '@/components/Modals/Competency_modal/competencyDetailsModal.vue';

import EmployeeCompetencyDetailsModal from '@/components/Modals/Competency_modal/employeeCompetencyDetailsModal.vue';
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import moment from 'moment';

export default {
    title: 'Employee Details',
    components: {
        MasterLayout,
        VueperSlides, 
        VueperSlide,
        ViewCompetencyModal,
        EmployeeCompetencyDetailsModal,
    },
    provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            backgroundUrl,
            UserDP,
            jobRoles: [],
            jobRole: {},
            employee: {},
            jobResps: [],
            jobResp: {},
            jobReqs: [],
            jobReq: {},
            empoyeeCompetency: {},
            jobHistories: [],
            assessments: [],
            appraisals: [],
            id: 0,
            competencies: [],
            competency: {},
            myCompetencies :[],
            newCompetencies: [],
            listOfCompetencies: [],
            competencyGroups: [],
            activeGroupName: null,
            activeTab: "employee competencies",
            progressBarTemplate: function(){
                return {
                     template: Vue.component("deletedTemplate", {
                        template:   `<div>  
                                        <div id="progressbar" v-if="$data.data.gapAnalysis >= 0 && $data.data.gapAnalysis <= 25">
                                            <div class="progress__bar__wrapper" style="background-color: red" v-bind:style="{ width: $data.data.gapAnalysis + '%'}"> 
                                                <div class="inner__div" style="border: 2px solid red;background-color: red;"></div>
                                            </div>
                                        </div>
                                        <div id="progressbar" v-if="$data.data.gapAnalysis >= 25 && $data.data.gapAnalysis <= 75">
                                            <div class="progress__bar__wrapper" style="background-color: yellow" v-bind:style="{ width: $data.data.gapAnalysis + '%'}">
                                                <div class="inner__div"></div>
                                            </div>
                                        </div>
                                         <div id="progressbar" v-if="$data.data.gapAnalysis > 75">
                                            <div class="progress__bar__wrapper" style="background-color: green" v-bind:style="{  width: $data.data.gapAnalysis + '%'}">
                                                <div class="inner__div"></div>
                                            </div>
                                        </div>
                                        <div class="text-center">{{$data.data.gapAnalysis}}%</div>
                                    </div>`,
                        data() {
                            return {
                            };
                        },
                    })
                }
            },
            documentViewTemplate: function() {
                return {
                    template: Vue.component("documentViewTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-success" title="Edit" v-on:click="_editUnit(data.id)">' +
                                '<i class=" fa fa-eye" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _deleteCompetency: function(compId) {
                                this.$eventHub.$emit("deleteCompetency", compId);
                            },
                            _editUnit: function(compId) {
                                this.$eventHub.$emit("editUnit", compId);
                            }
                        }
                    })
                };
            },
            actionButtonsTemplate() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-info" title="View" v-on:click="_viewCompetency(data.id)">' +
                                    '<i class="fa fa-eye" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _viewCompetency: function(compId) {
                                this.$eventHub.$emit("viewCompetency", compId);
                            },
                            _viewJobRole: function(jobRoleId) {
                                this.$eventHub.$emit("viewJobRole", jobRoleId);
                            },
                        }
                    })
                };
            },
            viewCompetencyTemplate() {
                return {
                    template: Vue.component("viewCompetencyTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                             '<button type="button" class="btn btn-info" title="View" v-on:click="_viewEmployeeCompetency(data.id)">' +
                                    '<i class="fa fa-eye" aria-hidden="true"></i>' +
                                '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _viewEmployeeCompetency(compId) {
                                this.$eventHub.$emit("viewEmployeeCompetencyVM", compId);
                            },
                        }
                    })
                };
            },
            aStatusTemplate(){
                return {
                    template: Vue.component("aStatusTemplate", {
                        template:
                        '<div>'+
                            `<b-badge pill variant="secondary" v-if="data.status === 'Pending'">{{data.status}}</b-badge>` +
                            `<b-badge pill variant="success" v-if="data.status === 'Approved'">{{data.status}}</b-badge>` +
                            `<b-badge pill variant="danger" v-if="data.status === 'Disapproved'">{{data.status}}</b-badge>` +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                };
            },
            assessmentActionTemplate(){
                return {
                    template: Vue.component("assessmentActionTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                             '<button type="button" class="btn btn-info btn-xs" title="Process Assessment" v-on:click="_processAssessment(data.id, data.employeeId)">' +
                                    '<i class="fa fa-eye" aria-hidden="true"></i>' +
                                '</button>' +
                        '</div>', 
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _processAssessment(assId, empId) {
                                this.$eventHub.$emit("processAssessmentVM", assId, empId);
                            },
                        }
                    })
                };
            },
            appraisalActionTemplate(){
                return {
                    template: Vue.component("appraisalActionTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                             `<button type="button" class="btn btn-info btn-xs" title="Process Assessment" v-on:click="_processAssessment(data.id, data.employeeId, 'app')">` +
                                    '<i class="fa fa-eye" aria-hidden="true"></i>' +
                                '</button>' +
                        '</div>', 
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _processAssessment(assId, empId, typ) {
                                this.$eventHub.$emit("processAssessmentVM", assId, empId, typ);
                            },
                        }
                    })
                };
            }
            
        }
    },
    mounted() {
        this.id = parseInt(this.$route.query.id);
        this.getEmployee();
        //this.getCompetencies();
        //this.getEmployeeAssessments();
        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created(){
        this.$eventHub.$on("viewEmployeeCompetencyVM", (compId) => {
            this.viewEmployeeCompetency(compId);
        });
        this.$eventHub.$on("processAssessmentVM", (assId, empId, typ) => {
            this.processAssessment(assId, empId, typ);
        });
        
        // this.id = parseInt(this.$route.query.id);
        this.$eventHub.$on("getGapAnalysis", () => {
            this.getEmployeeGapAnalysis();
        });
        this.$eventHub.$on("deleteCompetency", (compId) => {
            this.deleteCompetency(compId);
        });
        this.$eventHub.$on("viewCompetency", (compId) => {
            this.viewCompetency(compId);
        });
        this.$eventHub.$on("viewJobRole", (jobRoleId) => {
            this.viewJobRole(jobRoleId);
        });
        this.$eventHub.$on("viewJobResp", (jobRespId) => {
            this.viewJobResp(jobRespId);
        });
    },
    methods: {
        viewJobResp(jobRespId) {
            let jobRespDetail = this.jobReqs.filter(jobResp => jobReqs.id === jobRespId)[0];
            this.jobResp = jobRespDetail;
            console.log(jobDetail);
            this.openJobRespDetailModal();
        },
        viewJobRole(jobRoleId) {
            let jobDetail = this.jobRoles.filter(jobRole => jobRole.id === jobRoleId)[0];
            this.jobRole = jobDetail;
            console.log(jobDetail);
            this.openJobRoleDetail();
        },
        viewEmployeeCompetency(compId) {
            console.log('Viewing Competency 2');
            let compDetail = this.newCompetencies.filter(competency => competency.id === compId)[0];
            this.empoyeeCompetency = compDetail;
            this.openEmployeeCompDetail();
        },
        viewCompetency(compId) {
            let compDetail = this.listOfCompetencies.filter(competency => competency.id === compId)[0];
            this.competency = compDetail;
            this.opencompDetail();
        },
        openJobRoleDetail(){
            this.$modal.show("jobRoleDetail");
        },
        openJobRespDetailModal(){
            this.$modal.show("jobRespDetailModal");
        },
        opencompDetail(){
            this.$modal.show("competencyDetailsModal");
        },
        openEmployeeCompDetail(){
            this.$modal.show("employeeCompetencyDetailsModal");
        },
        changeTab($tabName){
            this.activeTab = $tabName;
        },
        checkActiveTab($activeGroupName){
            this.activeGroupName = $activeGroupName;
            this.getNewCompetencies($activeGroupName);
        }, 
        getNewCompetencies($activeGroupName){
            let _tableCompetencies = [];
            this.myCompetencies.find(comp => {
                if(comp.group === $activeGroupName) {
                    _tableCompetencies.push(comp);
                }
            });
            this.newCompetencies = _tableCompetencies;
            this.newCompetencies.forEach(cur => {
                if (cur.gapAnalysis > 100) {
                    cur.gapAnalysis = 100
                } 
            })
            this.getEmployeeCompetencies();
        },
        getEmployee(){
            this.axios
                .get(`${configObject.apiBaseUrl}/Employee/${this.id}`, configObject.authConfig)
                .then(response => {
                    let emp = response.data;
                    console.log(emp);
                    let index = 0;
                    if(emp.designationRoles !== null){
                        emp.designationRoles.forEach(r => {
                            index++;
                            r.index = index;
                        });
                    }
                    index = 0;
                    if(emp.designationResponsibilities !== null){
                        emp.designationResponsibilities.forEach(r => {
                            index++;
                            r.index = index;
                        });
                    }
                    index = 0;
                    if(emp.designationRequirements !== null){
                        emp.designationRequirements.forEach(r => {
                            index++;
                            r.index = index;
                        });
                    }
                    
                    index = 0;
                    if(emp.jobHistories !== null){
                        emp.jobHistories.forEach(r => {
                            index++;
                            r.index = index;
                            r.date = moment(r.dateAdded).format('DD-MMM-YYYY');
                        });
                    }

                    let _appraisals = [];
                    let _assessments = [];
                    index = 0;
                    let ii = 0;
                    if(emp.assessments !== null){
                        emp.assessments.forEach(r => {
                            r.trainingDate = moment(r.trainingDate).format('DD-MMM-YYYY');
                            if(r.assessmentType === 'Assessment'){
                                index++;
                                r.index = index;
                                _assessments.push(r);
                            }
                            else if(r.assessmentType === 'Appraisal'){
                                ii++;
                                r.index = ii;
                                _appraisals.push(r);
                            }
                        });
                    }

                    //empAss.trainingDate = moment(empAss.trainingDate).format('DD-MMM-YYYY');
                    this.employee = emp;
                    this.jobResps = emp.designationResponsibilities;
                    this.jobRoles = emp.designationRoles;
                    this.jobHistories = emp.jobHistories;
                    this.assessments = _assessments;
                    this.appraisals = _appraisals;
                    this.jobReqs = emp.designationRequirements;
                    this.getJobRoles();
                    this.getJobReqs();
                    this.getResps();
                    this.getAssessments();
                    this.getJobHistories();
                    this.getAppraisals();

                    //console.log(this.employee);
                    let newGroups = [];
                    index = 0;
                    emp.myCompetencies.forEach(competency => {
                        index++;
                        competency.index = index;
                        let findGroup = newGroups.find(ng => competency.group === ng.name);
                        if(!findGroup) {
                            newGroups.push({
                                id: competency.id,
                                name: competency.group,
                                abbr: competency.group.split(' ').map((item, index) => {
                                    if(index <= 1) return item[0].toUpperCase();
                                }).join('')
                            });
                        }
                    })
                    this.myCompetencies = emp.myCompetencies;
                    console.log(this.employee.passportUrl);
                    if(this.employee.passportUrl === null || this.employee.passportUrl.length <= 0){
                        //console.log('No Passport ... Use => ' + this.UserDP);
                        this.employee.passportUrl = this.UserDP;
                    }
                    
                    this.activeGroupName  = emp.myCompetencies[0].group;                    
                    this.competencyGroups = newGroups;
                    this.getNewCompetencies(this.activeGroupName);

                    //this.getNewCompetencies();
                })
                .catch((error) => {
                    console.log('ERROR::: ' + error);
                    // this.$toast.open({
                    //     message: "Unable to load Employee information",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
        getJobHistories(){
            this.showLoader = true;
            let grid = this.$refs.jobHistoryGrid;
            grid.ej2Instances.setProperties({ dataSource: this.jobHistories});
            this.refreshJobHistoryGrid();
        },
        getJobRoles(){
            let grid = this.$refs.jobRolesGrid;
            grid.ej2Instances.setProperties({ dataSource: this.jobRoles});
            this.refreshJobRoles();
        },
        getResps(){
            let grid = this.$refs.jobRespsGrid;
            grid.ej2Instances.setProperties({ dataSource: this.jobResps});
            this.refreshJobRespsGrid();
        },
        getJobReqs(){
            let grid = this.$refs.jobReqGrid;
            grid.ej2Instances.setProperties({ dataSource: this.jobReqs});
            this.refreshJobReqGrid();
        },
        getAssessments(){
            let grid = this.$refs.emeployeeAssessmentGrid;
            let ind = 0;
            this.assessments.forEach(el => {
                ind++;
                el.index = ind;
            })
            grid.ej2Instances.setProperties({ dataSource: this.assessments});
            this.refreshAssessments();
        },
        getAppraisals(){
            let grid = this.$refs.appraisalGrid;
            let ind = 0;
            this.appraisals.forEach(el => {
                ind++;
                el.index = ind;
            })
            grid.ej2Instances.setProperties({ dataSource: this.appraisals});
            this.refreshAppraisals();
        },
        // getEmployeeAssessments(){
        //     this.showLoader = true;
        //     let grid = this.$refs.emeployeeAssessmentGrid;
        //     this.axios
        //         .get(`${configObject.apiBaseUrl}/Employee/SelfAssessment/${this.$route.query.id}`, configObject.authConfig)
        //         .then(response => {
        //             this.listOfCompetencies = response.data;
        //             grid.ej2Instances.setProperties({ dataSource: response.data});
        //             this.refreshListOfCompetenciesGrid();
        //         })
        //         .catch((error) => {
        //             console.log('ERROR::: ' + error);
        //             this.$toast.open({
        //                 message: "Unable to load employee assessment",
        //                 type: "error",
        //                 duration: 3000
        //             });
        //         });
        // },
        getEmployeeCompetencies() {
            //this.showLoader = true;
            let grid = this.$refs.dataGrid;
            grid.ej2Instances.setProperties({ dataSource: this.newCompetencies});
            this.refreshGrid();
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        refreshJobRespsGrid(){
            this.$refs.jobRespsGrid.refresh();
        },
        refreshAssessments(){
            this.$refs.emeployeeAssessmentGrid.refresh();
        }, 
        refreshAppraisals(){
            this.$refs.appraisalGrid.refresh();
        },         
        refreshListOfCompetenciesGrid(){
            this.$refs.listOfCompetenciesGrid.refresh();
        },
        refreshJobRoles(){
            this.$refs.jobRolesGrid.refresh();
        },
        refreshJobHistoryGrid(){
            this.$refs.jobHistoryGrid.refresh();
        },
        refreshJobReqGrid(){
            this.$refs.jobReqGrid.refresh();
        },
        setupEmployee(){
            this.$router.push({name: 'employee_setup', query: {
                id: this.employee.id
            }});
        },
        backToEmployee(){
            this.$router.push({
                name: 'employees'
            });
        },
        viewSuccessionPlan(){
            this.$router.push({
                name: 'succession_Planning', 
                query: {
                    id: this.employee.designationId
                }
            });
        },
        processAssessment(assId, empId, _typ){
            localStorage.setItem('assessmentFrom', JSON.stringify('employee_assessment'))
            this.$router.push({
                name: 'process_assessment',
                query: {
                    id: assId,
                    emp: empId,
                    typ: _typ
                }
            });
        }
    }
}
</script>

