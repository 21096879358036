<template>
    <modal name="createEmployeeModal" transition="pop-out" :width="modalWidth" :height="640">
        <div class="modal__header">
            <h4>Add New Employee</h4>
        </div>
        <div class="cas_input">
            <div class="row__wrapper">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Firstname</span>
                            <div class="">
                                <input type="text" value="" v-model="firstname" required ref="inputs" /> 
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Surname</span>
                            <div class="">
                                <input type="text" value="" v-model="surname" /> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Login Email</span>
                            <div class="">
                                <input type="email" value="" v-model="email" /> 
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Phone Number</span>
                            <div class="">
                                <input type="text" value="" v-model="phoneNumber" /> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Gender</span>
                            <div class="">
                                <select ref="gender" class="ml-3 selector" v-model="gender" required>
                                    <option value="" disabled>Select Gender</option>
                                    <option value="Female">Female</option>
                                    <option value="Male">Male</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Grade Level</span>
                            <div class="">
                                <select ref="gradeLevel" class="ml-3 selector" v-model="gradeLevel" required>
                                    <option value="" disabled>Select Grade Level</option>
                                    <option v-for="d in grades" v-bind:key="d.id" v-bind:value="d.gradeName">{{d.gradeName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input_wrapper" style="width: 100%; margin-bottom: 0!important;">
                            <span>Employment Date</span>
                            <div class="">
                                <input type="date" value="" style="margin-bottom: 0!important; width: 100%;" v-model="employmentDate">
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <ejs-dropdownlist id='dropdownlist' placeholder='Select a Designation'
                            v-model="designationId"
                            :allowFiltering='allowFiltering' 
                            :filtering='filtering' 
                            :dataSource='profiles' 
                            :fields='fields'></ejs-dropdownlist>
                    </div>
                    <div class="col-md-7">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Passport</span>
                            <div class="">
                                <b-form-file
                                    v-model="file"
                                    :state="Boolean(file)"
                                    placeholder="Choose a file"
                                    drop-placeholder="Drop file here..."
                                    accept="image/jpeg, image/png, image/gif"></b-form-file>
                            </div>
                            <div class="mt-2">
                                <small class="">
                                    <i>
                                        <strong>N.B.:</strong> Only image files (jpg, png &amp; gif) are allowed and max file size of 400kb
                                    </i>
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Employee ID</span>
                            <div class="">
                                <input type="text" value="" v-model="employeeId" required ref="inputs" /> 
                            </div>
                        </div>
                    </div>
                </div>
                 <!-- <div class="row">   
                    
                   <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Designation</span>
                            <div class="">
                                <select ref="gradeLevel" class="ml-3 selector" v-model="designationId" required>
                                    <option value="Select a designation" disabled>Select a designation</option>
                                    <option v-for="(profile, index) in profiles" v-bind:key="index" v-bind:value="profile.id">{{profile.designationName}}</option>
                                </select>
                            </div>
                        </div>
                    </div> 
                </div>-->
            </div>
            <div class="text-center mt-3">
                <button class="creaet_branch_btn"
                    @click="validateDetails($event)"
                    :disabled="isButtonDisabled ? true : null"
                    :style="[
                    isButtonDisabled
                        ? { cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                    ]">
                    Add Employee <i class="fa fa-save"></i>
                    <span class="">
                        <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                    </span>
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
const MODAL_WIDTH = 800;
import configObject from "@/config";

export default {
    name: 'createEmployeeModal',
    props:  ['grades'],
    data(){
        return {
            firstname: "",
            surname: "",
            gender: "",
            phoneNumber: "",
            email: "",
            gradeLevel: "",
            passportUrl: "",
            designationId: '',
            employeeId: '',
            // grades: [],
            employmentDate: null,
            showLoader: false,
            isButtonDisabled: false,
            file: '',
            profiles: [],
            fields : { text: "displayText", value: "id" },
            allowFiltering: true,
            filtering: ''
        }
    },
    created(){
        this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
        this.cleanEntry();
    },
    mounted(){
        //this.getGrades();
        this.getProfiles()
    },
    methods: {
        getProfiles() {
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Designation/Profile`,
                    configObject.authConfig
                )
                .then(response => {
                    console.log(response.data)
                    response.data.forEach(p => {
                        p.displayText = `${p.designationName} [${p.divisionName} :: ${p.branchName}]`;
                    });
                    this.profiles = response.data
                })
                .catch((error) => {
                    console.log('ERROR ::: ' + error);
                    // this.$toast.open({
                    //     message: "unable to load profiles",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
        validateDetails($event){
            $event.preventDefault();
            console.log(`name: ${this.firstname}; surname: ${this.surname}; Gender: ${this.gender}; Phone: ${this.phoneNumber}; email: ${this.email}; Grade: ${this.gradeLevel}; ID: ${this.employeeId}`);
            if(!this.firstname || !this.surname || !this.gender || !this.phoneNumber || !this.email || !this.gradeLevel || !this.employeeId)
            {
                this.$toasted.show("Please complete all Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
            }
            else if(this.file && this.file.size > 400000){
                this.$toasted.show("File size more that 400kb", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
            }
            else {
                this.createEmployee();
            }
        },
        closeCreateEmployeeModal(){
            this.$modal.hide('createEmployeeModal');
        },
        createEmployee(){
            console.log('Creating Employee...');
            this.designationId = parseInt(this.designationId)
            
            this.showLoader = true;
            this.isButtonDisabled = true;
            let formData = new FormData();
            // console.log('File Size: ' + this.file.size);
            formData.append('file', this.file);
            //formData.append('employee', dataToSend);
            formData.append('firstname', this.firstname);
            formData.append('surname', this.surname);
            formData.append('gender', this.gender);
            formData.append('phoneNumber', this.phoneNumber); 
            formData.append('email', this.email);
            formData.append('gradeLevel', this.gradeLevel);
            formData.append('employeeId', this.employeeId);
            formData.append('employmentDate', this.employmentDate);
            formData.append('designationId', this.designationId);
            //console.log(formData);
            this.axios.post(`${configObject.apiBaseUrl}/Employee/Add`, formData, configObject.authConfigForUpload)
                .then(() => {
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    this.closeCreateEmployeeModal();
                    this.$eventHub.$emit("refreshEmployeeTable");
                    this.$toast.open({
                        message: "Employee created successfully",
                        type: "success",
                        duration: 4000
                    });
                    this.cleanEntry();
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "Unable to create employee",
                        type: "error",
                        duration: 4000
                    });
                    console.log(error);
                });
        },
        // getGrades(){
        //     this.axios.get(`${configObject.apiBaseUrl}/Grades`, configObject.authConfig)
        //     .then(resp => {
        //         this.grades = resp.data;
        //     });
        // },
        cleanEntry(){
            this.firstname = null;
            this.surname = null;
            this.gender = null;
            this.phoneNumber = null;
            this.email = null;
            this.gradeLevel = null;
            this.passportUrl = null;
            this.employeeId = null;
            this.employmentDate = null;
            this.file = null;
        },
        handleFileUpload(){
            this.file = this.$refs.file.files[0];
        }
    }
}
</script>
<style scoped>
    #dropdownlist_hidden.e-ddl-hidden{
        padding: 0!important;
    }
    .e-control-wrapper input{
        margin-bottom: 0px!important;
        padding: 7px 0;
        font-size: 15px;
    }
</style>