<template>
<modal name="mapDesignationModal" transition="pop-out" :width="modalWidth" :height="600" :key="modalKey">
  <div class="modal__header">
      <h4 v-if="designation !== null">Map roles to {{designation.designationName}}</h4>
  </div>
  <div class="cas_input">
        <div class="row__wrapper">
            <div class="row">
                <div class="col-md-12">
                    <h4>Currently mapped Roles</h4>
                    <div class="show-scroll" style="max-height: 350px; height:350px; overflow-y: auto; border-radius: 5px; border: 1px solid #ddd;">
                        <ul class="list-group">
                            <li class="list-group-item" v-for="r in allRoles" v-bind:key="r.id" v-bind:value="r.id">
                                <div class="custom-control custom-checkbox mr-3">
                                    <input type="checkbox" class="custom-control-input roleItem" v-if="myRoles.find(a => a.roleId === r.id)" v-bind:id="`role_${r.id}`" v-bind:value="r.id" checked>
                                    <input type="checkbox" class="custom-control-input roleItem" v-if="!myRoles.find(a => a.roleId === r.id)" v-bind:id="`role_${r.id}`" v-bind:value="r.id">
                                    <label class="custom-control-label" v-bind:for="`role_${r.id}`"> {{r.task}}</label>
                                </div>                               
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="text-center mt-4">
            <button class="creaet_branch_btn"
                @click="validateDetails($event)"
                :disabled="isButtonDisabled ? true : null"
                :style="[
                isButtonDisabled
                    ? { cursor: 'not-allowed' }
                    : { cursor: 'pointer' }
                ]">
                Save Mapping
                <span class="">
                    <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                </span>
            </button>
        </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 600;
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;

export default {
    name: 'mapDesignationModal',
    props:  ['designation', 'myRoles', 'allRoles'],
    data () {
        return {
            modalKey: 0,
            modalWidth: MODAL_WIDTH,
            isButtonDisabled:  false,
            showLoader: false,
            newRoles: []
        }
    },
    created () {
        this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;  
          
    },
    mounted(){
        $(document.body).on('click', '#testMe', function(){
            alert('Hello Jquery >> ' + this.designation);
        });
        //this.getRoles();
    },
    methods: {
        closeModal(){
            this.$modal.hide('mapDesignationModal');
        },
        validateDetails($event) {
            $event.preventDefault();
            this.newRoles = [];
            var checkboxes = document.getElementsByClassName('roleItem');
            console.log(checkboxes.length);
            let checked = 0;
            for (var i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].checked) {
                    checked += 1;
                    this.newRoles.push(parseInt(checkboxes[i].value));
                }
            }
            this.saveMapping();

        },
        saveMapping() {
            let data = {
                listOfIds: this.newRoles,
            };
            this.showLoader = true;
            this.isButtonDisabled = true;
            console.log(data);
            this.axios.post(`${configObject.apiBaseUrl}/Designation/MapRoles/${this.designation.id}`, data,  configObject.authConfig)
                .then(response => {
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    console.log(response.data);
                    this.closeModal();
                    this.$eventHub.$emit("refreshDesignationTable");
                    this.$toast.success("Designation Updated successfully");
                    })
                    .catch((error) => {
                        this.isButtonDisabled = false;
                        this.showLoader = false;
                        this.$toast.open({
                            message: "unable to save mapping",
                            type: "error",
                            duration: 4000
                        });
                        console.log(error);
                });
        },
        getRoles(){
            this.axios.get(`${configObject.apiBaseUrl}/RoleTask`, configObject.authConfig)
            .then(resp => {
                this.roles = resp.data;
                console.log('Get My roles');
                // this.axios.get(`${configObject.apiBaseUrl}/Designation/Roles/${this.designation.id}`, configObject.authConfig)
                // .then(resp => {
                //     console.log(resp.data);
                //     this.myRoles = resp.data;
                // });
            });
        },
        // getMyRoles(){
        //     this.axios.get(`${configObject.apiBaseUrl}/Designation/Roles/${this.designation.id}`, configObject.authConfig)
        //         .then(resp => {
        //             console.log(resp.data);
        //             this.myRoles = resp.data;
        //         });
        // }
    }
}
</script>
