<template>
    <MasterLayout>
        <div class="employees__assessment__wrapper">

            <div class="welcome_message">
                 <div class="row">
                     <div class="col-8">    
                        <h5 class=''>
                            <span class="greeeting__text"> Hi Admin,</span> you have 
                            <span class="branches__count"> {{roleTasks.length}} </span> Roles Tasks
                        </h5>
                     </div>
                     <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn" @click="openAddRoleModal()">
                                Add Role Task <i class="fa fa-plus"></i>
                            </button>
                        </div>
                     </div>
                 </div>
            </div>

            <section class="table__section mt-1 pb-5" v-show="roleTasks.length > 0">
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search" 
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" headerText="#" width="60px"></e-column>
                        <e-column field="task" headerText="Role Task"></e-column>
                        <e-column field="status" :visible='false' width="80px" headerText="Status"></e-column>
                        <e-column :template="deletedTemplate" width="80px" headerText="Status"></e-column>
                        <e-column :template="roleTaskButton" width="100px" headerText="Actions"></e-column>
                    </e-columns>
                </ejs-grid>
                <TableLoading v-show="showLoader"></TableLoading>
            </section>
            <div class="empty_branch__div mt-1" v-if="roleTasks.length < 1 && !showLoader">
                <div class="text-center">
                    <h6 class="text-center">No Role Task created yet</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
            
            <MapDesignatinoModal :designation ="designation" :myRoles="myRoles" :allRoles="roleTasks"></MapDesignatinoModal>
            <!-- <AddGradeModal></AddGradeModal> -->
            <EditRoleTasks :roleTask ="roleTask"></EditRoleTasks>
            <AddRoleTask></AddRoleTask>
        </div>

    </MasterLayout>
</template>

<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import MapDesignatinoModal from '@/components/Modals/DesignationProfile/mapDesignationModal.vue';

import configObject from "@/config";
import JQuery from "jquery";
// import AddGradeModal from '@/components/Modals/Grade/createGradeModal.vue'; 
import TableLoading from '@/components/TableLoading.vue'; 
// import EditGradeModal from '@/components/Modals/Grade/editGradeModal.vue'; 
import EditRoleTasks from '@/components/Modals/RoleTasks/editRoleTasks.vue';
import AddRoleTask from '@/components/Modals/RoleTasks/createRoleTask.vue';


let $ = JQuery;

import {
  Page,
  Sort,
  Search,
  ExcelExport,
  PdfExport,
  Toolbar
} from "@syncfusion/ej2-vue-grids";


export default {
    components: {
        MasterLayout,
        //AddGradeModal,
        //EditGradeModal,
        MapDesignatinoModal,
        EditRoleTasks,
        AddRoleTask,
        TableLoading
    },
    provide:{
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data(){
        return{
            roleTasks: [],
            myRoles: [],
            roleTask: {},
            designation: {},
            showLoader: false,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            roleTaskButton: function() {
                return {
                    template: Vue.component("roleTaskButton", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-primary" title="Edit" v-on:click="_editRoletask(data.id)">' +
                                '<i class="fa fa-edit" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-danger" title="Delete" v-on:click="_deleteRoleTask(data.id)" v-if="data.deleted === false">' +
                                '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-success" title="Re-Add" v-on:click="_readdRoleTask(data.id)" v-if="data.deleted === true">' +
                                '<i class="fa fa-plus" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            readdRoleTask: function(data) {
                                this.$eventHub.$emit("readdRoleTask", data)
                            },
                            _deleteRoleTask: function(roleTaskId) {
                                this.$eventHub.$emit("deleteRoleTaskVM", roleTaskId, 0);
                            },
                            _editRoletask: function(roleTaskId) {
                                this.$eventHub.$emit("editRoleTaskVM", roleTaskId);
                            },
                            _readdRoleTask: function(id){
                                this.$eventHub.$emit("deleteRoleTaskVM", id, 1);
                            }
                        }
                    })
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>` +
                                    `<div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            },
        }
    }, 
    created(){
        this.$eventHub.$on("refreshGradesTable", () => {
            this.getGrades();
        });
        this.$eventHub.$on("refreshRoleTaskTable", () => {
            this.getRoleTasks();
        });        
        this.$eventHub.$on("deleteGradeVM", (gradeId, typ) => {
            this.deleteGrade(gradeId, typ);
        });
        this.$eventHub.$on("editGrade", (gradeId) => {
            this.editGrade(gradeId);
        });
        this.$eventHub.$on("editRoleTaskVM", (roleTaskId) => {
            this.editRoleTask(roleTaskId);
        });
        this.$eventHub.$on("deleteRoleTaskVM", (roleTaskId, typ) => {
            this.deleteRoleTask(roleTaskId, typ);
        });
        this.$eventHub.$on("refreshRoleTasksTable", () => {
            this.getRoleTasks();
        });
        
    },
    mounted(){
        this.getRoleTasks();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    methods: {
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.dataGrid.getColumns()[2].visible = true;
                    this.$refs.dataGrid.getColumns()[3].visible = false;
                    this.$refs.dataGrid.getColumns()[4].visible = false;

                    this.$refs.dataGrid.pdfExport({
                        fileName: 'Role Task.pdf',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  'Role Task',
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
                case "Excel Export":
                    this.$refs.dataGrid.getColumns()[2].visible = true;
                    this.$refs.dataGrid.getColumns()[3].visible = false;
                    this.$refs.dataGrid.getColumns()[4].visible = false;
                    this.$refs.dataGrid.excelExport({
                        fileName: 'Role Task.xlsx',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  `Role Task`,
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
            }
        },
        editRoleTask(roleTaskId){
            let roleTaskDetail = this.roleTasks.filter(roleTask => roleTask.id === roleTaskId)[0];
            console.log(roleTaskDetail);
            this.roleTask = roleTaskDetail;
            this.openEditRoleTaskModal();
        },
        openEditRoleTaskModal(){
            this.$modal.show("editRoleTask");
        },
        openAddRoleModal(){
            this.$modal.show("addRoleTaskModal");
        },
        deleteRoleTask(roleTaskId, typ) {
            console.log(roleTaskId);
            var resp;
            
            if(typ === 0){
                resp = confirm("Are you sure  you want to delete this role task");
            }
            else{
                resp = confirm("Are you sure  you want to re-add this role task");
            }

            if (resp) {
                let msg = '';
                this.axios
                    .delete(
                        `${configObject.apiBaseUrl}/RoleTask/Delete/${roleTaskId}`,
                        configObject.authConfig
                    )
                    .then(() => {
                        if(typ === 0){
                            msg = "Role Task deleted successfully!";
                        }
                        else{
                            msg = "Role Task Re-added successfully!";
                        }
                        this.getRoleTasks();
                        this.$toast.open({
                            message: msg,
                            type: "success",
                            duration: 4000
                        });
                    })
                    .catch(error => {
                        console.log(error.response.data.message);
                        this.$toast.open({
                            message: error.response.data.message, //"unable to delete Role Task",
                            type: "error",
                            duration: 4000
                        });
                    });
            }
        },
        getRoleTasks(){
            this.showLoader = true;
            let grid = this.$refs.dataGrid;
            this.axios
                .get(`${configObject.apiBaseUrl}/RoleTask`, configObject.authConfig)
                .then(response => {
                    this.showLoader = false;
                    let index = 0;
                    response.data.forEach(role => {
                        index++;
                        role.index = index;
                        role.status = role.deleted ? 'deleted' : 'active'
                    });
                    this.roleTasks = response.data;
                    grid.ej2Instances.setProperties({ dataSource: this.roleTasks });
                    this.refreshGrid();
                })
                .catch((error) => {
                    console.log(error.response.data.message);
                    // this.$toast.open({
                    //     message: "Unable to load role tasks...",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
      
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        
        openModal(modalName){
            console.log('Open Modal :> ' + modalName);
            this.$modal.show(modalName);
        },
    }
}
</script>

<style scoped>

</style>