<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">

             <div class="welcome_message">
                 <div class="row">
                     <div class="col-8">    
                         <h5 class=''><span class="greeeting__text"> Hi Admin,
                            </span> you have <span class="branches__count"> {{profiles.length}} </span> Profiles
                        </h5> 
                     </div>
                     <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn" @click="openCreateProfileModal">Add Designation</button>
                        </div>
                     </div>
                 </div>
             </div>
             <section class="table__section mt-1 pb-5" v-show="!showLoader">
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search" 
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <!-- 0 -->
                        <e-column field="index" headerText="#" width="60px"></e-column>
                        <e-column field="id" headerText="ID" :visible="false" width="60px"></e-column>
                        <e-column field="designationName"  width="220px" headerText="Designation"></e-column>
                        <e-column field="divisionName" headerText="Division" width="150px"></e-column>
                        <e-column field="branchName" headerText="Branch" width="150px"></e-column>
                        <e-column field="unitName" headerText="Unit" width="150px"></e-column>
                        <!-- 6 -->
                        <e-column field="gradeName" headerText="Job Grade" width="100px"></e-column>
                        <e-column field="competencyCount" headerText="Competency" width="100px"></e-column>
                        <e-column field="requirementCount" headerText="Requirements" :visible="false" width="110px"></e-column>
                        <e-column field="responsibilityCount" headerText="Responsibilities" :visible="false" width="120px"></e-column>
                        <e-column field="roleCount" headerText="Roles" :visible="false" width="80px"></e-column>
                        <e-column :template="actionButtonsTemplate" headerText="Actions" width="120px"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
            <div class="empty_branch__div mt-3" v-if="profiles.length < 1 && !showLoader">
                <div class="text-center">
                    <h6 class="text-center"> You are yet to create a Profile</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
            <TableLoading v-show="showLoader"></TableLoading>
            
            <CreateProfileModal></CreateProfileModal>
            <EditProfileModal :profile ="profile"></EditProfileModal>
            <!-- <ProfileDetail :profile="profile"></ProfileDetail> -->
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import TableLoading from '@/components/TableLoading.vue'; 
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
import CreateProfileModal from '@/components/Modals/DesignationProfile/createProfileModal.vue';
import EditProfileModal from '@/components/Modals/DesignationProfile/editProfileModal.vue';
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    title: 'Designation Profiles',
    components: {
        MasterLayout,
        CreateProfileModal,
        EditProfileModal,
        TableLoading
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            backgroundUrl,
            UserDP,
            componentKey: 0,
            profiles: [],
            profile: {},
            showLoader: false,
            pageSettings: { pageSizes: [25, 50, 100, 200], pageCount: 24 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        '<div>' +
                       
                        '<div v-if="data.deleted === true">' +
                            '<span class="badge badge-danger">Deleted</span>&nbsp; &nbsp;' +
                            '<button type="button" class="btn btn-sm btn-success" title="Re-Add" v-on:click="_readdProfile(data.id)" v-if="data.deleted === true">' +
                                '<i class="fa fa-plus" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>' +
                        '<div class="btn-group btn-group-sm" role="group" v-if="data.deleted === false">'+
                             '<button type="button" class="btn btn-info" title="View" v-on:click="_viewDesigProfileDetail(data.id)">' +
                                    '<i class="fa fa-eye" aria-hidden="true"></i>' +
                            '</button>' +
                           
                            '<button type="button" class="btn btn-primary" title="Edit" v-on:click="_editProfile(data.id)">' +
                                '<i class="fa fa-edit" aria-hidden="true"></i>' +
                            '</button>'+
                            '<button type="button" class="btn btn-danger" title="Delete" v-on:click="_deleteProfile(data.id)">' +
                                '<i class="fa fa-trash" aria-hidden="true"></i>' +
                            '</button>'+
                        '</div></div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _editProfile: function(profileId) {
                                this.$eventHub.$emit("editProfileModel", profileId);
                            },
                            _deleteProfile: function(profileId) {
                                this.$eventHub.$emit("deleteProfileModel", profileId, 0);
                            },
                            _readdProfile: function(profileId) {
                                this.$eventHub.$emit("deleteProfileModel", profileId, 1);
                            },
                            _mapCompetencies: function(profileId) {
                                this.$eventHub.$emit("mapCompetenciesModel", profileId);
                            },
                            _mapRequirements: function(profileId) {
                                this.$eventHub.$emit("mapRequirementsModel", profileId);
                            },
                            _mapResponsibilities: function(profileId) {
                                this.$eventHub.$emit("mapResponsibilitiesModel", profileId);
                            },
                            _viewDesigProfileDetail: function(desigProfId) {
                                this.$eventHub.$emit("viewDesigProfileDetail", desigProfId);
                            },
                        }
                    })
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>
                                <div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            }            
        }
    },
     mounted() {
        this.getProfiles();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created() {
        this.$eventHub.$on("editProfileModel", (profileId) => {
            this.editProfile(profileId);
        });
        this.$eventHub.$on("refreshProfileTable", () => {
            this.getProfiles();
        });
        this.$eventHub.$on("mapCompetenciesModel", (profileId) => {
            this.mapCompetencies(profileId);
        });

        this.$eventHub.$on("mapRequirementsModel", (profileId) => {
            this.mapRequirements(profileId);
        });
        this.$eventHub.$on("mapResponsibilitiesModel", (profileId) => {
            this.mapResponsibilities(profileId);
        });
        this.$eventHub.$on("deleteProfileModel", (profileId, typ) => {
            this.deleteProfile(profileId, typ);
        });
        this.$eventHub.$on("viewDesigProfileDetail", (desigProfId) => {
            this.viewDesigProfileDetail(desigProfId);
        });
        
    },
    methods: {
        viewDesigProfileDetail(desigProfId) {
            this.$router.push({
                name: "designation_profile_detail",
                query: {
                    id: desigProfId
                }
            })
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.getColumns()[0].visible = false;
                this.$refs.dataGrid.getColumns()[1].visible = true;
                this.$refs.dataGrid.getColumns()[5].visible = true;
                this.$refs.dataGrid.getColumns()[6].visible = true;
                this.$refs.dataGrid.getColumns()[8].visible = true;
                this.$refs.dataGrid.getColumns()[9].visible = true;
                this.$refs.dataGrid.getColumns()[10].visible = true;
                this.$refs.dataGrid.getColumns()[11].visible = false;
                
                this.$refs.dataGrid.getColumns()[2].width = 140;
                this.$refs.dataGrid.getColumns()[3].width = 140;
                this.$refs.dataGrid.getColumns()[4].width = 140;
                this.$refs.dataGrid.getColumns()[5].width = 140;
                this.$refs.dataGrid.getColumns()[6].width = 55;
                this.$refs.dataGrid.getColumns()[7].width = 75;
                this.$refs.dataGrid.getColumns()[8].width = 75;
                this.$refs.dataGrid.getColumns()[9].width = 75;
                this.$refs.dataGrid.getColumns()[10].width = 55;

                this.$refs.dataGrid.pdfExport({
                    pageOrientation: 'Landscape',
                    fileName: 'Designation Profile.pdf',
                    theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                    },
                    header: {
                        fromTop:0,
                        height: 50,
                        contents:  [{
                            type: "Text",
                            value:  'Designation Profile',
                            position: {x:0, y: 0},
                            style: {fontSize: 20, textAlign: 'center'}
                        }],
                    },
                    footer: {
                        fromBottom: 0,
                        height: 50,
                        width: 50,
                        contents:  [{
                            type: "Text",
                            value: "Department of Petroleum Resources (DPR)",
                            position: {x: 0, y: 0},
                            style: {fontSize: 12}
                        }],                        
                    },
                });
                break;
                case "Excel Export":
                this.$refs.dataGrid.getColumns()[0].visible = false;
                this.$refs.dataGrid.getColumns()[1].visible = true;
                this.$refs.dataGrid.getColumns()[5].visible = true;
                this.$refs.dataGrid.getColumns()[6].visible = true;
                this.$refs.dataGrid.getColumns()[8].visible = true;
                this.$refs.dataGrid.getColumns()[9].visible = true;
                this.$refs.dataGrid.getColumns()[10].visible = true;
                this.$refs.dataGrid.getColumns()[11].visible = false;

                this.$refs.dataGrid.excelExport({
                    fileName: 'Designation Profile.xlsx',
                    theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 18
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 16
                        }
                    },
                    header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value: "Department of Petroleum Resources (DPR)",
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                        }],
                    },
                    footer: {
                        fromBottom: 130,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                        }],                    
                    }
                });
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        openCreateProfileModal(){
            this.$modal.show('createProfileModal');
        },
        openEditProfileModal(){
            this.$modal.show('editProfileModal');
        },
        openProfileDetail(){
            this.$modal.show('ProfileDetail');
        },
        
        mapCompetencies(profileId){
            this.$router.push({
                name: 'map_competencies_to_designation', 
                query: {
                    id: profileId
                }
            });
        },
        mapResponsibilities(respId){
            this.$router.push({
                name: 'map_responsibilities_to_deisgnation', 
                query: {
                    id: respId
                }
            });
        },
        mapRequirements(profileId){
            this.$router.push({
                name: 'map_requirements_to_designation', 
                query: {
                    id: profileId
                }
            });
        },
        editProfile(profileId) {
            let ProfileDetail = this.profiles.filter(profile => profile.id === profileId)[0];
            
            this.profile = ProfileDetail;
            // console.log('Profile to Edit: ' + profileId);
            // console.log(this.profile);
            this.openEditProfileModal();
        },
        deleteProfile(profileId, typ) {
            let resp = false;
            if(typ === 0){
                resp = confirm("Are you sure you want to delete this Designation Profile");
            }
            else{
                resp = confirm("Are you sure you want to Re-Add this Designation Profile");
            }

            if (resp) {
                this.axios
                    .delete(
                        `${configObject.apiBaseUrl}/Designation/Profile/Delete/${profileId}`,
                        configObject.authConfig
                    )
                    .then(() => {
                        this.getProfiles();
                        let msg = "";

                        if(typ === 0){
                            msg = "Designation Profile Deleted successfully!"
                        }
                        else{
                            msg = "Designation Profile Re-Added successfully!"
                        }

                        this.$toast.open({
                            message: msg,
                            type: "success",
                            duration: 4000
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        this.$toast.open({
                            message: "unable to perform action",
                            type: "error",
                            duration: 4000
                        });
                    });
            }
        },
        getProfiles() {
            this.showLoader = true;
            let grid = this.$refs.dataGrid;
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Designation/Profile`,
                    configObject.authConfig
                )
                .then(response => {
                    let index = 0;
                    response.data.forEach(profile => {
                        index++;
                        profile.index = index;
                    });
                    this.profiles = response.data;
                    this.showLoader = false;

                    grid.ej2Instances.setProperties({ dataSource: this.profiles });
                    this.refreshGrid();
                })
                .catch((error) => {
                    console.log('ERROR ::: ' + error);
                    // this.$toast.open({
                    //     message: "unable to load profiles",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
    }
}
</script>