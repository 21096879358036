<template>
    <modal name="createBatchEmployeeModal" transition="pop-out" :width="modalWidth" :height="400">
        <div class="modal__header">
            <h4>Add Multiple Employees</h4>
        </div>
        <div class="cas_input">
            <div class="row__wrapper">
                <p>
                    <a :href="`${url}`" target="_blank">Click here</a> to download the Excel Template to capture the accepted Employee list format.
                    <br>
                    <br>
                </p>
                <div class="row">
                    
                    <div class="col-md-12">
                        <div class="input_wrapper" style="width: 100%">
                            <div class="">
                                <b-form-file
                                    v-model="file"
                                    :state="Boolean(file)"
                                    placeholder="Choose a file or drop here"
                                    drop-placeholder="Drop file here..."
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    ></b-form-file>
                                <!-- v-on:change="handleFileUpload(this)" <input type="file" name="file" id="file" ref="file" />  -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <small><i><b>N.B.:</b> Please note that only Excel (*.xls &amp; *.xlsx) files are allowed and max size of 2mb</i></small>
                    </div>
                </div>
            </div>
            <div class="text-center mt-4">
                <button class="creaet_branch_btn"
                    @click="uploadEmployees()"
                    :disabled="isButtonDisabled ? true : null"
                    :style="[
                    isButtonDisabled
                        ? { cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                    ]">
                    Upload &amp; Create Employees
                    <span class="">
                        <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                    </span>
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
const MODAL_WIDTH = 500;
import configObject from "@/config";

export default {
    name: 'createBatchEmployeeModal',
    data(){
        return {    
            url: '',      
            showLoader: false,
            isButtonDisabled: false,
            file: ''
        }
    },
    created(){
        this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
        //this.cleanEntry();
    },
    mounted(){
        this.url = `${configObject.apiBaseUrl}/Temp/EmployeeUploadTemplate.xlsx`;
    },
    methods: {
        closeModal(){
            this.$modal.hide('createBatchEmployeeModal');
        },
        uploadEmployees(){
            console.log(this.file);            
            var validExts = new Array(".xlsx", ".xls");
            var fileName = this.file.name;
            let fileExt = fileName.substring(fileName.lastIndexOf('.'));
            console.log(fileExt);
            
            if (validExts.indexOf(fileExt) < 0) {                
                this.$toasted.show("Invalid file selected, valid files are of " + validExts.toString() + " types.", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
            }
            else if(this.file && this.file.size > 2000000){
                this.$toasted.show("File size cannot be more that 2mb", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
            }
            else{
                this.showLoader = true;
                this.isButtonDisabled = true;
                let formData = new FormData();
                formData.append('file', this.file);
                formData.append('id', -1);
                // formData.append('firstname', 'o');
                // formData.append('surname', 'o');
                // formData.append('gender', 'o');
                // formData.append('phoneNumber', '');
                // formData.append('email', '123test@email.com');
                // formData.append('gradeLevel', '');
                // formData.append('employmentDate', '');
                // formData.append('designationId', '');

                this.axios.post(`${configObject.apiBaseUrl}/Employee/AddMany`, formData, configObject.authConfigForUpload)
                    .then(response => {
                        //console.log(response.data);
                        let typ = '';
                        let msg = ''
                        if(response.data.filter(a => a.code === "-1")[0]){
                            msg = "One or more Employees not created. Please check that there is not duplicate entry and try again";
                            typ = 'warning';
                        }
                        else if(response.data.filter(a => a.code === "-2")[0]){
                            msg = "An error occured while processing upload. Please check and try again";
                            typ = 'error';
                        }
                        else{
                            msg = "Employees created successfully";
                            typ = 'success';
                        }
                        this.$toast.open({
                            message: msg,
                            type: typ,
                            duration: 5000
                        });
                        this.showLoader = false;
                        this.isButtonDisabled = false;
                        // this.$toast.success("Employees created successfully");
                        this.closeModal();
                        this.$eventHub.$emit("refreshEmployeeTable");
                    })
                    .catch((error) => {
                        this.isButtonDisabled = false;
                        this.showLoader = false;
                        this.$toast.open({
                            message: "Unable to create employee",
                            type: "error",
                            duration: 4000
                        });
                        console.log('ERR:: ' + error.msg);
                    });
            }
        },
    }
}
</script>