<template>
    <div class="side_bar__wrapper fix-navbar">
        <div class="side_bar">
            <div class="side_bar_Logo text-center">
                <div class="text-center">
                    <img v-bind:src="logo" width="70px" style="border-radius: 50%;width: 60px;height:60px;padding-top: 0px;" />
                </div>
                <p>{{portalName}} <br /> Compentency Administrative System</p>
            </div>
            <div>
                <ul class="list-group mt-4 " v-if="role !== 'HR'">
                    <li class="list-group-item" :class="currentRoute === 'admin_dashboard' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'admin_dashboard', query:{id: 1} }" class="vue_router_link">
                            <span class="mr-3"><img alt="" src="@/assets/img/dashboard (1).png" /></span>Dashboard
                        </router-link>
                    </li>
                    <li class="list-group-item treeview" :class="currentRoute === 'company' || currentRoute === 'units' ||
                    currentRoute === 'branch' || currentRoute === 'divisions' ? 'navbar_item_active open' : ''">
                        <a href="#">
                            <span class="mr-3"><span class="fa fa-cogs" aria-hidden="true" style="color: white;"></span></span>Company Configuration
                            <i class="fa fa-angle-right upArrow"></i>
                        </a>
                        <ul class="treeview-menu">
                            <li v-if="role === 'SuperAdmin'">
                                <router-link :to="{ name: 'company' }" :class="currentRoute === 'company' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Company
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'units' }" :class="currentRoute === 'units' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Units
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'branch' }" :class="currentRoute === 'branch' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Branch
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'divisions' }" :class="currentRoute === 'divisions' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Divisions
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="list-group-item treeview" :class="currentRoute === 'employees' || currentRoute === 'supervisors' 
                        ? 'navbar_item_active open' : ''">
                        <a href="#">
                            <span class="mr-3"><span class="fa fa-cogs" aria-hidden="true" style="color: white;"></span></span>Employees Mgt.
                            <i class="fa fa-angle-right upArrow"></i>
                        </a>
                        <ul class="treeview-menu">
                            <li>
                                <router-link :to="{ name: 'employees' }" :class="currentRoute === 'employees' ? 'active' : ''" class="vue_router_link">
                                    Employees
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'supervisors' }" :class="currentRoute === 'supervisors' ? 'active' : ''" class="vue_router_link">
                                    Supervisors
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <!-- <li class="list-group-item" :class="currentRoute === 'employees' || currentRoute === 'employee_assessment'
                    || currentRoute === 'employee_setup' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'employees' }" class="vue_router_link">
                            <span class="mr-3"><i class="fa fa-users"></i></span>Employees
                        </router-link>
                    </li> -->
                    <li class="list-group-item" :class="currentRoute === 'designation_profile' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'designation_profile' }" class="vue_router_link">
                            <span class="mr-3"><img src="@/assets/img/company.png" /></span>Designation Profile
                        </router-link>
                    </li>
                    <li class="list-group-item treeview" :class="currentRoute === 'role_setup' || currentRoute === 'grades' ||
                    currentRoute === 'requirement' || currentRoute === 'responsibilities' || currentRoute === 'designation'
                    ? 'navbar_item_active open' : ''">
                        <a href="#">
                            <span class="mr-3"><span class="fa fa-cogs" aria-hidden="true" style="color: white;"></span></span>System Configuration
                            <i class="fa fa-angle-right upArrow"></i>
                        </a>
                        <ul class="treeview-menu">
                            <li>
                                <router-link :to="{ name: 'designation' }" :class="currentRoute === 'designation' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Designations
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'role_setup' }" :class="currentRoute === 'role_setup' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Role Tasks
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'grades' }" :class="currentRoute === 'grades' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Grades
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'responsibilities' }" :class="currentRoute === 'responsibilities' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Responsibilities
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'requirement' }" :class="currentRoute === 'requirement' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Requirement
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="list-group-item treeview" :class="currentRoute === 'division_gap_analysis' || currentRoute === 'branch_gap_analysis' || currentRoute === 'gap_analysis_branch'
                    ? 'navbar_item_active open' : ''">
                        <a href="#">
                            <span class="mr-3"><span class="fa fa-area-chart" aria-hidden="true" style="color: white;"></span></span>Gap Analysis
                            <i class="fa fa-angle-right upArrow"></i>
                        </a>
                        <ul class="treeview-menu">
                            <li>
                                <router-link :to="{ name: 'division_gap_analysis' }" :class="currentRoute === 'division_gap_analysis' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Division Gap Analysis
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'branch_gap_analysis' }" :class="currentRoute === 'branch_gap_analysis' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Branch Gap Analysis
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="list-group-item" :class="currentRoute === 'employees_self_assessments' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'employees_self_assessments' }" class="vue_router_link">
                            <span class="mr-3"><img src="@/assets/img/worker.png" /></span>Employees Assessments
                        </router-link>
                    </li>
                    <li class="list-group-item" :class="currentRoute === 'competency_library' || currentRoute === 'create_competency' || 
                        currentRoute === 'edit_competency' || currentRoute === 'view_competency'
                        ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'competency_library' }" class="vue_router_link">
                            <span class="mr-3"><img src="@/assets/img/worker.png" /></span>Competency Library
                        </router-link>
                    </li>
                    <li class="list-group-item" :class="currentRoute === 'succession_Planning' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'succession_Planning' }" class="vue_router_link">
                            <span class="mr-3"><img src="@/assets/img/Success.png" /></span>Succession Planning
                        </router-link>
                    </li>                    
                    <li class="list-group-item" :class="currentRoute === 'limsaa' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'limsaa' }" class="vue_router_link">
                            <span class="mr-3"><img src="@/assets/img/Success.png" /></span>Learning Management
                        </router-link>
                    </li>
                    <li class="list-group-item" :class="currentRoute === 'organogram' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'organogram' }" class="vue_router_link">
                            <span class="mr-3"><img src="@/assets/img/Success.png" /></span>Organogram
                        </router-link>
                    </li>
                </ul>
                <ul class="list-group mt-4 " v-if="role === 'HR'">
                    <li class="list-group-item" :class="currentRoute === 'admin_dashboard' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'admin_dashboard' }" class="vue_router_link">
                            <span class="mr-3"><img alt="" src="@/assets/img/dashboard (1).png" /></span>Dashboard
                        </router-link>
                    </li>
                    <li class="list-group-item" :class="currentRoute === 'employees' || currentRoute === 'employee_assessment'
                    || currentRoute === 'employee_setup' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'employees' }" class="vue_router_link">
                            <span class="mr-3"><img src="@/assets/img/company.png" /></span>Employees
                        </router-link>
                    </li>
                    <li class="list-group-item" :class="currentRoute === 'designation_profile' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'designation_profile' }" class="vue_router_link">
                            <span class="mr-3"><img src="@/assets/img/company.png" /></span>Designation Profile
                        </router-link>
                    </li>
                    <!-- <li class="list-group-item treeview" :class="currentRoute === 'role_setup' || currentRoute === 'grades' ||
                        currentRoute === 'requirement' || currentRoute === 'responsibilities' || currentRoute === 'designation'
                        ? 'navbar_item_active open' : ''">
                        <a href="#">
                            <span class="mr-3"><span class="fa fa-cogs" aria-hidden="true" style="color: white;"></span></span>System Configuration
                            <i class="fa fa-angle-right upArrow"></i>
                        </a>
                        <ul class="treeview-menu">
                            <li>
                                <router-link :to="{ name: 'designation' }" :class="currentRoute === 'designation' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Designations
                                </router-link>
                            </li> -->
                    <li class="list-group-item treeview" :class="currentRoute === 'role_setup' || currentRoute === 'grades' ||
                    currentRoute === 'requirement' || currentRoute === 'responsibilities' || currentRoute === 'designation'
                    ? 'navbar_item_active open' : ''">
                        <a href="#">
                            <span class="mr-3"><span class="fa fa-cogs" aria-hidden="true" style="color: white;"></span></span>System Configuration
                            <i class="fa fa-angle-right upArrow"></i>
                        </a>
                        <ul class="treeview-menu">
                            <li>
                                <router-link :to="{ name: 'designation' }" :class="currentRoute === 'designation' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Designations
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'role_setup' }" :class="currentRoute === 'role_setup' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Role Tasks
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'grades' }" :class="currentRoute === 'grades' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Grades
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'responsibilities' }" :class="currentRoute === 'responsibilities' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Responsibilities
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'requirement' }" :class="currentRoute === 'requirement' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Requirement
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="list-group-item treeview" :class="currentRoute === 'division_gap_analysis' || currentRoute === 'branch_gap_analysis' || currentRoute === 'gap_analysis_branch'
                        ? 'navbar_item_active open' : ''">
                        <a href="#">
                            <span class="mr-3"><span class="fa fa-area-chart" aria-hidden="true" style="color: white;"></span></span>Gap Analysis
                            <i class="fa fa-angle-right upArrow"></i>
                        </a>
                        <ul class="treeview-menu">
                            <li>
                                <router-link :to="{ name: 'division_gap_analysis' }" :class="currentRoute === 'division_gap_analysis' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Division Gap Analysis
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'branch_gap_analysis' }" :class="currentRoute === 'branch_gap_analysis' ? 'active' : ''" @click.native="toggleMobileNav" class="vue_router_link">
                                    Branch Gap Analysis
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="list-group-item" :class="currentRoute === 'competency_library' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'competency_library' }" class="vue_router_link">
                            <span class="mr-3"><img src="@/assets/img/worker.png" /></span>Competency Library
                        </router-link>
                    </li>
                    <li class="list-group-item" :class="currentRoute === 'succession_Planning' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'succession_Planning' }" class="vue_router_link">
                            <span class="mr-3"><img src="@/assets/img/Success.png" /></span>Succession Planning
                        </router-link>
                    </li>
                    <li class="list-group-item" :class="currentRoute === 'limsaa' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'limsaa' }" class="vue_router_link">
                            <span class="mr-3"><img src="@/assets/img/Success.png" /></span>Learning Management
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>

        <b-navbar  toggleable="lg" fixed="top">
            <b-navbar-toggle id="open_sidebar" target="nav-collapse"></b-navbar-toggle>
            <div class="logo-sm">
                 <img v-bind:src="logo" />
                 <h4>{{portalName}}</h4>
            </div> 
            
            <b-navbar-toggle id="open_menu" target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav v-if="currentRoute === 'employee_assessment'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Employees</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'supervisors'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Supervisor</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'meet_requirements'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Met Requirements</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'close_to_requirements'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Close To Requirements</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'not_met_requirements'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Not Met Requirements</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'process_assessment'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Process Employees Assessment/Training</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'branch'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Branch</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'designation'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>System Configuration > Designations</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'requirement'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>System Configuration > Requirement</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'company'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Company</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'units'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Units</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'divisions'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Divisions</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'competency_library'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Competency Library</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'create_competency'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Create New Competency</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'edit_competency'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Edit Competency</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'map_branch_to_unit'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Map Units to Branch</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'map_divisions_to_branch'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Map Division to branch</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'division_analysis'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Gap Analysis > Divison Gap Analysis</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'branch_analysis'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Gap Analysis > Branch Gap Analysis</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'succession_Planning'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Succession Planning</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'employees'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Employees</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'role_setup'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>System Configuration > Role Tasks</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'employee_setup'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Employee Setup</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'map_divisions_to_unit'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Map Units Division</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'map_division_to_branches'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Map Branches to Division</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'map_competencies_to_designation'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Map Competencies to designation</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'map_competencies_to_employee'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Map Competencies to Employee</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'map_requirements_to_designation'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Map Requirements to Designation</b-nav-item>
                </b-navbar-nav>
                
                <b-navbar-nav v-if="currentRoute === 'employee_profile'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Employee Dashboard</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'responsibilities'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>System Configuration > Responsibilities</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'designation_profile'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Designation Profile</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'admin_dashboard'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Dashboard</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'map_roles_to_designation'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Map Roles To Designation</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'grades'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>System Configuration > Grades</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'designation_profile_detail'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Designation Profile</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'profile'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Change Password</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'limsaa'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Learning Management (LIMSAA)</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'organogram'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Organogram</b-nav-item>
                </b-navbar-nav>


            </b-collapse>
            <b-collapse id="nav-collapse" is-nav>
                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <b-nav-item href="#">
                        <img v-bind:src="'' + avatar" width="25px" />
                    </b-nav-item>
                    <b-nav-item-dropdown>
                        <template v-slot:button-content>
                            <span class="user_profile_span">{{userName}}</span>
                        </template>
                        <b-dropdown-item href="#"><i class="fa fa-user"></i> &nbsp; Profile</b-dropdown-item>
                        <b-dropdown-item href="#" @click="changeRoute('settings')"><i class="fa fa-cogs"></i> &nbsp; Change Password</b-dropdown-item>
                        <b-dropdown-item href="#" @click="signOutUser"><i class="fa fa-sign-out"></i> &nbsp; Log Out</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>

        <div class="main_content">
            <div class="inner__main_content">
                <slot>
                    <router-view />
                </slot>
            </div>   
        </div>
    </div>
</template>
<script>
import JQuery from "jquery";
import Avatar from "@/assets/img/avatar.svg";
import configObject from "@/config";
let $ = JQuery;

export default {
    data() {
      return {
        currentRoute: this.$route.name,
        //userInfo: {},
        portalName: "",
        other: false,
        logo: '',
        userName: null,
        role: null,
        showBranchDropDown: false,
        showCompanyDropDown: false,
        activeMenu: null,
        avatar: null
      }
    },
    created(){
        this.loadUser();
        this.portalName = configObject.portalName;
        this.other = configObject.portalName.toLowerCase() === 'dcas' ? false : true;
        this.logo = configObject.portalLogo;
    },    
    mounted(){
        //this.loadUser();

        $('.treeview > a').on('click', function(e){
            e.preventDefault();
            var me = $(this);
            
            if(me.parent('li').hasClass('open')){
                //close me
                me.parent('li').removeClass('open');
            }
            else{
                // Open me & close others
                $('.treeview').removeClass('open');
                me.parent('li').addClass('open');
            }
        });

        $('#open_sidebar').on('click', function(e){
            if($(this).hasClass('toggled')){
                // Close
                $(this).removeClass('toggled');
                $('.main_content, .navbar, .side_bar').removeClass('opened');
                $('.close_layer').remove();
            }
            else{
                // Open
                $(this).addClass('toggled')
                $('.main_content, .navbar, .side_bar').addClass('opened');
                $('.main_content').append('<div class="close_layer visible"></div>');
            }
        })

        // $(window).scroll(function() {
        //     if ($(window).scrollTop() > 63) {
        //         $('.navbar').addClass('navbar-fixed');
        //     }
        //     if ($(window).scrollTop() < 64) {
        //         $('.navbar').removeClass('navbar-fixed');
        //     }
        // });
    },
    methods: {
        changeRoute($routeName){
            this.$router.push({
                name: $routeName
            })
        },
        toggleBranchDropDown($activeMenu) {
            this.showBranchDropDown = !this.showBranchDropDown;
            this.activeMenu = $activeMenu;
        },
        toggleCompanyDropDown($activeMenu) {
            this.showCompanyDropDown = !this.showCompanyDropDown;
            this.activeMenu = $activeMenu;
        },
        signOutUser() {
            this.$store.dispatch("logout");
            //this.$router.push({ name: "/" });        
        },
        loadUser(){
            if(localStorage.getItem("dcasUserDetails"))
            {
                let userInfo = JSON.parse(localStorage.getItem("dcasUserDetails"));
                this.userName = `${userInfo.firstName} ${userInfo.lastName}`;
                if(userInfo.avatar) // && (userInfo.avatar === null || userInfo.avatar.length <= 0))
                {
                    //console.log('No Passport ... Use => ' + this.UserDP);
                    this.avatar = userInfo.avatar;
                }
                else
                {
                    this.avatar = Avatar;
                }
                 
                //console.log('Starting role' + this.role);
                if(userInfo.roles.includes('SuperAdmin')){
                    this.role = 'SuperAdmin';
                }
                else if(userInfo.roles.includes('Admin')){
                    this.role = 'Admin';
                }
                else{
                    this.role = 'Staff';
                }
                //console.log('Role setup ' + this.role);
            }
        }
    }
}
</script>