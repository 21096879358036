<template>
    <MasterLayout>
        <div class="employees__assessment__wrapper">
            <div class="welcome_message">
                <div class="row">
                    <div class="col-8">    
                        <h5 class=''><span class="greeeting__text"> Hi Admin,
                        </span> you have <span class="branches__count"> {{grades.length}} </span> Grades
                    </h5> 
                    </div>
                    <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn" @click="openAddGradeModal()">
                                Add New Grade
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <section class="table__section mt-1 pb-5" v-show="grades.length > 0">
                <ejs-grid
                    ref="gradeGrid"
                    :created="refreshGradeGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" headerText="#" width="60px"></e-column>
                        <e-column field="gradeName" headerText="Grade"></e-column>
                        <e-column field="status" :visible='false' width="80px" headerText="Status"></e-column>
                        <e-column :template="deletedTemplate" width="80px" headerText="Status"></e-column>
                        <e-column :template="gradeActionsButtons" width="100px" headerText="Actions"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
            <TableLoading v-show="showLoader"></TableLoading>  
            <div class="empty_branch__div mt-1" v-if="grades.length < 1">
                <div class="text-center">
                    <h6 class="text-center">Please wait while loading Grades</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
            
            <AddGradeModal></AddGradeModal>
            <EditGradeModal :grade ="grade"></EditGradeModal>
        </div>

    </MasterLayout>
</template>

<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import configObject from "@/config";
import JQuery from "jquery";
import AddGradeModal from '@/components/Modals/Grade/createGradeModal.vue'; 
import EditGradeModal from '@/components/Modals/Grade/editGradeModal.vue'; 


let $ = JQuery;

import {
  Page,
  Sort,
  Search,
  ExcelExport,
  PdfExport,
  Toolbar
} from "@syncfusion/ej2-vue-grids";


export default {
    components: {
        MasterLayout,
        AddGradeModal,
        EditGradeModal,
    },
    provide:{
        grid: [Page, Sort, Search, Toolbar, ExcelExport, PdfExport]
    },
    data(){
        return{
            grades: [],
            myRoles: [],
            grade: null,
            showLoader: false,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            
            gradeActionsButtons: function() {
                return {
                    template: Vue.component("roleTaskButton", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-primary" title="Edit" v-on:click="_editGrade(data.id)">' +
                                '<i class="fa fa-edit" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-danger" title="Delete" v-on:click="_deleteGrade(data.id)" v-if="data.deleted === false">' +
                                '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-success" title="Re-Add" v-on:click="_readdGrade(data.id)" v-if="data.deleted === true">' +
                                '<i class="fa fa-plus" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _deleteGrade: function(gradeId) {
                                this.$eventHub.$emit("deleteGradeVM", gradeId, 0);
                            },
                            _readdGrade: function(gradeId) {
                                this.$eventHub.$emit("deleteGradeVM", gradeId, 1);
                            },
                            _editGrade: function(gradeId) {
                                console.log(gradeId);
                                this.$eventHub.$emit("editGrade", gradeId);
                            },
                        }
                    })
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>` +
                                    `<div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            },
        }
    }, 
    created(){
        this.$eventHub.$on("refreshGradesTable", () => {
            this.getGrades();
        });
        this.$eventHub.$on("deleteGradeVM", (gradeId, typ) => {
            this.deleteGrade(gradeId, typ);
        });
        this.$eventHub.$on("editGrade", (gradeId) => {
            this.editGrade(gradeId);
        });

    },
    mounted(){
        this.getGrades();
        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    methods: {
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.gradeGrid.getColumns()[2].visible = true;
                    this.$refs.gradeGrid.getColumns()[3].visible = false;
                    this.$refs.gradeGrid.getColumns()[4].visible = false;

                    this.$refs.gradeGrid.pdfExport({
                        fileName: 'Grades.pdf',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  'Grades',
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
                case "Excel Export":
                    this.$refs.gradeGrid.getColumns()[2].visible = true;
                    this.$refs.gradeGrid.getColumns()[3].visible = false;
                    this.$refs.gradeGrid.getColumns()[4].visible = false;
                    this.$refs.gradeGrid.excelExport({
                        fileName: 'Grades.xlsx',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  `Grades`,
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
            }
        },
        editGrade(gradeId) {
            let gradeDetail = this.grades.filter(grade => grade.id === gradeId)[0];
            console.log(gradeDetail);
            this.grade = gradeDetail;
            this.openEditGRadeModal();
        },
        openEditGRadeModal(){
            this.$modal.show("editGradeModal");
        },
        openAddRoleModal(){
            this.$modal.show("addRoleTaskModal");
        },
        deleteGrade(gradeId, typ) {
            var resp = false;
            if(typ === 0){
                resp = confirm("Are you sure  you want to delete this grade");
            }
            else{
                resp = confirm("Are you sure  you want to Re-Add this grade");
            }
            if (resp) {
                let msg = '';
                this.axios
                    .delete(
                        `${configObject.apiBaseUrl}/Grades/Delete/${gradeId}`,
                        configObject.authConfig
                    )
                    .then(() => {
                        msg = typ === 0 ? "Grade deleted successfully!" : "Grade re-added successfully!";
                        this.getGrades();
                        this.$toast.open({
                            message: msg,
                            type: "success",
                            duration: 4000
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        msg = typ === 0 ? "Unable to delete grade" : "Unable to re-add grade";
                        this.$toast.open({
                            message: msg,
                            type: "error",
                            duration: 4000
                        });
                    });
            }
        },
        openAddGradeModal(){
            this.$modal.show("createGradeModal");
        },
       
        getGrades(){
            this.showLoader = true;
            let grid = this.$refs.gradeGrid;
            this.axios
                .get(`${configObject.apiBaseUrl}/Grades`, configObject.authConfig)
                .then(response => {
                    this.showLoader = false;
                    console.log(response.data);
                    let index = 0;
                    response.data.forEach(grade => {
                        index++;
                        grade.index = index;
                        grade.status = grade.deleted ? 'deleted' : 'active'
                    });
                    this.grades = response.data;
                    grid.ej2Instances.setProperties({ dataSource: this.grades });
                    this.refreshGradeGrid();
                })
                .catch((error) => {
                    console.log(error);
                    // this.$toast.open({
                    //     message: "unable to load grades",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        refreshGradeGrid() {
            this.$refs.gradeGrid.refresh();
        },
        openModal(modalName){
            console.log('Open Modal :> ' + modalName);
            this.$modal.show(modalName);
        },
    }
}
</script>

<style scoped>

</style>