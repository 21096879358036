<template>
    <div class="side_bar__wrapper fix-navbar">
        <div class="side_bar">
            <div class="side_bar_Logo text-center">
                <div class="text-center">
                    <img src="@/assets/img/logo.png" width="70px" style="border-radius: 50%;width: 60px;height:60px;padding-top: 0px;" />
                </div>
                <p>DPR compentency Administrative System</p>
                <!-- <h3 class="logo__text">D.C.A.S.</h3>
                <p>DPR compentency Administrative System</p> -->
            </div>
            <div>
                <ul class="list-group mt-4 ">
                    <li class="list-group-item" :class="currentRoute === 'employee_dashboard' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'employee_dashboard' }" class="vue_router_link">
                            <span class="mr-3"><img src="@/assets/img/dashboard (1).png" /></span>Dashboard
                        </router-link>
                    </li>
                    <!-- <li class="list-group-item" :class="currentRoute === '' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: '' }" class="vue_router_link">
                            <span class="mr-3"><img src="@/assets/img/company.png" /></span>Gap Analysis
                        </router-link>
                    </li> -->
                    <li class="list-group-item" :class="currentRoute === 'self_assessment' || currentRoute === 'create_self_assessment' || currentRoute === 'step_one' || currentRoute === 'step_two' || currentRoute === 'step_three' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'self_assessment' }" class="vue_router_link">
                            <span class="mr-3"><img src="@/assets/img/company.png" /></span>Self Assessment/Trainings
                        </router-link>
                    </li>
                    <!-- <li class="list-group-item" v-if="hasDownline"  :class="currentRoute === 'downlines' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'downlines' }" class="vue_router_link">
                            <span class="mr-3"><i class="fa fa-user"></i></span>Downlines
                        </router-link>
                    </li> -->
                    <li class="list-group-item" :class="currentRoute === 'downline_approvals' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'downline_approvals' }" class="vue_router_link">
                            <span class="mr-3"><i class="fa fa-user"></i></span>Downlines Approvals
                        </router-link>
                    </li>
                    <li class="list-group-item" :class="currentRoute === 'employee_settings' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'employee_settings' }" class="vue_router_link">
                            <span class="mr-3"><i class="fa fa-cogs"></i></span>Change Password
                        </router-link>
                    </li>
                    <li class="list-group-item" :class="currentRoute === 'profile' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'profile' }" class="vue_router_link">
                            <span class="mr-3"><i class="fa fa-user"></i></span>Profile
                        </router-link>
                    </li>
                    <li class="list-group-item" :class="currentRoute === 'lmshome' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'lmshome' }" class="vue_router_link">
                            <span class="mr-3"><i class="fa fa-user"></i></span>LIMSAA
                        </router-link>
                    </li>
                    <!-- <li class="list-group-item" :class="currentRoute === 'courses' ? 'navbar_item_active' : ''">
                        <router-link :to="{ name: 'courses' }" class="vue_router_link">
                            <span class="mr-3"><i class="fa fa-user"></i></span>LIMSAA
                        </router-link>
                    </li> -->
                </ul>
            </div>
        </div>

        <b-navbar toggleable="lg" fixed="top">
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav v-if="currentRoute === 'employee_dashboard'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Employee Dashboard</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'self_assessment'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Self Assessment &amp; Trainings</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'create_self_assessment'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Create Assessment/Training</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'step_one'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Create Assessment/Training > Step One</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'step_two'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Create Assessment/Training > Step Two</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'step_three'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Create Assessment/Training > Step Three</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav v-if="currentRoute === 'lmshome'">
                    <b-nav-item href="#"><span><i class="fa fa-home menu_icons mr-2" aria-hidden="true"></i></span>Learning Management</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
            <b-collapse id="nav-collapse" is-nav>
                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <b-nav-item href="#">
                        <!-- <img src="@/assets/img/avatar.svg" width="25px" /> -->
                        <img v-bind:src="'' + avatar" alt="Avatar" width="25px" />
                    </b-nav-item>
                    <b-nav-item-dropdown>
                        <template v-slot:button-content>
                            <span class="user_profile_span">{{userName}}</span>
                        </template>
                        <b-dropdown-item href="#" @click="changeRoute('profile')"><i class="fa fa-user"></i> Profile</b-dropdown-item>
                        <b-dropdown-item href="#" @click="changeRoute('employee_settings')"><i class="fa fa-cogs"></i> Change Password</b-dropdown-item>
                        <b-dropdown-item href="#" @click="signOutUser"><i class="fa fa-sign-out"></i> Log Out</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>

        <div class="main_content">
            <div class="inner__main_content">
                <slot>
                    <router-view />
                </slot>
            </div>
        </div>
    </div>
</template> 
<script>
import Avatar from "@/assets/img/avatar.svg";
export default {
    data() {
        return {
            currentRoute: this.$route.name,
            userName: null,
            role: null,
            // userName: localStorage.getItem("dcasUserDetails")
            //   ? JSON.parse(localStorage.getItem("dcasUserDetails")).firstName + " " +  JSON.parse(localStorage.getItem("dcasUserDetails")).lastName 
            //   : null,
            // userInfo: localStorage.getItem("dcasUserDetails")
            //   ? JSON.parse(localStorage.getItem("dcasUserDetails"))
            //   : null,
            avatar: null,
            hasDownline: false
        }
    },
    created() {
        this.$eventHub.$on("refreshLocal", () => {
            this.loadUser();
        });
    },
    mounted(){
        this.loadUser();
    },
    methods: {
        changeRoute($routeName){
            this.$router.push({
                name: $routeName
            })
        },
        signOutUser() {
            this.$store.dispatch("logout");
            //this.$router.push({ path: "/" });
        },
        loadUser(){
            //console.log('Loading User...');
            if(localStorage.getItem("dcasUserDetails")){
                let userInfo = JSON.parse(localStorage.getItem("dcasUserDetails"));
                this.userName = `${userInfo.firstName} ${userInfo.lastName}`;
                //console.log('Loading User... ' + userInfo.avatar);
                if(userInfo.avatar) // && (userInfo.avatar === null || userInfo.avatar.length <= 0))
                {
                    this.avatar = userInfo.avatar;
                }
                else
                {
                    this.avatar = Avatar;
                }
                if(userInfo.hasDownline && userInfo.hasDownline === true)
                {
                    this.hasDownline = userInfo.hasDownline;
                }

                //console.log('Starting role' + this.role);
                if(userInfo.roles.includes('SuperAdmin')){
                    this.role = 'SuperAdmin';
                }
                else if(userInfo.roles.includes('Admin')){
                    this.role = 'Admin';
                }
                else{
                    this.role = 'Staff';
                }
                //console.log('Role setup ' + this.role);
            }
        }
    }
}
</script>