<template>
    <div>
        <MasterLayout>
            <div class="employees__assessment__wrapper">

                <div class="welcome_message">
                    <div class="row">
                        <div class="col-6">    
                            <h5 class=''><span class="greeeting__text"> Hi Admin,
                                </span> you have <span class="branches__count"> {{employees.length}} </span> Employees
                            </h5> 
                        </div>
                        <div class="col-6 text-right">
                            <div>
                                <button class="creaet_branch_btn" @click="openBatchEmployeeModal">Add Employee by Upload</button>
                                <button class="creaet_branch_btn" @click="openCreateEmployeeModal">Add Employee</button>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="table__section mt-1 pb-5" v-show="employees.length > 0 || !showLoader">
                    <ejs-grid
                        ref="dataGrid"
                        :created="refreshGrid"
                        :allowPaging="true"
                        :allowSorting="true"
                        :pageSettings="pageSettings"
                        :toolbar="toolbar"
                        :searchSettings="search"
                        :allowExcelExport="true"
                        :allowPdfExport="true"
                        :toolbarClick="toolbarClick">
                        <e-columns>
                            <e-column field="index" headerText="#" width="60px"></e-column>
                            <e-column field="fullName" headerText="Full Name" width="150px"></e-column>
                            <e-column field="division" headerText="Division" width="150px"></e-column>
                            <e-column field="designationName" headerText="Designation" width="150px"></e-column>
                            <e-column field="gradeLevel" width="80px" headerText="Grade"></e-column>
                            <e-column field="employmentDateString" headerText="Employment Date" width="135px"></e-column>
                            <e-column field="phoneNumber" headerText="Phone" :visible="false" width="120px"></e-column>
                            <e-column field="email" headerText="Email" width="150px"></e-column>
                            <!-- <e-column :template="deletedTemplate" width="150px" headerText="Status"></e-column> -->
                            <e-column :template="actionButtonsTemplate" width="150px" headerText="Actions"></e-column>
                        </e-columns>
                    </ejs-grid>
                </section>
                <div class="empty_branch__div mt-3" v-if="employees.length < 1 && !showLoader">
                    <div class="text-center">
                        <h6 class="text-center"> You are yet to create a Employee</h6>
                        <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                    </div>
                </div>
                <TableLoader v-show="showLoader"></TableLoader>
                
                <BatchEmployeeModal></BatchEmployeeModal>
                <EditEmployeeModal :employee="employee" :grades="grades"></EditEmployeeModal>
            </div>
        </MasterLayout>
        <CreateEmployeeModal :grades="grades"></CreateEmployeeModal>
    </div>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/avatar.svg";
import CreateEmployeeModal from '@/components/Modals/Employee/createEmployeeModal.vue';
import EditEmployeeModal from '@/components/Modals/Employee/editEmployeeModal.vue';
import BatchEmployeeModal from '@/components/Modals/Employee/createBatchEmployeeModal.vue';
import TableLoader from '@/components/TableLoading.vue';
import configObject from "@/config";
import JQuery from "jquery";
import moment from "moment";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    title: 'All Employees',
    components: {
        MasterLayout,
        CreateEmployeeModal,
        BatchEmployeeModal,
        EditEmployeeModal,
        TableLoader
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            backgroundUrl,
            UserDP,
            componentKey: 0,
            employees: [],
            employee: {},
            grades: [],
            showLoader: false,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-success" title="Map Competencies" v-on:click="_mapCompetencies(data.id)">' +
                                    '<i class="fa fa-refresh" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-info" title="View" v-on:click="_viewEmployee(data.id)">' +
                                '<i class="fa fa-eye" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-primary" title="Edit" v-on:click="_editEmployee(data.id)">' +
                                '<i class="fa fa-edit" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-danger" title="Delete" v-on:click="_deleteEmployee(data.id)" v-if="data.deleted === false">' +
                                '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-success" title="Re-Add" v-on:click="_deleteEmployee(data.id)" v-if="data.deleted === true">' +
                                '<i class="fa fa-plus" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _deleteEmployee: function(id) {
                                this.$eventHub.$emit("deleteEmployeeVM", id);
                            },
                            _editEmployee: function(id) {
                                this.$eventHub.$emit("editEmployeeVM", id);
                            },
                            _viewEmployee: function(id){
                                this.$eventHub.$emit("viewEmployeeVM", id);
                            },
                            _mapCompetencies: function(employeeId) {
                                this.$eventHub.$emit("mapCompetenciesVM", employeeId);
                            },
                        }
                    })
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>` +
                            `<div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            }
        }
    },
     mounted() {
        this.getEmployees();
        this.getGrades();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created() {
        this.$eventHub.$on("editEmployeeVM", (id) => {
            this.editEmployee(id);
        });
        this.$eventHub.$on("viewEmployeeVM", (id) => {
            this.viewEmployee(id);
        });
        this.$eventHub.$on("refreshEmployeeTable", () => {
            this.getEmployees();
        });
        this.$eventHub.$on("mapCompetenciesVM", (employeeId) => {
            this.mapCompetencies(employeeId);
        });
        
    },
    methods: {
        mapCompetencies(employeeId){
            this.$router.push({
                name: 'map_competencies_to_employee', 
                query: {
                    id: employeeId
                }
            });
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.dataGrid.getColumns()[6].visible = true;
                    this.$refs.dataGrid.getColumns()[8].visible = false;
                    this.$refs.dataGrid.pdfExport({
                        pageOrientation: 'Landscape'
                    });
                    this.$refs.dataGrid.pdfExport({
                        pageOrientation: 'Landscape',
                        fileName: 'Employees.pdf',
                        theme: {
                            header: {
                                bold: true,
                                fontColor: "#000000",
                                fontName: 'Calibri',
                                fontSize: 10
                            },
                            record: {
                                fontColor: '#000000',
                                fontName: "Calibri",
                                fontSize: 8
                            }
                        },
                        header: {
                            fromTop:0,
                            height: 130,
                            contents:  [{
                                type: "Text",
                                value:  'Employees',
                                position: {x:0, y: 50},
                                style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],                        
                        }
                    });
                    break;                
                case "Excel Export":
                    this.$refs.dataGrid.getColumns()[6].visible = true;
                    this.$refs.dataGrid.getColumns()[8].visible = false;
                    this.$refs.dataGrid.excelExport({
                        fileName: 'Employees.xlsx',
                        theme: {
                            header: {
                                bold: true,
                                fontColor: "#000000",
                                fontName: 'Calibri',
                                fontSize: 10
                            },
                            record: {
                                fontColor: '#000000',
                                fontName: "Calibri",
                                fontSize: 8
                            }
                        },
                        header: {
                            fromTop:0,
                            height: 130,
                            contents:  [{
                                type: "Text",
                                value:  `Employees`,
                                position: {x:0, y: 50},
                                style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                                type: "Text",
                                value: "Thank You",
                                position: {x:0, y: 50},
                                style: {fontSize: 20}
                            }],                        
                        }
                    });
                    break;
            }
            this.$refs.dataGrid.getColumns()[6].visible = false;
            this.$refs.dataGrid.getColumns()[8].visible = true;
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        viewEmployee($id) {
            this.$router.push({name: 'employee_assessment', query: {
                id: $id
            }}).catch(err => {});
        },
        openCreateEmployeeModal(){
            this.$modal.show('createEmployeeModal');
        },
        openBatchEmployeeModal(){
            this.$modal.show('createBatchEmployeeModal');
        },
        openEditUnitModal(){
            this.$modal.show('editEmployeeModal');
        },
        editEmployee(id) {
            let emp = this.employees.filter(e => e.id === id)[0];
            emp.oldEmail = emp.email;
            emp.employmentDate = moment(emp.employmentDate).format('YYYY-MM-DD');
            console.log('Employment Date: ' + emp.employmentDate);
            this.employee = emp;
            this.openEditUnitModal();
        },
        deleteEmployee(id) {
            console.log(unitId);
            var resp = confirm("Are you sure  you want to delete this employee");
            if (resp) {
                this.axios
                    .delete(
                        `${configObject.apiBaseUrl}/Unit/Delete/${unitId}`,
                        configObject.authConfig
                    )
                    .then(() => {
                        this.getEmployees();
                        this.$toast.open({
                            message: "Employee deleted successfully!",
                            type: "success",
                            duration: 3000
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        this.$toast.open({
                            message: error.response.data.message,
                            type: "error",
                            duration: 3000
                        });
                    });
            }
        },
        getEmployees() {
            this.showLoader = true;
            let grid = this.$refs.dataGrid;
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Employee`,
                    configObject.authConfig
                )
                .then(response => {
                    let index = 0;
                    response.data.forEach(employee => {
                        index++;
                        employee.index = index;
                        employee.fullName = employee.firstname + " " + employee.surname
                    });

                    console.log(response.data);
                    this.showLoader = false;

                    grid.ej2Instances.setProperties({ dataSource: response.data });
                    this.refreshGrid();
                    this.employees = response.data;
                })
                .catch((error) => {
                    console.log(error);
                    // this.$toast.open({
                    //     message: "unable to load employees",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
        getGrades(){
            this.axios.get(`${configObject.apiBaseUrl}/Grades`, configObject.authConfig)
            .then(resp => {
                this.grades = resp.data;
            });
        },
    }
}
</script>

        