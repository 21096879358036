<template>
    <MasterLayout>
        <div class="competency_library__wrapper">
            <div class="welcome_message">
                <!-- <div class="row">
                    <div class="col-md-8 col-xs-12 text-left">

                    </div>
                    
                </div> -->
            </div>
        </div>
        <div class="competency_table__wrapper mt-2">
            <div class="welcome_message">
                <div class="row">
                    <div class="col-md-4  col-xs-12">
                        <div class="d-card">
                            <b-card header-tag="header">
                                <template v-slot:header>
                                    <h6 class="mb-0">Competency Levels</h6>
                                </template>
                                <ul class="list-group">
                                    <li class="list-group-item comp_level" v-for="(competencyLevel, index) in competencyLevels" :key="index">
                                        <div class="icon">
                                            <img src="@/assets/img/review.png" />
                                        </div>
                                        <div class="name">
                                            <p class="" v-show="pointObj.id !== competencyLevel.id">{{competencyLevel.level}}</p>
                                            <input type="text" class="set_point_input" v-model="pointObj.level"  placeholder="Edit Level" v-show="pointObj.id === competencyLevel.id" />
                                        </div>
                                        <div class="point">
                                            <p class="point_label text-center" v-show="pointObj.id !== competencyLevel.id">{{competencyLevel.point}} Points</p>
                                            <input type="text" class="set_point_input" v-model="pointObj.point" placeholder="Set  Point" v-show="pointObj.id === competencyLevel.id" />
                                        </div>
                                        <div class="action">
                                            <div class="btn-group btn-group-sm" role="group" v-show="pointObj.id === competencyLevel.id">
                                                <button title="save" class="set_point_btn btn btn-success btn-sm" @click="savePoints(competencyLevel)">
                                                    <i class="fa fa-save"></i>
                                                </button>
                                                <button title="close" class="set_point_btn btn btn-danger btn-sm" @click="closeEdit()" >
                                                    <i class="fa fa-times"></i>  
                                                </button>
                                            </div>
                                            <button class="btn btn-info btn-sm" @click="toggleSetpoint(competencyLevel)" v-show="pointObj.id !== competencyLevel.id" title="Edit">
                                                
                                                <i class="fa fa-edit"></i>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </b-card>
                        </div>
                        <table v-show="showLoader" style="margin: 0 auto; width: 95%">
                            
                        </table>
                    </div>
                    
                    <div class="col-md-8 col-xs-12">
                        <div class="row">
                            <div class="col-md-12 col-xs-12 text-right mb-3">
                                <div>
                                    <router-link :to="{ name: 'competency_group' }" class="creaet_branch_btn" style="text-decoration: none; color: white;">View Groups</router-link>
                                    <router-link class="creaet_branch_btn" :to="{name: 'create_competency'}" style="text-decoration: none; color: white;">Add Competency</router-link>
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-12">
                                <!-- <div class="d-card">
                                    <div class="d-tile typ1 warning">
                                        <div class="d-tile-head">
                                            Sub Competencies
                                        </div>
                                        <div class="d-tile-body">
                                            <div class="icon">
                                                < ! -- <i class="fa fa-users"></i> -- >
                                                <img src="@/assets/img/worker.png" />
                                            </div>
                                            <div class="content">
                                                <h1>{{getSubCompetencyCount()}}</h1>            
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="col-md-4 col-xs-12">
                                <div class="d-card">
                                    <div class="d-tile typ1 info">
                                        <div class="d-tile-head">
                                            Groups
                                        </div>
                                        <div class="d-tile-body">
                                            <div class="icon">
                                                <i class="fa fa-sitemap"></i>
                                            </div>
                                            <div class="content">
                                                <h1>{{competencyGroups.length}}</h1>            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4  col-xs-12">
                                <div class="d-card">
                                    <div class="d-tile typ1 success">
                                        <div class="d-tile-head">
                                            Competencies
                                        </div>
                                        <div class="d-tile-body">
                                            <div class="icon">
                                                <img src="@/assets/img/worker.png" />
                                                <!-- <i class="fa fa-users"></i> -->
                                            </div>
                                            <div class="content">
                                                <h1>{{getCompetencyCount()}}</h1>            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clear-fix"></div>
                    <div class="col-12 mt-5">
                        <div class="employees__assessment__wrapper" v-show="competencies.length > 0 && !showLoader">
                            <section class="table__section mt-1 pb-5 tall_table_header" >
                                <ejs-grid
                                    ref="dataGrid"
                                    :created="refreshGrid"
                                    :allowPaging="true"
                                    :allowSorting="true"
                                    :pageSettings="pageSettings"
                                    :toolbar="toolbar"
                                    :searchSettings="search"
                                    :allowExcelExport="true"
                                    :allowPdfExport="true"
                                    :toolbarClick="toolbarClick">
                                    <e-columns>
                                        <e-column field="index" headerText="#" width="60px"></e-column>
                                        <e-column field="name" headerText="Competency Name"></e-column>
                                        <e-column field="group" headerText="Group" width="180px"></e-column>
                                        <e-column field="divisionCount" headerText="Divs" width="80px"></e-column>
                                        <e-column :template="actionButtonsTemplate" headerText="Action"  width="150px"></e-column>
                                    </e-columns>
                                </ejs-grid>
                            </section>
                        </div>
                        <TableLoader v-show="showLoader"></TableLoader>
                    </div>
                    
                </div>
            </div>
        </div>

        <EditCompetencyModal :model="competency" :competencyGroups="competencyGroups"></EditCompetencyModal>
        <CreateCompetencyModal :competencyGroups="competencyGroups" :groupId="groupId"></CreateCompetencyModal>
        <CreateSubCompetencyModal :compId="compId" :compName="compName" :groupName="groupName" :maxPoint="pointToUse"></CreateSubCompetencyModal>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import configObject from "@/config";
import TableLoader from '@/components/TableLoading.vue';
import CreateCompetencyModal from "@/components/Modals/Competency_modal/createCompetencyModal.vue";
import EditCompetencyModal from "@/components/Modals/Competency_modal/editCompetencyModal.vue";
import CreateSubCompetencyModal from "@/components/Modals/Competency_modal/createSubCompetencyModal.vue";
import JQuery from "jquery";
let $ = JQuery;

import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    title: 'Competency Library',
    components: {
        MasterLayout,
        CreateCompetencyModal,
        TableLoader,
        CreateSubCompetencyModal,
        EditCompetencyModal
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            competencies: [],
            competencyGroups: [],
            groupId: null,
            groupName: '',
            compName: '',
            compId: null,
            group: {},
            competency: {},
            pointToUse: 0,
            //information: [],
            showLoader: false,
            competencyLevels: [],
            setPointId: null,
            level: null,
            showing: false,
            search: "",
            pointObj: {
                level: null,
                point: null,
                id: null
            },
            pageSettings: { pageSizes: [50, 100, 200, 500], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            `<a v-bind:href="'/edit-competency?id=' + data.id" class="btn btn-xs btn-primary" title="Details" v-on:click="editCompetency($event, data.id)">` +
                                'Edit' +
                            '</a>' +
                            `<a v-bind:href="'/view-competency?id=' + data.id" class="ml-2 btn btn-xs btn-info" title="Details" v-on:click="viewCompetency($event, data.id)">` +
                                'View' +
                            '</a>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            editCompetency: function(event, id) {
                                event.preventDefault();
                                this.$eventHub.$emit("editCompetencyVM", id);
                            },
                            viewCompetency: function(event, id) {
                                event.preventDefault();
                                this.$eventHub.$emit("viewCompetencyVM", id);
                            },
                        }
                    })
                };
            },
        };
    },
    watch: {
      selected() {
        this.perPage = this.selected;
        this.calcIndexes();
      }
    },
    mounted() {
        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created() {
        this.getCompetencies();
        this.getCompetencyGroups();
        this.getCompetencyLevels();
        //this.calcIndexes();
        //console.log(configObject.authConfig);
        this.$eventHub.$on("refreshCompetencyGroupTable", () => {
            this.getCompetencyGroups();
        });
        this.$eventHub.$on("refreshCompetency", () => {
            this.getCompetencies();
        });

        this.$eventHub.$on("editCompetencyVM", (id) => {
                                console.log('edit');
            this.$router.push({ name: 'edit_competency', query: { id }})
        });
        this.$eventHub.$on("viewCompetencyVM", (id) => {
                                console.log('Viewing');
            this.$router.push({ name: 'view_competency', query: { id }})
        });
      
    },
    beforeDestroy() {
        this.$eventHub.$off("editCompetency")
        this.$eventHub.$off("viewCompetency")
    },
    computed: {
        // pages() {
        //     return Math.ceil(this.competencies.length / this.perPage);
        // },
        // filteredList() {
        //     return  this.competencies.filter((cp,i) => cp.groupName.toLowerCase().includes(this.search.toLowerCase()));
        // },
    },
    methods: {
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        changeRoute($routeName) {
            this.$router.push({ name: $routeName });
        },
        // compRowClass(item, type){
        //     if (!item || type !== 'row') return
        //     if (item.deleted){
        //         return 'table-danger'
        //     }
        // },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        toggleSetpoint(competencyLevel) {
            this.pointObj.level = competencyLevel.level;
            this.pointObj.point = competencyLevel.point;
            this.pointObj.id = competencyLevel.id;
        },
        closeEdit(){
            this.pointObj.level = null;
            this.pointObj.point = null;
            this.pointObj.id = null;
        },
        savePoints(competencyLevel) {
            let data = {
                level: this.pointObj.level,
                point: parseInt(this.pointObj.point),
                id: competencyLevel.id
            };
            console.log(data);
            this.axios.put(`${configObject.apiBaseUrl}/Competency/Level/Edit/${competencyLevel.id}`, data, configObject.authConfig)
            .then(() => {
                this.getCompetencyLevels();
                this.pointObj.level = null;
                this.$toast.success(`Level Updated successfully`);
                this.level = null;
                this.pointObj.level = null;
                this.pointObj.point = null;
                this.pointObj.id = null;
            })
            .catch(error => {
                this.isButtonDisabled = false;
                this.showLoader = false;
                this.$toast.open({
                    message: "unable to save points",
                    type: "error",
                    duration: 3000
                });
                console.log(error);
            });
        },
        // getCompetencies1() {
        //     this.showLoader = true;
        //     this.axios.get(`${configObject.apiBaseUrl}/Competency/Full`, configObject.authConfig)
        //     .then(response => {
        //         this.showLoader = false;
        //         this.competencies = response.data;
        //         // const newCompetencies = this.competencies.map(() => {
        //         //     return { state: false };
        //         // });
        //         //this.information = newCompetencies;

        //         this.competencies.forEach(a => {
        //             a.competencies.forEach(b => {
        //                 let _pp = 0;
        //                 b.subCompetencies.forEach(c => {
        //                     _pp += c.point;
        //                 });
                        
        //                 b.subPercUsed  = _pp;
        //                 b.subPercRem = 100 - _pp;
        //             });
        //         });
        //         this.totalRows = this.competencies.length;
        //         console.log(this.competencies);
        //     })
        //     .catch(error => {
        //         console.log(error);
        //         this.$toast.open({
        //             message: "unable to load Competencies",
        //             type: "error",
        //             duration: 3000
        //         });
        //     });
        // },
        getCompetencies() {
            this.showLoader = true;
            this.axios.get(`${configObject.apiBaseUrl}/Competency`, configObject.authConfig)
            .then(response => {
                let grid = this.$refs.dataGrid;
                this.showLoader = false;                

                let index = 0;
                response.data.sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase()
                    ? -1
                    : a.name.toLowerCase() > b.name.toLowerCase()
                    ? 1
                    : 0
                );
                response.data.forEach((a, i) => {
                    index++;
                    a.index = index;
                    a.description = ""
                    a.descriptionPlain = `<ul><li>thanks</li><li>now</li><li>everyone ${i}</li></ul>`
                });
                this.competencies = response.data;
                grid.ej2Instances.setProperties({ dataSource: this.competencies });
                this.refreshGrid();
                this.loading = false;
                this.loaded = true;
            })
            .catch(error => {
                console.log(error);
                this.showLoader = false
                this.$toast.open({
                    message: "unable to load Competencies",
                    type: "error",
                    duration: 3000
                });
            });
        },
        getCompetencyLevels() {
            this.axios.get(`${configObject.apiBaseUrl}/Competency/Level`, configObject.authConfig)
            .then(response => {
                //console.log("this is res" + response.data);
                this.competencyLevels = response.data;
            })
            .catch(error => {
                console.log(error);
                this.$toast.open({
                    message: "unable to load competency levels",
                    type: "error",
                    duration: 3000
                });
            });
        },
        getCompetencyGroups() {
            this.axios.get(`${configObject.apiBaseUrl}/Competency/Group`, configObject.authConfig)
            .then(response => {
                this.competencyGroups = response.data;
            })
            .catch((error) => {
                console.log(error);
                this.$toast.open({
                    message: "Unable to load competency groups",
                    type: "error",
                    duration: 3000
                });
            });
        },
        getSubCompetencyCount(){
            let count = 0;
            this.competencies.forEach(a => {
                a.competencies.forEach(c => {
                    count += c.subCompetencies.length;
                });                
            });

            return count;
        },
        getCompetencyCount(){
            // let count = 0;
            // this.competencies.forEach(a => {
            //     count += a.competencies.length;
            // });

            //return count;
            return this.competencies.length;
        },
    }
  
};
</script>

<style scoped>
    .no-style{
        margin-bottom: 0.3rem!important;
    }
</style>