<template>
    <MasterLayout>
        <div class="profile__section__outer_wrapper" style="margin-top
        :0">
        <div class="account__header__banner">
        <div
          class="account_settings_banner "
          :style="[
            {
              backgroundImage: `linear-gradient(rgb(12, 4, 31 , 0.7), rgb(12, 4, 31, 0.7)), url(${backgroundUrl})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }
          ]"
        >
          <div class="banner__text_section">
            <h3 class="banner__text">Account Profile</h3>
          </div>
        </div>
        <div class="account_Settings_profile_details_section_row">
            <div class="account_Settings_profile_details_section_first_row">
                <img v-bind:src="'' + avatar"  class="profile__pic" />
                <!-- <img src="@/assets/img/profile_pic.png" class="profile__pic" /> -->
            </div>
          <div class="account_Settings_profile_details_section_second_row">
            <h5 class="profile__detail__header mt-2 ml-3">
              {{userName}}<br />
              <small>{{email}}</small>
            </h5>
          </div>
        </div>
      </div>
        <div class="cas_input" id="profile_color" v-if="employee">
            <div class="row__wrapper">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Firstname</span>
                            <div class="">
                                <input type="text" value="" v-model="fname" readonly /> 
                                <!-- <input type="hidden" v-model="employee.id" /> 
                                <input type="hidden" v-model="employee.oldEmail" />  -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Surname</span>
                            <div class="">
                                <input type="text" value="" v-model="sname" readonly /> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Email</span>
                            <div class="">
                                <input type="email" value="" v-model="email" readonly /> 
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Phone Number</span>
                            <div class="">
                                <input type="text" value="" v-model="employee.phoneNumber" /> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Gender</span>
                            <div class="">
                                <select ref="gender" class="ml-3 selector" v-model="gender" readonly>
                                    <option value="">Select Gender</option>
                                    <option value="Female">Female</option>
                                    <option value="Male">Male</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Grade Level</span>
                            <div class="">
                                <!-- <select ref="gradeLevel" class="ml-3 selector" v-model="employee.gradeLevel" required>
                                    <option value="">Select Grade Level</option>
                                    <option v-for="d in grades" v-bind:key="d.id" v-bind:value="d.gradeName">{{d.gradeName}}</option>
                                </select> -->
                                <input type="text" readonly style="margin-bottom: 0!important; width: 100%;" v-model="grade" >
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input_wrapper" style="width: 100%; margin-bottom: 0!important;">
                            <span>Employment Date</span>
                            <div class="">
                                <!-- <datepicker placeholder="Select Date" v-model="employee.employmentDate" :format="format"></datepicker> -->
                                <input type="date" style="margin-bottom: 0!important; width: 100%;" v-model="empDate" readonly>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Passport</span>
                            <div class="">
                                <b-form-file
                                    v-model="file"
                                    placeholder="Choose a file"
                                    drop-placeholder="Drop file here..."
                                    accept="image/jpeg, image/png, image/gif"></b-form-file>
                                <!-- <input type="file" name="file" id="file" ref="file" v-on:change="handleFileUpload()"/>  -->
                            </div>
                            <div class="mt-2">
                                <small class="">
                                    <i>
                                        <strong>N.B.:</strong> Only image files (jpg, png &amp; gif) are allowed and max file size of 400kb
                                    </i>
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="input_wrapper" style="width: 100%;">
                            <span>Current Picture</span>
                        </div>
                            <div class="profile__pic" :style="{
                                backgroundImage: `url(${employee.passportUrl})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center', 
                                width: '80px', 
                                height: '80px',
                                marginTop: '20px'
                                }">
                            </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-4">
                <button class="creaet_branch_btn"
                    @click="validateDetails($event)"
                    :disabled="isButtonDisabled ? true : null"
                    :style="[
                    isButtonDisabled
                        ? { cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                    ]">
                    Edit &amp; Save <i class="fa fa-save"></i>
                    <span class="">
                        <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                    </span>
                </button>
            </div>
        </div>
        </div>
    </MasterLayout>
</template>
<script>

import Vue from "vue";
import MasterLayout from "@/views/Employee/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
import CreateUnitModal from '@/components/Modals/Unit/createUnitModal.vue';
import editUnitModal from '@/components/Modals/Unit/editUnitModal.vue';
import configObject from "@/config";
import Avatar from "@/assets/img/avatar.svg";
import JQuery from "jquery";

let $ = JQuery;

import moment from "moment";
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";

export default {
    title: 'My Profile',
    components:{
        MasterLayout,
        Datepicker
    },
    data () {
        return {
            file:'',
            backgroundUrl,
            format: "MM-dd-yyyy",
            isButtonDisabled:  false,
            showLoader: false,
            userName: '',
            email: '',
            oldEmail: '',
            employee: null,
            avatar: null,
            fname: '',
            sname: '',
            gender: '',
            grade: '',
            empDate: '',

        }
    },
    created () {
        this.loadUser()
        // console.log(this.employee.employmentDate);

        // this.empdate = moment(this.employee.employmentDate, 'MM-DD-YYYY').format('MM-DD-YYYY');
        // console.log(datethis.empdate);
    },
    methods: {
        loadUser(){
            if(localStorage.getItem("dcasUserDetails"))
            {
                let userInfo = JSON.parse(localStorage.getItem("dcasUserDetails"));
                this.userName = `${userInfo.firstName} ${userInfo.lastName}`;
                this.fname = userInfo.firstName;
                this.sname = userInfo.lastName;
                this.email = userInfo.email;
                this.oldEmail = userInfo.email
                this.avatar = userInfo.avatar;
                this.getEmployee(userInfo)
                this.$eventHub.$emit("refreshLocal");
            }
        },
        updateLocal() {
            let userInfo = JSON.parse(localStorage.getItem("dcasUserDetails"));
            userInfo.firstName = this.employee.firstname
            userInfo.lastName = this.employee.surname
            userInfo.email = this.employee.email
            userInfo.phoneNumber = this.employee.phoneNumber;
            userInfo.avatar = this.employee.passportUrl;
            localStorage.setItem("dcasUserDetails", JSON.stringify(userInfo))
            this.loadUser();
        },
        getEmployee(userInfo) {
            this.axios.get(`${configObject.apiBaseUrl}/Employee/${userInfo.employeeId}`, configObject.authConfig)
            .then(response => {
                this.employee = response.data;
                const newEmploymentDate = moment(this.employee.employmentDate).format('YYYY-MM-DD');
                this.employee.employmentDate = newEmploymentDate

                this.fname = response.data.firstname;
                this.sname = response.data.surname;
                this.gender = response.data.gender;
                this.grade = response.data.gradeLevel;
                this.empDate = newEmploymentDate;
            });
        }, 
        validateDetails($event){
            $event.preventDefault();
            if(!this.employee.firstname || !this.employee.surname || !this.employee.gender || !this.employee.phoneNumber 
                || !this.employee.email || !this.employee.gradeLevel)
            {
                this.$toasted.show("Please complete all required Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
            }else {
                this.editEmployee();
            }
        },
        editEmployee() {            
            this.showLoader = true;
            this.isButtonDisabled = true;
            // this.employee.employmentDate = moment(this.employee.employmentDate, 'MM-DD-YYYY').format('MM-DD-YYYY')
            console.log('Updating Employee.....');

            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('id', this.employee.id);
            formData.append('firstname', this.employee.firstname);
            formData.append('surname', this.employee.surname);
            formData.append('gender', this.employee.gender);
            formData.append('phoneNumber', this.employee.phoneNumber);
            formData.append('email', this.employee.email);
            formData.append('oldEmail', this.oldEmail);
            formData.append('gradeLevel', this.employee.gradeLevel);
            formData.append('employmentDate', this.employee.employmentDate);

            this.axios.put(`${configObject.apiBaseUrl}/Employee/Edit/${this.employee.id}`, formData,  configObject.authConfigForUpload)
            .then(response => {
                this.showLoader = false;
                this.isButtonDisabled = false;

                this.employee = response.data;
                this.updateLocal()
                // this.getEmployee(this.employee.id)
                this.$toast.open({
                    message: "Employee details edited successfully",
                    type: "success",
                    duration: 5000
                });
            })
            .catch((error) => {
                this.isButtonDisabled = false;
                this.showLoader = false;
                this.$toast.open({
                    message: "Unable to edit Employee details",
                    type: "error",
                    duration: 4000
                });
                console.log(error);
            });
        },
    }
}
</script>

