<template>
    <MasterLayout>
         <div class="admin__assessment__wrapper">
             <div class="welcome_message">
                 <div class="row">
                     <div class="col-8">    
                        <h5 class=''><span class="greeeting__text"> Hi Admin,</span> Welcome to your Dashboard
                        </h5> 
                     </div>
                 </div>
            </div>
            <!-- <section id="dashboard_requirements">
                <div class="dashboard_requirements">
                    <div class="requirements_title">
                        Meets Requirements
                    </div>
                    <div class="percentage_wrapper">
                        <div class="requirements_percentage_wrapper">
                            <div class="requirements_percentage">
                                65%
                            </div>
                            <div class="percentage-bar">
                                <div> 
                                    <div id="progressbar">
                                        <div class="progress__bar__wrapper">
                                            <div class="inner__div"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src="@/assets/img/requirements.png" class="requirements_image">
                        </div>
                    </div>
                    <div class="requirements_text">
                        The current statistics explains the percentage of staff that meet the requirements 
                    </div>
                </div>
                <div class="dashboard_requirements">
                    <div class="requirements_title">
                        Focus Area
                    </div>
                    <div class="percentage_wrapper">
                        <div class="requirements_percentage_wrapper">
                            <div class="requirements_percentage blue">
                                25%
                            </div>
                            <div class="percentage-bar">
                                <div> 
                                    <div id="progressbar">
                                        <div class="progress__bar__wrapper" id="blue">
                                            <div class="inner__div"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src="@/assets/img/requirements.png" class="requirements_image">
                        </div>
                    </div>
                    <div class="requirements_text">
                        The current statistics explains the percentage of staff that meet the requirements 
                    </div>
                </div>
                <div class="dashboard_requirements" style="margin-right: 0">
                    <div class="requirements_title">
                        Close to Requirement
                    </div>
                    <div class="percentage_wrapper">
                        <div class="requirements_percentage_wrapper">
                            <div class="requirements_percentage red">
                                10%
                            </div>
                            <div class="percentage-bar">
                                <div> 
                                    <div id="progressbar">
                                        <div class="progress__bar__wrapper" id="red">
                                            <div class="inner__div"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src="@/assets/img/requirements.png" class="requirements_image">
                        </div>
                    </div>
                    <div class="requirements_text">
                        The current statistics explains the percentage of staff that meet the requirements 
                    </div>
                </div>
            </section> -->
            <section class="pad-section">
                <b-row>
                    <b-col cols="3">
                        <router-link :to="{ name: 'employees' }" style="text-decoration: none">
                        <div class="d-card">
                            <div class="d-tile typ1 info">
                                <div class="d-tile-head">
                                    Employees
                                </div>
                                <div class="d-tile-body">
                                    <div class="icon">
                                        <!-- <img src="@/assets/img/requirements.png" class=""> -->
                                        <i class="fa fa-users"></i>
                                    </div>
                                    <div class="content">
                                        <h1>{{dashboardCounter.employees}}</h1>            
                                    </div>
                                </div>
                                <div class="d-tile-foot">
                                </div>
                            </div>
                        </div>
                        </router-link>
                    </b-col>
                    <b-col cols="3">
                        <router-link :to="{ name: 'meet_requirements' }" style="text-decoration: none">
                        <div class="d-card">
                            <div class="d-tile typ1 success">
                                <div class="d-tile-head">
                                    Meet Requirements
                                </div>
                                <div class="d-tile-body">
                                    <div class="icon">
                                        <!-- <img src="@/assets/img/requirements.png" class=""> -->
                                        <span class="fa-stack fa-lg">
                                            <i class="fa fa-list-alt fa-stack-2x"></i>
                                            <i class="fa fa-check fa-stack-1x green"></i>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <h1>{{dashboardCounter.meetPerc}}%</h1>
                                    </div>
                                </div>
                                <div class="d-tile-foot">
                                    <div class="d-tile-prog">
                                        <b-progress :value="dashboardCounter.meetPerc" :max="100" variant="success" striped></b-progress>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </router-link>
                    </b-col>
                    <b-col cols="3">
                        <router-link :to="{ name: 'close_to_requirements' }" style="text-decoration: none">
                        <div class="d-card">
                            <div class="d-tile typ1 warning">
                                <div class="d-tile-head">
                                    Close to Requirements
                                </div>
                                <div class="d-tile-body">
                                    <div class="icon">
                                        <!-- <img src="@/assets/img/requirements.png" class=""> -->
                                        <span class="fa-stack fa-lg">
                                            <i class="fa fa-list-alt fa-stack-2x"></i>
                                            <i class="fa fa-share fa-stack-1x orange"></i>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <h1>{{dashboardCounter.closePerc}}%</h1>
                                    </div>
                                </div>
                                <div class="d-tile-foot">
                                    <div class="d-tile-prog">
                                        <b-progress :value="dashboardCounter.closePerc" :max="100" variant="warning" striped></b-progress>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </router-link>
                    </b-col>
                    <b-col cols="3">
                        <router-link :to="{ name: 'not_met_requirements' }" style="text-decoration: none">
                        <div class="d-card">
                            <div class="d-tile typ1 danger">
                                <div class="d-tile-head">
                                    Not Meet Requirements
                                </div>
                                <div class="d-tile-body">
                                    <div class="icon">
                                        <!-- <img src="@/assets/img/requirements.png" class=""> -->
                                        <span class="fa-stack fa-lg">
                                            <i class="fa fa-list-alt fa-stack-2x"></i>
                                            <i class="fa fa-times fa-stack-1x red"></i>
                                        </span>
                                    </div>
                                    <div class="content">
                                        <h1>{{dashboardCounter.notMeetPerc}}%</h1>
                                    </div>
                                </div>
                                <div class="d-tile-foot">
                                    <div class="d-tile-prog">
                                        <b-progress :value="dashboardCounter.notMeetPerc" :max="100" variant="danger" striped></b-progress>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </router-link>
                    </b-col>
                </b-row>
            </section>
            <section class="pad-section mt-5 mb-5">
                <b-row>
                    <b-col cols="4">
                        <div class="d-card" style="height: 338px">
                            <b-card title-tag="Title" header-tag="header">
                                <template v-slot:header>
                                    <h6 class="mb-0">Distribution</h6>
                                </template>
                                <ul class="list-group d-counter-ul lg" style="height: calc(338px - 84px);">
                                    <li class="list-group-item">
                                        <div class="pull-left">
                                            <div class="icon">
                                                <img src="@/assets/img/office-desk.png" class="mt-3" width="25px"  />
                                            </div>
                                        </div>
                                        <div class="pull-right text-right">
                                            <h6>Divisions</h6>
                                            <h3>{{dashboardCounter.divisions}}</h3>
                                        </div>
                                    </li>
                                    <li class="list-group-item">
                                        <div class="pull-left">
                                            <div class="icon">
                                                <img src="@/assets/img/building (1).png" class="mt-3" width="25px"  />
                                            </div>
                                        </div>
                                        <div class="pull-right text-right">
                                            <h6>Branches</h6>
                                            <h3>{{dashboardCounter.branches}}</h3>
                                        </div>
                                    </li>
                                    <li class="list-group-item">
                                        <div class="pull-left">
                                            <div class="icon">
                                                <img src="@/assets/img/building_green.png" class="mt-3" width="25px" style="opacity: 0.6"  />
                                            </div>
                                        </div>
                                        <div class="pull-right text-right">
                                            <h6>Units</h6>
                                            <h3>{{dashboardCounter.units}}</h3>
                                        </div>
                                    </li>
                                </ul>
                            </b-card>                            
                        </div>
                    </b-col>
                    <!-- <b-col cols="8">
                        <div class="d-card">
                            <b-card title-tag="Title" header-tag="header" class="card-body-h292">
                                <template v-slot:header>
                                    <h6 class="mb-0">Gap Analysis by Designations</h6>
                                </template>
                                <b-table striped hover :items="jobRoles" :fields="jobFields" class="no-style">
                                    <template v-slot:cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <template v-slot:cell(gap)="data">
                                        <span class="text-center">{{data.item.gap}} <i class="fa" v-bind:class="data.item.arrow"></i></span>
                                    </template>
                                </b-table>
                            </b-card>
                        </div>
                    </b-col> -->
                    <b-col cols="8">
                        <div class="d-card">
                            <b-card title-tag="Title" header-tag="header" class="card-body-h292">
                                <template v-slot:header>
                                    <h6 class="mb-0">Gap Analysis by Division</h6>
                                </template>
                                <b-table striped hover 
                                    :bordered=true
                                    :busy="isBusy"
                                    :items="gapByDivision" 
                                    :fields="divGapFields" class="no-style">
                                    <template v-slot:cell(name)="data">
                                        <a class="" style="color: #7d7f82;" :href="'/division_gap_analysis?id=' + data.item.divisionId">{{data.item.name}}</a>
                                    </template>
                                    <template v-slot:cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <template v-slot:cell(gap)="data">
                                        <span class="text-center">{{data.item.gap}} <i class="fa" v-bind:class="data.item.arrow"></i></span>
                                    </template>
                                </b-table>
                            </b-card>
                        </div>
                    </b-col>
                </b-row>
            </section>
            <!-- <section id="dashboard_data">
                <div class="dashboard_data_information">
                    <div class="dashboard_data_information_head">
                        <div class="dashboard_data_information_text" id="green">
                            Office of the director
                            <div class="dashboard_data_information_text_div"></div>
                        </div>
                        <div class="dashboard_data_information_text">
                            corperate office
                        </div>
                        <div class="dashboard_data_information_text">
                            Engineering
                        </div>
                        <div class="dashboard_data_information_text">
                            HES
                        </div>
                        <div class="dashboard_data_information_text">
                            BALA
                        </div>
                        <div class="dashboard_data_information_text">
                            Down Stream
                        </div>
                        <div class="dashboard_data_information_text">
                            Up Stream
                        </div>
                    </div>
                    <div class="dashboard_data_information_chart">
                        <div class="row">
                            <div class="col-md-6 text-center">
                                <vue-speedometer class="mt-5"
                                    :minValue="0"
                                    :width="380"
                                    :forceRender=true
                                    :maxValue="100"
                                    needleColor="transparent"
                                    :maxSegmentLabels="5"
                                    :segments="3"
                                    :customSegmentStops="[0, 30, 75, 100]"
                                    :segmentColors='["#069E2A", "#06579E", "#9E2006"]'
                                />
                            </div>
                            <div class="col-md-6 text-center">
                                 <vue-speedometer class="mt-5"
                                    :minValue="0"
                                    :width="380"
                                    :forceRender=true
                                    :maxValue="100"
                                    needleColor="transparent"
                                    :maxSegmentLabels="5"
                                    :segments="3"
                                    :customSegmentStops="[0, 30, 75, 100]"
                                    :segmentColors='["#069E2A", "#06579E", "#9E2006"]'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dashboard_data_right">

                </div>
            </section> -->
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import VueApexCharts from 'vue-apexcharts';
import VueSpeedometer from "vue-speedometer";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    title: 'Dashboard',
    components: {
        MasterLayout,
        VueApexCharts,
        VueSpeedometer 
    },
    provide: {
        grid: [Page, Sort, Toolbar, Search]
    },
    data() {
        return {
            dashboardCounter: {},
            jobRoles: [],
            gapByDivision: [],
            jobFields: [
                {
                    key: 'index',
                }, 
                {
                    key: 'designationName',
                    label: 'Designation',
                    sortable: true
                },
                {
                    key: 'employees',
                    class: 'text-center'
                },
                {
                    key: 'basic',
                    label: 'Current',
                    class: 'text-center'
                },
                {
                    key: 'expected',
                    class: 'text-center'
                },
                {
                    key: 'gap',
                    class: 'text-center',
                    sortable: true
                }],
            divGapFields: [
                {
                    key: 'index',
                    label: '#',
                    sortable: true
                }, 
                {
                    key: 'name',
                    label: 'Division Name',
                    sortable: true
                },
                {
                    key: 'employees',
                    class: 'text-center',
                    sortable: true
                },
                {
                    key: 'current',
                    label: 'Current',
                    class: 'text-center',
                    sortable: true
                },
                {
                    key: 'expected',
                    class: 'text-center',
                    sortable: true
                },
                {
                    key: 'gap',
                    class: 'text-center',
                    sortable: true
                }],
            series: [70],
            options: {
                chart: {
                    height: 350,
                    type: "radialBar"
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: "70%"
                        }
                    }
                },
                labels: ["Cricket"]
            },
            isBusy: false,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["Search"],
            search: { operator: "contains", ignoreCase: true },
            gapTemplate: function(){
                return {
                    template: Vue.component("gapTemplate", {
                        template: `<span class="text-center">{{data.gap}} <i class="fa" v-bind:class="data.arrow"></i></span>`,
                        data: function(){
                            return {
                                data: {}
                            }
                        }
                    })
                }
            },
        }
    },
    created() {
        this.getData();
    },
    mounted(){

    },
    methods:{
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        getData(){
            //let grid = this.$refs.jobRolesGrid;
            this.isBusy = true;
            this.axios
                .get(`${configObject.apiBaseUrl}/Dashboard`, configObject.authConfig)
                .then(response => {
                    this.dashboardCounter = response.data.counter;
                    let index = 0;
                    response.data.jobRoles.forEach(d => {
                        // index += 1;
                        // d.index = index;
                        
                        let g= parseInt(d.basic) - parseInt(d.expected);                    
                        d.gap = isNaN(g) ? 0 : Math.abs(g);
                        d.arrow = g > 0 ? "fa-caret-up green" : g < 0 ? "fa-caret-down red" : "fa-minus gray"
                    });
                    console.log(response.data.gapByDivision);
                    response.data.gapByDivision.forEach(d => {                        
                        let g= parseInt(d.current) - parseInt(d.expected);                    
                        d.gap = isNaN(g) ? 0 : Math.abs(g);
                        d.arrow = g > 0 ? "fa-caret-up green" : g < 0 ? "fa-caret-down red" : "fa-minus gray"
                    });

                    this.dashboardCounter.meetPerc = Math.round((parseFloat(this.dashboardCounter.meetRequirement) / this.dashboardCounter.employees) * 100);
                    this.dashboardCounter.notMeetPerc = Math.round((parseFloat(this.dashboardCounter.notMeetRequirement) / this.dashboardCounter.employees) * 100);
                    this.dashboardCounter.closePerc = Math.round((parseFloat(this.dashboardCounter.closeToRequirement) / this.dashboardCounter.employees) * 100);

                    this.jobRoles = response.data.jobRoles;
                    this.gapByDivision = response.data.gapByDivision;
                    console.log(this.jobRoles);
                    //grid.ej2Instances.setProperties({ dataSource: this.jobRoles });
                    this.refreshJobRolesGrid();
                    this.isBusy = false;
                })
                .catch(error => {
                    console.log(error);
                    this.isBusy = false;
                });
        },
        refreshJobRolesGrid(){
            this.$refs.jobRolesGrid.refresh();
        },
        openGap(id){
            this.$router.push({name: "division_gap_analysis", query:{
                id: id
            }});
        }
    }

}
</script>

        