<template>
    <MasterLayout>
        <div class="employees__assessment__wrapper">

            <div class="welcome_message">
                <div class="row">
                    <div class="col-8">
                        <h5 class=''><span class="greeeting__text"> Hi Admin,
                            </span> you have <span class="branches__count"> {{ units.length }} </span> Units
                        </h5>
                    </div>
                    <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn" @click="openCreateUnitModal">Create Unit</button>
                        </div>
                    </div>
                </div>
            </div>

            <section class="table__section mt-1 pb-5" v-show="units.length > 0 || !showLoader">
                <ejs-grid ref="dataGrid" :created="refreshGrid" :allowPaging="true" :allowSorting="true"
                    :pageSettings="pageSettings" :toolbar="toolbar" :searchSettings="search" :allowExcelExport="true"
                    :allowPdfExport="true" :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" headerText="#" width="60px"></e-column>
                        <e-column field="name" headerText="Unit Name"></e-column>
                        <e-column field="objective" headerText="Objective"></e-column>
                        <e-column field="status" :visible='false' headerText="Status"></e-column>
                        <e-column :template="deletedTemplate" width="150px" headerText="Status"></e-column>
                        <e-column :template="actionButtonsTemplate" width="150px" headerText="Actions"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
            <div class="empty_branch__div mt-3" v-if="units.length < 1 && !showLoader">
                <div class="text-center">
                    <h6 class="text-center"> You are yet to create a branch</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
            <table v-show="showLoader" style="margin: 0 auto; width: 95%">
                <tbody>
                    <tr>
                        <td class="td-1"><span></span></td>
                        <td class="td-2"><span></span></td>
                        <td class="td-3"><span></span></td>
                        <td class="td-4"></td>
                        <td class="td-5"><span></span></td>
                    </tr>
                    <tr>
                        <td class="td-1"><span></span></td>
                        <td class="td-2"><span></span></td>
                        <td class="td-3"><span></span></td>
                        <td class="td-4"></td>
                        <td class="td-5"><span></span></td>
                    </tr>
                    <tr>
                        <td class="td-1"><span></span></td>
                        <td class="td-2"><span></span></td>
                        <td class="td-3"><span></span></td>
                        <td class="td-4"></td>
                        <td class="td-5"><span></span></td>
                    </tr>
                    <tr>
                        <td class="td-1"><span></span></td>
                        <td class="td-2"><span></span></td>
                        <td class="td-3"><span></span></td>
                        <td class="td-4"></td>
                        <td class="td-5"><span></span></td>
                    </tr>
                    <tr>
                        <td class="td-1"><span></span></td>
                        <td class="td-2"><span></span></td>
                        <td class="td-3"><span></span></td>
                        <td class="td-4"></td>
                        <td class="td-5"><span></span></td>
                    </tr>
                    <tr>
                        <td class="td-1"><span></span></td>
                        <td class="td-2"><span></span></td>
                        <td class="td-3"><span></span></td>
                        <td class="td-4"></td>
                        <td class="td-5"><span></span></td>
                    </tr>
                    <tr>
                        <td class="td-1"><span></span></td>
                        <td class="td-2"><span></span></td>
                        <td class="td-3"><span></span></td>
                        <td class="td-4"></td>
                        <td class="td-5"><span></span></td>
                    </tr>
                    <tr>
                        <td class="td-1"><span></span></td>
                        <td class="td-2"><span></span></td>
                        <td class="td-3"><span></span></td>
                        <td class="td-4"></td>
                        <td class="td-5"><span></span></td>
                    </tr>
                    <tr>
                        <td class="td-1"><span></span></td>
                        <td class="td-2"><span></span></td>
                        <td class="td-3"><span></span></td>
                        <td class="td-4"></td>
                        <td class="td-5"><span></span></td>
                    </tr>
                </tbody>
            </table>
            <CreateUnitModal></CreateUnitModal>
            <editUnitModal :unit="unit"></editUnitModal>
        </div>
    </MasterLayout>
</template>

<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import backgroundUrl from "@/assets/img/f6c324105a64e036f9e668224c23e501.jpg";
import UserDP from "@/assets/img/eben.jpeg";
import CreateUnitModal from '@/components/Modals/Unit/createUnitModal.vue';
import editUnitModal from '@/components/Modals/Unit/editUnitModal.vue';
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
    Page,
    Sort,
    Toolbar,
    Search,
    ExcelExport,
    PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    components: {
        MasterLayout,
        CreateUnitModal,
        editUnitModal,
    },
    provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            backgroundUrl,
            UserDP,
            componentKey: 0,
            showLoader: false,
            units: [],
            unit: {},
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            actionButtonsTemplate: function () {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                            '<div class="btn-group btn-group-sm" role="group">' +
                            '<button type="button" class="btn btn-primary" title="Edit" v-on:click="_editUnit(data.id)">' +
                            '<i class="fa fa-edit" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-danger" title="Delete" v-on:click="_deleteUnit(data.id)" v-if="data.deleted === false">' +
                            '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-success" title="Re-Add" v-on:click="_readdeUnit(data.id)" v-if="data.deleted === true">' +
                            '<i class="fa fa-plus" aria-hidden="true"></i>' +
                            '</button>' +
                            '</div>',
                        data: function () {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _deleteUnit: function (unitId) {
                                this.$eventHub.$emit("deleteUnitModal", unitId, 0);
                            },
                            _readdeUnit: function (unitId) {
                                this.$eventHub.$emit("deleteUnitModal", unitId, 1);
                            },
                            _editUnit: function (unitId) {
                                //console.log(unitId);
                                this.$eventHub.$emit("editUnitModal", unitId);
                            }
                        }
                    })
                };
            },
            deletedTemplate: function () {
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>
<div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
                        data: function () {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            }
        }
    },
    mounted() {
        this.getUnits();

        $(".e-input").keyup(function (e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created() {
        this.$eventHub.$on("deleteUnitModal", (unitId, typ) => {
            this.deleteUnit(unitId, typ);
        });
        this.$eventHub.$on("editUnitModal", (unitId) => {
            this.editUnit(unitId);
        });
        this.$eventHub.$on("refreshUnitTable", () => {
            this.getUnits();
        });
    },
    methods: {
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.dataGrid.getColumns()[3].visible = true;
                    this.$refs.dataGrid.getColumns()[4].visible = false;
                    this.$refs.dataGrid.getColumns()[5].visible = false;

                    this.$refs.dataGrid.pdfExport({
                        pageOrientation: 'Landscape',
                        fileName: 'Units.pdf',
                        theme: {
                            header: {
                                bold: true,
                                fontColor: "#000000",
                                fontName: 'Calibri',
                                fontSize: 10
                            },
                            record: {
                                fontColor: '#000000',
                                fontName: "Calibri",
                                fontSize: 8
                            }
                        },
                        header: {
                            fromTop: 0,
                            height: 130,
                            contents: [{
                                type: "Text",
                                value: 'Units',
                                position: { x: 0, y: 50 },
                                style: { fontSize: 20, textAlign: 'center' }
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents: [{
                                type: "Text",
                                value: "Thank You",
                                position: { x: 0, y: 50 },
                                style: { fontSize: 20 }
                            }],

                        }
                    });
                    break;
                case "Excel Export":
                    this.$refs.dataGrid.getColumns()[3].visible = true;
                    this.$refs.dataGrid.getColumns()[4].visible = false;
                    this.$refs.dataGrid.getColumns()[5].visible = false;
                    this.$refs.dataGrid.excelExport({
                        fileName: 'Units.xlsx',
                        theme: {
                            header: {
                                bold: true,
                                fontColor: "#000000",
                                fontName: 'Calibri',
                                fontSize: 10
                            },
                            record: {
                                fontColor: '#000000',
                                fontName: "Calibri",
                                fontSize: 8
                            }
                        },
                        header: {
                            fromTop: 0,
                            height: 130,
                            contents: [{
                                type: "Text",
                                value: `Units`,
                                position: { x: 0, y: 50 },
                                style: { fontSize: 20, textAlign: 'center' }
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents: [{
                                type: "Text",
                                value: "Thank You",
                                position: { x: 0, y: 50 },
                                style: { fontSize: 20 }
                            }],

                        }
                    });
                    break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        forceRerender() {
            this.openeditUnitModal();
            this.componentKey += 1;
        },
        openCreateUnitModal() {
            this.$modal.show('createUnitModal');
        },
        openEditUnitModal() {
            this.$modal.show('editUnitModal');
        },
        editUnit(unitId) {
            let unitDetail = this.units.filter(unit => unit.id === unitId)[0];
            this.unit = unitDetail;
            this.openEditUnitModal();
        },
        deleteUnit(unitId, typ) {
            let resp = false;
            if (typ === 0) {
                resp = confirm("Are you sure you want to delete this unit");
            }
            else {
                resp = confirm("Are you sure you want to Re-Add this unit");
            }
            console.log(resp);

            if (resp) {
                this.axios
                    .delete(
                        `${configObject.apiBaseUrl}/Unit/Delete/${unitId}`,
                        configObject.authConfig
                    )
                    .then(() => {
                        this.getUnits();
                        let msg = "";

                        if (typ === 0) {
                            msg = "Unit Deleted successfully!"
                        }
                        else {
                            msg = "Unit Re-Added successfully!"
                        }

                        this.$toast.open({
                            message: msg,
                            type: "success",
                            duration: 4000
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        this.$toast.open({
                            message: "unable to perform action",
                            type: "error",
                            duration: 4000
                        });
                    });
            }
        },
        getUnits() {
            this.showLoader = true;
            let grid = this.$refs.dataGrid;
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Unit`,
                    configObject.authConfig
                )
                .then(response => {
                    console.log('Units loaded');
                    let index = 0;
                    response.data.forEach(unit => {
                        index++;
                        unit.index = index;
                        unit.status = unit.deleted ? 'deleted' : 'active'
                    });
                    this.showLoader = false;

                    grid.ej2Instances.setProperties({ dataSource: response.data });
                    this.refreshGrid();
                    this.units = response.data;
                    this.showLoader = false;
                })
                .catch((error) => {
                    console.log('Unit Error: ' + error);
                    // this.$toast.open({
                    //     message: "Unable to load units",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
    }
}
</script>

        