<template>
<modal name="addRoleTaskModal" transition="pop-out" :width="modalWidth" :height="300">
  <div class="modal__header">
      <h4>Add New Role Task</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-12">
                <div class="" style="width: 100%">
                    <span>Task</span>
                    <div class="">
                        <input type="text" v-model="task" required />
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <div class="text-center mt-4">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[isButtonDisabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' }]">
            Add Task 
            <span class="">
                <img src="@/assets/img/git_loader.gif" alt="" class="ml-3" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 400;
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;

export default {
  name: 'addRoleTaskModal',
  data () {
    return {
        modalWidth: MODAL_WIDTH,
        designationName: null,   
        isButtonDisabled:  false,
        showLoader: false,
        task: null        
    }
  },
  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH    
  },
  mounted(){
  },
  methods: {
      validateDetails($event) {
        $event.preventDefault();
        if(!this.task){
            this.$toasted.show("Please complete all Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
        }else {
            this.createRoleTask();
        }
    },
    closeaddRoleTaskModal(){
        this.$modal.hide('addRoleTaskModal');
    },
    createRoleTask() {
        let dataToSend = {
            task: this.task
        };
        this.showLoader = true;
        this.isButtonDisabled = true;
        this.axios.post(`${configObject.apiBaseUrl}/RoleTask/Add`, dataToSend, configObject.authConfig)
        .then(() => {
            this.showLoader = false;
            this.isButtonDisabled = false;
            this.$toast.open({
                message: "Role Task created successfully",
                type: "success",
                duration: 4000
            });
            this.task = null;
            this.$eventHub.$emit("refreshRoleTaskTable");
            this.closeaddRoleTaskModal();
        })
        .catch((error) => {
            this.isButtonDisabled = false;
            this.showLoader = false;

            let err = error.response.data;
            this.$toast.open({
                message: "unable to create role task",
                type: "error",
                duration: 3000
            });
        });
    },
  }
}

$(function(){
    console.log('JQuery Ready');

    $('.selector').on('change', function(){
        alert('Valeu changed to ' + $(this).val());
    })
})
</script>
