<template>
    <MasterLayout>
        <div class="employees__assessment__wrapper">
            <div class="welcome_message">
                <div class="row">
                    <div class="col-8">    
                        <h5 class=''><span class="greeeting__text"> Hi Admin,
                        </span> you have <span class="branches__count"> {{designations.length}} </span> Designations
                    </h5> 
                    </div>
                    <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn" @click="openModal('createDesignationModal')">
                                Add Designation
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <section class="table__section mt-1 pb-5" v-show="designations.length > 0">
                <ejs-grid
                    ref="desigGrid"
                    :created="refreshDesigGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" headerText="#" width="60px"></e-column>
                        <e-column field="designationName" headerText="Designation"></e-column>
                        <e-column field="roleCount" width="80px" headerText="Roles"></e-column>
                        <e-column field="status" width="80px" :visible='false' headerText="Status"></e-column>
                        <e-column :template="deletedTemplate" width="80px" headerText="Status"></e-column>
                        <e-column :template="designationActionTemplate" width="125px" headerText="Actions"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
            
            <div class="empty_branch__div mt-1" v-if="designations.length < 1">
                <div class="text-center">
                    <h6 class="text-center">No Designation created yet</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
            
            <TableLoading v-show="showLoader"></TableLoading>              
            <CreateDesignationModal></CreateDesignationModal>
            <EditDesignationModal :designation ="designation"></EditDesignationModal>
        </div>
    </MasterLayout>
</template>

<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import CreateDesignationModal from '@/components/Modals/DesignationProfile/createDesignationModal.vue';
import EditDesignationModal from '@/components/Modals/DesignationProfile/editDesignationModal.vue';
import MapDesignatinoModal from '@/components/Modals/DesignationProfile/mapDesignationModal.vue';
import TableLoading from '@/components/TableLoading.vue'; 

import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;

import {
  Page,
  Sort,
  Search,
  ExcelExport,
  PdfExport,
  Toolbar
} from "@syncfusion/ej2-vue-grids";

export default {
    title: 'Designations',
    components: {
        MasterLayout,
        CreateDesignationModal,
        EditDesignationModal,
        MapDesignatinoModal,
        TableLoading
    },
    provide:{
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data(){
        return{
            designations: [],
            designation: null,
            showLoader: false,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },            
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>` +
                            `<div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            },            
            designationActionTemplate: function() {
                return {
                    template: Vue.component("roleTaskButton", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-primary" title="Edit Designation" v-on:click="_editDesig(data.id)">' +
                                '<i class="fa fa-edit" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-warning" title="Map roles to Designation" v-on:click="_mapRoleToDesig(`map_roles_to_designation`, data.id, data.designationName)">' +
                                '<i class="fa fa-map-o" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-danger" title="Delete Designation" v-on:click="_deleteDesig(data.id)" v-if="data.deleted === false">' +
                                '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-success" title="Re-Add Designation" v-on:click="_readdDesig(data.id)" v-if="data.deleted === true">' +
                                '<i class="fa fa-plus" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            _deleteDesig: function(desigId) {
                                this.$eventHub.$emit("deleteDesignationVM", desigId, 0);
                            },
                            _readdDesig: function(desigId) {
                                this.$eventHub.$emit("deleteDesignationVM", desigId, 1);
                            },
                            _editDesig: function(desigId) {
                                this.$eventHub.$emit("editDesignation", desigId);
                            },
                            _mapCompetencies: function(profileId) {
                                this.$eventHub.$emit("mapCompetencies", profileId);
                            },
                             _mapRoleToDesig: function($routeName, $desigId, $designationName) {
                                // console.log("divId=>" + $desigId);
                                // console.log('Designation: ' + $designationName);
                                this.$eventHub.$emit("mapRoles", $routeName, $desigId, $designationName);
                            }
                        }
                    })
                };
            },
        }
    },
    created(){
        this.$eventHub.$on("refreshDesignationTable", () => {
            this.getDesignations();
        });
        this.$eventHub.$on("editDesignation", (desigId) => {
            let desig = this.designations.filter(d => d.id === desigId)[0];
            this.designation = desig;
            this.openModal('editDesignationModal');
        });
        this.$eventHub.$on("mapRoleToDesig", (desigId) => {
            let desig = this.designations.filter(d => d.id === desigId)[0];
            this.designation = desig;
            this.getDesignationRoles();
            this.openModal('m');
        });
        this.$eventHub.$on("deleteDesignationVM", (desigId, typ) => {
            this.deleteDesignation(desigId, typ);
        });

        this.$eventHub.$on("mapRoles", ($routeName, $desigId, $designationName) => {
            //console.log($desigId);
            //this.changeRoute($routeName, $desigId);
            this.$router.push(
                {
                    name: $routeName , 
                    query: {
                        id: $desigId,
                        name: $designationName
                    }
                }
            )
        }); 
    },
    mounted(){
        this.getDesignations();
        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    methods:{
        changeRoute($routeName, $desigId) {
            this.$router.push(
                {
                    name: $routeName , 
                    query: {
                        id: $desigId
                    }
                }
            )
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.desigGrid.getColumns()[3].visible = true;
                    this.$refs.desigGrid.getColumns()[4].visible = false;
                    this.$refs.desigGrid.getColumns()[5].visible = false;

                    this.$refs.desigGrid.pdfExport({
                        fileName: 'Designation.pdf',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  'Designation',
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
                case "Excel Export":
                    this.$refs.desigGrid.getColumns()[3].visible = true;
                    this.$refs.desigGrid.getColumns()[4].visible = false;
                    this.$refs.desigGrid.getColumns()[5].visible = false;
                    this.$refs.desigGrid.excelExport({
                        fileName: 'Designation.xlsx',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  `Designation`,
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
            }
        },
        deleteDesignation(desigId, typ) {
            // var resp = confirm("Are you sure  you want to delete this designation");
            // if (resp) {
            //     this.axios
            //         .delete(
            //             `${configObject.apiBaseUrl}/Designation/Delete/${desigId}`,
            //             configObject.authConfig
            //         )
            //         .then(() => {
            //             this.getDesignations();
            //             this.$toast.open({
            //                 message: "Designation deleted successfully!",
            //                 type: "success",
            //                 duration: 3000
            //             });
            //         })
            //         .catch(error => {
            //             console.log(error);
            //             this.$toast.open({
            //                 message: "unable to delete designation",
            //                 type: "error",
            //                 duration: 3000
            //             });
            //         });
            // }
            let resp = false;
            if(typ === 0){
                resp = confirm("Are you sure you want to delete this Designation");
            }
            else{
                resp = confirm("Are you sure you want to Re-Add this Designation");
            }

            if (resp) {
                 this.axios.delete(
                        `${configObject.apiBaseUrl}/Designation/Delete/${desigId}`,
                        configObject.authConfig
                    )
                    .then(() => {
                        this.getDesignations();
                        let msg = "";

                        if(typ === 0){
                            msg = "Designation Deleted successfully!"
                        }
                        else{
                            msg = "Designation Re-Added successfully!"
                        }

                        this.$toast.open({
                            message: msg,
                            type: "success",
                            duration: 4000
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        this.$toast.open({
                            message: "Unable to perform action",
                            type: "error",
                            duration: 4000
                        });
                    });
            }
        },
        getDesignations(){
            let grid = this.$refs.desigGrid;
            this.showLoader = true;
            this.axios
                .get(`${configObject.apiBaseUrl}/Designation`, configObject.authConfig)
                .then(response => {
                    this.showLoader = false;
                    let index = 0;
                    response.data.forEach(grade => {
                        index++;
                        grade.index = index;
                        grade.status = grade.deleted ? 'deleted' : 'active'
                    });
                    this.designations = response.data;
                    grid.ej2Instances.setProperties({ dataSource: this.designations });
                    this.refreshDesigGrid();
                })
                .catch((error) => {
                    console.log(error);
                    // this.$toast.open({
                    //     message: "unable to load designations",
                    //     type: "error",
                    //     duration: 5000
                    // });
                });
        },
        getDesignationRoles(){
            this.showLoader = true;
            if(this.designation){
                this.axios.get(`${configObject.apiBaseUrl}/Designation/Roles/${this.designation.id}`, configObject.authConfig)
                .then(resp => {
                    this.showLoader = false;
                    this.myRoles = resp.data;
                    console.log(this.myRoles);
                });
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        refreshDesigGrid() {
            this.$refs.desigGrid.refresh();
        },
        openModal(modalName){
            console.log('Open Modal :> ' + modalName);
            this.$modal.show(modalName);
        },
    }
}
</script>