<template>
    <modal name="editEmployeeModal" transition="pop-out" :width="modalWidth" :height="600">
        <div class="modal__header">
            <h4>Edit Employee Details</h4>
        </div>
        <div class="cas_input">
            <div class="row__wrapper">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Firstname</span>
                            <div class="">
                                <input type="text" value="" v-model="employee.firstname" required ref="inputs" />
                                <input type="hidden" v-model="employee.id" />
                                <input type="hidden" v-model="employee.oldEmail" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Surname</span>
                            <div class="">
                                <input type="text" value="" v-model="employee.surname" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Login Email</span>
                            <div class="">
                                <input type="email" value="" v-model="employee.email" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Phone Number</span>
                            <div class="">
                                <input type="text" value="" v-model="employee.phoneNumber" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Gender</span>
                            <div class="">
                                <select ref="gender" class="ml-3 selector" v-model="employee.gender" required>
                                    <option value="">Select Gender</option>
                                    <option value="Female">Female</option>
                                    <option value="Male">Male</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Grade Level</span>
                            <div class="">
                                <select ref="gradeLevel" class="ml-3 selector" v-model="employee.gradeLevel" required>
                                    <option value="">Select Grade Level</option>
                                    <option v-for="d in grades" v-bind:key="d.id" v-bind:value="d.gradeName">{{ d.gradeName }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input_wrapper" style="width: 100%; margin-bottom: 0!important;">
                            <span>Employment Date</span>
                            <div class="">
                                <!-- <datepicker placeholder="Select Date" v-model="employee.employmentDate" :format="format"></datepicker> -->
                                <input type="date" style="margin-bottom: 0!important; width: 100%;"
                                    v-model="employee.employmentDate">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mt-3 mb-3">
                        <ejs-dropdownlist id='dropdownlist' placeholder='Select a Designation'
                            v-model="employee.designationProfileId" :allowFiltering='allowFiltering' :filtering='filtering'
                            :dataSource='profiles' :fields='fields'></ejs-dropdownlist>
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Designation</span>
                            <div class="">
                                <select ref="gradeLevel" class="ml-3 selector" v-model="designationId" required>
                                    <option value="Select a designation" disabled>Select a designation</option>
                                    <option v-for="(profile, index) in profiles" v-bind:key="index" v-bind:value="profile.id">{{profile.designationName}}</option>
                                </select>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Passport</span>
                            <div class="">
                                <b-form-file v-model="file" placeholder="Choose a file" drop-placeholder="Drop file here..."
                                    accept="image/jpeg, image/png, image/gif"></b-form-file>
                                <!-- <input type="file" name="file" id="file" ref="file" v-on:change="handleFileUpload()"/>  -->
                            </div>
                            <div class="mt-2">
                                <small class="">
                                    <i>
                                        <strong>N.B.:</strong> Only image files (jpg, png &amp; gif) are allowed and max
                                        file size of 400kb
                                    </i>
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="input_wrapper" style="width: 100%;">
                            <span>Current Picture</span>
                        </div>
                        <div class="profile__pic" :style="{
                            backgroundImage: `url(${employee.passportUrl})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            width: '80px',
                            height: '80px',
                            marginTop: '20px'
                        }">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input_wrapper" style="width: 100%">
                            <span>Employee ID</span>
                            <div class="">
                                <input type="text" value="" v-model="employee.employeeId" required ref="inputs" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-4">
                <button class="creaet_branch_btn" @click="validateDetails($event)"
                    :disabled="isButtonDisabled ? true : null" :style="[
                        isButtonDisabled
                            ? { cursor: 'not-allowed' }
                            : { cursor: 'pointer' }
                    ]">
                    Edit &amp; Save <i class="fa fa-save"></i>
                    <span class="">
                        <img src="@/assets/img/git_loader.gif" v-if="showLoader" width="25px" />
                    </span>
                </button>
            </div>
        </div>
    </modal>
</template>
<script>

const MODAL_WIDTH = 800;
import configObject from "@/config";
import moment from "moment";
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";

export default {
    name: 'editEmployeeModal',
    props: ['employee', 'grades'],
    components: {
        Datepicker
    },
    data() {
        return {
            file: '',
            format: "MM-dd-yyyy",
            modalWidth: MODAL_WIDTH,
            isButtonDisabled: false,
            showLoader: false,
            empdate: null,
            designationId: null,
            profiles: [],
            fields: { text: "displayText", value: "id" },
            allowFiltering: true,
            filtering: ''
        }
    },
    created() {
        this.modalWidth = window.innerWidth < MODAL_WIDTH
            ? MODAL_WIDTH / 2
            : MODAL_WIDTH

        // console.log(this.employee.employmentDate);

        // this.empdate = moment(this.employee.employmentDate, 'MM-DD-YYYY').format('MM-DD-YYYY');
        // console.log(datethis.empdate);
    },
    mounted() {
        this.getProfiles()
    },
    methods: {
        getProfiles() {
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Designation/Profile`,
                    configObject.authConfig
                )
                .then(response => {
                    response.data.forEach(p => {
                        p.displayText = `${p.designationName} [${p.divisionName} :: ${p.branchName}]`;
                    });
                    this.profiles = response.data
                })
                .catch((error) => {
                    console.log('ERROR ::: ' + error);
                    // this.$toast.open({
                    //     message: "unable to load profiles",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        },
        closeModal() {
            this.$modal.hide('editEmployeeModal');
        },
        validateDetails($event) {
            $event.preventDefault();
            if (!this.employee.firstname || !this.employee.surname || !this.employee.gender || !this.employee.phoneNumber
                || !this.employee.email || !this.employee.gradeLevel || !this.employee.employeeId) {
                this.$toasted.show("Please complete all required Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
            } else {
                this.editEmployee();
            }
        },
        editEmployee() {
            this.showLoader = true;
            this.isButtonDisabled = true;
            //moment(this.employee.employmentDate, 'MM-DD-YYYY').format('MM-DD-YYYY')

            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('id', this.employee.id);
            formData.append('firstname', this.employee.firstname);
            formData.append('surname', this.employee.surname);
            formData.append('gender', this.employee.gender);
            formData.append('phoneNumber', this.employee.phoneNumber);
            formData.append('email', this.employee.email);
            formData.append('oldEmail', this.employee.oldEmail);
            formData.append('gradeLevel', this.employee.gradeLevel);
            formData.append('employmentDate', this.employee.employmentDate);
            formData.append('designationId', this.employee.designationProfileId);
            formData.append('employeeId', this.employee.employeeId);
            console.log(formData);

            this.axios.put(`${configObject.apiBaseUrl}/Employee/Edit/${this.employee.id}`, formData, configObject.authConfigForUpload)
                .then(response => {
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    console.log(response.data);
                    this.closeModal();
                    this.$eventHub.$emit("refreshEmployeeTable");
                    this.$toast.open({
                        message: "Employee details edited successfully",
                        type: "success",
                        duration: 5000
                    });
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "Unable to edit Employee details",
                        type: "error",
                        duration: 4000
                    });
                    console.log(error);
                });
        },
    }
}
</script>


<style scoped>
.cas_input .input_wrapper {
    margin-bottom: 5px !important;
}

.cas_input textarea,
.cas_input [type='text'] {
    margin-bottom: 5px !important;
}
</style>
<style scoped>
#dropdownlist_hidden.e-ddl-hidden {
    padding: 0 !important;
}

.e-control-wrapper input {
    margin-bottom: 0px !important;
    padding: 7px 0;
    font-size: 15px;
}
</style>
