import Vue from "vue";
import router from "./router";
import store from "@/store/index";
import logo from "@/assets/img/coat-of-arm.jpg";
import dLogo from "@/assets/img/logo.png";
// let $ = JQuery;

let token;
const lms_EndPoints = {
  courseList: "/v1/courses/",
  users: "/v1/users",
};

export let configObject = {
  // IHCMS: Props
  // portalName: "iHCMS",
  // apiBaseUrl: "https://api.ihcmsportal.com",
  // portalLogo: logo,

  // DCAS: Props
  portalName: "NCAS",
  apiBaseUrl: "https://dcas-api.azurewebsites.net",
  portalLogo: dLogo,

  //apiBaseUrl: "https://localhost:5001",

  lmsUrl: "https://limsaa.talentlms.com/api",
  lmsEndPoints: lms_EndPoints,
  lmsAuth: {
    headers: {
      Authorization: "Basic dTROVXl5TXhqdDlJYVozdVdEaWxqNmw5aWE1WnZUOg==",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Cache-Control": "no-cache",
    },
  },

  authConfig: configObjectFunc().authConfig,
  authConfigForUpload: configObjectFunc().authConfigForUpload,
};

function configObjectFunc() {
  if (store.getUserInfo) {
    token = store.getUserInfo.token;
  }

  if (localStorage.expiryTime) {
    const elapsed = JSON.parse(localStorage.expiryTime);
    const now = Date.now();

    if (now > elapsed) {
      localStorage.clear();
      alert("Session has expired, please login again");
      window.location = "/admin/dashboard";
      // router.push({
      //     name: 'login'
      // });
      return;
    }
  }

  if (localStorage.getItem("dcasUserDetails")) {
    token = JSON.parse(localStorage.getItem("dcasUserDetails")).token;
  } else {
    token = null;
  }

  if (token !== null) {
    return {
      authConfig: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + token,
          Accept: "application/json",
        },
      },
      authConfigForUpload: {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "bearer " + token,
          Accept: "*/*",
        },
      },
    };
  } else {
    return {
      authConfig: {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
      authConfigForUpload: {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    };
  }
}

export default configObject;
//export default configObjectFunc;
