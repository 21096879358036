<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">
            <div class="welcome_message">
                <div class="row">
                    <div class="col-8">
                        <h5>
                            <span v-if="assessment.assessmentType === 'Training'">Training</span>
                            <span v-if="assessment.assessmentType !== 'Training'">Assessment</span> &nbsp;
                            <span class="branches__count" v-if="isSupervisor !== true"
                                :style="[
                                    assessment.uplineAction === 'Not Approved' ? { color: 'red' } :
                                    assessment.uplineAction === 'Pending' ? { color: '#888' } : 
                                    assessment.uplineAction === 'Approved' ? { color: '#098c28' } : 
                                    {}
                                ]">{{assessment.uplineAction.toUpperCase()}} </span>
                            <span class="branches__count" v-if="isSupervisor === true"
                                :style="[
                                    assessment.supervisorAction === 'Not Approved' ? { color: 'red' } :
                                    assessment.supervisorAction === 'Pending' ? { color: '#888' } : 
                                    assessment.supervisorAction === 'Approved' ? { color: '#098c28' } : 
                                    {}
                                ]">{{assessment.supervisorAction.toUpperCase()}} </span>
                        </h5>
                        <!-- <h5 v-if="assessment.status === 'Approved'"><span class="greeeting__text">Assessment</span> <span class="branches__count"> {{assessment.status.toUpperCase()}} </span></h5>
                        <h5 v-if="assessment.status === 'Disapproved'"><span class="greeeting__text">Assessment</span> <span class="branches__count" style="color: red;"> {{assessment.status.toUpperCase()}} </span></h5> -->
                    </div>
                    <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn default" @click="backToPreviousPage"><i class="fa fa-arrow-left"></i> Back</button>
                            <!-- <button class="creaet_branch_btn" @click="processAssessment"
                                :disabled="isButtonDisabled ? true : null"
                                :style="[
                                isButtonDisabled
                                    ? { cursor: 'not-allowed' }
                                    : { cursor: 'pointer' }
                                ]" v-if="assessment.status === 'Pending'">
                                {{assessment.assessmentType === 'Assessment' ? 'Process Assessment' : 'Process Appraisal' }}
                                
                                <span class="" v-if="showLoader">
                                    <img src="@/assets/img/git_loader.gif"  width="25px"/>
                                </span><i class="fa fa-save ml-2" v-if="!showLoader"></i>
                            </button>                   -->
                        </div>
                    </div>
                </div>
            </div>
            <section class="table__section mt-1 pb-5">
                <div class="cas_input mt-3">
                    <div class="row">
                        <div class="col-md-offset-2 col-md-12">
                            <div class="row__wrapper push-up">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="row" v-if="assessment.assessmentType === 'Training'">
                                            <div class="col-md-6">
                                                <div class="" style="width: 100%">
                                                    <span>Training Name</span>
                                                    <div class="">
                                                        
                                                        <input type="text" placeholder="Enter Training name here" readonly v-model="assessment.trainingName"  />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="" style="width: 100%">
                                                    <span>Training Date</span>
                                                    <div class="">
                                                        <!-- <datepicker placeholder="Select Date" v-model="trainingDate" :format="format" v-on:selected="dateChanged($event)"></datepicker> -->
                                                        <input type="text" v-model="trainingDate" readonly />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="" style="width: 100%">
                                                    <!-- <span>General Commments</span> -->
                                                    <span v-if="assessment.assessmentType !== 'Training'">General Commments</span>
                                                    <span class="mb-2" style="display: block;" v-if="assessment.assessmentType === 'Training'">Training Note</span>
                                                    <div class="">
                                                        <textarea type="text" placeholder="Enter general comment here" readonly class="" v-model="assessment.employeeComment"
                                                            :style="[ assessment.assessmentType !== 'Training' ? { height: '400px' } : { height: '200px' }]"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-if="assessment.status !== 'Pending'">
                                            <div class="col-md-12">
                                                <hr />
                                                <div class="" style="width: 100%">
                                                    <span>Processor <b>"{{assessment.actionBy}}"</b> Commments</span>
                                                    <div class="">
                                                        <textarea type="text" readonly disabled class="" v-model="assessment.comment"></textarea>
                                                        <i>Assessment actioned on "{{dateActioned}}"</i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="" style="width: 100%">
                                            <div v-if="assessment.assessmentType === 'Training'">
                                                <span v-if="assessment.documents && assessment.documents.length < 2">Training Evidence</span>
                                                <span v-if="assessment.documents && assessment.documents.length > 1">Training Evidences</span>
                                            </div>
                                            <div v-if="assessment.assessmentType !== 'Training'">
                                                <span v-if="assessment.documents && assessment.documents.length < 2">Assessment Evidence</span>
                                                <span v-if="assessment.documents && assessment.documents.length > 1">Assessment Evidences</span>
                                            </div>
                                            <b-row class="mt-4">
                                                <b-col v-for="(doc, i) in assessment.documents" v-bind:key="doc.id">
                                                    <div class="file-icon">
                                                        <a v-bind:href="'' + doc.documentUrl" target="_blank">
                                                            <i class="fa fa-file-image-o img" v-if="doc.documentUrl.substring(doc.documentUrl.lastIndexOf('.')).toLowerCase() !== '.pdf'"></i>
                                                            <i class="fa fa-file-pdf-o" v-if="doc.documentUrl.substring(doc.documentUrl.lastIndexOf('.')).toLowerCase() === '.pdf'" ></i>
                                                            <br>
                                                            Document {{i + 1}}
                                                        </a>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            <b-row class="mt-4" v-if="assessment.documents && assessment.documents.length <= 0">
                                                <p>No document uploaded</p>
                                            </b-row>                                       
                                        </div>
                                        <div class="mt-5" width="100%">
                                            <hr class="mt-3" />
                                            <div class="" v-if="isSupervisor !== true" >
                                                <h5 style="text-align: center;">My Action</h5>
                                                <textarea v-model="assessment.uplineComment" style="width: 100%; height: 100px;" class="form-control mb-3"></textarea>
                                                <!-- <i>Upline: <span>{{assessment.upline}}</span></i><br /> -->
                                                <div v-if="assessment.uplineAction.toLowerCase() === 'pending'">
                                                    <button class="creaet_branch_btn" @click="approveAssessment('Approve')">
                                                        Approve <i class="fa fa-thumb-up ml-2" v-if="!showLoader"></i>
                                                    </button>
                                                    <button class="creaet_branch_btn" style="background-color: #d30303;" @click="approveAssessment('Disapprove')">
                                                        Dis-Approve <i class="fa fa-thumb-up ml-2" v-if="!showLoader"></i>
                                                    </button>
                                                </div>
                                                <div v-if="assessment.uplineAction.toLowerCase() !== 'pending'">
                                                    <i>Action: <span :style="[
                                                                        assessment.uplineAction === 'Not Approved' ? { color: 'red' } :
                                                                        assessment.uplineAction === 'Pending' ? { color: '#888' } : 
                                                                        assessment.uplineAction === 'Approved' ? { color: '#098c28' } : 
                                                                        {}
                                                                    ]">{{assessment.supervisorAction.toUpperCase()}}</span></i>
                                                </div>
                                                <div v-if="assessment.uplineAction.toLowerCase() !== 'pending'">
                                                    <i>Date Actioned: <span>{{uplineActionedDate}}</span></i>
                                                </div>
                                                
                                            </div>
                                            <div class="" v-if="isSupervisor === true" >
                                                <h5 style="text-align: center;">My Action</h5>
                                                <textarea v-model="assessment.supervisorComment" style="width: 100%; height: 80px;" class="form-control mb-3"></textarea>
                                                <!-- <i>Upline: <span>{{assessment.upline}}</span></i><br /> -->
                                                <div v-if="assessment.supervisorAction.toLowerCase() === 'pending'">
                                                    <button class="creaet_branch_btn" @click="approveAssessment('Approve', 'sup')">
                                                        Approve <i class="fa fa-thumb-up ml-2" v-if="!showLoader"></i>
                                                    </button>
                                                    <button class="creaet_branch_btn" style="background-color: #d30303;" @click="approveAssessment('Disapprove', 'sup')">
                                                        Dis-Approve <i class="fa fa-thumb-up ml-2" v-if="!showLoader"></i>
                                                    </button>
                                                </div>
                                                <div v-if="assessment.supervisorAction.toLowerCase() !== 'pending'">
                                                    <i>Action: <span :style="[
                                                                        assessment.supervisorAction === 'Not Approved' ? { color: 'red' } :
                                                                        assessment.supervisorAction === 'Pending' ? { color: '#888' } : 
                                                                        assessment.supervisorAction === 'Approved' ? { color: '#098c28' } : 
                                                                        {}
                                                                    ]">{{assessment.supervisorAction.toUpperCase()}}</span></i>
                                                </div>
                                                <div v-if="assessment.supervisorAction.toLowerCase() !== 'pending'">
                                                    <i>Date Actioned: <span>{{supervisorActionedDate}}</span></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mt-3">
                                            <ejs-grid
                                                ref="compGrid"
                                                :dataSource="competencies"
                                                
                                                :allowPaging="true"
                                                :allowSorting="true"
                                                :pageSettings="pageSettings"
                                                :toolbar="toolbar"
                                                :searchSettings="search"
                                                :allowExcelExport="false"
                                                :allowPdfExport="false"
                                                :toolbarClick="toolbarClick">
                                                <e-columns>
                                                    <e-column field="index" headerText="#" width="50px"></e-column>
                                                    <e-column field="competencyName" headerText="Competency" width="400"></e-column>
                                                    <e-column field="level" headerText="Competency Level" width="120px"></e-column>
                                                    <e-column width="20px" headerText=""></e-column>
                                                </e-columns>
                                            </ejs-grid>
                                        </div>
                                        <!-- <TableLoading v-show="showLoader"></TableLoading> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                        </div>
                    </div>
                </div>                
            </section>
            <!-- <ProcessAssessmentModal :assessment="assessment"></ProcessAssessmentModal> -->
        </div>
    </MasterLayout>
</template>

<script>
import Vue from "vue";
import MasterLayout from "@/views/Employee/MasterLayout.vue";
import TableLoading from '@/components/TableLoading.vue';
// import ProcessAssessmentModal from '@/components/Modals/Employee/processAssessmentModal.vue';
import configObject from "@/config";
import JQuery from "jquery";
import moment from "moment";
let $ = JQuery;

import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    title: 'Assessment Details',
    components:{
        MasterLayout,
        TableLoading,
        // ProcessAssessmentModal
    },
    provide: {
        grid: [Page, Sort, Toolbar, Search]
    },  
    data(){
        return {
            id: null,
            empId: null,
            comment: null,
            supComment: null,
            assessment: {},
            competencies: [],
            trainingDate: null,
            dateActioned: null,
            uplineActionedDate: null,
            supervisorActionedDate: null,
            showLoader: false,
            isButtonDisabled: false,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["Search"], // "PdfExport", "ExcelExport"],
            search: { operator: "contains", ignoreCase: true },
            isSupervisor: !localStorage.getItem("dcasUserDetails")
            ? false 
            : JSON.parse(localStorage.getItem("dcasUserDetails")).roles.includes('Supervisor') ? true : false,
        }
    },
    mounted() {
        this.id = parseInt(this.$route.query.id);
        this.empId = parseInt(this.$route.query.emp);
        this.loadAssessment();
        
        //this.getCompetencies();
        //this.getEmployeeAssessments();
        
        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created(){
        console.log('Hello Created');
        this.$eventHub.$on("refreshEmployeeAssessment", () => {
            this.loadAssessment();
        });
    },
    methods: {
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.compGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.compGrid.excelExport();
                break;
            }
        },
        refreshGrid(){
            this.$refs.compGrid.refresh();
        },
        loadAssessment(){
            let grid = this.$refs.compGrid;
            this.showLoader = true;
            this.axios
                .get(`${configObject.apiBaseUrl}/Employee/SelfAssessment/${this.empId}?id=${this.id}&cont=false`, configObject.authConfig)
                .then(response => {
                    this.showLoader = false;
                    this.assessment = response.data;
                    this.trainingDate = moment(String(this.assessment.trainingDate)).format('MM-DD-YYYY');
                    let index = 0;
                    this.assessment.competencies.forEach(element => {
                        index++;
                        element.index = index;
                    });
                    this.competencies = this.assessment.competencies;
                    this.dateActioned = moment(String(this.assessment.dateActioned)).format('MM-DD-YYYY');
                    this.uplineActionedDate = moment(String(this.assessment.uplineDateActioned)).format('MM-DD-YYYY');
                    this.supervisorActionedDate = moment(String(this.assessment.supervisorDateActioned)).format('MM-DD-YYYY');
                    this.comment = response.data.uplineComment;

                    //console.log(this.competencies);
                    //grid.ej2Instances.setProperties({ dataSource: this.competencies });
                    //this.refreshGrid();
                })
                .catch((error) => {
                    this.showLoader = false;
                    // this.$toast.open({
                    //     message: "Unable to load self assessments",
                    //     type: "error",
                    //     duration: 4000
                    // });
                    console.log(error);
                });
        },
        processAssessment(action, typ){            
            this.showLoader = true;
            this.isButtonDisabled = true;

            let formData = {};
            formData.id = this.assessment.id;
            formData.comment = typ === 'sup' ? this.assessment.supervisorComment : this.assessment.uplineComment;
            formData.action = action;
            
            let url = typ === 'sup' 
                ? `${configObject.apiBaseUrl}/Employee/SelfAssessment/SupervisorAction` 
                : `${configObject.apiBaseUrl}/Employee/SelfAssessment/UplineAction`;

            console.log(formData);
            let assType = this.assessment.assessmentType === 'Training' ? 'Training' : 'Assessment';
            this.axios.post(url, formData, configObject.authConfig)
                .then(data => {
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    // this.$eventHub.$emit("refreshEmployeeAssessment");
                    this.$toast.open({
                        message: `Employee ${assType} ${action.toUpperCase()} and saved successfully`,
                        type: "success",
                        duration: 5000
                    });
                    this.$router.push({
                        name: 'downline_approvals'
                    });
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    let msg = '';
                    let secs = 5000;
                    console.log(error.response);
                    if(error.response){
                        msg = error.response.data.msg;
                        secs = 10000;
                    }
                    else{
                        msg = "Unable to complete the Employee " + assType + " processing.";
                    }
                    this.$toast.open({
                        message: msg,
                        type: "error",
                        duration: secs
                    });
                    console.log(error);
                });
        },
        approveAssessment(action, typ){
            let err = false;
            if(typ === 'sup'){
                if(this.assessment.supervisorComment === null || this.assessment.supervisorComment.length <= 0){
                    console.log('1: Action: ' + action + '; Type: ' + typ + '; Comment: ' + this.assessment.supervisorComment + '; Comment Len: ');
                    err = true;
                }
                else{
                     err = false;
                }
            }
            else {
                if(this.assessment.uplineComment === null || this.assessment.uplineComment.length <= 0){
                    console.log('2: Action: ' + action + '; Type: ' + typ + '; Comment: ' + this.assessment.uplineComment + '; Comment Len: ');
                    err = true;
                }
                else{
                    err = false;
                }
            }
            console.log('Error: ' + err);
            if(err === false){
                let assType = this.assessment.assessmentType === 'Training' ? 'Training' : 'Assessment';
                let msg = `Are you sure you want to ${action.toUpperCase()} this ${assType}?"`;
                
                let _confirm = confirm(msg);
                if(_confirm === true){
                    this.processAssessment(action, typ);
                }
            }
            else{
                this.$toasted.show("Please enter comment for your Action.", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 5000
                });
            }
        },
        backToPreviousPage(){
            // const goto = JSON.parse(localStorage.getItem('assessmentFrom'))
            // this.$router.push({
            //     name: goto ? goto : 'employee_assessment',
            //     query: {
            //         id: this.empId
            //     }
            // });
            
            this.$router.push({
                name: 'downline_approvals'
            });
        }
    }
}
</script>