<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">

             <div class="welcome_message">
                 <div class="row">
                     <div class="col-7">
                        <h5 class=''>
                            Map Competencies to Employee <span class="greeeting__text">"{{employee.firstname}} {{employee.surname}}"</span>.
                            There are <span class="branches__count"> {{competencies.length}}</span> Competencies available
                        </h5>
                     </div>
                     <div class="col-5 text-right">
                        <div>
                            <button class="creaet_branch_btn default" @click="backToEmployee"><i class="fa fa-arrow-left"></i> Back</button>
                            <button class="creaet_branch_btn" @click="mapCompetenciesToEmployee"
                            :disabled="isButtonDisabled ? true : null"
                                :style="[
                                isButtonDisabled
                                    ? { cursor: 'not-allowed' }
                                    : { cursor: 'pointer' }
                                ]">Map Competencies to Employee
                                <span class="">
                                    <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
                                </span>
                            </button>
                        </div>
                     </div>
                 </div>
             </div>
             <section class="table__section mt-1 pb-5" v-show="competenciesFetched">
                <ejs-grid
                    ref="dataGrid"
                    :datasource=competencies
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column headerText="#"  :template="checkBoxTemplate" width="50px"></e-column>
                        <e-column field="name" headerText="Competency"></e-column>
                        <e-column :template="competencyLevelDropDownTemplate" headerText="Competency Level" width="150px"></e-column>
                        <e-column :template="deletedTemplate" width="120px" headerText="Status"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
            <div class="empty_branch__div mt-3" v-if="!competenciesFetched"> 
                <div class="text-center">
                    <h6 class="text-center"> Please wait while we get competencies</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

var competencyLevelSelectTemplate = Vue.component("competencyLevelSelectTemplate", {
    template:  '<select v-on:change="getLevel()" v-bind:data-cid="`${data.id}`" class="geLev" v-model="data.levelId">' +
                    '<option value="">Select Level</option>' +
                    '<option v-for="(cpLvl, i) in data.levels" v-bind:value="cpLvl.id" v-bind:key="cpLvl.id" ' +
                    '>{{cpLvl.level}}</option>'+
                    
                '</select>',
    //template: '<ejs-button :content="`${data.ShipCountry}`"></ejs-button>',
    data() {
        return {
            data: {},
        };
    },  
    methods: {
        selectAll: function(e) {
            this.$eventHub.$emit("selectAll", e);
        },
        getLevel() {
            console.log('Level Change :: Competency = ' + this.data.id + '; Level = ' + this.data.levelId);
            this.$eventHub.$emit("doLevelSelect", this.data.id, this.data.levelId);
        },
        
    }
});

export default {
    components: {
        MasterLayout,
    },
    provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            employee:{},
            showLoader: false,
            isButtonDisabled: false,
            competenciesFetched: false,
            newMappedReqArray: [],
            competenciesArray: [],
            competencies: [],
            competencyLevels: [],

            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["Search" ], //"ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            // <e-column headerText="#" :headerTemplate="selectAllTemplate" :template="checkBoxTemplate"></e-column>
            checkBoxTemplate: function() {
                return {
                    template: Vue.component("columnTemplate", {
                        template: `<div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="data.selected" v-on:change="competencySelected($event, data.id)" checked />
                            <input type="checkbox" class="custom-control-input" v-bind:id="data.id" v-if="!data.selected" v-on:change="competencySelected($event, data.id)" />
                            <label class="custom-control-label" v-bind:for="data.id"></label>
                        </div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            competencySelected: function(e, id) {
                                this.$eventHub.$emit("competenciesCheck", e, id);
                            }
                        }
                    })
                };
            },
             selectAllTemplate: function() {
                return {
                template: Vue.component("columnTemplate", {
                    template: `<div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="selectAll" v-on:change="_selectAll($event)"/>
                        <label class="custom-control-label" for="selectAll"></label></div>`,
                    data: function() {
                    return {
                        data: {}
                    };
                    },
                    methods: {
                    _selectAll: function(e) {
                        this.$eventHub.$emit("selectAll", e);
                    }
                    }
                })
                };
            },
            competencyLevelDropDownTemplate: function(e) {
                return {
                    template: competencyLevelSelectTemplate
                };
            },
            deletedTemplate: function(){
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>` +
                                    `<div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            }
        }
    },
    mounted() {
        console.log('Second CALL');
        this.getCompetencies();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created(){
        this.getCompetencyLevels();
        this.$eventHub.$on("competenciesCheck", (event, id) => {
            this.createCompetencySelections(event, id);
        });

        this.$eventHub.$on("doLevelSelect", (competencyId, compLvlId) => {
            this.doLevelSelect(competencyId, compLvlId);
        });
         this.$eventHub.$on("selectAll", event => {
            this.selectAll(event);
        });
    },
    methods: {
        selectAll(event) {
            event.preventDefault();
            let _this = this;
            if (event.target.checked) {
                $(":checkbox").each(function() {
                    this.checked = true;
                });
                
                this.competencies.forEach(comp => {
                    this.competenciesArray.push({id: comp.id});
                })
            } else {
                $(":checkbox").each(function() {
                    this.checked = false;
                });
                _this.competenciesArray = [];
            }
        },
        doLevelSelect(competencyId, compLvlId){
            let comp = this.competencies.findIndex(a => a.id === competencyId);
            
            if(comp !== -1){
                if(compLvlId > 0){
                    this.competencies[comp].selected = true;
                    this.competencies[comp].levelId = compLvlId;
                }
                else{
                    this.competencies[comp].selected = false;
                    this.competencies[comp].levelId = 0;
                }
                this.refreshGrid();
            }
        },
        createCompetencySelections(event, id){
            let comp = this.competencies.findIndex(a => a.id === id);
            if(comp !== -1){
                if (event.target.checked){
                    // Selected
                    this.competencies[comp].selected = true;
                    this.refreshGrid();
                }
                else{
                    // Unselected
                    this.competencies[comp].selected = false;
                    this.refreshGrid();
                }
            }
        },
        toolbarClick(args) {
            // switch (args.item.text) {
            //     case "PDF Export":
            //     this.$refs.dataGrid.pdfExport();
            //     break;
            //     case "Excel Export":
            //     this.$refs.dataGrid.excelExport();
            //     break;
            // }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        mapCompetenciesToEmployee(){
            this.showLoader = true;
            this.isButtonDisabled = true;
            let dataArray = [];
            this.competencies.forEach(c => {
                if(c.selected){
                    dataArray.push({
                        "competencyId": c.id,
                        "competencyLevelId": c.levelId
                    })
                }
            });
            if(dataArray.length < 1){
                this.$toast.open({
                    message: "Please select atleast one competency (and its level) before proceeding",
                    type: "error",
                    duration: 4000
                });
                this.showLoader = false;
                this.isButtonDisabled = false;
                return;
            }
            var check = dataArray.findIndex(a => a.competencyId === 0 || a.competencyLevelId === 0);
            if(check >= 0){
                this.$toast.open({
                    message: "Please ensure that competency level is selected for all selected competency(ies)",
                    type: "error",
                    duration: 4000
                });
                this.showLoader = false;
                this.isButtonDisabled = false;
                return;
            }
            let data = {};
            data.employeeId = parseInt(this.$route.query.id);
            data.competencies = dataArray;
            console.log(data);

            let url = `${configObject.apiBaseUrl}/Employee/Competencies/Add`;
            this.axios.post(url, data, configObject.authConfig)
                .then((res) => {
                    console.log(res.data);
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    this.$toast.success("Competencies mapped to designation successfully!");
                    })
                    .catch((error) => {
                        this.isButtonDisabled = false;
                        this.showLoader = false;
                        this.$toast.open({
                            message: "Unable to map competecies to designation",
                            type: "error",
                            duration: 3000
                        });
                        console.log(error);
                });
        },
        getCompetencies() {
            let grid = this.$refs.dataGrid;
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Competency/ForMapToEmployee/${this.$route.query.id}`,
                    configObject.authConfig
                )
                .then(response => {
                    //console.log(response.data);
                    response.data.competencies.forEach(element => {
                        element.levels = this.competencyLevels;
                    });
                    this.employee = response.data.employee;
                    this.competencies = response.data.competencies;
                    this.competenciesFetched = true;
                    console.log(this.selections);
                    grid.ej2Instances.setProperties({ dataSource: this.competencies });
                    this.refreshGrid();
                })
                .catch((error) => {
                    console.log(error);
                });
            
        },
        getCompetencyLevels(){
            this.axios.get(`${configObject.apiBaseUrl}/Competency/Level`, configObject.authConfig)
            .then(response => {
                this.competencyLevels = response.data;
            })
            .catch(error => {
                console.log(error);
                this.$toast.open({
                    message: "Unable to load Competency Levels",
                    type: "error",
                    duration: 4000
                });
            });
        },
        backToEmployee(){
            this.$router.push({
                name: 'employees'
            });
        }
    }
}
</script>

        