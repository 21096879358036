<template>
<modal name="editDivisionModal" transition="pop-out" :width="modalWidth" :height="450">
  <div class="modal__header">
      <h4>Edit Division</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
        <div class="row">
            <div class="col-md-12">
                <div class="input_wrapper" style="width: 100%">
                    <span>Division Name</span>
                    <div class="input___group">
                        <!-- <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" /> -->
                        <input type="text" placeholder="name" class="ml-3" v-model="division.name" />
                    </div>
                </div>
            </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="input_wrapper" style="">
                        <span>Division Objective</span>
                        <div class="">
                            <textarea type="text" placeholder="Enter Division objective" class="" v-model="division.objective" style="height: 115px;"></textarea>
                        </div>
                    </div>
                </div>
            </div>
      </div>
      <div class="text-center mt-2">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Update Division
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 500;
import configObject from "@/config";

export default {
  name: 'editDivisionModal',
  props:  ['division'],
  data () {
    return {
      modalWidth: MODAL_WIDTH,
      isButtonDisabled:  false,
      showLoader: false,
    }
  },

  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

    //console.log(configObject.authConfig);

  },
  methods: {
    closeModal(){
        this.$modal.hide('editDivisionModal');
    },
     validateDetails($event) {
        $event.preventDefault();
        if(!this.division.name ){
            this.$toasted.show("Fill in the missing Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
        }else {
            this.editDivision();
        }
    },
    editDivision() {
        let data = {
            name: this.division.name,
            objective:  this.division.objective,
            id: this.division.id
        };
        console.log(data);
        this.showLoader = true;
        this.isButtonDisabled = true;
        this.axios.put(`${configObject.apiBaseUrl}/Divisions/Edit/${this.division.id}`, data,  configObject.authConfig)
            .then(response => {
                this.showLoader = false;
                this.isButtonDisabled = true;
                console.log(response.data);
                this.closeModal();
                this.$eventHub.$emit("refreshDivisionTable");
                this.$toast.success("Division Updated successfully");
                this.isButtonDisabled = false;
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "unable to edit division",
                        type: "error",
                        duration: 3000
                    });
                    console.log(error);
            });
    },
  }
}
</script>
