<template>
<modal name="competencyDetailsModal" transition="pop-out" :width="modalWidth" :height="500">
  <div class="modal__header">
      <!-- <h4>{{competency.name.substring(0,50)}}... </h4> -->
      <h4>Competency Details</h4>
  </div>
   <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-12">
                <div class="" style="width: 100%">
                    <span>Competency</span>
                    <div class="">
                        <textarea type="text" rows="5" placeholder="competency name" class="ml-3" v-model="competency.name" disabled />
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-12">
                <div class="" style="width: 100%">
                    <span>Competency Groups</span>
                    <div class="">
                        <input type="text" disabled placeholder="competency group" class="ml-3" v-model="competency.group"  />
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <!-- <div class="text-center mt-4">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Update Grade  <i class="fa fa-save ml-2"></i>
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div> -->
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 850;
import configObject from "@/config";

export default {
  name: 'competencyDetailsModal',
  props:  ['competency'],
  data () {
    return {
      modalWidth: MODAL_WIDTH,
      isButtonDisabled:  false,
      showLoader: false,
    }
  },

  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

    console.log(configObject.authConfig);

  },
  methods: {
    changeRoute($routeName) {
        this.$router.push({name: $routeName , query: {
            id: this.branch.id
        }});
    },
    closeCreateBranchModal(){
        this.$modal.hide('editBranchModal');
    },
  }
}
</script>
