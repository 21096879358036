<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">

             <div class="welcome_message">
                 <div class="row">
                     <div class="col-6 text-left geo_header">    
                        <h5 class='greeeting__text'>{{profile.designationName}}</h5> 
                       
                        <div class="btn-group btn-group-sm ml-5 mt-2" role="group">
                            <div type="button" @click="mapCompetencies" class="btn btn-success" title="Map Competencies" style="height: 33px">
                                <i class="fa fa-map-o" aria-hidden="true" ></i>
                            </div>
                            <!-- <a class="btn btn-success" title="Map Competencies" href="/map_competencies_to_designation?id" style="height: 33px">
                                <i class="fa fa-map-o" aria-hidden="true" ></i>
                            </a> -->
                            <div type="button" @click="mapRequirements" class="btn btn-info" title="Map Requirements" style="height: 33px">
                                <i class="fa fa-map-o" aria-hidden="true"></i>
                            </div>
                            <div type="button" @click="mapResponsibilities" class="btn btn-warning" title="Map Responsibilities" style="height: 33px">
                                <i class="fa fa-map-o" aria-hidden="true"></i>
                            </div>
                        </div>
                     </div>
                     <div class="col-6 text-right">
                        <button class="creaet_branch_btn default" @click="goBack"><i class="fa fa-arrow-left"></i> Back</button>
                        <!-- <button class="creaet_branch_btn">Save Designation</button> -->
                     </div>
                 </div>
             </div>

             <div class="description_holder">
                <div class="description_box">
                    <p class="description_title">Job Grade</p>
                    <img src="@/assets/img/grade.png" class="description_image">
                    <p class="description_class">{{profile.gradeName}}</p>
                </div>
                <div class="description_box">
                    <p class="description_title">Branch</p>
                    <img src="@/assets/img/building (1).png" class="description_image">
                    <p class="description_class">{{profile.branchName}}</p>
                </div>
                <div class="description_box">
                    <p class="description_title">Division</p>
                    <img src="@/assets/img/office-desk.png" class="description_image">
                    <p class="description_class">{{profile.divisionName}}</p>
                </div>
                <div class="description_box">
                <p class="description_title">Unit</p>
                <img src="@/assets/img/building_green.png" class="description_image">
                <p class="description_class">{{profile.unitName}}</p>
                </div>
             </div>
             <!-- <div class="description_requirements_holder">
                <div class="description_competency_holder" @click="changeTab('competency')" >
                    <div class="description_competency " :class="[activeTab === 'competency' ? 'activeDesigProfile' : null]">
                        <p class="description_title">Competency</p>
                        <div class="description_number" v-show="activeTab === 'competency'">{{profile.competencyCount}}</div>
                    </div>
                </div>
                <div class="description_competency_holder" @click="changeTab('reqs')" >
                    <div class="description_competency" :class="[activeTab === 'reqs' ? 'activeDesigProfile' : null]">
                        <p class="description_title">Requirements</p>
                        <div class="description_number" v-show="activeTab === 'reqs'">{{profile.requirementCount}}</div>
                    </div>
                </div>
                <div class="description_competency_holder" @click="changeTab('resps')">
                    <div class="description_competency" :class="[activeTab === 'resps' ? 'activeDesigProfile' : null]">
                        <p class="description_title">Requirements</p>
                        <div class="description_number" v-show="activeTab === 'resps'">{{profile.requirementCount}}</div>
                    </div>
                </div>
                <div class="description_competency_holder" @click="changeTab('roles')">
                    <div class="description_competency" :class="[activeTab === 'roles' ? 'activeDesigProfile' : null]">
                        <p class="description_title">Roles</p>
                        <div class="description_number" v-show="activeTab === 'roles'"> {{profile.roleCount}}</div>
                    </div>
                </div>
             </div> -->
              <!-- <section class="table__section mt-1 pb-5" v-show="!showLoader">
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" headerText="#" width="60px"></e-column>
                        <e-column field="designationName"  width="220px" headerText="Designation"></e-column>
                        <e-column field="gradeName" headerText="Job Grade"></e-column>
                        <e-column field="divisionName" headerText="Division"></e-column>
                    </e-columns>
                </ejs-grid>
            </section> -->

            <div class="" style="width: 97%; margin: 0 auto;">
                <b-card no-body>
                    <b-tabs card class="employee_tabs" style="margin: 20px 10px;" fill>
                        <b-tab title="Competencies" active>
                            <b-card-text class="employee-tab-content">
                                <div class="tab-content-body">
                                    <b-card  no-body class="for-panel1">
                                        <ejs-grid                                            
                                            ref="dataGrid"
                                            :created="refreshGrid"
                                            :allowPaging="true"
                                            :allowSorting="true"
                                            :pageSettings="pageSettings"
                                            :toolbar="toolbar"
                                            :searchSettings="search"
                                            :allowExcelExport="true"
                                            :allowPdfExport="true"
                                            :toolbarClick="toolbarClick">
                                            <e-columns>
                                                <e-column field="competency" headerText="Competency" width="800"></e-column>
                                                <e-column field="group" headerText="Competency Group"></e-column>
                                                <!-- <e-column :template="progressBarTemplate" width="150px" headerText="Gap Analysis"></e-column> -->
                                                <e-column width="30px" headerText="Actions"></e-column>
                                            </e-columns>
                                        </ejs-grid>
                                    </b-card>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Job Roles">
                            <b-card-text class="employee-tab-content">
                                <!-- <div class="tab-content-header">
                                    <h4 class="">Job Roles</h4>
                                </div> -->
                                <div class="tab-content-body">
                                    <ejs-grid                                    
                                        ref="jobRolesGrid"
                                        :created="refreshJobRolesGrid"
                                        :allowPaging="true"
                                        :allowSorting="true"
                                        :pageSettings="pageSettings"
                                        :toolbar="toolbar"
                                        :searchSettings="search"
                                        :allowExcelExport="true"
                                        :allowPdfExport="true"
                                        :toolbarClick="toolbarClick">
                                        <e-columns>
                                            <e-column field="index" headerText="#" width="50"></e-column>
                                            <e-column field="task" headerText="Job Roles"></e-column>
                                            <e-column width="30px" headerText="Actions"></e-column>
                                        </e-columns>
                                    </ejs-grid>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Responsibilities">
                            <b-card-text class="employee-tab-content">
                                <!-- <div class="tab-content-header">
                                    <h4 class="">Responsibilities</h4>
                                </div> -->
                                <div class="tab-content-body">
                                    <ejs-grid
                                        ref="jobRespsGrid"
                                        :created="refreshJobRespsGrid"
                                        :allowPaging="true"
                                        :allowSorting="true"
                                        :pageSettings="pageSettings"
                                        :toolbar="toolbar"
                                        :searchSettings="search"
                                        :allowExcelExport="true"
                                        :allowPdfExport="true"
                                        :toolbarClick="toolbarClick">
                                        <e-columns>
                                            <e-column field="index" headerText="#" width="50px"></e-column>
                                            <e-column field="responsibilityName" headerText="Responsibility"></e-column>
                                            <e-column  width="50px" headerText=""></e-column>
                                        </e-columns>
                                    </ejs-grid>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Requirement">
                            <b-card-text class="employee-tab-content">
                                <!-- <div class="tab-content-header">
                                    <h4 class="">Gap Analysis</h4>
                                </div> -->
                                <div class="tab-content-body">
                                    <ejs-grid
                                        ref="jobReqGrid"
                                        :created="refreshJobReqGrid"
                                        :allowPaging="true"
                                        :allowSorting="true"
                                        :pageSettings="pageSettings"
                                        :toolbar="toolbar"
                                        :searchSettings="search"
                                        :allowExcelExport="true"
                                        :allowPdfExport="true"
                                        :toolbarClick="toolbarClick">
                                        <e-columns>
                                            <e-column field="index" headerText="#" width="50px"></e-column>
                                            <e-column field="task" headerText="Requirement"></e-column>
                                            <e-column field="typeName" headerText="Requirement Type"></e-column>
                                            <e-column  width="50px" headerText=""></e-column>
                                        </e-columns>
                                    </ejs-grid>
                                </div>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>

            

             <!-- <div>
                <div class="page_reviews">
                    <div class="page_icon">
                        <img src="@/assets/img/note.png">
                    </div>
                    <div class="page_text">
                        <p>Review and approvals of Well proposals like this review and approval of well proposals like</p>
                    </div>
                    <div class="page_actions_holder">
                        <div class="page_action">
                            <div class="edit_holder">
                                <img src="@/assets/img/edit.png" class="edit_image">
                            </div>
                            <p class="page_blue">Edit</p>
                        </div>
                        <div class="page_action">
                            <div class="application_holder">
                                <img src="@/assets/img/application.png" class="application_image">
                            </div>
                            <p class="page_red">Delete</p>
                        </div>
                    </div>
                </div>
                <div class="page_reviews">
                    <div class="page_icon">
                        <img src="@/assets/img/note.png">
                    </div>
                    <div class="page_text">
                        <p>Review and approvals of Well proposals like this review and approval of well proposals like</p>
                    </div>
                    <div class="page_actions_holder">
                        <div class="page_action">
                            <div class="edit_holder">
                                <img src="@/assets/img/edit.png" class="edit_image">
                            </div>
                            <p class="page_blue">Edit</p>
                        </div>
                        <div class="page_action">
                            <div class="application_holder">
                                <img src="@/assets/img/application.png" class="application_image">
                            </div>
                            <p class="page_red">Delete</p>
                        </div>
                    </div>
                </div>
                <div class="page_reviews">
                    <div class="page_icon">
                        <img src="@/assets/img/note.png">
                    </div>
                    <div class="page_text">
                        <p>Review and approvals of Well proposals like this review and approval of well proposals like</p>
                    </div>
                    <div class="page_actions_holder">
                        <div class="page_action">
                            <div class="edit_holder">
                                <img src="@/assets/img/edit.png" class="edit_image">
                            </div>
                            <p class="page_blue">Edit</p>
                        </div>
                        <div class="page_action">
                            <div class="application_holder">
                                <img src="@/assets/img/application.png" class="application_image">
                            </div>
                            <p class="page_red">Delete</p>
                        </div>
                    </div>
                </div>
                <div class="page_reviews">
                    <div class="page_icon">
                        <img src="@/assets/img/note.png">
                    </div>
                    <div class="page_text">
                        <p>Review and approvals of Well proposals like this review and approval of well proposals like</p>
                    </div>
                    <div class="page_actions_holder">
                        <div class="page_action">
                            <div class="edit_holder">
                                <img src="@/assets/img/edit.png" class="edit_image">
                            </div>
                            <p class="page_blue">Edit</p>
                        </div>
                        <div class="page_action">
                            <div class="application_holder">
                                <img src="@/assets/img/application.png" class="application_image">
                            </div>
                            <p class="page_red">Delete</p>
                        </div>
                    </div>
                </div>
             </div> -->
             
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import configObject from "@/config";

import JQuery from "jquery";
let $ = JQuery;

import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    components: {
        MasterLayout
    },
    data() {
        return  {
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            showLoader: false,
            // jobGrade: null,
            // division: null,
            // branch: null,
            // unit: null,
            profile: {},
            // competencyCount: null,
            // requirementCount: null,
            // responsibilityCount: null,
            // roleCount: null,
            activeTab: "competency"
        }
    },
    provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    methods: {
        changeTab($tab) {
            this.activeTab = $tab;
        },
        mapCompetencies(){
            this.$router.push({
                name: 'map_competencies_to_designation', 
                query: {
                    id: this.$route.query.id,
                    name: this.profile.designationName
                }
            });
        },
        mapResponsibilities(respId){
            this.$router.push({
                name: 'map_responsibilities_to_deisgnation', 
                query: {
                    id: this.$route.query.id,
                    name: this.profile.designationName
                }
            });
        },
        mapRequirements(profileId){
            this.$router.push({
                name: 'map_requirements_to_designation', 
                query: {
                    id: this.$route.query.id,
                    name: this.profile.designationName
                }
            });
        },
        goBack() {
            this.$router.push({
                name: 'designation_profile'
            })
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                this.$refs.dataGrid.pdfExport();
                break;
                case "Excel Export":
                this.$refs.dataGrid.excelExport();
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        refreshJobRolesGrid(){
            this.$refs.jobRolesGrid.refresh();
        },
        refreshJobRespsGrid(){
            this.$refs.jobRespsGrid.refresh();
        },
        refreshJobReqGrid(){
            this.$refs.jobReqGrid.refresh();
        },
        getProfileDetail() {
            this.showLoader = true;
            this.axios
                .get(`${configObject.apiBaseUrl}/Designation/ProfileFull/${this.$route.query.id}`, configObject.authConfig)
                .then(response => {
                    this.showLoader = false;
                    console.log(response.data);
                    this.profile = response.data;

                    // this.designationName = response.data.designationName;
                    // console.log(response.data.designationName);
                    // this.division = response.data.divisionName;
                    // this.branch = response.data.branchName;
                    // this.unit = response.data.unitName;
                    // this.profileDetail = response.data;
                    // this.competencyCount = response.data.competencyCount;
                    // this.requirementCount = response.data.requirementCount;
                    // this.responsibilityCount = response.data.responsibilityCount;
                    // this.roleCount = response.data.roleCount;
                    let _grid = this.$refs.dataGrid;
                    let _jobRolesGrid = this.$refs.jobRolesGrid;
                    let _jobRespsGrid = this.$refs.jobRespsGrid;
                    let _jobReqGrid = this.$refs.jobReqGrid;

                    _grid.ej2Instances.setProperties({ dataSource: this.profile.competencies});
                    _jobRolesGrid.ej2Instances.setProperties({ dataSource: this.profile.roles});
                    _jobRespsGrid.ej2Instances.setProperties({ dataSource: this.profile.responsibilities});
                    _jobReqGrid.ej2Instances.setProperties({ dataSource: this.profile.requirements});
                    this.refreshGrid();
                })
                .catch((error) => {
                    console.log('ERROR::: ' + error);
                    // this.$toast.open({
                    //     message: "Unable to load this designatiion profile ",
                    //     type: "error",
                    //     duration: 3000
                    // });
                });
        }
    },
    mounted() {
        this.getProfileDetail();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
}
</script>