<template>
<modal name="editCompanyModal" transition="pop-out" :width="modalWidth" :height="560">
  <div class="modal__header">
       <h4>Edit {{company.name}}</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-10">
                <div class="input_wrapper" style="width: 90%">
                    <span>Company Name</span>
                    <div class="input___group">
                        <!-- <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" /> -->
                        <input type="text" placeholder="company name" class="ml-3" v-model="company.name" />
                    </div>
                </div>
            </div>
            <div class="col-md-10">
                <div class="input_wrapper" style="width: 90%">
                    <span>Objective</span>
                    <div class="input___group">
                        <!-- <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" /> -->
                        <textarea type="text" placeholder="Objective" class="ml-3" v-model="company.objective"></textarea>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>Street</span>
                    <div class="input___group">
                        <!-- <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" /> -->
                        <input type="text" placeholder="" class="ml-3" v-model="company.streetName" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>City</span>
                    <div class="input___group">
                        <!-- <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" /> -->
                        <input type="text" placeholder="" class="ml-3" v-model="company.city" />
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>State</span>
                    <div class="input___group">
                        <select class="ml-3" v-model="company.state" required @change="stateChanged($event)">
                            <option value="">Select State</option>
                            <option v-for="st in states" v-bind:key="st.name" v-bind:value="st.name">{{st.name}}</option>
                        </select>
                        <!-- <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" /> -->
                        <!-- <input type="text" placeholder="" class="ml-3" v-model="company.state"/> -->
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input_wrapper" style="width: 100%">
                    <span>LGA</span>
                    <div class="">
                        <select class="ml-3" v-model="company.lga" required v-if="stateLgas.length <= 0">
                            <option value="">Select LGA</option>
                            <option v-for="_lga in lgas" v-bind:key="_lga.name" v-bind:value="_lga.name">{{_lga.name}}</option>
                        </select>
                        <select class="ml-3" v-model="company.lga" required v-if="stateLgas.length > 0">
                            <option value="">Select LGA</option>
                            <option v-for="_lga in stateLgas" v-bind:key="_lga.name" v-bind:value="_lga.name">{{_lga.name}}</option>
                        </select>
                        <!-- <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" /> -->
                        <!-- <input type="text" placeholder="" class="ml-3"  v-model="company.lga"/> -->
                    </div>
                </div>
            </div>
        </div>

      </div>
      <div class="text-center mt-2">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[
            isButtonDisabled
                ? { cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            ]">
            Update company
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 800;
import configObject from "@/config";

export default {
  name: 'editCompanyModal',
  props:  ['company'],
  data () {
    return {
        states: [],
        lgas: [],
        stateLgas: [],
        modalWidth: MODAL_WIDTH,
        isButtonDisabled:  false,
        showLoader: false,
    }
  },

  created () {
      console.log('first Call');
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

        this.getStates();
        this.getLgas();
        if(this.company.state && this.states){
            console.log('---> ' + this.company.state);
            console.log(this.states);
            let _id = this.states.find(state => state.name === this.branch.state).id;
            console.log('Loaded with State, pull LGAs' + _id);
            this.getStateLga(_id);
        }
  },
  mounted (){
      console.log('Second Call');
  },
    methods: {
        closeCompanyModal(){
            this.$modal.hide('editCompanyModal');
        },
        validateDetails($event) {
            $event.preventDefault();
            if(!this.company.name || !this.company.objective){
                this.$toasted.show("Fill in the missing Fields", {
                        theme: "bubble",
                        position: "top-center",
                        duration: 2000
                    });
            }else {
                this.editcompany();
            }
        },
        editcompany() {
            let data = {
                name: this.company.name,
                objective: this.company.objective,
                id: this.company.id,
                address: {
                    "streetName": this.company.streetName,
                    "city": this.company.city,
                    "lga": this.company.lga,
                    "state": this.company.state,
                    "id": this.company.addressId
                }
            };
            this.showLoader = true;
            this.isButtonDisabled = true;
            const url = `${configObject.apiBaseUrl}/Company/Edit/${this.company.id}`;
            this.axios.put(url,data,  configObject.authConfig)
                .then(response => {
                    this.showLoader = false;
                    this.isButtonDisabled = false;
                    //console.log(response.data);
                    this.closeCompanyModal();
                    this.$eventHub.$emit("refreshCompanyTable");
                    this.$toast.success("Company Updated successfully");
                    this.street = null;
                        this.company.city = null;
                        this.company.state = null;
                        this.company.lga = null;
                        this.company.streetName = null;
                    })
                    .catch((error) => {
                        this.isButtonDisabled = false;
                        this.showLoader = false;
                        this.$toast.open({
                            message: "unable to edit company",
                            type: "error",
                            duration: 3000
                        });
                        console.log(error);
                });
        },
        getStates(){
            this.axios.get(`${configObject.apiBaseUrl}/Setup/States`, configObject.authConfig)
            .then(resp => {
                this.states = resp.data;
                // this.state = resp.data[0].name
                //console.log("STATES" + JSON.stringify(resp.data));
            });
        },
        getLgas(){
            this.axios.get(`${configObject.apiBaseUrl}/Setup/Lga`, configObject.authConfig)
            .then(resp => {
                this.lgas = resp.data;
                // this.lga = resp.data[0].name
                // this.stateLgas = resp.data[0];
                //console.log(this.stateLgas);
               // console.log("LGAs" + JSON.stringify(resp.data));
            });
        },
        stateChanged(){
            //console.log(this.states);
            let _st = this.states.find(state => state.name === this.company.state);
            
            let _id = _st.id;
            let _stateLgas = [];
            this.lgas.forEach((lga) => {                
                if(lga.stateId === _id){
                    _stateLgas.push(lga);
                }
            });
            this.stateLgas =  _stateLgas;
            //console.log(_stateLgas);
        },
        sortFunc(prop){
            console.log('Sort by ' + prop);
            var sortOrder = 1;
            if(prop[0] === "-") {
                sortOrder = -1;
                prop = prop.substr(1);
            }
            return function (a,b) {
                /* next line works with strings and numbers, 
                * and you may want to customize it to your needs
                */
                var result = (a[prop] < b[prop]) ? -1 : (a[prop] > b[prop]) ? 1 : 0;
                return result * sortOrder;
            }
        },
        getStateLga(_stateId){            
            console.log('Fetching states >><< ' + _stateId);
            this.stateLgas = this.lgas.filter(a => a.stateId === _stateId).sort(this.sortFunc("name"));
            console.log(this.stateLgas);
        },
  }
}
</script>
