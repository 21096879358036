<template>
<modal name="companyDetail" transition="pop-out" :width="modalWidth" :height="700">
  <div class="modal__header">
      <h4>{{company.name}}</h4>
  </div>
  <div class="create__company__section__form mt-5">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-6">
                <div class="login__input" style="width: 95%">
                    <span>Company Name</span>
                    <div class="input___group">
                        <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" />
                        <input type="text" placeholder="company name" class="ml-3" v-model="company.name" disabled="disabled" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="login__input" style="width: 90%">
                    <span>Objective</span>
                    <div class="input___group">
                        <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" />
                        <input type="text" placeholder="Objective" class="ml-3" v-model="company.objective"  />
                    </div>
                </div>
            </div>
        </div>

        <span class="row__wraapper__span">company Information</span>
      </div>
      
     <div class="row__wrapper mt-3">
         <div class="row mt-4 form_row_margin">
            <div class="col-md-6">
                <div class="login__input" style="width: 100%">
                    <span>Street</span>
                    <div class="input___group">
                        <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" />
                        <input type="text" placeholder="eben@dpr.com.ng" class="ml-3" v-model="company.street" disabled="disabled" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="login__input" style="width: 100%">
                    <span>City</span>
                    <div class="input___group">
                        <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" />
                        <input type="text" placeholder="eben@dpr.com.ng" class="ml-3" v-model="company.city" disabled="disabled" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4 form_row_margin">
            <div class="col-md-6">
                <div class="login__input" style="width: 100%">
                    <span>LGA</span>
                    <div class="input___group">
                        <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" />
                        <input type="text" placeholder="eben@dpr.com.ng" class="ml-3"  v-model="company.lga" disabled="disabled" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="login__input" style="width: 100%">
                    <span>State</span>
                    <div class="input___group">
                        <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" />
                        <input type="text" placeholder="eben@dpr.com.ng" class="ml-3" v-model="company.state" disabled="disabled" />
                    </div>
                </div>
            </div>
        </div>
        <span class="row__wraapper__span">company Address</span>
     </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 850;
import configObject from "@/config";

export default {
  name: 'companyDetail',
  props:  ['company'],
  data () {
    return {
      modalWidth: MODAL_WIDTH,
      isButtonDisabled:  false,
      showLoader: false,
    }
  },

  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH

  },
  methods: {
    closeCompanyModal(){
        this.$modal.hide('editCompanyModal');
    },
    validateDetails($event) {
        $event.preventDefault();
        if(!this.company.name || !this.company.objective){
            this.$toasted.show("Fill in the missing Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 2000
                });
        }else {
            this.editcompany();
        }
    },
    editcompany() {
        let data = {
            name: this.company.name,
            objective: this.company.objective,
            id: this.company.id,
            address: {
                "streetName": this.company.street,
                "city": this.company.city,
                "lga": this.company.lga,
                "state": this.company.state,
                "id": this.company.addressId
            }
        };
        this.showLoader = true;
        this.isButtonDisabled = true;
        const url = `${configObject.apiBaseUrl}/Company/Edit/${this.company.id}`;
        this.axios.put(url,data,  configObject.authConfig)
            .then(response => {
                this.showLoader = false;
                this.isButtonDisabled = true;
                console.log(response.data);
                this.closeCompanyModal();
                this.$eventHub.$emit("refreshCompanyTable");
                this.$toast.success("Company Updated successfully");
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "unable to edit company",
                        type: "error",
                        duration: 3000
                    });
                    console.log(error);
            });
    },
  }
}
</script>
