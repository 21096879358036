<template>
    <MasterLayout>
         <div class="employees__assessment__wrapper">

             <div class="welcome_message">
                 <div class="row">
                     <div class="col-8">    
                         <h5 class=''>
                            <span class="greeeting__text">Hi Admin</span>, There are <span class="branches__count">{{requirements.length}}</span> Requirements available
                        </h5>  
                     </div>
                     <div class="col-4 text-right">
                        <div>
                            <button class="creaet_branch_btn" @click="addRequirement">Add Requirement</button>
                        </div>
                     </div>
                 </div>
             </div>
             <section class="table__section mt-1 pb-5" v-show="!showLoader">
                <ejs-grid
                    ref="dataGrid"
                    :created="refreshGrid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :pageSettings="pageSettings"
                    :toolbar="toolbar"
                    :searchSettings="search"
                    :allowExcelExport="true"
                    :allowPdfExport="true"
                    :toolbarClick="toolbarClick">
                    <e-columns>
                        <e-column field="index" headerText="#" width="50px"></e-column>
                        <e-column field="task" headerText="Requirement"></e-column>
                        <e-column field="status" :visible='false' width="80px" headerText="Status"></e-column>
                        <e-column :template="deletedTemplate" headerText="Status" width="100px"></e-column>
                        <e-column :template="actionButtonsTemplate" headerText="Action" width="100px"></e-column>
                    </e-columns>
                </ejs-grid>
            </section>
            <div class="empty_branch__div mt-3" v-if="requirements.length < 1 && !showLoader">
                <div class="text-center">
                    <h6 class="text-center"> You are yet to create a Division</h6>
                    <img src="@/assets/img/document.png" class="mt-3" width="130px" />
                </div>
            </div>
            <TableLoader v-show="showLoader" style="margin: 0 auto; width: 95%"></TableLoader>
            
            <CreateModal></CreateModal>
            <EditModal :requirement="requirement"></EditModal>
         </div>
    </MasterLayout>
</template>
<script>
import Vue from "vue";
import MasterLayout from "@/views/Admin/MasterLayout.vue";
import CreateModal from '@/components/Modals/Requirement/createModal.vue';
import EditModal from '@/components/Modals/Requirement/editModal.vue';
import TableLoader from '@/components/TableLoading.vue';
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;


import {
  Page,
  Sort,
  Toolbar,
  Search,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-vue-grids";

export default {
    components: {
        MasterLayout,
        CreateModal,
        EditModal,
        TableLoader
    },
     provide: {
        grid: [Page, Sort, Toolbar, Search, ExcelExport, PdfExport]
    },
    data() {
        return {
            requirements: [],
            requirement: {},
            showLoader: false,
            pageSettings: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            toolbar: ["ExcelExport", "PdfExport", "Search"],
            search: { operator: "contains", ignoreCase: true },
            actionButtonsTemplate: function() {
                return {
                    template: Vue.component("actionButtonsTemplate", {
                        template:
                        '<div class="btn-group btn-group-sm" role="group">'+
                            '<button type="button" class="btn btn-primary" title="Edit" v-on:click="edit(data.id)">' +
                                '<i class="fa fa-edit" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-danger" title="Delete" v-on:click="deleteReq(data.id)" v-if="data.deleted === false">' +
                                '<i class="fa fa-trash-o" aria-hidden="true"></i>' +
                            '</button>' +
                            '<button type="button" class="btn btn-success" title="Re-Add" v-on:click="_readdeReq(data.id)" v-if="data.deleted === true">' +
                                '<i class="fa fa-plus" aria-hidden="true"></i>' +
                            '</button>' +
                        '</div>',
                        data: function() {
                            return {
                                data: {}
                            };
                        },
                        methods: {
                            deleteReq: function($reqId) {
                                this.$eventHub.$emit("deleteRequirementVM", $reqId, 0);
                            },
                            _readdeReq: function(reqId) {
                                this.$eventHub.$emit("deleteRequirementVM", reqId, 1);
                            },
                            edit: function(id) {                                
                                this.$eventHub.$emit("editRequirement", id);
                            }
                        }
                    })
                };
            },
            deletedTemplate: function() {
                return {
                    template: Vue.component("deletedTemplate", {
                        template: `<div><div v-if="data.deleted === true"><span class="badge badge-danger badge-pill">Deleted</span></div>` +
                                    `<div v-if="data.deleted === false"><span class="badge badge-success badge-pill">Active</span></div></div>`,
                        data: function() {
                            return {
                                data: {}
                            };
                        }
                    })
                }
            }
        }
    },
    mounted() {
        this.getRequirements();

        $(".e-input").keyup(function(e) {
            searchFun(e);
        });
        function searchFun(event) {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            var value = event.target.value;
            grid.search(value);
        }
    },
    created() {
        
        this.$eventHub.$on("refreshRequirementTable", () => {
            this.getRequirements();
        });
        this.$eventHub.$on("deleteRequirementVM", ($reqId, $typ) => {
            this.deleteRequirement($reqId, $typ);
        });
        this.$eventHub.$on("editRequirement", (id) => {
            this.editRequirement(id);
        });
    },
    methods: {
        changeRoute($routeName, $divId) {
            this.$router.push(
                {
                    name: $routeName , 
                    query: {
                        id: $divId
                    }
                }
            )
        },
        toolbarClick(args) {
            switch (args.item.text) {
                case "PDF Export":
                    this.$refs.dataGrid.getColumns()[2].visible = true;
                    this.$refs.dataGrid.getColumns()[3].visible = false;
                    this.$refs.dataGrid.getColumns()[4].visible = false;

                    this.$refs.dataGrid.pdfExport({
                        fileName: 'Requirements.pdf',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  'Requirements',
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
                case "Excel Export":
                    this.$refs.dataGrid.getColumns()[2].visible = true;
                    this.$refs.dataGrid.getColumns()[3].visible = false;
                    this.$refs.dataGrid.getColumns()[4].visible = false;
                    this.$refs.dataGrid.excelExport({
                        fileName: 'Requirements.xlsx',
                        theme: {
                        header: {
                            bold: true,
                            fontColor: "#000000",
                            fontName: 'Calibri',
                            fontSize: 10
                        },
                        record: {
                            fontColor: '#000000',
                            fontName: "Calibri",
                            fontSize: 8
                        }
                        },
                        header: {
                        fromTop:0,
                        height: 130,
                        contents:  [{
                            type: "Text",
                            value:  `Requirements`,
                            position: {x:0, y: 50},
                            style: {fontSize: 20, textAlign: 'center'}
                            }],
                        },
                        footer: {
                            fromBottom: 130,
                            height: 130,
                            contents:  [{
                            type: "Text",
                            value: "Thank You",
                            position: {x:0, y: 50},
                            style: {fontSize: 20}
                            }],
                        
                        }
                    });
                break;
            }
        },
        refreshGrid() {
            this.$refs.dataGrid.refresh();
        },
        addRequirement(){
            this.$modal.show('createModal');
        },
        getRequirements() {
            this.showLoader = true;
            let grid = this.$refs.dataGrid;
            this.axios
                .get(
                    `${configObject.apiBaseUrl}/Requirement`,
                    configObject.authConfig
                )
                .then(response => {
                    this.showLoader = false;
                    let index = 0;
                    response.data.forEach(req => {
                        index++;
                        req.index = index;
                        req.status = req.deleted ? 'deleted' : 'active'
                    });
                    
                    grid.ej2Instances.setProperties({ dataSource: response.data });
                    this.refreshGrid();
                    this.requirements = response.data;
                    console.log(this.requirements);
                })
                .catch((error) => {
                    console.log(error);
                    // this.$toast.open({
                    //     message: "An Error Occured please check your internet connection",
                    //     type: "error",
                    //     duration: 4000
                    // });
                });
        },
        editRequirement(id){
            let req = this.requirements.filter(r => r.id === id)[0];
            this.requirement = req;
            this.$modal.show('editModal');
        },
        deleteRequirement(id, typ){
            let resp = false;
            if(typ === 0){
                resp = confirm("Are you sure you want to delete this Requirement");
            }
            else{
                resp = confirm("Are you sure you want to Re-Add this Requirement");
            }
            if (resp) {
                 this.axios.delete(`${configObject.apiBaseUrl}/Requirement/Delete/${id}`, configObject.authConfig)
                    .then(() => {
                        this.getRequirements();
                        let msg = typ === 0 ? "Requirement Deleted successfully!" :"Requirement Re-Added successfully!";

                        this.$toast.open({
                            message: msg,
                            type: "success",
                            duration: 4000
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        let msg = typ === 0 ? "Unable to Delete Requirement" :"Unable to Re-Add Requirement";
                        this.$toast.open({
                            message: msg,
                            type: "error",
                            duration: 4000
                        });
                    });
            }
        }
    }
}
</script>

        