<template>
    <MasterLayout>
        
        <h4 class="welcome_header" style="font-size:18px; margin-top:50px">Forgot Password</h4>
                  
        <!-- <p class="text-center mt-4">DPR compentency Administrative System</p> -->
        <section class="login__section__Wrapper mt-5">
            <form @submit="validateSignUpForm($event)">
                <div class="login___section__container">
                    <div class="login__input" ref="email_input_div">
                        <span>Username</span>
                        <div class="input___group">
                            <img src="@/assets/img/username-icon.svg" class="ml-3" width="20px" />
                            <input type="email" placeholder="email" v-model="email" class="ml-3" @blur="captureFormEvents($event)" />
                        </div>
                    </div>
                    <div ref="email_error_message" class="error-validation text-center"></div>
                    
                </div>
                <div class="text-center mt-5">
                    <button type="submit" class="login__btn"
                            @click="forgotPassword($event)"
                            :disabled="isButtonDisabled ? true : null"
                            :style="[
                            isButtonDisabled
                                ? { cursor: 'not-allowed' }
                                : { cursor: 'pointer' }
                            ]">Submit
                            <span class="" v-if="showLoader">
                                <img src="@/assets/img/git_loader.gif"  width="25px"/>
                            </span>
                    </button>
                </div>
            </form>
            <div class="text-center mt-3 grey__text" style="cursor:pointer"><a @click="changeRoute('login')">Back to Login</a></div>
            
            <div style="margin: 0 auto: width: 95%">
                <img src="@/assets/img/Group 139.png" />
            </div>
        </section>
    </MasterLayout>
</template>
<script>
    import MasterLayout from "@/views/Accounts/MasterLayout.vue";
    import configObject from "@/config";


export default {
    components:{
        MasterLayout,
    },
    data() {
        return {
             isButtonDisabled: false,
             showLoader: false,
             showPassword: false,
             formIsValid: false,
             email: null,
        }
    },
    created() {       
    },
    methods: {
        validEmail(email) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        },
        forgotPassword($event) {
            $event.preventDefault();
            let dataToSend = {
                userName: this.email,
            };
            this.showLoader = true;
            this.isButtonDisabled = true;
            this.axios.post(`${configObject.apiBaseUrl}/Account/ForgotPassword/${this.email}`)
            .then(response => {
                console.log(response.data);
                this.showLoader = false;
                this.isButtonDisabled = false;
               
                this.$toast.open({
                    type: "success",
                    message: "Forgot Password request sent Successfully",
                    duration: 5000
                });
                this.$router.push({
                    name: "login"
                });
            })
            .catch((error) => {
                console.log(error);
                this.$toast.open({
                    message: "Forgot password request failed",
                    type: "error",
                    duration: 4000
                });
                // this.$toasted.show("Request Failed", {
                //     theme: "bubble",
                //     position: "top-center",
                //     duration: 4000
                // });
                this.isButtonDisabled = false;
                this.showLoader = false;
            });
        },
        validateSignUpForm($event) {
            $event.preventDefault();
            if (!this.email || !this.password) {
                this.$toasted.show("Username & Password fields are required", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 3000
                });
                this.formIsValid = false;
            }
            if (this.formIsValid) {
                this.login();
            }
        },
        captureFormEvents($event) {
            if ($event.target.placeholder === "email") {
                if ($event.target.value === "") {
                    this.$refs.email_error_message.style.display = "block";
                    this.$refs.email_error_message.innerHTML =
                        "Email field cannot be blank";
                    this.$refs.email_input_div.style.border = "1px solid red";
                    this.$refs.email_error_message.style.color = "red";
                    this.formIsValid = false;
                } else {
                    if (this.validEmail($event.target.value)) {
                        this.$refs.email_error_message.style.display = "none";
                        this.$refs.email_error_message.innerHTML = "";
                        this.$refs.email_input_div.style.border =
                            "1px solid rgb(9, 140, 40)";
                        this.formIsValid = true;
                    } else {
                        this.$refs.email_error_message.style.color = "red";
                        this.$refs.email_error_message.innerHTML = "Invalid Email format";
                        this.$refs.email_input_div.style.border = "1px solid red";
                        this.formIsValid = false;
                    }
                }
            }
        },        
        changeRoute($routeName){
            this.$router.push({
                name: $routeName
            })
        },
    }
    
}
</script>
<style>
</style>