<template>
<modal name="createDesignationModal" transition="pop-out" :width="modalWidth" :height="300">
  <div class="modal__header">
      <h4>Add New Designation</h4>
  </div>
  <div class="cas_input">
      <div class="row__wrapper">
          <div class="row">
            <div class="col-md-12">
                <div class="" style="width: 100%">
                    <span>Designation</span>
                    <div class="">
                        <input type="text" v-model="designationName" required />
                    </div>
                </div>
            </div>
        </div>
      </div>
      
      <div class="text-center mt-4">
        <button class="creaet_branch_btn"
            @click="validateDetails($event)"
            :disabled="isButtonDisabled ? true : null"
            :style="[isButtonDisabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' }]">
            Save Designation <i class="fa fa-save"></i>
            <span class="">
                <img src="@/assets/img/git_loader.gif" v-if="showLoader"  width="25px"/>
            </span>
        </button>
      </div>
  </div>
</modal>
</template>
<script>
const MODAL_WIDTH = 400;
import configObject from "@/config";
import JQuery from "jquery";
let $ = JQuery;

export default {
  name: 'createDesignationModal',
  data () {
    return {
        modalWidth: MODAL_WIDTH,
        designationName: null,   
        isButtonDisabled:  false,
        showLoader: false        
    }
  },
  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH    
  },
  mounted(){
  },
  methods: {
      validateDetails($event) {
        $event.preventDefault();
        if(!this.designationName){
            this.$toasted.show("Please complete all Fields", {
                    theme: "bubble",
                    position: "top-center",
                    duration: 4000
                });
        }else {
            this.createDesignation();
        }
    },
    closeCreateDesignationModal(){
        this.$modal.hide('createDesignationModal');
    },
    createDesignation() {
        let dataToSend = {
            designationName: this.designationName
        };
        this.showLoader = true;
        this.isButtonDisabled = true;
        this.axios.post(`${configObject.apiBaseUrl}/Designation/Add`, dataToSend, configObject.authConfig)
            .then(() => {
                this.showLoader = false;
                this.isButtonDisabled = false;
                this.closeCreateDesignationModal();
                this.$eventHub.$emit("refreshDesignationTable");
                this.$toast.success("Designation created successfully");
                this.designationName = null;
                })
                .catch((error) => {
                    this.isButtonDisabled = false;
                    this.showLoader = false;
                    this.$toast.open({
                        message: "unable to create designation",
                        type: "error",
                        duration: 3000
                    });
                    console.log(error);
            });
    },
  }
}

$(function(){
    console.log('JQuery Ready');

    $('.selector').on('change', function(){
        alert('Valeu changed to ' + $(this).val());
    })
})
</script>
